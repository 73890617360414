<template>
  <div style="margin: 2% 3% 0% 3%;">
    <v-card color="border" elevation="3" style="padding: 20px; max-height: 700px; overflow-y: auto;">
      <v-data-table-virtual :headers="display.billPaymentValidationHeaders" :items="display.billPaymentValidationRows">
        <template v-slot:item.localityName="{ item }">
          <v-chip :color="item.localityColor" variant="flat">
            <p :style="{color: item.localityFontColor}">{{ item.localityName }}</p>
          </v-chip>
        </template>
        <template v-slot:item.requestAgentName="{ item }">
          <v-chip :color="item.requestAgentColor" variant="flat">
            <p :style="{color: item.requestAgentFontColor}">{{ item.requestAgentName }}</p>
          </v-chip>
        </template>
        <template v-slot:item.billPaymentValidationRequestDatetime="{ item }">
          {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.billPaymentValidationRequestDatetime) }}
        </template>
        <template v-slot:item.billPaymentValidationAmount="{ item }">
          ₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(item.billPaymentValidationAmount) }}
        </template>
        <template v-slot:item.billPaymentValidationAction="{ item }">
          <div v-if="item.billPaymentValidationLoader">
            <v-progress-circular color="primary" indeterminate></v-progress-circular>
          </div>
          <v-btn v-else @click="approveBillPaymentValidation(item)" dark height="38" color="primary">
            <h3>APROBAR</h3>
          </v-btn>
        </template>
      </v-data-table-virtual>
    </v-card>
    <br><br>
  </div>
</template>

<style scoped>
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BillPaymentValidationView',
  inject: ['$generalFunctions'],

  data: () => ({
    input: {},

    display: 
    {
      billPaymentValidationHeaders: 
      [
        {key: 'billPaymentValidationID', title: 'ID'},
        {key: 'localityName', title: 'SOLICITADO EN'},
        {key: 'requestAgentName', title: 'SOLICITADO POR'},
        {key: 'billPaymentValidationRequestDatetime', title: 'SOLICITADO EL'},
        {key: 'billPaymentValidationPaymentType', title: 'TIPO DE PAGO'},
        {key: 'billPaymentValidationAmount', title: 'MONTO'},
        {key: 'billPaymentValidationAction', title: null}
      ],
      billPaymentValidationRows: []
    },

    navigation: 
    {
      selectBillPaymentValidationFromBillPaymentValidationViewInterval: null
    },

    loader: {},

    localStorage: 
    {
      localityID: null,
      agentID: null
    },

    accessCredential: {}
  }),

  watch: {},

  methods: {
    async approveBillPaymentValidation(billPaymentValidation){
      billPaymentValidation.billPaymentValidationLoader = true;
      const approveBillPaymentValidationRequestQuery = 
      {
        'billPaymentValidationID': billPaymentValidation.billPaymentValidationID,
        'billPaymentValidationApproveAgentID': this.localStorage.agentID
      };
      const approveBillPaymentValidationResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/billPaymentValidation/functions/approveBillPaymentValidation', approveBillPaymentValidationRequestQuery);
      if (approveBillPaymentValidationResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'Se ha aprobado la confirmación de pago exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        await this.selectBillPaymentValidationFromBillPaymentValidationView();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al aprobar la confirmación de pago, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      billPaymentValidation.billPaymentValidationLoader = false;
    },

    async selectBillPaymentValidationFromBillPaymentValidationView(){
      const selectBillPaymentValidationFromBillPaymentValidationViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/billPaymentValidation/functions/selectBillPaymentValidationFromBillPaymentValidationView');
      if (selectBillPaymentValidationFromBillPaymentValidationViewResult.success){
        this.display.billPaymentValidationRows = selectBillPaymentValidationFromBillPaymentValidationViewResult.result.map(billPaymentValidationRow => ({
          ...billPaymentValidationRow,
          billPaymentValidationLoader: false
        }));
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de confirmaciones de pago, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },
    
    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
    }
  },

  async mounted(){
    await this.selectBillPaymentValidationFromBillPaymentValidationView();
    await this.getLocalStorageData();

    this.navigation.selectBillPaymentValidationFromBillPaymentValidationViewInterval = setInterval(async () => {
      await this.selectBillPaymentValidationFromBillPaymentValidationView();
    }, 3000);
  }
});
</script>
