<template>
  <div style="margin: 2% 3% 0% 3%;">
    <div style="display: flex;">
      <v-card color="border" elevation="3" style="padding: 20px; width: 100%;">
        <v-expansion-panels v-model="navigation.searchVisible">
          <v-expansion-panel value="searchVisible">
            <v-expansion-panel-title>
              <p style="font-size: large;">Comparación de productos</p>
              <template v-slot:actions>
                <v-icon icon="mdi-compare-horizontal"></v-icon>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <br>
              <v-select 
                v-model="input.productCompareType" 
                :items="['Por producto', 'Por marca']" 
                @update:model-value="updateProductCompareType()" 
                append-inner-icon="mdi-state-machine" 
                label="Tipo de comparación" 
                variant="solo-filled"
                density="compact"
              ></v-select>
              <div style="display: flex;">
                <v-text-field 
                  v-model="display.startDateFormatted" 
                  @click="navigation.startDatePickerIsVisible = true" 
                  readonly label="Fecha inicial" 
                  append-inner-icon="mdi-calendar" 
                  variant="solo-filled" 
                  density="compact"
                  style="width: 49.5%; margin-right: 0.5%;"
                ></v-text-field>
                <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
                  <v-card style="display: flex;">
                    <v-date-picker v-model="input.startDate" hide-header color="primary" width="100%"></v-date-picker>
                    <v-chip @click="input.startDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
                  </v-card>
                </v-dialog>
                <v-text-field 
                  v-model="display.endDateFormatted" 
                  @click="navigation.endDatePickerIsVisible = true" 
                  readonly 
                  label="Fecha final" 
                  append-inner-icon="mdi-calendar" 
                  variant="solo-filled" 
                  density="compact"
                  style="width: 49.5%; margin-left: 0.5%;"
                  ></v-text-field>
                <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
                  <v-card>
                    <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
                    <v-chip @click="input.endDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
                  </v-card>
                </v-dialog>
              </div>
              <br>
              <div v-if="input.comparedProducts.length == 0" style="width: 100%; padding: 20px; text-align: center;">
                <v-icon @click="appendProductToCompare()" size="40" color="success" dark right style="cursor: pointer;">mdi-plus-circle-outline</v-icon>
              </div>
              <div v-else style="padding: 20px 20px 0px 20px; overflow-x: auto; display: flex;">
                <div v-for="comparedProduct in input.comparedProducts" v-bind:key="comparedProduct">
                  <div style="width: 500px; margin-right: 20px;">
                    <v-autocomplete
                      v-model="comparedProduct.id"
                      :items="input.productCompareType == 'Por producto' ? display.productOptions : display.productBrandOptions"
                      :loading="loader.selectProductBasicInformationForBillView"
                      :disabled="loader.selectProductBasicInformationForBillView"
                      @click:append-inner="removeProductToCompare(comparedProduct)"
                      :item-title="input.productCompareType == 'Por producto' ? 'productAutocompleteTitle' : 'productBrandName'" 
                      :item-value="input.productCompareType == 'Por producto' ? 'productID' : 'productBrandID'" 
                      append-inner-icon="mdi-close"
                      :label="input.productCompareType == 'Por producto' ? 'Código o nombre del producto' : 'Nombre de la marca'"
                      variant="solo-filled"
                      density="compact"
                    ></v-autocomplete>
                  </div>
                </div>
                <v-icon @click="appendProductToCompare()" size="40" color="success" dark right style="cursor: pointer;">mdi-plus-circle-outline</v-icon>
              </div>
              <br>
              <div v-if="loader.compareProduct" style="text-align: center;">
                <br>
                <v-progress-circular color="primary" indeterminate></v-progress-circular>
                <br><br>
              </div>
              <div v-else>
                <v-btn v-if="input.comparedProducts.filter(comparedProduct => comparedProduct.id != null).length > 1" @click="compareProduct()" dark block height="38" color="primary">
                  <h3>COMPARAR</h3>
                  <v-icon style="margin-left: 20px;" dark right>mdi-compare-horizontal</v-icon>
                </v-btn>
                <br>
                <v-btn @click="setDefaultValues()" dark block height="38" color="#b3b3b3">
                  <h3>LIMPIAR BÚSQUEDA</h3>
                  <v-icon style="margin-left: 20px;" dark right>mdi-backspace</v-icon>
                </v-btn>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
      <div v-if="input.productCompareType == 'Por marca' && display.comparedProducts">
        <div v-if="Object.values(display.comparedProducts).flatMap(productBrand => Object.values(productBrand.productSaleByFlavor).flatMap(productFlavor => Object.values(productFlavor.products))).filter(product => product.compared === true).length >= 2" style="margin-left: 50px;">
          <v-tooltip text="Comparar productos seleccionados" location="top">
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props" @click="complexCompareProduct()" size="60" color="success" style="cursor: pointer; margin-left: 5px; position: relative; top: 15px;">mdi-compare-horizontal</v-icon>
              </template>
            </v-tooltip>
        </div>
      </div>
    </div>
    <br><br><br>
    <div v-if="display.comparedProducts">
      <div v-if="input.productCompareType == 'Por producto'">
        <div style="width: 100%; display: flex; justify-content: center;" v-if="display.productCompareChartOptions && display.productCompareChartSeries">
          <apexchart type="pie" width="500" :options="display.productCompareChartOptions" :series="display.productCompareChartSeries"></apexchart>
        </div>
        <br><br>
        <div style="display: flex; overflow-x: auto;">
          <div v-for="comparedProduct in display.comparedProducts" v-bind:key="comparedProduct" style="margin: 20px;">
            <v-card color="border" elevation="3" style="width: 500px; padding: 25px;">
              <div style="display: flex; justify-content: center; gap: 10px; margin-bottom: 20px;">
                <v-tooltip :text="`Ranking de producto${comparedProduct.productRanking == 'NA' ? ' (sin compras registradas)' : ''}`" location="top">
                  <template v-slot:activator="{ props }">
                    <v-chip v-bind="props" color="purple" label style="font-size: larger; font-weight: bold; cursor: pointer;">
                      <v-icon icon="mdi-medal" start></v-icon>
                      <p>{{ comparedProduct.productRanking }}</p>
                    </v-chip>
                  </template>
                </v-tooltip>
                <v-tooltip text="Panel de producto" location="top">
                  <template v-slot:activator="{ props }">
                    <v-chip v-bind="props" v-if="accessCredential['/product']" color="warning" label style="font-size: larger; cursor: pointer;">
                      <v-icon @click="$generalFunctions.default.methods.openNewTab('/product/'+comparedProduct.productID)" icon="mdi-bulletin-board"></v-icon>
                    </v-chip>
                  </template>
                </v-tooltip>
                <v-tooltip text="Tracking de producto" location="top">
                  <template v-slot:activator="{ props }">
                    <v-chip v-bind="props" v-if="accessCredential['/productHistory']" color="indigo" label style="font-size: larger; cursor: pointer;">
                      <v-icon @click="$generalFunctions.default.methods.openNewTab('/productHistory/'+comparedProduct.productID)" icon="mdi-update"></v-icon>
                    </v-chip>
                  </template>
                </v-tooltip>
              </div>
              <div style="width: 100%; text-align: center; margin-top: 10px;">
                <h2>{{ comparedProduct.productName }}</h2>
                <p style="font-size: larger;">{{ comparedProduct.productID }}</p>
              </div>
              <br><br>
              <div style="max-height: 400px; overflow-y: auto;">
                <v-expansion-panels v-model="comparedProduct.lockProductSaleExpansionPanel">
                  <v-expansion-panel value="true">
                    <v-expansion-panel-title>
                      <p style="font-size: larger;">Ventas</p>
                      <template v-slot:actions>
                        <p style="font-size: x-large; font-weight: bold; margin-right: 10px;">{{ Object.values(comparedProduct.productSaleByLocality).reduce((productSaleByLocalityAmount, productSaleByLocality) => productSaleByLocalityAmount + productSaleByLocality.billProductAmount, 0) }}</p>
                        <v-icon icon="mdi-package" color="success"></v-icon>
                      </template>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      <div v-for="productSaleByLocality in Object.values(comparedProduct.productSaleByLocality).sort((a, b) => b.billProductAmount - a.billProductAmount)" v-bind:key="productSaleByLocality" style="margin: 10px;">
                        <div style="display: flex;">
                          <p>{{ productSaleByLocality.localityName }}</p>
                          <v-chip :color="productSaleByLocality.localityColor" variant="flat" style="margin-left: auto; position: relative; top: -5px;">
                            <p :style="{color: productSaleByLocality.localityFontColor}">{{ productSaleByLocality.billProductAmount }}</p>
                          </v-chip>
                        </div>
                      </div>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
                <br>
                <v-expansion-panels v-model="comparedProduct.lockProductChangeExpansionPanel">
                  <v-expansion-panel value="true">
                    <v-expansion-panel-title>
                      <p style="font-size: larger;">Cambios</p>
                      <template v-slot:actions>
                        <p style="font-size: x-large; font-weight: bold; margin-right: 10px;">{{ Object.values(comparedProduct.productChangeByLocality).reduce((productChangeByLocalityAmount, productChangeByLocality) => productChangeByLocalityAmount + productChangeByLocality.productChangeProductAmount, 0) }}</p>
                        <v-icon icon="mdi-alert" color="error"></v-icon>
                      </template>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      <div v-for="productChangeByLocality in Object.values(comparedProduct.productChangeByLocality).sort((a, b) => b.productChangeProductAmount - a.productChangeProductAmount)" v-bind:key="productChangeByLocality" style="margin: 10px;">
                        <div style="display: flex;">
                          <p>{{ productChangeByLocality.localityName }}</p>
                          <v-chip :color="productChangeByLocality.localityColor" variant="flat" style="margin-left: auto; position: relative; top: -5px;">
                            <p :style="{color: productChangeByLocality.localityFontColor}">{{ productChangeByLocality.productChangeProductAmount }}</p>
                          </v-chip>
                        </div>
                      </div>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </v-card>
          </div>
        </div>
      </div>
      <div v-else>
        <div style="width: 100%; display: flex; justify-content: center;" v-if="display.productCompareChartOptions && display.productCompareChartSeries">
          <apexchart type="pie" width="500" :options="display.productCompareChartOptions" :series="display.productCompareChartSeries"></apexchart>
        </div>
        <br><br>
        <div style="display: flex; overflow-x: auto;">
          <div v-for="comparedBrand in display.comparedProducts" v-bind:key="comparedBrand" style="margin: 10px;">
            <v-card color="border" elevation="3" style="width: 500px; padding: 20px;">
              <div style="width: 100%; text-align: center;">
                <h2>{{ comparedBrand.productBrandName }}</h2>
              </div>
              <br>
              <div>
                
              </div>
              <div v-for="productSaleByFlavor in Object.values(comparedBrand.productSaleByFlavor).sort((a, b) => b.billProductAmount - a.billProductAmount)" v-bind:key="productSaleByFlavor" style="margin: 10px;">
                <v-expansion-panels v-model="comparedBrand.lockProductSaleExpansionPanel">
                  <v-expansion-panel value="true">
                    <v-expansion-panel-title>
                      <p style="font-size: larger;">{{ productSaleByFlavor.productFlavorName }}</p>
                      <template v-slot:actions>
                        <p style="font-size: x-large; font-weight: bold; margin-right: 10px;">{{ productSaleByFlavor.billProductAmount }}</p>
                        <v-icon icon="mdi-package" color="success"></v-icon>
                      </template>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      <div v-for="product in productSaleByFlavor.products" v-bind:key="product">
                        <div :style="`display: flex; padding: 5px; border-radius: 10px; ${product.compared ? 'background-color: #93bf8f' : ''}`">
                          <p>{{ product.productName }}</p>
                          <div style="display: flex; margin-left: auto;">
                            <v-chip color="gray" variant="flat" style="position: relative; top: -5px;">
                              <p>{{ product.billProductAmount }}</p>
                            </v-chip>
                            <v-tooltip :text="product.compared ? 'Quitar de la comparación' : 'Llevar a comparación'" location="top">
                              <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" @click="product.compared = !product.compared" size="30" style="cursor: pointer; margin-left: 10px; position: relative; top: -5px;">mdi-compare-horizontal</v-icon>
                              </template>
                            </v-tooltip>
                          </div>
                        </div>
                      </div>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </div>
    <br><br>
  </div>
</template>

<style scoped>
</style>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ProductCompare',
  inject: ['$generalFunctions'],

  data: () => ({
    input: 
    {
      productCompareType: 'Por producto',
      startDate: null,
      endDate: null,
      comparedProducts: []
    },

    display: 
    {
      productCompareTypeOptions: ['Por producto', 'Por marca'],
      startDateFormatted: null,
      endDateFormatted: null,
      productOptions: [],
      productBrandOptions: [],
      comparedProducts: null,
      productCompareChartOptions: null,
      productCompareChartSeries: null
    },

    navigation: 
    {
      searchVisible: 'searchVisible',
      startDatePickerIsVisible: false,
      endDatePickerIsVisible: false
    },

    loader:
    {
      compareProduct: false,
      selectProductBasicInformationForBillView: false,
      selectProductSearchParameters: false
    },

    localStorage: 
    {
      localityID: null,
      agentID: null
    },
    accessCredential: {}
  }),

  watch: {
    'input.startDate': function() {
      this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB');
      this.navigation.startDatePickerIsVisible = false;
      this.input.comparedProducts.length = 0;
      this.display.comparedProducts = null;
      this.display.productCompareChartOptions = null;
      this.display.productCompareChartSeries = null;
    },
    'input.endDate': function() {
      this.display.endDateFormatted = new Date(this.input.endDate).toLocaleDateString('en-GB');
      this.navigation.endDatePickerIsVisible = false;
      this.input.comparedProducts.length = 0;
      this.display.comparedProducts = null;
      this.display.productCompareChartOptions = null;
      this.display.productCompareChartSeries = null;
    }
  },

  methods: {
    async generateProductCompareChart(){
      this.display.productCompareChartSeries = Object.values(this.display.comparedProducts).map(comparedProduct => Object.values(comparedProduct.productSaleByLocality).reduce((productSaleByLocalityAmount, productSaleByLocality) => productSaleByLocalityAmount + productSaleByLocality.billProductAmount, 0));
      this.display.productCompareChartOptions = 
      {
        chart: 
        {
          width: 500,
          type: 'pie',
        },
        labels: Object.values(this.display.comparedProducts).map(comparedProduct => comparedProduct.productName)
      };
    },

    async generateProductBrandChart(){
      this.display.productCompareChartSeries = Object.values(this.display.comparedProducts).map(comparedBrand => Object.values(comparedBrand.productSaleByFlavor).reduce((productSaleByFlavorAmount, productSaleByFlavor) => productSaleByFlavorAmount + productSaleByFlavor.billProductAmount, 0));
      this.display.productCompareChartOptions = 
      {
        chart: 
        {
          width: 500,
          type: 'pie',
        },
        labels: Object.values(this.display.comparedProducts).map(comparedBrand => comparedBrand.productBrandName)
      };
    },

    async complexCompareProduct(){
      const comparedProducts = [];
      Object.values(this.display.comparedProducts).forEach((productBrand) => {
        Object.values(productBrand.productSaleByFlavor).forEach((productFlavor) => {
          Object.values(productFlavor.products).forEach((product) => {
            if (product.compared) {
              comparedProducts.push({'id': product.productID, 'productToCompareID': Math.floor(1000 + Math.random() * 9000)});
            }
          });
        });
      });
      this.loader.compareProduct = true;
      this.input.productCompareType = 'Por producto';
      this.input.comparedProducts = comparedProducts;
      this.display.comparedProducts = null;
      const compareProductRequestQuery = 
      {
        'productCompareType': 'Por producto',
        'startDate': this.input.startDate,
        'endDate': this.input.endDate,
        'comparedProducts': comparedProducts
      };
      const compareProductResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/compareProduct', compareProductRequestQuery);
      if (compareProductResult.success){
        this.display.comparedProducts = compareProductResult.result;
        Object.values(this.display.comparedProducts).forEach(comparedProduct => {
          comparedProduct.lockProductSaleExpansionPanel = Object.values(comparedProduct.productSaleByLocality).length != 0 ? 'true' : '';
          comparedProduct.lockProductChangeExpansionPanel = Object.values(comparedProduct.productChangeByLocality).length != 0 ? 'true' : '';
        });
        await this.generateProductCompareChart();
        this.navigation.searchVisible = '';
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al realizar la comparación, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.compareProduct = false;
    },

    async compareProduct(){
      this.loader.compareProduct = true;
      this.display.comparedProducts = null;
      this.input.comparedProducts = this.input.comparedProducts.filter(comparedProduct => comparedProduct.id != null);
      const compareProductRequestQuery = 
      {
        'productCompareType': this.input.productCompareType,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate,
        'comparedProducts': this.input.comparedProducts.map(comparedProduct => ({'id': comparedProduct.id}))
      };
      const compareProductResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/compareProduct', compareProductRequestQuery);
      if (compareProductResult.success){
        this.display.comparedProducts = compareProductResult.result;
        if (this.input.productCompareType == 'Por producto'){
          Object.values(this.display.comparedProducts).forEach(comparedProduct => {
            comparedProduct.lockProductSaleExpansionPanel = Object.values(comparedProduct.productSaleByLocality).length != 0 ? 'true' : '';
            comparedProduct.lockProductChangeExpansionPanel = Object.values(comparedProduct.productChangeByLocality).length != 0 ? 'true' : '';
          });
          await this.generateProductCompareChart();
        } else {
          Object.values(this.display.comparedProducts).forEach(comparedBrand => {
            comparedBrand.lockProductSaleExpansionPanel = Object.values(comparedBrand.productSaleByFlavor).length != 0 ? 'true' : '';
            Object.values(comparedBrand.productSaleByFlavor).forEach(productSaleByFlavor => {
              Object.values(productSaleByFlavor.products).forEach(product => {
                product.compared = false;
              });
            });
          });
          await this.generateProductBrandChart();
        }
        this.navigation.searchVisible = '';
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al realizar la comparación, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.compareProduct = false;
    },

    appendProductToCompare(){
      this.input.comparedProducts.push({'id': null, 'productToCompareID': Math.floor(1000 + Math.random() * 9000)});
    },

    removeProductToCompare(comparedProductToRemove){
      this.input.comparedProducts = this.input.comparedProducts.filter(comparedProduct => comparedProduct.productToCompareID != comparedProductToRemove.productToCompareID);
      this.display.comparedProducts = null;
    },

    async selectProductBasicInformationForBillView(){
      this.loader.selectProductBasicInformationForBillView = true;
      const selectProductBasicInformationForBillViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductBasicInformationForBillViewByLocalityID', {'localityID': this.localStorage.localityID});
      if (selectProductBasicInformationForBillViewResult.success){
        this.display.productOptions = selectProductBasicInformationForBillViewResult.result.map(productOption => ({
          'productID': productOption.i,
          'productSKU': productOption.s,
          'productName': productOption.n,
          'productStockAmount': productOption.a,
          'productAutocompleteTitle': productOption.s + ' - ' + productOption.n
        }));
        this.loader.selectProductBasicInformationForBillView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductSearchParameters(){
      this.loader.selectProductSearchParameters = true;
      const selectProductSearchParametersResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductSearchParameters');
      if (selectProductSearchParametersResult.success){
        this.display.productBrandOptions = selectProductSearchParametersResult.result.productBrand;
        this.loader.selectProductSearchParameters = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de características de los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    updateProductCompareType(){
      this.input.comparedProducts.length = 0;
      this.display.comparedProducts = null;
      this.display.productCompareChartOptions = null;
      this.display.productCompareChartSeries = null;
    },

    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    },

    async setDefaultValues(){
      this.input.productCompareType = 'Por producto';
      this.input.startDate = new Date();
      this.input.startDate.setDate(1);
      this.input.endDate = new Date();
      this.input.comparedProducts.length = 0;
      this.display.startDateFormatted = null;
      this.display.endDateFormatted = null;
      this.display.comparedProducts = null;
      this.display.productCompareChartOptions = null;
      this.display.productCompareChartSeries = null;
      this.navigation.startDatePickerIsVisible = false;
      this.navigation.endDatePickerIsVisible = false;
      this.loader.compareProduct = false;
    }
  },
  
  async mounted(){
    await this.setDefaultValues();
    await this.getLocalStorageData();
    this.selectProductBasicInformationForBillView();
    this.selectProductSearchParameters();
  }
});
</script>
