<template>
  <v-dialog v-model="navigation.productInAndOutProductsDialogIsVisible" :persistent="false" width="auto">
    <v-card color="background" elevation="3" width="900" style="padding: 20px;">
      <p style="font-size: larger;"><strong>Cantidad de piezas movidas: </strong>{{ display.productInAndOut.productInAndOutProductsTotalAmount }}</p>
      <br>
      <v-data-table-virtual :headers="display.productHeaders" :items="display.productInAndOut.productInAndOutProducts" style="max-height: 600px; overflow-y: auto;">
        <template v-slot:item.productName="{ item }">
          <p>{{ item.productName }}</p>
          <p><strong>{{ item.productID }}</strong></p>
        </template>
        <template v-slot:item.productInAndOutProductAmount="{ item }">
          <v-chip v-if="display.productInAndOut.productInAndOutType == 'Entrada'" color="#97cc91" variant="flat" style="margin: 0px 5px 0px 5px;">
            <p style="color: white;">+ {{ item.productInAndOutProductAmount }}</p>
          </v-chip>
          <v-chip v-else color="#ff6e69" variant="flat" style="margin: 0px 5px 0px 5px;">
            <p style="color: white;">- {{ item.productInAndOutProductAmount }}</p>
          </v-chip>
        </template>
        <template v-slot:item.productInAndOutProductResultAmount="{ item }">
          <p v-if="display.productInAndOut.productInAndOutType == 'Entrada'">{{ item.productInAndOutProductCurrentAmount + item.productInAndOutProductAmount }}</p>
          <p v-else>{{ item.productInAndOutProductCurrentAmount - item.productInAndOutProductAmount }}</p>
        </template>
        <template v-slot:item.productInAndOutProductNote="{ item }">
          {{ item.productInAndOutProductNote || 'Sin nota' }}
        </template>
      </v-data-table-virtual>
    </v-card>
  </v-dialog>
  <div style="margin: 2% 3% 0% 3%;">
    <v-card color="border" elevation="3" style="padding: 20px;">
      <v-expansion-panels v-model="navigation.searchVisible">
        <v-expansion-panel value="searchVisible">
          <v-expansion-panel-title>
            <p style="font-size: large;">Búsqueda de movimientos de producto</p>
            <template v-slot:actions>
              <v-icon icon="mdi-magnify"></v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <br>
            <v-tooltip open-delay="2000" location="top">
              <template v-slot:activator="{ props }">
                <v-text-field 
                  v-bind="props"
                  @keydown.enter.prevent="selectProductInAndOutForProductInAndOutView()"
                  v-model="input.productInAndOutID" 
                  :append-inner-icon="input.productInAndOutID ? 'mdi-barcode-off' : 'mdi-barcode'" 
                  @click:append-inner="input.productInAndOutID = null" 
                  label="Buscar por identificador interno" 
                  variant="solo-filled" 
                  density="compact"
                ></v-text-field>
              </template>
              <span style="max-width: 400px; display: block;">El identificador interno es el consecutivo único del movimiento de producto que se genera de forma automática a nivel interno. Si se coloca un identificador interno, se buscará el único movimiento de producto que coincida con el mismo. Al presionar el ícono de la derecha se limpia el identificador interno colocado</span>
            </v-tooltip>
            <div style="display: flex;">
              <div style="width: 49.5%; margin-right: 0.5%;">
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-autocomplete 
                      v-bind="props"
                      v-model="input.productInAndOutGeneratedLocalityID" 
                      :append-inner-icon="input.productInAndOutGeneratedLocalityID.length == 0 ? 'mdi-store' : 'mdi-store-off'" 
                      @click:append-inner="input.productInAndOutGeneratedLocalityID.length = 0; this.blurInput('productInAndOutGeneratedLocalityID')" 
                      :items="display.localityOptions" 
                      :loading="loader.selectLocalityIDAndLocalityNameByLocalityIsActive" 
                      :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive" 
                      multiple
                      chips
                      closable-chips
                      color="primary"
                      ref="productInAndOutGeneratedLocalityID"
                      item-title="localityName" 
                      item-value="localityID" 
                      label="Filtrar por localidad generadora" 
                      placeholder="Todas las localidades"
                      variant="solo-filled" 
                      density="compact"
                    ></v-autocomplete>
                  </template>
                  <span style="max-width: 400px; display: block;">La localidad generadora es el lugar desde donde se realizó el movimiento de producto. Al seleccionar una o varias localidades, la lista de agentes generadores se actualiza con la lista de los agentes generadores asignados a la o las localidades seleccionadas. Este espacio permite selección múltiple para filtrar por varias localidades en paralelo. Si ninguna localidad es seleccionada, se buscarán todas los movimientos de producto sin importar la localidad generadora. Al presionar el ícono de la derecha se limpian las localidades seleccionadas</span>
                </v-tooltip>
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-autocomplete 
                      v-bind="props"
                      v-model="input.productInAndOutAgentID" 
                      :append-inner-icon="input.productInAndOutAgentID.length == 0 ? 'mdi-account' : 'mdi-account-off'" 
                      @click:append-inner="input.productInAndOutAgentID.length = 0; this.blurInput('productInAndOutAgentID')" 
                      :items="display.agentOptions" 
                      :loading="loader.selectAgentByAgentLocalityIDAndByAgentIsActive" 
                      :disabled="loader.selectAgentByAgentLocalityIDAndByAgentIsActive" 
                      multiple
                      chips
                      closable-chips
                      color="primary"
                      ref="productInAndOutAgentID"
                      item-title="agentName" 
                      item-value="agentID" 
                      label="Filtrar por agente generador"
                      placeholder="Todos los agentes generadores" 
                      variant="solo-filled" 
                      density="compact"
                    ></v-autocomplete>
                  </template>
                  <span style="max-width: 400px; display: block;">El agente generador es el colaborador que realizó el movimiento de producto. Este espacio permite selección múltiple para filtrar por varios agentes en paralelo. Si ningún agente es seleccionado, se buscarán todas las facturas sin importar el agente. Al presionar el ícono de la derecha se limpian los agentes seleccionados</span>
                </v-tooltip>
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-autocomplete
                      v-bind="props"
                      v-model="input.productInAndOutLocalityID" 
                      :append-inner-icon="input.productInAndOutLocalityID.length == 0 ? 'mdi-store' : 'mdi-store-off'" 
                      @click:append-inner="input.productInAndOutLocalityID.length = 0; this.blurInput('productInAndOutLocalityID')" 
                      :items="display.localityOptions" :loading="loader.selectLocalityIDAndLocalityNameByLocalityIsActive" 
                      :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive" 
                      multiple
                      chips
                      closable-chips
                      color="primary"
                      ref="productInAndOutLocalityID"
                      item-title="localityName" 
                      item-value="localityID" 
                      label="Filtrar por localidad afectada" 
                      placeholder="Todas las localidades afectadas"
                      variant="solo-filled" 
                      density="compact"
                    ></v-autocomplete>
                  </template>
                  <span style="max-width: 400px; display: block;">La localidad afectada es el lugar que sufrió una modificación en su inventario producto del movimiento de producto. Este espacio permite selección múltiple para filtrar por varias localidades en paralelo. Si ninguna localidad es seleccionada, se buscarán todas los movimientos de producto sin importar la localidad afectada. Al presionar el ícono de la derecha se limpian las localidades seleccionadas</span>
                </v-tooltip>
              </div>
              <div style="width: 49.5%; margin-left: 0.5%;">
                <v-text-field 
                  v-model="display.startDateFormatted" 
                  @click="navigation.startDatePickerIsVisible = true" 
                  readonly 
                  label="Filtrar por fecha inicial" 
                  append-inner-icon="mdi-calendar-start" 
                  variant="solo-filled" 
                  density="compact"
                ></v-text-field>
                <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
                  <v-card style="display: flex;">
                    <v-date-picker v-model="input.startDate" hide-header color="primary" width="100%"></v-date-picker>
                    <v-chip @click="input.startDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
                  </v-card>
                </v-dialog>
                <v-text-field 
                  v-model="display.endDateFormatted" 
                  @click="navigation.endDatePickerIsVisible = true" 
                  readonly 
                  label="Filtrar por fecha final" 
                  append-inner-icon="mdi-calendar-end" 
                  variant="solo-filled" 
                  density="compact"
                ></v-text-field>
                <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
                  <v-card>
                    <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
                    <v-chip @click="input.endDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
                  </v-card>
                </v-dialog>
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-select 
                      v-bind="props"
                      v-model="input.productInAndOutType" 
                      :items="display.productInAndOutTypeOptions" 
                      item-title="title" 
                      item-value="value" 
                      :append-inner-icon="input.productInAndOutType == null ? 'mdi-sync' : 'mdi-sync-off'" 
                      @click:append-inner="input.productInAndOutType = null; this.blurInput('productInAndOutType')" 
                      ref="productInAndOutType"
                      label="Filtrar por tipo de movimiento" 
                      variant="solo-filled" 
                      density="compact"
                    ></v-select>
                  </template>
                  <span style="max-width: 400px; display: block;">El tipo de movimiento indica si se trata de una entrada o una salida de producto. Si ningún tipo de movimiento es seleccionado, se buscarán todas los movimientos de producto sin importar si se trata de una entrada o salida de producto. Al presionar el ícono de la derecha se limpian el tipo de movimiento seleccionado</span>
                </v-tooltip>
              </div>
            </div>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-title>
                  Búsqueda avanzada por producto
                  <template v-slot:actions>
                    <v-icon icon="mdi-magnify"></v-icon>
                  </template>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <br>
                  <v-tooltip open-delay="2000" location="top">
                    <template v-slot:activator="{ props }">
                      <v-autocomplete 
                        v-bind="props"
                        v-model="input.selectedProductID" 
                        v-model:search="input.searchedProductID"
                        :append-inner-icon="input.selectedProductID ? 'mdi-alphabetical-variant-off' : 'mdi-alphabetical-variant'" 
                        @click:append-inner="cleanProductIDInput()" 
                        :items="display.productOptions" 
                        :loading="loader.selectProductBasicBasicInformationForBillsView" 
                        :disabled="loader.selectProductBasicBasicInformationForBillsView" 
                        @keydown.enter.prevent="selectProductByScanner(input.searchedProductID)"
                        ref="productID" 
                        item-title="productAutocompleteTitle" 
                        item-value="productID" 
                        label="Filtrar por producto" 
                        placeholder="Todos los productos" 
                        variant="solo-filled" 
                        density="compact"
                      ></v-autocomplete>
                    </template>
                    <span style="max-width: 400px; display: block;">Este espacio permite la búsqueda o escaneo de un producto. Si ningún producto es seleccionado, se buscarán todos los movimientos de producto sin importar los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpia el producto seleccionado</span>
                  </v-tooltip>   
                  <div style="display: flex;">
                    <div style="width: 49.5%; margin-right: 0.5%;">
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete 
                            v-bind="props"
                            v-model="input.productCategoryID" 
                            :append-inner-icon="input.productCategoryID.length == 0 ? 'mdi-cog' : 'mdi-cog-off'" 
                            @click:append-inner="input.productCategoryID.length = 0; this.blurInput('productCategoryID')" 
                            :items="display.productCategoryOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            closable-chips
                            color="primary"
                            ref="productCategoryID"
                            item-title="productCategoryName" 
                            item-value="productCategoryID" 
                            label="Filtrar por categoría del producto" 
                            placeholder="Todas las categorías del producto" 
                            variant="solo-filled" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">La categoría del producto hace referencia al primer nivel de categorización de un producto. Este espacio permite selección múltiple para filtrar por varias categorías de producto en paralelo. Si ninguna categoría de producto es seleccionada, se buscarán todos los movimientos de producto sin importar las categorías de los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpian las categorías de producto seleccionadas</span>
                      </v-tooltip>
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete 
                            v-bind="props"
                            v-model="input.productBrandID" 
                            :append-inner-icon="input.productBrandID.length == 0 ? 'mdi-briefcase-variant' : 'mdi-briefcase-variant-off'" 
                            @click:append-inner="input.productBrandID.length = 0; this.blurInput('productBrandID')" 
                            :items="display.productBrandOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            closable-chips
                            color="primary"
                            ref="productBrandID"
                            item-title="productBrandName" 
                            item-value="productBrandID" 
                            label="Filtrar por marca del producto" 
                            placeholder="Todas las marcas del producto" 
                            variant="solo-filled" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">La marca del producto hace referencia al segundo nivel de categorización de un producto. Este espacio permite selección múltiple para filtrar por varias marcas de producto en paralelo. Si ninguna marca de producto es seleccionada, se buscarán todos los movimientos de producto sin importar las marcas de los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpian las marcas de producto seleccionadas</span>
                      </v-tooltip>
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete 
                            v-bind="props"
                            v-model="input.productModelID" 
                            :append-inner-icon="input.productModelID.length == 0 ? 'mdi-source-branch' : 'mdi-source-branch-remove'" 
                            @click:append-inner="input.productModelID.length = 0; this.blurInput('productModelID')" 
                            :items="display.productModelOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            closable-chips
                            color="primary"
                            ref="productModelID"
                            item-title="productModelName" 
                            item-value="productModelID" 
                            label="Filtrar por modelo del producto" 
                            placeholder="Todos los modelos del producto" 
                            variant="solo-filled" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">El modelo del producto hace referencia al tercer nivel de categorización de un producto. Este espacio permite selección múltiple para filtrar por varios modelos de producto en paralelo. Si ningún modelo de producto es seleccionado, se buscarán todos los movimientos sin importar los modelos de los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpian los modelos de producto seleccionados</span>
                      </v-tooltip>
                    </div>
                    <div style="width: 49.5%; margin-left: 0.5%;">
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete 
                            v-bind="props"
                            v-model="input.productFlavorID" 
                            :append-inner-icon="input.productFlavorID.length == 0 ? 'mdi-water' : 'mdi-water-off'" 
                            @click:append-inner="input.productFlavorID.length = 0; this.blurInput('productFlavorID')" 
                            :items="display.productFlavorOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            closable-chips
                            color="primary"
                            ref="productFlavorID"
                            item-title="productFlavorName" 
                            item-value="productFlavorID" 
                            label="Filtrar por sabor del producto" 
                            placeholder="Todos los sabores del producto" 
                            variant="solo-filled" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">El sabor del producto hace referencia al primer tipo de categorización opcional de un producto. Este espacio permite selección múltiple para filtrar por varios sabores de producto en paralelo. Si ningún sabor de producto es seleccionado, se buscarán todas los movimientos de producto sin importar los sabores de los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpian los sabores de producto seleccionados</span>
                      </v-tooltip>
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete 
                            v-bind="props"
                            v-model="input.productNicotineLevelID" 
                            :append-inner-icon="input.productNicotineLevelID.length == 0 ? 'mdi-cloud-outline' : 'mdi-cloud-off-outline'" 
                            @click:append-inner="input.productNicotineLevelID.length = 0; this.blurInput('productNicotineLevelID')" 
                            :items="display.productNicotineLevelOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            closable-chips
                            color="primary"
                            ref="productNicotineLevelID"
                            item-title="productNicotineLevelName" 
                            item-value="productNicotineLevelID" 
                            label="Filtrar por graduación de nicotina del producto"  
                            placeholder="Todas las graduaciones de nicotina del producto" 
                            variant="solo-filled" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">La graduación de nicotina del producto hace referencia al segundo tipo de categorización opcional de un producto. Este espacio permite selección múltiple para filtrar por varias graduaciones de nicotina de producto en paralelo. Si ninguna graduación de nicotina de producto es seleccionada, se buscarán todos los movimientos de producto sin importar las graduaciones de nicotina de los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpian las graduaciones de nicotina de producto seleccionadas</span>
                      </v-tooltip>
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete
                            v-bind="props" 
                            v-model="input.productSizeID" 
                            :append-inner-icon="input.productSizeID.length == 0 ? 'mdi-signal' : 'mdi-signal-off'" 
                            @click:append-inner="input.productSizeID.length = 0; this.blurInput('productSizeID')" 
                            :items="display.productSizeOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            closable-chips
                            color="primary"
                            ref="productSizeID"
                            item-title="productSizeName" 
                            item-value="productSizeID" 
                            label="Filtrar por tamaño del producto" 
                            variant="solo-filled" 
                            placeholder="Todos los tamaños del producto" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">El tamaño del producto hace referencia al tercer tipo de categorización opcional de un producto. Este espacio permite selección múltiple para filtrar por varios tamaños de producto en paralelo. Si ningún tamaño de producto es seleccionado, se buscarán todos los movimientos de producto sin importar los tamaños de los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpian los tamaños de producto seleccionados</span>
                      </v-tooltip>
                    </div>
                  </div>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
            <br><br>
            <div v-if="loader.selectProductInAndOutForProductInAndOutView" style="text-align: center;">
              <br>
              <v-progress-circular color="primary" indeterminate></v-progress-circular>
              <br><br>
            </div>
            <div v-else>
              <v-btn @click="selectProductInAndOutForProductInAndOutView()" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive || loader.selectAgentByAgentLocalityIDAndByAgentIsActive || loader.selectProductBasicBasicInformationForBillsView || loader.selectProductSearchParameters || loader.selectProductInAndOutForProductInAndOutView" dark block height="38" color="primary">
                <h3>BUSCAR</h3>
                <v-icon style="margin-left: 20px;" dark right>mdi-magnify</v-icon>
              </v-btn>
              <br>
              <v-tooltip open-delay="2000" location="top">
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" @click="setDefaultValues()" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive || loader.selectAgentByAgentLocalityIDAndByAgentIsActive || loader.selectProductBasicBasicInformationForBillsView || loader.selectProductSearchParameters || loader.selectProductInAndOutForProductInAndOutView" dark block height="38" color="#b3b3b3">
                    <h3>LIMPIAR BÚSQUEDA</h3>
                    <v-icon style="margin-left: 20px;" dark right>mdi-backspace</v-icon>
                  </v-btn>
                </template>
                <span style="max-width: 400px; display: block;">Al presionar este botón, se limpiarán los resultados de la búsqueda actual, y se reestablecerán todos los filtros de búsqueda a sus valores por defecto</span>
              </v-tooltip>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <br><br>
    <v-card v-if="(display.productInAndOutRows.length > 0) || (input.productInAndOutSearchQuery != null)" color="border" elevation="3" style="padding: 20px;">
      <div style="display: flex;">
        <div style="width: 49.5%; margin-right: 0.5%;">
          <v-tooltip open-delay="2000" location="top">
            <template v-slot:activator="{ props }">
              <v-text-field
                v-bind="props" 
                v-model="input.productInAndOutSearchQuery" 
                :append-inner-icon="(input.productInAndOutSearchQuery == null) || (input.productInAndOutSearchQuery.trim() == '')  ? 'mdi-magnify' : 'mdi-magnify-remove-outline'" 
                @click:append-inner="input.productInAndOutSearchQuery = null" 
                label="Búsqueda rápida" 
                variant="solo-filled" 
                density="compact"
              ></v-text-field>
            </template>
            <span style="max-width: 400px; display: block;">La búsqueda rápida permite buscar en toda la lista de movimientos de producto por localidad generadora, agente generador, localidad afectada, tipo, cantidad de unidades movidas, motivo, nota, nombre de producto y código de producto. Se buscan todas los movimientos de producto que coincidan parcial o completamente con el criterio de búsqueda colocado. Al presionar el ícono de la derecha se elimina el criterio de búsqueda colocado</span>
          </v-tooltip>
        </div>
        <div style="width: 49.5%; margin-left: 0.5%;">
          <v-tooltip open-delay="2000" location="top">
            <template v-slot:activator="{ props }">
              <v-autocomplete
                v-bind="props" 
                v-model="input.productInAndOutSortOptions" 
                :append-inner-icon="input.productInAndOutSortOptions.length == 0 ? 'mdi-sort-variant' : 'mdi-sort-variant-remove'" 
                @click:append-inner="clearProductInAndOutSortOptions(); this.blurInput('productInAndOutSortOptions')" 
                :items="display.productInAndOutSortOptions" 
                multiple 
                chips 
                closable-chips
                color="primary"
                ref="productInAndOutSortOptions"
                label="Ordenar por" 
                variant="solo-filled" 
                density="compact"
              ></v-autocomplete>
            </template>
            <span style="max-width: 400px; display: block;">Las facturas inicialmente se ordenan por identificador interno. Este espacio permite selección múltiple para ordenar por varios criterios en paralelo, siempre tomando en cuenta el órden de selección de los criterios de ordenamiento (por ejemplo, al seleccionar localidad y luego facturador, ordenará primero por localidad, y luego cada localidad se ordenará por facturador). Al presionar el ícono de la derecha se eliminan todos los criterios de ordenamiento seleccionados</span>
          </v-tooltip>
        </div>
      </div>
      <v-data-table :headers="display.productInAndOutHeaders" :items="display.productInAndOutRows" :items-per-page="250" style="max-height: 700px; overflow-y: auto;">
        <template v-slot:item.productInAndOutID="{ item }">
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <p v-bind="props" style="cursor: pointer;">{{ item.productInAndOutID }}</p>
            </template>
            <p><strong>IP: </strong>{{ item.verifiedTransactionIP }}</p>
            <p><strong>Latitud: </strong>{{ item.verifiedTransactionLatitude }}</p>
            <p><strong>Longitud: </strong>{{ item.verifiedTransactionLongitude }}</p>
            <p><strong>Región: </strong>{{ item.verifiedTransactionRegion }}</p>
          </v-tooltip>
        </template>
        <template v-slot:item.generatedLocalityName="{ item }">
          <v-chip :color="item.generatedLocalityColor" variant="flat" style="margin: 0px 5px 0px 5px;">
            <p :style="{color: item.generatedLocalityFontColor}">{{ item.generatedLocalityName }}</p>
          </v-chip>
        </template>
        <template v-slot:item.agentName="{ item }">
          <v-chip :color="item.agentColor" variant="flat" style="margin: 0px 5px 0px 5px;">
            <p :style="{color: item.agentFontColor}">{{ item.agentName }}</p>
          </v-chip>
        </template>
        <template v-slot:item.affectedLocalityName="{ item }">
          <v-chip :color="item.affectedLocalityColor" variant="flat" style="margin: 0px 5px 0px 5px;">
            <p :style="{color: item.affectedLocalityFontColor}">{{ item.affectedLocalityName }}</p>
          </v-chip>
        </template>
        <template v-slot:item.productInAndOutDatetime="{ item }">
          {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.productInAndOutDatetime) }}
        </template>
        <template v-slot:item.productInAndOutType="{ item }">
          <v-chip :color="item.productInAndOutType == 'Entrada' ? '#97cc91' : '#ff6e69'" variant="flat" style="margin: 0px 5px 0px 5px;">
            <p style="color: white">{{ item.productInAndOutType }} ({{item.productInAndOutType == 'Entrada' ? '+' : '-'}}{{ item.productInAndOutProductsTotalAmount}})</p>
          </v-chip>
        </template>
        <template v-slot:item.productInAndOutReason="{ item }">  
          <div style="width: 300px;">
            <p><strong>{{ item.productInAndOutReason }}:</strong></p>
            <p>{{ item.productInAndOutNote || 'Sin nota' }}</p>
          </div>
        </template>
        <template v-slot:item.productInAndOutProducts="{ item }">
          <v-tooltip open-delay="500" location="top">
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props" @click="openProductInAndOutProductsDialog(item)" color="info" dark right>mdi-magnify</v-icon>
            </template>
            <span style="max-width: 400px; display: block;">Ver producto{{ item.productInAndOutProductsTotalAmount > 1 ? 's' : '' }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.productInAndOutPrintAction="{ item }">
          <div style="width: 30px;">
            <v-progress-circular v-if="item.productInAndOutPrintLoader" color="success" indeterminate></v-progress-circular>
            <v-tooltip v-else open-delay="500" location="top">
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props" @click="generateReportOnProductInAndOutView(item)" color="success" dark right>mdi-printer</v-icon>
              </template>
              <span style="max-width: 400px; display: block;">Reimprimir {{ item.productInAndOutType.toLowerCase() }} de producto{{ item.productInAndOutProductsTotalAmount > 1 ? 's' : '' }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
      <div v-if="accessCredential['/productInAndOut/seeTotal']">
        <br>
        <v-data-table :headers="display.productInAndOutTotalHeaders" :items="[0]"  style="max-height: 700px; overflow-y: auto;">
          <template v-slot:item.productInAndOutLength>
            <p>{{ display.productInAndOutRows.length }}</p>
          </template>
          <template v-slot:item.productInAndOutProductInLength>
            <p>{{ display.productInAndOutRows.filter(productInAndOut => productInAndOut.productInAndOutType == 'Entrada').length }}</p>
          </template>
          <template v-slot:item.productInAndOutProductInAmount>
            <v-chip color="#97cc91" variant="flat" style="margin: 0px 5px 0px 5px;">
              <p style="color: white">+ {{ display.productInAndOutRows.filter(productInAndOut => productInAndOut.productInAndOutType == 'Entrada').reduce((productInAndOutProductInAmount, productInAndOut) => productInAndOutProductInAmount + productInAndOut.productInAndOutProductsTotalAmount, 0) }}</p>
            </v-chip>
          </template>
          <template v-slot:item.productInAndOutProductOutLength>
            <p>{{ display.productInAndOutRows.filter(productInAndOut => productInAndOut.productInAndOutType == 'Salida').length }}</p>
          </template>
          <template v-slot:item.productInAndOutProductOutAmount>
            <v-chip color="#ff6e69" variant="flat" style="margin: 0px 5px 0px 5px;">
              <p style="color: white">- {{ display.productInAndOutRows.filter(productInAndOut => productInAndOut.productInAndOutType == 'Salida').reduce((productInAndOutProductOutAmount, productInAndOut) => productInAndOutProductOutAmount + productInAndOut.productInAndOutProductsTotalAmount, 0) }}</p>
            </v-chip>
          </template>
          <template #bottom></template>
        </v-data-table>
      </div>
    </v-card>
    <br><br>
  </div>
</template>

<style scoped>
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProductInAndOutView',
  inject: ['$generalFunctions'],

  data: () => ({
    input: 
    {
      productInAndOutID: null,
      productInAndOutGeneratedLocalityID: [],
      productInAndOutAgentID: [],
      productInAndOutLocalityID: [],
      startDate: null,
      endDate: null,
      productInAndOutType: null,
      selectedProductID: null,
      searchedProductID: null,
      productCategoryID: [],
      productBrandID: [],
      productModelID: [],
      productFlavorID: [],
      productNicotineLevelID: [],
      productSizeID: [],
      productInAndOutSearchQuery: null,
      productInAndOutSortOptions: []
    },

    display: 
    {
      localityOptions: [],
      agentOptions: [],
      productInAndOutTypeOptions: 
      [
        {value: null, title: 'Todos los tipos de movimiento'},
        {value: 'Entrada', title: 'Entrada'},
        {value: 'Salida', title: 'Salida'}
      ],
      startDateFormatted: null,
      endDateFormatted: null,
      productOptions: [],
      productCategoryOptions: [],
      productModelOptions: [],
      productBrandOptions: [],
      productFlavorOptions: [],
      productNicotineLevelOptions: [],
      productSizeOptions: [],
      productInAndOutSortOptions: 
      [
        {title: 'Localidad generadora (A-Z)', value: 'generatedLocalityName'},
        {title: 'Localidad generadora (Z-A)', value: 'reverse_generatedLocalityName'},
        {title: 'Agente generador (A-Z)', value: 'agentName'},
        {title: 'Agente generador (Z-A)', value: 'reverse_agentName'},
        {title: 'Localidad afectada (A-Z)', value: 'affectedLocalityName'},
        {title: 'Localidad afectada (Z-A)', value: 'reverse_affectedLocalityName'},
        {title: 'Fecha (más reciente primero)', value: 'productInAndOutDatetime'},
        {title: 'Fecha (más antiguo primero)', value: 'reverse_productInAndOutDatetime'},
        {title: 'Tipo (A-Z)', value: 'productInAndOutType'},
        {title: 'Tipo (Z-A)', value: 'reverse_productInAndOutType'},
        {title: 'Cantidad de piezas movidas (mayor primero)', value: 'productInAndOutProductsTotalAmount'},
        {title: 'Cantidad de piezas movidas (menor primero)', value: 'reverse_productInAndOutProductsTotalAmount'},
        {title: 'Motivo (A-Z)', value: 'productInAndOutReason'},
        {title: 'Motivo (Z-A)', value: 'reverse_productInAndOutReason'}
      ],
      productInAndOutHeaders: 
      [
        {key: 'productInAndOutID', title: 'ID', sortable: false},
        {key: 'generatedLocalityName', title: 'GENERADO EN', sortable: false},
        {key: 'agentName', title: 'GENERADO POR', sortable: false},
        {key: 'affectedLocalityName', title: 'AFECTACIÓN EN', sortable: false},
        {key: 'productInAndOutDatetime', title: 'FECHA', sortable: false},
        {key: 'productInAndOutType', title: 'TIPO', sortable: false},
        {key: 'productInAndOutReason', title: 'NOTA', sortable: false},
        {key: 'productInAndOutProducts', title: null, sortable: false},
        {key: 'productInAndOutPrintAction', title: null, sortable: false}
      ],
      productHeaders: 
      [
        {key: 'productName', title: 'PRODUCTO', sortable: false},
        {key: 'productInAndOutProductCurrentAmount', title: 'CANTIDAD ANTERIOR', sortable: false},
        {key: 'productInAndOutProductAmount', title: 'CANTIDAD MODIFICADA', sortable: false},
        {key: 'productInAndOutProductResultAmount', title: 'CANTIDAD RESULTANTE', sortable: false},
        {key: 'productInAndOutProductNote', title: 'NOTA', sortable: false}
      ],
      productInAndOutRows: [],
      backupProductInAndOutRows: [],
      productInAndOut: null,


      productInAndOutTotalHeaders:
      [
        {key: 'productInAndOutLength', title: 'CANTIDAD DE MOVIMIENTOS DE PRODUCTO', sortable: false},
        {key: 'productInAndOutProductInLength', title: 'CANTIDAD DE ENTRADAS', sortable: false},
        {key: 'productInAndOutProductInAmount', title: 'CANTIDAD DE PIEZAS (ENTRADA)', sortable: false},
        {key: 'productInAndOutProductOutLength', title: 'CANTIDAD DE SALIDAS', sortable: false},
        {key: 'productInAndOutProductOutAmount', title: 'CANTIDAD DE PIEZAS (SALIDA)', sortable: false},
        {key: 'productStockAction', title: null, sortable: false}
      ],
    },

    navigation: 
    {
      searchVisible: 'searchVisible',
      startDatePickerIsVisible: false,
      endDatePickerIsVisible: false,
      productInAndOutProductsDialogIsVisible: false
    },

    loader:
    {
      selectLocalityIDAndLocalityName: false,
      selectProductSearchParameters: false,
      selectProductBasicBasicInformationForBillsView: false,
      selectProductForForProductInAndOutView: false,
      selectProductInAndOutForProductInAndOutView: false
    },

    localStorage:
    {
      localityID: null,
      agentID: null
    },

    accessCredential: {}
  }),

  watch: {
    'input.productInAndOutGeneratedLocalityID': async function(){
      this.selectAgentByAgentLocalityIDAndByAgentIsActive();
    },
    'input.startDate': async function() {
      this.input.startDate ? this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB') : this.display.startDateFormatted = null;
      this.navigation.startDatePickerIsVisible = false;
    },
    'input.endDate': async function() {
      this.input.endDate ? this.display.endDateFormatted = new Date(this.input.endDate).toLocaleDateString('en-GB') : this.display.endDateFormatted = null;
      this.navigation.endDatePickerIsVisible = false;
    },
    'input.productInAndOutSearchQuery': async function(){
      const backupProductInAndOutRows = JSON.parse(JSON.stringify(this.display.backupProductInAndOutRows));
      const productInAndOutSearchQuery = this.input.productInAndOutSearchQuery ? this.input.productInAndOutSearchQuery.toLowerCase().trim() : null;
      if (!productInAndOutSearchQuery) {
        this.display.productInAndOutRows = backupProductInAndOutRows;
        return;
      }
      this.display.productInAndOutRows = backupProductInAndOutRows.filter(productInAndOut => {
        return (
          [
            productInAndOut.generatedLocalityName,
            productInAndOut.agentName,
            productInAndOut.affectedLocalityName,
            productInAndOut.productInAndOutType,
            productInAndOut.productInAndOutNote,
            productInAndOut.productInAndOutProductsTotalAmount,
            productInAndOut.productInAndOutProducts.map(productInAndOutProduct => productInAndOutProduct.productName).join(', '),
            productInAndOut.productInAndOutProducts.map(productInAndOutProduct => productInAndOutProduct.productID).join(', ')
          ]
          .filter(value => value)
          .some(value => value.toString().toLowerCase().includes(productInAndOutSearchQuery)) 
        );
      });
    },
    'input.productInAndOutSortOptions': async function () {
      if (this.display.productInAndOutRows.length == 0) return;
      const lastAddedProductInAndOutSortOption = this.input.productInAndOutSortOptions[this.input.productInAndOutSortOptions.length - 1];
      let contradictoryProductInAndOutOption = null;
      if (lastAddedProductInAndOutSortOption && lastAddedProductInAndOutSortOption.startsWith('reverse_')) {
        const normalVersion = lastAddedProductInAndOutSortOption.replace('reverse_', '');
        if (this.input.productInAndOutSortOptions.includes(normalVersion)) {
          contradictoryProductInAndOutOption = normalVersion;
        }
      } else if (lastAddedProductInAndOutSortOption) {
        const reverseVersion = `reverse_${lastAddedProductInAndOutSortOption}`;
        if (this.input.productInAndOutSortOptions.includes(reverseVersion)) {
          contradictoryProductInAndOutOption = reverseVersion;
        }
      }
      const filteredProductInAndOutSortOptions = this.input.productInAndOutSortOptions.filter(option => option != contradictoryProductInAndOutOption);
      if (JSON.stringify(filteredProductInAndOutSortOptions) != JSON.stringify(this.input.productInAndOutSortOptions)) {
        this.input.productInAndOutSortOptions = filteredProductInAndOutSortOptions;
      }
      this.display.productInAndOutRows = this.display.productInAndOutRows.sort((productInAndOutA, productInAndOutB) => {
        return productInAndOutB.productInAndOutID - productInAndOutA.productInAndOutID;
      });
      this.display.productInAndOutRows = [...this.display.productInAndOutRows].sort((productInAndOutA, productInAndOutB) => {
        for (const selectedBillRowsSortOption of this.input.productInAndOutSortOptions) {
          const isReverse = selectedBillRowsSortOption.startsWith('reverse_');
          const sortKey = isReverse ? selectedBillRowsSortOption.replace('reverse_', '') : selectedBillRowsSortOption;
          let productInAndOutAValue = productInAndOutA[sortKey];
          let productInAndOutBValue = productInAndOutB[sortKey];
          if (sortKey == 'productInAndOutProductsTotalAmount') {
            productInAndOutAValue = Number(productInAndOutAValue);
            productInAndOutBValue = Number(productInAndOutBValue);
          } else if (sortKey == 'productInAndOutDatetime') {
            productInAndOutAValue = new Date(productInAndOutAValue);
            productInAndOutBValue = new Date(productInAndOutBValue);
          } else {
            productInAndOutAValue = String(productInAndOutAValue).toLowerCase();
            productInAndOutBValue = String(productInAndOutBValue).toLowerCase();
          }
          let productInAndOutComparison = null;
          if (sortKey == 'productInAndOutProductsTotalAmount' || sortKey == 'productInAndOutDatetime') {
            productInAndOutComparison = productInAndOutBValue - productInAndOutAValue;
          } else {
            productInAndOutComparison = productInAndOutAValue.localeCompare(productInAndOutBValue); 
          }
          if (productInAndOutComparison != 0) {
            return isReverse ? -productInAndOutComparison : productInAndOutComparison;
          }
        }
        return 0; 
      });
    }
  },

  methods: {
    async generateReportOnProductInAndOutView(productInAndOut){
      productInAndOut.productInAndOutPrintLoader = true;
      const generateReportOnProductInAndOutViewRequestQuery = 
      {
        'productInAndOutID': productInAndOut.productInAndOutID
      };
      const generateReportOnProductInAndOutViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productInAndOut/functions/generateReportOnProductInAndOutView', generateReportOnProductInAndOutViewRequestQuery);
      if (generateReportOnProductInAndOutViewResult.success){
        if (generateReportOnProductInAndOutViewResult.result.PDFDocument.success == false){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el documento en PDF, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
        if (generateReportOnProductInAndOutViewResult.result.excelDocument.success == false){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el documento en Excel, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': generateReportOnProductInAndOutViewResult.result.PDFDocument.result.documentFile.data,
          'printablePDFDocumentName': generateReportOnProductInAndOutViewResult.result.PDFDocument.result.documentName,
          'printableExcelDocumentFile': generateReportOnProductInAndOutViewResult.result.excelDocument.result.documentFile.data,
          'printableExcelDocumentName': generateReportOnProductInAndOutViewResult.result.excelDocument.result.documentName
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': `Ha ocurrido un error inesperado al generar el reporte de la ${productInAndOut.productInAndOutType.toLowerCase()} de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema`,
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      productInAndOut.productInAndOutPrintLoader = false;
    },

    async selectProductInAndOutForProductInAndOutView(){
      this.loader.selectProductInAndOutForProductInAndOutView = true;
      this.display.productInAndOutRows.length = 0;
      this.display.backupProductInAndOutRows.length = 0;
      this.input.productInAndOutSearchQuery = null;
      const selectProductInAndOutForProductInAndOutViewRequestQuery = 
      {
        'productInAndOutID': this.input.productInAndOutID,
        'productInAndOutGeneratedLocalityID': this.input.productInAndOutGeneratedLocalityID,
        'productInAndOutLocalityID': this.input.productInAndOutLocalityID,
        'productInAndOutAgentID': this.input.productInAndOutAgentID,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate,
        'productInAndOutType': this.input.productInAndOutType,
        'productID': this.input.selectedProductID,
        'productCategoryID': this.input.productCategoryID,
        'productBrandID': this.input.productBrandID,
        'productModelID': this.input.productModelID,
        'productFlavorID': this.input.productFlavorID,
        'productNicotineLevelID': this.input.productNicotineLevelID,
        'productSizeID': this.input.productSizeID
      };
      const selectProductInAndOutForProductInAndOutViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productInAndOut/functions/selectProductInAndOutForProductInAndOutView', selectProductInAndOutForProductInAndOutViewRequestQuery);
      if (selectProductInAndOutForProductInAndOutViewResult.success){
        if (selectProductInAndOutForProductInAndOutViewResult.result.length > 0){
          this.display.productInAndOutRows = selectProductInAndOutForProductInAndOutViewResult.result;
          this.display.backupProductInAndOutRows = JSON.parse(JSON.stringify(this.display.productInAndOutRows));
          this.navigation.searchVisible = '';
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ATENCIÓN',
            'notificationDialogBody': 'No se ha encontrado ningún movimiento de productos con los filtros seleccionados, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los movimientos de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectProductInAndOutForProductInAndOutView = false;
    },

    async selectLocalityIDAndLocalityNameByLocalityIsActive(){
      this.loader.selectLocalityIDAndLocalityNameByLocalityIsActive = true;
      const selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery = {'localityIsActive': true};
      const selectLocalityIDAndLocalityNameByLocalityIsActiveResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityNameByLocalityIsActive', selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery);
      if (selectLocalityIDAndLocalityNameByLocalityIsActiveResult.success){
        this.display.localityOptions = selectLocalityIDAndLocalityNameByLocalityIsActiveResult.result;
        this.loader.selectLocalityIDAndLocalityNameByLocalityIsActive = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectAgentByAgentLocalityIDAndByAgentIsActive(){
      this.loader.selectAgentByAgentLocalityIDAndByAgentIsActive = true;
      this.input.productInAndOutAgentID.length = 0;
      const selectAgentByAgentLocalityIDAndByAgentIsActiveRequestQuery = 
      {
        'agentLocalityID': this.input.productInAndOutGeneratedLocalityID, 
        'agentIsActive': true
      };
      const selectAgentByAgentLocalityIDAndByAgentIsActiveResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/select/byAgentLocalityIDAndByAgentIsActive', selectAgentByAgentLocalityIDAndByAgentIsActiveRequestQuery);
      if (selectAgentByAgentLocalityIDAndByAgentIsActiveResult.success){
        this.display.agentOptions = selectAgentByAgentLocalityIDAndByAgentIsActiveResult.result;
        this.loader.selectAgentByAgentLocalityIDAndByAgentIsActive = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los facturadores, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductBasicBasicInformationForBillsView(){
      this.loader.selectProductBasicBasicInformationForBillsView = true;
      const selectProductBasicBasicInformationForBillsViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductBasicBasicInformationForBillsView');
      if (selectProductBasicBasicInformationForBillsViewResult.success){
        this.display.productOptions = selectProductBasicBasicInformationForBillsViewResult.result.map(productOption => ({
          'productID': productOption.i,
          'productSKU': productOption.s,
          'productName': productOption.n,
          'productAutocompleteTitle': productOption.s + ' - ' + productOption.n
        }));
        this.loader.selectProductBasicBasicInformationForBillsView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los facturadores, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductSearchParameters(){
      this.loader.selectProductSearchParameters = true;
      const selectProductSearchParametersResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductSearchParameters');
      if (selectProductSearchParametersResult.success){
        this.display.productCategoryOptions = selectProductSearchParametersResult.result.productCategory;
        this.display.productBrandOptions = selectProductSearchParametersResult.result.productBrand;
        this.display.productModelOptions = selectProductSearchParametersResult.result.productModel;
        this.display.productFlavorOptions = selectProductSearchParametersResult.result.productFlavor;
        this.display.productNicotineLevelOptions = selectProductSearchParametersResult.result.productNicotineLevel;
        this.display.productSizeOptions = selectProductSearchParametersResult.result.productSize;
        this.loader.selectProductSearchParameters = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de características de los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    clearProductInAndOutSortOptions(){
      this.input.productInAndOutSortOptions.length = 0;
      this.display.productInAndOutRows = this.display.productInAndOutRows.sort((productInAndOutA, productInAndOutB) => {
        return productInAndOutB.productInAndOutID - productInAndOutA.productInAndOutID;
      });
    },

    blurInput(inputReference){
      const blurInputInterval = setInterval(() => {
        if (this.$refs[inputReference]) {
          this.$refs[inputReference].blur();
          clearInterval(blurInputInterval);
        }
      }, 1);
    },

    selectProductByScanner(){
      const selectedProduct = this.display.productOptions.find(productOption => productOption.productID == this.input.searchedProductID);
      if (selectedProduct){
        this.blurInput('productID');
        this.input.selectedProductID = selectedProduct.productID;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': null,
          'notificationDialogBody': 'El producto escaneado no ha sido encontrado, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
      }
    },

    cleanProductIDInput(){
      this.input.searchedProductID = null;
      this.input.selectedProductID = null;
      this.blurInput('productID');
    },

    openProductInAndOutProductsDialog(productInAndOut){
      this.display.productInAndOut = productInAndOut;
      this.navigation.productInAndOutProductsDialogIsVisible = true;
    },

    setDefaultValues(){
      this.input.productInAndOutID = null;
      this.input.productInAndOutGeneratedLocalityID.length = 0;
      this.input.productInAndOutAgentID.length = 0;
      this.input.productInAndOutLocalityID.length = 0;
      const startDate = new Date();
      startDate.setDate(1);
      this.input.startDate = startDate;
      this.input.endDate = new Date();
      this.input.productInAndOutType = null;
      this.input.selectedProductID = null;
      this.input.searchedProductID = null;
      this.input.productCategoryID.length = 0;
      this.input.productBrandID.length = 0;
      this.input.productModelID.length = 0;
      this.input.productFlavorID.length = 0;
      this.input.productNicotineLevelID.length = 0;
      this.input.productSizeID.length = 0;
      this.input.productInAndOutSearchQuery = null;
      this.input.productInAndOutSortOptions.length = 0;
      this.display.productInAndOutRows.length = 0;
      this.display.backupProductInAndOutRows.length = 0;
      this.display.productInAndOut = null;
      this.navigation.startDatePickerIsVisible = false;
      this.navigation.endDatePickerIsVisible = false;
      this.navigation.productInAndOutProductsDialogIsVisible = false;
      this.loader.selectProductForForProductInAndOutView = false;
      this.loader.selectProductInAndOutForProductInAndOutView = false;
    },

    async getLocalStorageData(){
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    },
  },

  async mounted(){
    this.setDefaultValues();
    await this.getLocalStorageData();
    this.selectProductBasicBasicInformationForBillsView();
    this.selectProductSearchParameters();
    await this.selectLocalityIDAndLocalityNameByLocalityIsActive();
    this.selectAgentByAgentLocalityIDAndByAgentIsActive();
  }
});
</script>