<template>
  <div style="margin: 3% 2% 0% 2%;">
    <div v-if="display.information">
      <v-card style="padding: 20px; background-color: #e9e8e8;" elevation="3">
        <v-data-table-virtual :headers="display.headers" :items="display.information" hide-default-footer>
          <template v-slot:item.agentName="{ item }">
            <p>{{ item.agentName }}</p>
            <br>
            <v-icon @click="reporte(item)" color="success" dark right>mdi-printer</v-icon>
          </template>
          <template v-slot:item.cash="{ item }">
            <br>
            <div v-for="invoice in item.paymentMethods['Efectivo']['invoices']" v-bind:key="invoice">
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <p v-bind="props" style="cursor: pointer;">
                    <strong>{{ invoice.name.replace(/\s*\(.*\)$/, '') }}: </strong>
                    ₡{{ $generalFunctions.default.methods.parseAmountToDisplay(invoice.amount) }}
                  </p>                
                </template>
                <p><strong>ID: </strong>{{ invoice.id }}</p>
                <p><strong>Nombre completo: </strong>{{ invoice.name }}</p>
                <p><strong>Entregado el: </strong>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(invoice.date) }}</p>
              </v-tooltip>
            </div>
            <br>
            <p style="font-size: large;"><strong>Subtotal: </strong>₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.paymentMethods['Efectivo']['amount']) }}</p>
            <br>
          </template>
          <template v-slot:item.card="{ item }">
            <br>
            <div v-for="invoice in item.paymentMethods['Tarjeta']['invoices']" v-bind:key="invoice">
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <p v-bind="props" style="cursor: pointer;">
                    <strong>{{ invoice.name.replace(/\s*\(.*\)$/, '') }}: </strong>
                    ₡{{ $generalFunctions.default.methods.parseAmountToDisplay(invoice.amount) }}
                  </p>                
                </template>
                <p><strong>ID: </strong>{{ invoice.id }}</p>
                <p><strong>Nombre completo: </strong>{{ invoice.name }}</p>
                <p><strong>Entregado el: </strong>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(invoice.date) }}</p>
              </v-tooltip>
            </div>
            <br>
            <p style="font-size: large;"><strong>Subtotal: </strong>₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.paymentMethods['Tarjeta']['amount']) }}</p>
            <br>
          </template>
          <template v-slot:item.sinpe="{ item }">
            <br>
            <div v-for="invoice in item.paymentMethods['SINPE']['invoices']" v-bind:key="invoice">
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <p v-bind="props" style="cursor: pointer;">
                    <strong>{{ invoice.name.replace(/\s*\(.*\)$/, '') }}: </strong>
                    ₡{{ $generalFunctions.default.methods.parseAmountToDisplay(invoice.amount) }}
                  </p>                
                </template>
                <p><strong>ID: </strong>{{ invoice.id }}</p>
                <p><strong>Nombre completo: </strong>{{ invoice.name }}</p>
                <p><strong>Entregado el: </strong>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(invoice.date) }}</p>
              </v-tooltip>
            </div>
            <br>
            <p style="font-size: large;"><strong>Subtotal: </strong>₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.paymentMethods['SINPE']['amount']) }}</p>
            <br>
          </template>
          <template v-slot:item.deposit="{ item }">
            <br>
            <div v-for="invoice in item.paymentMethods['Transferencia o depósito']['invoices']" v-bind:key="invoice">
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <p v-bind="props" style="cursor: pointer;">
                    <strong>{{ invoice.name.replace(/\s*\(.*\)$/, '') }}: </strong>
                    ₡{{ $generalFunctions.default.methods.parseAmountToDisplay(invoice.amount) }}
                  </p>                
                </template>
                <p><strong>ID: </strong>{{ invoice.id }}</p>
                <p><strong>Nombre completo: </strong>{{ invoice.name }}</p>
                <p><strong>Entregado el: </strong>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(invoice.date) }}</p>
              </v-tooltip>
            </div>
            <br>
            <p style="font-size: large;"><strong>Subtotal: </strong>₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.paymentMethods['Transferencia o depósito']['amount']) }}</p>
            <br>
          </template>
          <template v-slot:item.mixedPayment="{ item }">
            <br>
            <div v-for="invoice in item.paymentMethods['Mixto']['invoices']" v-bind:key="invoice">
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <p v-bind="props" style="cursor: pointer;">
                    <strong>{{ invoice.name.replace(/\s*\(.*\)$/, '') }}: </strong>
                    ₡{{ $generalFunctions.default.methods.parseAmountToDisplay(invoice.amount) }}
                  </p>                
                </template>
                <p><strong>ID: </strong>{{ invoice.id }}</p>
                <p><strong>Nombre completo: </strong>{{ invoice.name }}</p>
                <p><strong>Entregado el: </strong>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(invoice.date) }}</p>
                <p><strong>Nota: </strong>{{ invoice.note }}</p>
              </v-tooltip>
            </div>
            <br>
            <p style="font-size: large;"><strong>Subtotal: </strong>₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.paymentMethods['Mixto']['amount']) }}</p>
            <br>
          </template>
          <template v-slot:item.totalAmount="{ item }">
            <p style="font-size: large;">₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(item.totalAmount) }}</p>
          </template>
        </v-data-table-virtual>
      </v-card>
      <br><br>
      <v-btn v-if="accessCredential['/deliveryClose/generalReport']" @click="reporteGlobal()" dark block height="38" color="success">
        <h3>GENERAR REPORTE GLOBAL</h3>
        <v-icon style="margin-left: 20px;" dark right>mdi-printer</v-icon>
      </v-btn>
    </div>
    <div v-else style="text-align: center;">
        <br>
        <v-progress-circular color="primary" indeterminate></v-progress-circular>
        <br><br>
      </div>
    <br><br>
    
  </div>
</template>

<style scoped>

</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DeliveryCloseView',
  inject: ['$generalFunctions'],

  
  data: () => ({
    input: 
    {
    },

    navigation: 
    {
      printablePDFExcelDocumentDialogRequestQuery: null
    },

    loader: 
    {
    },

    display:
    {
      information: null,
      headers: 
      [
        {key: 'agentName', title: 'NOMBRE'},
        {key: 'cash', title: 'EFECTIVO'},
        {key: 'card', title: 'TARJETA'},
        {key: 'sinpe', title: 'SINPE'},
        {key: 'deposit', title: 'TRANSFERENCIA'},
        {key: 'mixedPayment', title: 'MIXTO'},
        {key: 'totalAmount', title: 'TOTAL'}
      ],
    },

    localStorage: 
    {
      localityID: null
    },

    accessCredential: {}

  }),

  watch: {
  },

  methods: {
    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    },

    reporteGlobal(){
      this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(this.navigation.printablePDFExcelDocumentDialogRequestQuery);
    },
    
    async reporte(agent){
      const getInformationRequestQuery = 
      {
        'information': agent,
        'localityName': localStorage.getItem('localityName')
      };
      const getInformationResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/webpage/functions/deliveryReport', getInformationRequestQuery);
      if (getInformationResult.success){
        const printablePDFExcelDocumentDialogRequestQuery = 
          {
            'printablePDFDocumentFile': getInformationResult.result.pdf.data,
            'printablePDFDocumentName': getInformationResult.name,
            'printableExcelDocumentFile': this.display.excelDocumentFile,
            'printableExcelDocumentName': this.display.excelDocumentName
          };
          this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
      } else {
        alert('error')
      }
    },

    async getInformation(){

      const localityReconciliation = 
      {
        1: 1,
        2: 4,
        3: 5,
        4: 3
      };

      const getInformationRequestQuery = 
      {
        'whatsappInvoiceLocalityID': localityReconciliation[this.localStorage.localityID],
        'localityName': localStorage.getItem('localityName'),
        'initialDate': null,
        'endDate': null
      };

      const getInformationResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/webpage/functions/deliveryClose', getInformationRequestQuery);
      if (getInformationResult.success){
        this.navigation.printablePDFExcelDocumentDialogRequestQuery = 
          {
            'printablePDFDocumentFile': getInformationResult.result.pdf.data,
            'printablePDFDocumentName': getInformationResult.name,
            'printableExcelDocumentFile': this.display.excelDocumentFile,
            'printableExcelDocumentName': this.display.excelDocumentName
          };
          this.display.information = Object.values(getInformationResult.result.data);
      } else {
        alert('error')
      }
    }
  },

  async mounted(){
    await this.getLocalStorageData();
    await this.getInformation();
  }

});

</script>
