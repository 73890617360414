<template>
  <v-dialog v-model="navigation.agentDialogIsVisible" width="auto" :persistent="true">
    <v-card width="700" style="padding: 20px; text-align: center;">
      <div v-if="loader.selectAgentFromAgentDialog || loader.updateAgentFromAgentDialog || loader.insertAgentFromAgentDialog" style="text-align: center;">
        <v-progress-circular color="primary" indeterminate></v-progress-circular>
        <br>
      </div>
      <div v-else>
        <v-text-field 
          v-if="accessCredential['/agent/updateAgentDialog/showAll']"
          v-model="display.agent.agentSSN" 
          append-inner-icon="mdi-barcode" 
          label="SSN del agente"
          variant="solo-filled" 
          readonly
          density="compact"
        ></v-text-field>
        <v-text-field 
          v-else
          v-model="display.agent.agentSSN" 
          append-inner-icon="mdi-barcode" 
          label="SSN del agente"
          variant="solo-filled" 
          density="compact"
        ></v-text-field>
        <v-text-field 
          v-model="display.agent.agentFirstName" 
          append-inner-icon="mdi-alpha-a-box" 
          label="Primer nombre del agente"
          variant="solo-filled"
          density="compact"
        ></v-text-field>
        <v-text-field 
          v-model="display.agent.agentMiddleName" 
          append-inner-icon="mdi-alpha-b-box" 
          label="Segundo nombre del agente (opcional)" 
          variant="solo-filled"
          density="compact"
        ></v-text-field>
        <v-text-field 
          v-model="display.agent.agentFirstSurname" 
          append-inner-icon="mdi-alpha-c-box" 
          label="Primer apellido del agente" 
          variant="solo-filled"
          density="compact"
        ></v-text-field>
        <v-text-field 
          v-model="display.agent.agentSecondSurname" 
          append-inner-icon="mdi-alpha-d-box" 
          label="Segundo apellido del agente" 
          variant="solo-filled"
          density="compact"
        ></v-text-field>
        <br>
        <v-text-field 
          v-model="display.agent.agentEmail" 
          append-inner-icon="mdi-email" 
          label="Correo electrónico del agente" 
          variant="solo-filled"
          density="compact"
        ></v-text-field>
        <v-text-field 
          v-model="display.agent.agentPhoneNumber" 
          append-inner-icon="mdi-phone" 
          label="Teléfono del agente" 
          variant="solo-filled"
          density="compact"
        ></v-text-field>
        <br>
        <div v-if="accessCredential['/agent/updateAgentDialog/showAll']">
          <v-text-field 
            v-model="display.agent.agentUsername" 
            append-inner-icon="mdi-account" 
            label="Nombre de usuario del agente" 
            variant="solo-filled"
            density="compact"
          ></v-text-field>
          <v-text-field 
            v-model="display.agent.agentPassword" 
            @click:append-inner="navigation.agentPasswordInputIsVisible = !navigation.agentPasswordInputIsVisible"
            @click:append="navigation.generateAgentPasswordDialogIsVisible = true"
            :type="navigation.agentPasswordInputIsVisible == false ? 'password' : 'text'"
            :append-inner-icon="navigation.agentPasswordInputIsVisible == false ? 'mdi-eye' : 'mdi-eye-off'" 
            append-icon="mdi-dice-5" 
            label="Contraseña del agente" 
            variant="solo-filled"
            density="compact"
          ></v-text-field>
          <br>
        </div>
        <v-text-field 
          @click="navigation.agentColorDialogIsVisible = true" 
          v-model="display.agent.agentColor" 
          :bg-color="display.agent.agentColor" 
          readonly="true" 
          append-inner-icon="mdi-palette" 
          label="Color del agente" 
          variant="solo-filled"
          density="compact"
        ></v-text-field>
        <v-dialog v-model="navigation.agentColorDialogIsVisible" width="auto">
          <v-card width="300">
            <v-color-picker v-model="display.agent.agentColor" :v-model:mode="'hex'" hide-inputs></v-color-picker>
          </v-card>
        </v-dialog>
        <v-text-field 
          @click="navigation.agentFontColorDialogIsVisible = true" 
          v-model="display.agent.agentFontColor" 
          :bg-color="display.agent.agentFontColor" 
          readonly="true" 
          append-inner-icon="mdi-palette" 
          label="Color de letra del agente" 
          variant="solo-filled"
          density="compact"
        ></v-text-field>
        <v-dialog v-model="navigation.agentFontColorDialogIsVisible" width="auto">
          <v-card width="300">
            <v-color-picker v-model="display.agent.agentFontColor" :v-model:mode="'hex'" hide-inputs></v-color-picker>
          </v-card>
        </v-dialog>
        <div v-if="accessCredential['/agent/updateAgentDialog/showAll']">
          <br>
          <v-text-field 
            v-model="display.agent.agentLandingPage" 
            append-inner-icon="mdi-search-web" 
            label="Landing page del agente (opcional)" 
            variant="solo-filled"
            density="compact"
          ></v-text-field>
        </div>
        <br>
        <v-btn @click="updateAgentFromAgentDialog()" dark block height="38" color="success">
          <h3>ACTUALIZAR</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-content-save</v-icon>
        </v-btn>
        <br>
        <v-btn @click="setDefaultValues()" dark block height="38" color="error">
          <h3>CERRAR</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
  <v-dialog v-model="navigation.generateAgentPasswordDialogIsVisible" width="auto">
    <v-card width="500" style="padding: 20px; text-align: center;">
      <p>¿Desea generar una nueva contraseña segura para el agente?</p>
      <br><br>
      <v-btn @click="generateUpdatingAgentPassword()" dark block height="38" color="success">
        <h3>GENERAR</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-dice-5</v-icon>
      </v-btn>
      <br>
      <v-btn @click="navigation.generateAgentPasswordDialogIsVisible = false" dark block height="38" color="error">
        <h3>CANCELAR</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-close</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<style scoped>
</style>

<script>

export default {
  name: "AgentDialog",
  inject: ['$generalFunctions'],

  data: () => ({
    navigation: 
    {
      agentID: null,
      agentDialogIsVisible: false,
      agentPasswordInputIsVisible: false,
      generateAgentPasswordDialogIsVisible: false,
      agentColorDialogIsVisible: false,
      agentFontColorDialogIsVisible: false
    },

    input: {},

    display: 
    {
      agent: null
    },

    loader:
    {
      selectAgentFromAgentDialog: false,
      updateAgentFromAgentDialog: false
    },

    localStorage: 
    {
      localityID: null,
      agentID: null
    },

    accessCredential: {}
  }),

  methods: {
    async updateAgentFromAgentDialog(){
      this.loader.updateAgentFromAgentDialog = true;
      const updateAgentFromAgentDialogRequestQuery = 
      {
        'agentID': this.display.agent.agentID,
        'agentFirstName': this.display.agent.agentFirstName,
        'agentMiddleName': this.display.agent.agentMiddleName,
        'agentFirstSurname': this.display.agent.agentFirstSurname,
        'agentSecondSurname': this.display.agent.agentSecondSurname,
        'agentEmail': this.display.agent.agentEmail,
        'agentPhoneNumber': this.display.agent.agentPhoneNumber,
        'agentUsername': this.display.agent.agentUsername,
        'agentPassword': this.display.agent.agentPassword,
        'agentColor': this.display.agent.agentColor,
        'agentFontColor': this.display.agent.agentFontColor,
        'agentLandingPage': this.display.agent.agentLandingPage
      };
      const updateAgentFromAgentDialogResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/functions/updateAgentFromAgentDialog', updateAgentFromAgentDialogRequestQuery);
      if (updateAgentFromAgentDialogResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'El agente ha sido actualizado exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        await this.setDefaultValues();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al actualizar la información del agente, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.updateAgentFromAgentDialog = false;
    },

    async selectAgentFromAgentDialog(){
      this.loader.selectAgentFromAgentDialog = true;
      const selectAgentFromAgentDialogRequestQuery = 
      {
        'agentID': this.navigation.agentID
      };
      const selectAgentFromAgentDialogResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/functions/selectAgentFromAgentDialog', selectAgentFromAgentDialogRequestQuery);
      if (selectAgentFromAgentDialogResult.success){
        this.display.agent = selectAgentFromAgentDialogResult.result;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la información del agente, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectAgentFromAgentDialog = false;
    },

    generateUpdatingAgentPassword(){
      const generatedPasswordLength = 10;
      const lowecaseSymbols = 'abcdefghijklmnopqrstuvwxyz';
      const upperCaseSymbols = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const numericSymbols = '0123456789';
      const getRandomCharacter = (symbols) => symbols[Math.floor(Math.random() * symbols.length)];
      var generatedPassword = [getRandomCharacter(lowecaseSymbols), getRandomCharacter(upperCaseSymbols), getRandomCharacter(numericSymbols)];
      const allSymbols = lowecaseSymbols + upperCaseSymbols + numericSymbols;
      for (let i = 0; i < generatedPasswordLength; i++) {
        generatedPassword.push(getRandomCharacter(allSymbols));
      }
      generatedPassword = generatedPassword.join('');
      this.display.agent.agentPassword = generatedPassword;
      this.navigation.generateAgentPasswordDialogIsVisible = false;
    },

    async setDefaultValues(){
      this.navigation.agentID = null;
      this.navigation.agentDialogIsVisible = false;
      this.loader.selectAgentFromAgentDialog = false;
    },

    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    },


    async openAgentDialog(agentDialogRequestQuery) {
      await this.setDefaultValues();
      this.navigation.agentID = agentDialogRequestQuery.agentID;
      this.navigation.agentDialogIsVisible = true;
      await this.selectAgentFromAgentDialog();
    }
  },

  mounted(){
    this.getLocalStorageData();
  }
}
</script>