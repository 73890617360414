<template>
  <div class="closeViewContainer">
    <v-card v-if="loader.selectBillOnCloseView || (display.billsRows.length > 0)" class="closeViewInformationContainer" elevation="3">
      <div v-if="loader.selectBillOnCloseView" style="text-align: center;">
        <br>
        <v-progress-circular color="info" indeterminate></v-progress-circular>
        <br><br>
      </div>
      <div v-else class="closeViewInformationMainContainer">
        <div class="closeViewInformationTableContainer">
          <v-data-table-virtual :headers="display.billsHeaders" :items="display.billsRows" style="max-height: 600px; overflow-y: auto;">
            <template v-slot:item.billState="{ item }">
              <v-chip :color="localStorage.billStateColors[item.billState].billStateColor" variant="flat">
                <p :style="{color: localStorage.billStateColors[item.billState].billStateFontColor}">{{ item.billState }}</p>
              </v-chip>
            </template>
            <template v-slot:item.billAgentName="{ item }">
              <v-chip :color="item.billAgentColor" variant="flat">
                <p :style="{color: item.billAgentFontColor}">{{ item.billAgentName }}</p>
              </v-chip>
            </template>
            <template v-slot:item.clientName="{ item }">
              {{ item.clientName || item.contactName || 'No registrado' }}
            </template>
            <template v-slot:item.billCreationDatetime="{ item }">
              {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.billCreationDatetime) }}
            </template>
            <template v-slot:item.billAmount="{ item }">
              <div v-if="item.billCurrency == '₡'">
                {{ item.billCurrency }}{{ $generalFunctions.default.methods.parseAmountToDisplay(item.billAmount) }}
              </div>
              <div v-else>
                <v-tooltip location="top">
                  <template v-slot:activator="{ props }">
                    <p v-bind="props" style="cursor: pointer;">{{$generalFunctions.default.methods.parseAmountToDisplay(item.billAmount / item.billExchangeRate)}} {{item.billCurrency}}</p>
                  </template>
                  <p>₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(item.billAmount) }}</p>
                  <p>(1$ = ₡{{ item.billExchangeRate }})</p>
                </v-tooltip>
              </div>
            </template>
            <template v-slot:item.billPaymentType="{ item }">
              <v-tooltip v-if="item.billPaymentType == 'Mixto'" location="top">
                <template v-slot:activator="{ props }">
                  <p v-bind="props" style="cursor: pointer;">{{ item.billPaymentType }}</p>
                </template>
                <p v-if="item.billMixedPaymentCashAmount != null"><strong>Efectivo: </strong>{{ item.billCurrency == '₡' ? `${item.billCurrency} ${$generalFunctions.default.methods.parseAmountToDisplay(item.billMixedPaymentCashAmount)}` : `${$generalFunctions.default.methods.parseAmountToDisplay(item.billMixedPaymentCashAmount)} ${item.billCurrency}` }}</p>
                <p v-if="item.billMixedPaymentCardAmount != null"><strong>Tarjeta: </strong>{{ item.billCurrency == '₡' ? `${item.billCurrency} ${$generalFunctions.default.methods.parseAmountToDisplay(item.billMixedPaymentCardAmount)}` : `${$generalFunctions.default.methods.parseAmountToDisplay(item.billMixedPaymentCardAmount)} ${item.billCurrency}` }}</p>
                <p v-if="item.billMixedPaymentSINPEAmount != null"><strong>SINPE: </strong>{{ item.billCurrency == '₡' ? `${item.billCurrency} ${$generalFunctions.default.methods.parseAmountToDisplay(item.billMixedPaymentSINPEAmount)}` : `${$generalFunctions.default.methods.parseAmountToDisplay(item.billMixedPaymentSINPEAmount)} ${item.billCurrency}` }}</p>
                <p v-if="item.billMixedPaymentDepositAmount != null"><strong>Transferencia o depósito: </strong>{{ item.billCurrency == '₡' ? `${item.billCurrency} ${$generalFunctions.default.methods.parseAmountToDisplay(item.billMixedPaymentDepositAmount)}` : `${$generalFunctions.default.methods.parseAmountToDisplay(item.billMixedPaymentDepositAmount)} ${item.billCurrency}` }}</p>
              </v-tooltip>
              <p v-else>{{ item.billPaymentType }}</p>
            </template>
            <template v-slot:item.billAction="{ item }">
              <div v-if="item.loader" style="text-align: center;">
                <v-progress-circular color="success" indeterminate></v-progress-circular>
              </div>
              <div v-else style="display: flex;">
                <v-icon @click="openBillDialog(item)" color="info" dark right>mdi-magnify</v-icon>
                <v-icon @click="openPrintableBillDialog(item)" style="margin-left: 10px;" color="success" dark right>mdi-printer</v-icon>
              </div>
            </template>
          </v-data-table-virtual>
          <br>
          <div>
            <v-data-table-virtual :headers="display.saleByAgentHeaders" :items="display.saleByAgentRows" style="max-height: 300px; overflow-y: auto;">
              <template v-slot:item.agentName="{ item }">
                <v-chip :color="item.agentColor" variant="flat">
                  <p :style="{color: item.agentFontColor}">{{ item.agentName }}</p>
                </v-chip>
              </template>
              <template v-slot:item.amount="{ item }">
                ₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(item.amount) }}
              </template>
            </v-data-table-virtual>
          </div>

        </div>
        <div class="closeViewInformationResultContainer">
          <div v-if="accessCredential['/close/seeTotal']">
            <div class="closeViewInformationResultLabelContainer">
              <div style="display: flex; width: 100%;">
                <div style="width: 50%; text-align: left;">
                  <p class="closeViewInformationLabel"><strong>Efectivo:</strong></p>
                  <p class="closeViewInformationLabel"><strong>Tarjeta:</strong></p>
                  <p class="closeViewInformationLabel"><strong>SINPE:</strong></p>
                  <p class="closeViewInformationLabel"><strong>Transferencia:</strong></p>
                  <p class="closeViewInformationLabel"><strong>Página web:</strong></p>
                  <p class="closeViewInformationLabel"><strong>Rappi:</strong></p>
                  <p class="closeViewInformationLabel"><strong>Uber:</strong></p>
                  <br>
                  <p class="closeViewInformationTotalLabel"><strong>TOTAL:</strong></p>
                </div>
                <div style="width: 50%; text-align: right;">
                  <p class="closeViewInformationLabel">{{ `₡ ${$generalFunctions.default.methods.parseAmountToDisplay(getBillPaymentTypeAmount('Efectivo', 'billMixedPaymentCashAmount'))}` }}</p>
                  <p class="closeViewInformationLabel">{{ `₡ ${$generalFunctions.default.methods.parseAmountToDisplay(getBillPaymentTypeAmount('Tarjeta', 'billMixedPaymentCardAmount'))}` }}</p>
                  <p class="closeViewInformationLabel">{{ `₡ ${$generalFunctions.default.methods.parseAmountToDisplay(getBillPaymentTypeAmount('SINPE', 'billMixedPaymentSINPEAmount'))}` }}</p>
                  <p class="closeViewInformationLabel">{{ `₡ ${$generalFunctions.default.methods.parseAmountToDisplay(getBillPaymentTypeAmount('Transferencia o depósito', 'billMixedPaymentDepositAmount'))}` }}</p>
                  <p class="closeViewInformationLabel">{{ `₡ ${$generalFunctions.default.methods.parseAmountToDisplay(getBillPaymentTypeAmount('Página web', null))}` }}</p>
                  <p class="closeViewInformationLabel">{{ `₡ ${$generalFunctions.default.methods.parseAmountToDisplay(getBillPaymentTypeAmount('Rappi', null))}` }}</p>
                  <p class="closeViewInformationLabel">{{ `₡ ${$generalFunctions.default.methods.parseAmountToDisplay(getBillPaymentTypeAmount('Uber', null))}` }}</p>
                  <br>
                  <p class="closeViewInformationTotalLabel">{{ `₡ ${$generalFunctions.default.methods.parseAmountToDisplay(getBillsTotalAmount())}` }}</p>
                </div>
              </div>
            </div>
            <br>
          </div>
          
          <div class="closeViewInformationResultLabelContainer">
            <v-text-field @wheel.prevent v-model="input.closingCashAmount" type="number" hide-spin-buttons append-inner-icon="mdi-cash" label="Cantidad en efectivo" variant="solo-filled" density="compact"></v-text-field>
            <v-text-field @wheel.prevent v-model="input.closingCardAmount" type="number" hide-spin-buttons append-inner-icon="mdi-credit-card" label="Cantidad en tarjeta" variant="solo-filled" density="compact"></v-text-field>
            <v-text-field @wheel.prevent v-model="input.closingSINPEAmount" type="number" hide-spin-buttons append-inner-icon="mdi-cellphone" label="Cantidad en SINPE" variant="solo-filled" density="compact"></v-text-field>
            <v-text-field @wheel.prevent v-model="input.closingDepositAmount" type="number" hide-spin-buttons append-inner-icon="mdi-bank" label="Cantidad en depósito o transferencia" variant="solo-filled" density="compact"></v-text-field>
            <v-text-field @wheel.prevent v-model="input.closingWebpageAmount" type="number" hide-spin-buttons append-inner-icon="mdi-web" label="Cantidad en página web" variant="solo-filled" density="compact"></v-text-field>
            <v-text-field @wheel.prevent v-model="input.closingRappiAmount" type="number" hide-spin-buttons append-inner-icon="mdi-alpha" label="Cantidad en Rappi" variant="solo-filled" density="compact"></v-text-field>
            <v-text-field @wheel.prevent v-model="input.closingUberAmount" type="number" hide-spin-buttons append-inner-icon="mdi-beta" label="Cantidad en Uber" variant="solo-filled" density="compact"></v-text-field>
            <v-text-field @wheel.prevent v-model="input.closingExpenseAmount" type="number" hide-spin-buttons append-inner-icon="mdi-store" label="Cantidad de gastos" variant="solo-filled" density="compact"></v-text-field>
            <br>
            <div style="display: flex; width: 100%;">
              <div style="width: 50%; text-align: left;">
                <p style="font-size: 30px;"><strong>Total reportado:</strong></p>
              </div>
              <div style="width: 50%; text-align: right;">
                <div v-if="isNaN(getBillsReportedTotalAmount()) == false">
                  <p style="font-size: 30px;">{{ `₡ ${$generalFunctions.default.methods.parseAmountToDisplay(getBillsReportedTotalAmount())}` }}</p>
                </div>
                <div v-else>
                  <p style="font-size: 30px;">Error</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br><br>

      <div v-if="loader.generateClose" style="text-align: center;">
        <v-progress-circular color="dark" indeterminate></v-progress-circular>
      </div>
      <v-btn v-else @click="generateClose()" height="38" dark block color="text">
        <h3>GENERAR CIERRE</h3>
        <v-icon
          :color="navigation.printerAvailable ? 'green' : 'red'"
          size="20"
          dark
          style="margin-left: 10px;"
        >
          {{ navigation.printerAvailable ? 'mdi-printer-check' : 'mdi-printer-off' }}
        </v-icon>
      </v-btn>
    </v-card>
    <br><br>
  </div>
</template>

<style scoped>
  .closeViewContainer {
    margin: 3% 2% 0% 2%;
  }

  .closeViewInputsContainer {
    padding: 1.5%;
    background-color: #e9e8e8;
  }

  .selectTodayChip {
    margin: 0% 5% 5% 5%;
    text-align: center;
    cursor: pointer;
  }

  .closeViewInformationContainer {
    padding: 1%;
    background-color: #e9e8e8;
  }

  .closeViewInformationMainContainer {
    display: flex;
  }

  .closeViewInformationTableContainer {
    width: 64.5%;
    margin-right: 0.5%;
    min-height: 1000px;
    max-height: 1000px;
    overflow-y: auto;
    padding: 1.5%;
    background-color: #c3c3c3;

  }

  .closeViewInformationResultContainer {
    width: 34.5%;
    margin-left: 0.5%;
  }

  .closeViewInformationLabel strong {
    display: inline-block;
    text-align:left;
  }

  .closeViewInformationResultLabelContainer {
    background-color: #c3c3c3;
    padding: 2%;
  }

  .closeViewInformationLabel {
    font-size: 28px;
  }

  .closeViewInformationTotalLabel {
    font-size: 35px;
  }

  .coinIcon {
    width: 50px; 
    height: 50px;
  }

  .billIcon {
    width: 90px; 
    height: 50px;
  }
  
</style>

<script>
import { defineComponent } from 'vue';
import axios from 'axios';

export default defineComponent({
  name: 'CloseView',
  inject: ['$generalFunctions'],

  
  data: () => ({
    input: 
    {
      closingCashAmount: 0,
      closingCardAmount: 0,
      closingSINPEAmount: 0,
      closingDepositAmount: 0,
      closingWebpageAmount: 0,
      closingRappiAmount: 0,
      closingUberAmount: 0,
      closingExpenseAmount: 0
    },

    navigation: 
    {
      printerAvailable: false
    },

    loader: 
    {
      selectBillOnCloseView: false,
      generateClose: false
    },

    display:
    {
      billsRows: [],
      billsHeaders: 
      [
        {key: 'billID', title: 'ID'},
        {key: 'billState', title: 'ESTADO'},
        {key: 'billAgentName', title: 'FACTURADOR'},
        {key: 'clientName', title: 'CLIENTE'},
        {key: 'billCreationDatetime', title: 'FECHA'},
        {key: 'billType', title: 'TIPO'},
        {key: 'billAmount', title: 'MONTO'},
        {key: 'billPaymentType', title: 'MÉTODO'},
        {key: 'billAction', title: null}        
      ],

      saleByAgentRows: [],
      saleByAgentHeaders: 
      [
        {key: 'agentName', title: 'FACTURADOR'},
        {key: 'amount', title: 'TOTAL'}
      ],

      dolarExchangeRate: null,
      localityPaymentTerminals: [],
      localitySINPEAccounts: [],
      localityDepositAccounts: []
    },

    localStorage: 
    {
      localityID: null,
      agentID: null,
      agentName: null,
      billStateColors: null
    },

    accessCredential: {}

  }),

  watch: {
  },

  methods: {
    PrintCountin(){
      const printInformation = 
      {
        'closeCopies': 1,
        'agentName': this.localStorage.agentName,
        'localityName':this.localStorage.localityName,
        'CreationDatetime': new Date().toISOString().replace('T', ' ').split('.')[0],
        'countCashAmount': parseInt(this.input.closingCashAmount),
        'countCardAmount': parseInt(this.input.closingCardAmount),
        'countSinpeAmount': parseInt(this.input.closingSINPEAmount),
        'countDepositAmount': parseInt(this.input.closingDepositAmount),
        'countWebPageAmount': parseInt(this.input.closingWebpageAmount),
        'countRappiAmount': parseInt(this.input.closingRappiAmount),
        'countUberAmount': parseInt(this.input.closingUberAmount),
        'countExpenseAmount': parseInt(this.input.closingExpenseAmount),
        'countTotal': parseInt(this.getBillsReportedTotalAmount()),
        'closeTotal': parseInt(this.getBillsTotalAmount()),
        'closeCashAmount': parseInt(this.getBillPaymentTypeAmount('Efectivo', 'billMixedPaymentCashAmount')),
        'closeCardAmount': parseInt(this.getBillPaymentTypeAmount('Tarjeta', 'billMixedPaymentCardAmount')),
        'closeSinpeAmount': parseInt(this.getBillPaymentTypeAmount('SINPE', 'billMixedPaymentSINPEAmount')),
        'closeDepositAmount': parseInt(this.getBillPaymentTypeAmount('Transferencia o depósito', 'billMixedPaymentDepositAmount')),
        'closeWebpageAmount': parseInt(this.getBillPaymentTypeAmount('Página web', null)),
        'closeUberAmount': parseInt(this.getBillPaymentTypeAmount('Uber', null)),
        'closeRappiAmount': parseInt(this.getBillPaymentTypeAmount('Rappi', null)),
        'serviceTax':0,
        'salesByAgent':this.display.saleByAgentRows,
        "billSpecialTax": 0,
        "closeExchangeRate": this.display.dolarExchangeRate,
      };
      this.$generalFunctions.default.methods.printCounterRequest(printInformation);
    },
    openBillDialog(bill){
      const billDialogRequestQuery = 
      {
        'billID': bill.billID
      };
      this.$root.billDialog.openBillDialog(billDialogRequestQuery);
    },

    async openPrintableBillDialog(bill){
      bill.loader = true;
      const createBillPDFDocumentRequestQuery = 
      {
        'billID': bill.billID
      };
      const createBillPDFDocumentResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/functions/createBillPDFDocument', createBillPDFDocumentRequestQuery);
      if (createBillPDFDocumentResult.success){
        this.$root.printableBillDialog.showPrintableBillDialog(createBillPDFDocumentResult.result);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al imprimir la factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      bill.loader = false;
    },

    async generateClose(){
      this.loader.generateClose = true;
      const generateCloseRequestQuery = 
      {
        'closingLocalityID': this.localStorage.localityID,
        'closingAgentID': this.localStorage.agentID,
        'closingRequiredAmount': this.getBillsTotalAmount(),
        'closingRequiredCashAmount': this.getBillPaymentTypeAmount('Efectivo', 'billMixedPaymentCashAmount'),
        'closingCashAmount': this.input.closingCashAmount,
        'closingRequiredCardAmount': this.getBillPaymentTypeAmount('Tarjeta', 'billMixedPaymentCardAmount'),
        'closingCardAmount': this.input.closingCardAmount,
        'closingRequiredSINPEAmount': this.getBillPaymentTypeAmount('SINPE', 'billMixedPaymentSINPEAmount'),
        'closingSINPEAmount': this.input.closingSINPEAmount,
        'closingRequiredDepositAmount': this.getBillPaymentTypeAmount('Transferencia o depósito', 'billMixedPaymentDepositAmount'),
        'closingDepositAmount': this.input.closingDepositAmount,
        'closingRequiredWebpageAmount': this.getBillPaymentTypeAmount('Página web', null),
        'closingWebpageAmount': this.input.closingWebpageAmount,
        'closingRequiredRappiAmount': this.getBillPaymentTypeAmount('Rappi', null),
        'closingRappiAmount': this.input.closingRappiAmount,
        'closingRequiredUberAmount': this.getBillPaymentTypeAmount('Uber', null),
        'closingUberAmount': this.input.closingUberAmount,
        'closingExpenseAmount': this.input.closingExpenseAmount
      };
      
      const generateCloseResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/functions/generateClose', generateCloseRequestQuery);
      if (generateCloseResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'Se ha generado el cierre exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': generateCloseResult.result.documentFile.data,
          'printablePDFDocumentName': generateCloseResult.result.documentName,
          'printableExcelDocumentFile': null,
          'printableExcelDocumentName': null
        };
        if (this.accessCredential['/close/generatePDF']){
          this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
        }
        this.PrintCountin();
        await this.selectBillOnCloseView();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el cierre, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.generateClose = false;
    },
    
    async selectBillOnCloseView(){
      this.loader.selectBillOnCloseView = true;
      const selectBillOnCloseViewRequestQuery = 
      {
        'billLocalityID': this.localStorage.localityID
      };
      const selectBillOnCloseViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/functions/selectBillOnCloseView', selectBillOnCloseViewRequestQuery);
      if (selectBillOnCloseViewResult.success){
        this.display.billsRows = selectBillOnCloseViewResult.result.billsRows;
        this.display.saleByAgentRows = selectBillOnCloseViewResult.result.saleByAgentRows;

        this.input.closingCashAmount = this.getBillPaymentTypeAmount('Efectivo', 'billMixedPaymentCashAmount');
        this.input.closingCardAmount = this.getBillPaymentTypeAmount('Tarjeta', 'billMixedPaymentCardAmount');
        this.input.closingSINPEAmount = this.getBillPaymentTypeAmount('SINPE', 'billMixedPaymentSINPEAmount');
        this.input.closingDepositAmount = this.getBillPaymentTypeAmount('Transferencia o depósito', 'billMixedPaymentDepositAmount');
        this.input.closingWebpageAmount = this.getBillPaymentTypeAmount('Página web', null);
        this.input.closingRappiAmount = this.getBillPaymentTypeAmount('Rappi', null);
        this.input.closingUberAmount = this.getBillPaymentTypeAmount('Uber', null);


        if (this.display.billsRows.length == 0){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': '',
            'notificationDialogBody': 'No se ha encontrado ninguna factura con las características especificadas, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'info',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las facturas, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectBillOnCloseView = false;
    },

    async selectDolarExchangeRate(){
      this.display.dolarExchangeRate = await this.$generalFunctions.default.methods.selectDolarExchangeRate();
    },

    getBillPaymentTypeAmount(billPaymentType, billMixedPaymentType){
      var billPaymentTypeAmount = 0;
      for (var billIndex in this.display.billsRows){
        const billRow = this.display.billsRows[billIndex];
        if (billRow.billPaymentType == billPaymentType){
          billPaymentTypeAmount = billPaymentTypeAmount + billRow.billAmount;
        } else if (billRow.billPaymentType == 'Mixto'){
          if (billRow[billMixedPaymentType]){
            billPaymentTypeAmount = billPaymentTypeAmount + billRow[billMixedPaymentType];
          }
        }
      }
      return billPaymentTypeAmount;
    },

    getBillsTotalAmount(){
      var billsTotalAmount = 0;
      for (var billIndex in this.display.billsRows){
        const billRow = this.display.billsRows[billIndex];
        billsTotalAmount = billsTotalAmount + billRow.billAmount;
      }
      return billsTotalAmount;
    },

    getBillsReportedTotalAmount(){
      const sumAmounts = (...amounts) => amounts.reduce((total, amount) => total + (isNaN(amount) ? 0 : parseInt(amount)), 0);
      const total = sumAmounts(
        this.input.closingCashAmount,
        this.input.closingCardAmount,
        this.input.closingSINPEAmount,
        this.input.closingDepositAmount,
        this.input.closingWebpageAmount,
        this.input.closingRappiAmount,
        this.input.closingUberAmount
      );
      return total;
    },

    printerStatus(){
      axios.get('http://localhost:5001/status').then(() => {
        this.navigation.printerAvailable = true;
      })
      .catch(() => {
        this.navigation.printerAvailable = false;
      });
    },

    async setDefaultValues(){
      this.input.closingCashAmount = 0;
      this.input.closingCardAmount = 0;
      this.input.closingSINPEAmount = 0;
      this.input.closingDepositAmount = 0;
      this.input.closingWebpageAmount = 0;
      this.input.closingRappiAmount = 0;
      this.input.closingUberAmount = 0;
      this.input.closingExpenseAmount = 0;
      this.navigation.printerAvailable = false;
      this.loader.selectBillOnCloseView = false;
      this.display.billsRows = [];
      this.display.saleByAgentRows = [];
      this.display.dolarExchangeRate = null;
      this.display.localityPaymentTerminals = [];
      this.display.localitySINPEAccounts = [];
      this.display.localityDepositAccounts = [];
      this.localStorage.localityID = null;
      this.localStorage.agentName = localStorage.getItem('agentName');
      this.localStorage.billStateColors = null;
    },

    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.localStorage.localityName = localStorage.getItem('localityName');
      this.localStorage.billStateColors = this.$generalFunctions.default.data.billStateColors;
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    },
  },

  async mounted(){
    await this.setDefaultValues();
    this.printerStatus();
    await this.getLocalStorageData();
    await this.selectBillOnCloseView();
    await this.selectDolarExchangeRate();
  }

});

</script>
