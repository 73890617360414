<template>
  <div style="margin: 2% 3% 0% 3%;">
    <v-dialog v-model="navigation.billXMLDialogIsVisible" width="auto">
      <v-card width="1500" style="padding: 20px;">
        <pre style="white-space: pre-wrap; word-break: break-word;">{{ navigation.billXML }}</pre>
      </v-card>
    </v-dialog>
    <v-card color="border" elevation="3" style="padding: 20px;">
      <v-expansion-panels v-model="navigation.searchVisible">
        <v-expansion-panel value="searchVisible">
          <v-expansion-panel-title>
            <p style="font-size: large;">Búsqueda de facturas previas</p>
            <template v-slot:actions>
              <v-icon icon="mdi-magnify"></v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <div v-if="input.searchByClientSSN">
              <div style="display: flex;">
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-switch 
                      v-bind="props"
                      v-model="input.searchByClientSSN" 
                      label="Búsqueda por cliente" 
                      hide-details 
                      color="secondary"
                      style="margin-left: auto;"
                    ></v-switch>
                  </template>
                  <span style="max-width: 400px; display: block;">Si la búsqueda por cliente está activada, se buscarán facturas tomando en cuenta el cliente asignado como comprador al realizar la factura</span>
                </v-tooltip>
              </div>
              <br>
              <v-tooltip open-delay="2000" location="top">
                <template v-slot:activator="{ props }">
                  <v-autocomplete 
                    v-bind="props"
                    v-model="input.selectedClientSSN" 
                    :append-inner-icon="input.selectedClientSSN == null ? 'mdi-account' : 'mdi-account-off'" 
                    @click:append-inner="clearClientSearch()"  
                    :items="display.clientOptions" 
                    :loading="loader.selectClientForClientSearch" 
                    :disabled="loader.selectClientForClientSearch" 
                    item-title="ac" 
                    item-value="ssn" 
                    label="Filtrar por cliente" 
                    variant="solo-filled" 
                    density="compact"
                  ></v-autocomplete>
                </template>
                <span style="max-width: 400px; display: block;">El cliente es la persona o empresa que compra, y que es asignado al realizar la factura (ya sea factura electrónica o no). Al colocar un cliente, se buscarán todas las facturas asignadas al mismo. Al presionar el ícono de la derecha se limpia el cliente seleccionado</span>
              </v-tooltip>
            </div>
            <div v-else>
              <div style="display: flex;">
                <div style="display: flex; margin-left: auto;">
                  <v-tooltip open-delay="2000" location="top">
                    <template v-slot:activator="{ props }">
                      <v-switch 
                        v-bind="props" 
                        v-model="input.stackableFilter" 
                        label="Filtros acumulables" 
                        hide-details 
                        color="secondary" 
                      ></v-switch>
                    </template>
                    <span style="max-width: 400px; display: block;">Si los filtros son acumulables, se buscarán solo las facturas que cumplan con TODOS los filtros seleccionados. Si los filtros no son acumulables, se buscarán todas las facturas que cumplan con AL MENOS UNO de los filtros seleccionados. Las fechas de consulta SIEMPRE son acumulables, es decir, que siempre se toman en cuenta al realizar la consulta de las facturas</span>
                  </v-tooltip>
                  <v-tooltip open-delay="2000" location="top">
                    <template v-slot:activator="{ props }">
                      <v-switch 
                        v-bind="props"
                        v-model="input.searchByClientSSN" 
                        label="Búsqueda por cliente" 
                        hide-details 
                        color="secondary"
                        style="margin-left: 20px;"
                      ></v-switch>
                    </template>
                    <span style="max-width: 400px; display: block;">Si la búsqueda por cliente está activada, se buscarán facturas tomando en cuenta el cliente asignado como comprador al realizar la factura</span>
                  </v-tooltip>
                </div>
              </div>              
              <br>
              <div style="display: flex;">
                <div style="width: 49.5%; margin-right: 0.5%;">
                  <v-tooltip open-delay="2000" location="top">
                    <template v-slot:activator="{ props }">
                      <v-text-field 
                        v-bind="props"
                        v-model="input.billID" 
                        @keydown.enter.prevent="selectBillOnBillsView()"
                        :append-inner-icon="input.billID ? 'mdi-barcode-off' : 'mdi-barcode'" 
                        @click:append-inner="input.billID = null" 
                        label="Buscar por identificador interno" 
                        variant="solo-filled" 
                        density="compact"
                      ></v-text-field>
                    </template>
                    <span style="max-width: 400px; display: block;">El identificador interno es el consecutivo único de la factura que se genera de forma automática a nivel interno. Puede ser encontrado en el tiquete de caja bajo el nombre de "ID interno". Si se coloca un identificador interno, se buscará la única factura que coincida con el mismo. Al presionar el ícono de la derecha se limpia el identificador interno colocado</span>
                  </v-tooltip>
                  <v-tooltip open-delay="2000" location="top">
                    <template v-slot:activator="{ props }">
                      <v-autocomplete 
                        v-if="accessCredential['/bills/selectLocality']" 
                        v-bind="props"
                        v-model="input.selectedLocalityID"
                        :append-inner-icon="input.selectedLocalityID.length == 0 ? 'mdi-store' : 'mdi-store-off'" 
                        @click:append-inner="input.selectedLocalityID.length = 0"  
                        :items="display.localityOptions" 
                        :loading="loader.selectLocalityIDAndLocalityNameByLocalityIsActive" 
                        :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive" 
                        multiple
                        chips
                        item-title="localityName" 
                        item-value="localityID" 
                        label="Filtrar por localidad" 
                        placeholder="Todas las localidades"
                        variant="solo-filled" 
                        density="compact"
                      ></v-autocomplete>
                    </template>
                    <span style="max-width: 400px; display: block;">La localidad es el lugar desde donde se realizó la factura. Al seleccionar una o varias localidades, la lista de facturadores se actualiza con la lista de los facturadores asignados a la o las localidades seleccionadas. Este espacio permite selección múltiple para filtrar por varias localidades en paralelo. Si ninguna localidad es seleccionada, se buscarán todas las facturas sin importar la localidad. Al presionar el ícono de la derecha se limpian las localidades seleccionadas</span>
                  </v-tooltip>
                  <v-tooltip open-delay="2000" location="top">
                    <template v-slot:activator="{ props }">
                      <v-autocomplete 
                        v-bind="props"
                        v-model="input.selectedAgentID" 
                        :append-inner-icon="input.selectedAgentID.length == 0 ? 'mdi-account' : 'mdi-account-off'" 
                        @click:append-inner="input.selectedAgentID.length = 0" 
                        :items="display.agentOptions" 
                        :loading="loader.selectAgentByAgentLocalityIDAndByAgentIsActive" 
                        :disabled="loader.selectAgentByAgentLocalityIDAndByAgentIsActive" 
                        multiple 
                        chips 
                        item-title="agentName" 
                        item-value="agentID" 
                        label="Filtrar por facturador" 
                        placeholder="Todos los facturadores" 
                        variant="solo-filled" 
                        density="compact"
                      ></v-autocomplete>
                    </template>
                    <span style="max-width: 400px; display: block;">El facturador es el colaborador que fue seleccionado como vendedor a la hora de realizar la factura. Este espacio permite selección múltiple para filtrar por varios facturadores en paralelo. Si ningún facturador es seleccionado, se buscarán todas las facturas sin importar el facturador. Al presionar el ícono de la derecha se limpian los facturadores seleccionados</span>
                  </v-tooltip>
                  <v-tooltip open-delay="2000" location="top">
                    <template v-slot:activator="{ props }">
                      <v-autocomplete 
                        v-bind="props"
                        v-model="input.selectedBillPaymentType" 
                        :append-inner-icon="input.selectedBillPaymentType.length == 0 ? 'mdi-cash' : 'mdi-cash-off'" 
                        @click:append-inner="input.selectedBillPaymentType.length = 0" 
                        :items="display.billPaymentTypeOptions" 
                        multiple 
                        chips 
                        label="Filtrar por método de pago" 
                        placeholder="Todos los métodos de pago" 
                        variant="solo-filled" 
                        density="compact"
                      ></v-autocomplete>
                    </template>
                    <span style="max-width: 400px; display: block;">El método de pago con el que fue cancelada la factura. Este espacio permite selección múltiple para filtrar por varios métodos de pago en paralelo. Si ningún método de pago es seleccionado, se buscarán todas las facturas sin importar el método de pago. Al presionar el ícono de la derecha se limpian los métodos de pago seleccionados</span>
                  </v-tooltip>                    
                </div>
                <div style="width: 49.5%; margin-left: 0.5%;">
                  <v-tooltip open-delay="2000" location="top">
                    <template v-slot:activator="{ props }">
                      <v-text-field 
                        v-bind="props"
                        @keydown.enter.prevent="selectBillOnBillsView()"
                        v-model="input.billTaxID" 
                        :append-inner-icon="input.billTaxID ? 'mdi-qrcode-remove' : 'mdi-qrcode'" 
                        @click:append-inner="input.billTaxID = null" 
                        label="Buscar por clave numérica" 
                        variant="solo-filled" 
                        density="compact"
                      ></v-text-field>
                    </template>
                    <span style="max-width: 400px; display: block;">La clave numérica es la clave única de 20 dígitos que el Ministerio de Hacienda asigna a cada documento electrónico que se envía para su validación. Puede ser encontrado en el tiquete de caja después del total de venta de la factura. Si se coloca una clave numérica, se buscará la única factura que coincida con la misma. Al presionar el ícono de la derecha se limpia la clave numérica colocada</span>
                  </v-tooltip>
                  <v-text-field v-model="display.startDateFormatted" @click="navigation.startDatePickerIsVisible = true" readonly :label="`Filtrar por fecha ${accessCredential['/bills/selectEndDate'] ? 'inicial' : ''}`" append-inner-icon="mdi-calendar" variant="solo-filled" density="compact"></v-text-field>
                  <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
                    <v-card style="display: flex;">
                      <v-date-picker v-model="input.startDate" :min="getMinimumDate()" :max="getMaximumDate()" hide-header color="primary" width="100%"></v-date-picker>
                      <v-chip @click="input.startDate = new Date()" color="primary" variant="flat" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;">Hoy</v-chip>
                    </v-card>
                  </v-dialog>
                  <v-text-field v-if="accessCredential['/bills/selectEndDate']" v-model="display.endDateFormatted" @click="navigation.endDatePickerIsVisible = true" readonly label="Filtrar por fecha final" append-inner-icon="mdi-calendar" variant="solo-filled" density="compact"></v-text-field>
                  <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
                    <v-card>
                      <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
                      <v-chip @click="input.endDate = new Date()" color="primary" variant="flat" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;">Hoy</v-chip>
                    </v-card>
                  </v-dialog>
                  <v-tooltip open-delay="2000" location="top">
                    <template v-slot:activator="{ props }">
                      <v-autocomplete 
                        v-bind="props"
                        v-model="input.selectedBillType" 
                        :append-inner-icon="input.selectedBillType.length == 0 ? 'mdi-text-box-outline' : 'mdi-text-box-minus-outline'" 
                        @click:append-inner="input.selectedBillType.length = 0" 
                        :items="display.billTypeOptions" 
                        multiple 
                        chips 
                        label="Filtrar por tipo de factura" 
                        placeholder="Todos los tipos de factura" 
                        variant="solo-filled" 
                        density="compact"
                      ></v-autocomplete>
                    </template>
                    <span style="max-width: 400px; display: block;">El tipo de factura que fue seleccionado a la hora de realizar la factura. Este espacio permite selección múltiple para filtrar por varios tipos de factura en paralelo. Si ningún tipo de factura es seleccionado, se buscarán todas las facturas sin importar el tipo de factura. Al presionar el ícono de la derecha se limpian los tipos de factura seleeccionados</span>
                  </v-tooltip>
                </div>
              </div>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-title>
                    Búsqueda avanzada por producto
                    <template v-slot:actions>
                      <v-icon icon="mdi-magnify"></v-icon>
                    </template>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <br>
                    <v-tooltip open-delay="2000" location="top">
                      <template v-slot:activator="{ props }">
                        <v-autocomplete 
                          v-bind="props"
                          v-model="input.selectedProductID" 
                          v-model:search="input.searchedProductID"
                          :append-inner-icon="input.selectedProductID ? 'mdi-alphabetical-variant-off' : 'mdi-alphabetical-variant'" 
                          @click:append-inner="cleanProductIDInput()" 
                          :items="display.productOptions" 
                          :loading="loader.selectProductBasicBasicInformationForBillsView" 
                          :disabled="loader.selectProductBasicBasicInformationForBillsView" 
                          @keydown.enter.prevent="selectProductByScanner()"
                          ref="selectedProductIDInput" 
                          item-title="productAutocompleteTitle" 
                          item-value="productID" 
                          label="Filtrar por producto" 
                          placeholder="Todos los productos" 
                          variant="solo-filled" 
                          density="compact"
                        ></v-autocomplete>
                      </template>
                      <span style="max-width: 400px; display: block;">Este espacio permite la búsqueda o escaneo de un producto. Si ningún producto es seleccionado, se buscarán todas las facturas sin importar los productos facturados en las mismas. Al presionar el ícono de la derecha se limpia el producto seleccionado</span>
                    </v-tooltip>
                    <div style="display: flex;">
                      <div style="width: 49.5%; margin-right: 0.5%;">
                        <v-tooltip open-delay="2000" location="top">
                          <template v-slot:activator="{ props }">
                            <v-autocomplete 
                              v-bind="props"
                              v-model="input.selectedProductCategory" 
                              :append-inner-icon="input.selectedProductCategory.length == 0 ? 'mdi-cog' : 'mdi-cog-off'" 
                              @click:append-inner="input.selectedProductCategory.length = 0" 
                              :items="display.productCategoryOptions" 
                              :loading="loader.selectProductSearchParameters" 
                              :disabled="loader.selectProductSearchParameters" 
                              multiple 
                              chips 
                              item-title="productCategoryName" 
                              item-value="productCategoryID" 
                              label="Filtrar por categoría del producto" 
                              placeholder="Todas las categorías del producto" 
                              variant="solo-filled" 
                              density="compact"
                            ></v-autocomplete>
                          </template>
                          <span style="max-width: 400px; display: block;">La categoría del producto hace referencia al primer nivel de categorización de un producto. Este espacio permite selección múltiple para filtrar por varias categorías de producto en paralelo. Si ninguna categoría de producto es seleccionada, se buscarán todas las facturas sin importar las categorías de los productos facturados en las mismas. Al presionar el ícono de la derecha se limpian las categorías de producto seleccionadas</span>
                        </v-tooltip>
                        <v-tooltip open-delay="2000" location="top">
                          <template v-slot:activator="{ props }">
                            <v-autocomplete 
                              v-bind="props"
                              v-model="input.selectedProductBrand" 
                              :append-inner-icon="input.selectedProductBrand.length == 0 ? 'mdi-briefcase-variant' : 'mdi-briefcase-variant-off'" 
                              @click:append-inner="input.selectedProductBrand.length = 0" 
                              :items="display.productBrandOptions" 
                              :loading="loader.selectProductSearchParameters" 
                              :disabled="loader.selectProductSearchParameters" 
                              multiple 
                              chips 
                              item-title="productBrandName" 
                              item-value="productBrandID" 
                              label="Filtrar por marca del producto" 
                              placeholder="Todas las marcas del producto" 
                              variant="solo-filled" 
                              density="compact"
                            ></v-autocomplete>
                          </template>
                          <span style="max-width: 400px; display: block;">La marca del producto hace referencia al segundo nivel de categorización de un producto. Este espacio permite selección múltiple para filtrar por varias marcas de producto en paralelo. Si ninguna marca de producto es seleccionada, se buscarán todas las facturas sin importar las marcas de los productos facturados en las mismas. Al presionar el ícono de la derecha se limpian las marcas de producto seleccionadas</span>
                        </v-tooltip>
                        <v-tooltip open-delay="2000" location="top">
                          <template v-slot:activator="{ props }">
                            <v-autocomplete 
                              v-bind="props"
                              v-model="input.selectedProductModel" 
                              :append-inner-icon="input.selectedProductModel.length == 0 ? 'mdi-source-branch' : 'mdi-source-branch-remove'" 
                              @click:append-inner="input.selectedProductModel.length = 0" 
                              :items="display.productModelOptions" 
                              :loading="loader.selectProductSearchParameters" 
                              :disabled="loader.selectProductSearchParameters" 
                              multiple 
                              chips 
                              item-title="productModelName" 
                              item-value="productModelID" 
                              label="Filtrar por modelo del producto" 
                              placeholder="Todos los modelos del producto" 
                              variant="solo-filled" 
                              density="compact"
                            ></v-autocomplete>
                          </template>
                          <span style="max-width: 400px; display: block;">El modelo del producto hace referencia al tercer nivel de categorización de un producto. Este espacio permite selección múltiple para filtrar por varios modelos de producto en paralelo. Si ningún modelo de producto es seleccionado, se buscarán todas las facturas sin importar los modelos de los productos facturados en las mismas. Al presionar el ícono de la derecha se limpian los modelos de producto seleccionados</span>
                        </v-tooltip>
                      </div>
                      <div style="width: 49.5%; margin-left: 0.5%;">
                        <v-tooltip open-delay="2000" location="top">
                          <template v-slot:activator="{ props }">
                            <v-autocomplete 
                              v-bind="props"
                              v-model="input.selectedProductFlavor" 
                              :append-inner-icon="input.selectedProductFlavor.length == 0 ? 'mdi-water' : 'mdi-water-off'" 
                              @click:append-inner="input.selectedProductFlavor.length = 0" 
                              :items="display.productFlavorOptions" 
                              :loading="loader.selectProductSearchParameters" 
                              :disabled="loader.selectProductSearchParameters" 
                              multiple 
                              chips 
                              item-title="productFlavorName" 
                              item-value="productFlavorID" 
                              label="Filtrar por sabor del producto" 
                              placeholder="Todos los sabores del producto" 
                              variant="solo-filled" 
                              density="compact"
                            ></v-autocomplete>
                          </template>
                          <span style="max-width: 400px; display: block;">El sabor del producto hace referencia al primer tipo de categorización opcional de un producto. Este espacio permite selección múltiple para filtrar por varios sabores de producto en paralelo. Si ningún sabor de producto es seleccionado, se buscarán todas las facturas sin importar los sabores de los productos facturados en las mismas. Al presionar el ícono de la derecha se limpian los sabores de producto seleccionados</span>
                        </v-tooltip>
                        <v-tooltip open-delay="2000" location="top">
                          <template v-slot:activator="{ props }">
                            <v-autocomplete 
                              v-bind="props"
                              v-model="input.selectedProductNicotineLevel" 
                              :append-inner-icon="input.selectedProductNicotineLevel.length == 0 ? 'mdi-cloud-outline' : 'mdi-cloud-off-outline'" 
                              @click:append-inner="input.selectedProductNicotineLevel.length = 0" 
                              :items="display.productNicotineLevelOptions" 
                              :loading="loader.selectProductSearchParameters" 
                              :disabled="loader.selectProductSearchParameters" 
                              multiple 
                              chips 
                              item-title="productNicotineLevelName" 
                              item-value="productNicotineLevelID" 
                              label="Filtrar por graduación de nicotina del producto"  
                              placeholder="Todas las graduaciones de nicotina del producto" 
                              variant="solo-filled" 
                              density="compact"
                            ></v-autocomplete>
                          </template>
                          <span style="max-width: 400px; display: block;">La graduación de nicotina del producto hace referencia al segundo tipo de categorización opcional de un producto. Este espacio permite selección múltiple para filtrar por varias graduaciones de nicotina de producto en paralelo. Si ninguna graduación de nicotina de producto es seleccionada, se buscarán todas las facturas sin importar las graduaciones de nicotina de los productos facturados en las mismas. Al presionar el ícono de la derecha se limpian las graduaciones de nicotina de producto seleccionadas</span>
                        </v-tooltip>
                        <v-tooltip open-delay="2000" location="top">
                          <template v-slot:activator="{ props }">
                            <v-autocomplete
                              v-bind="props" 
                              v-model="input.selectedProductSize" 
                              :append-inner-icon="input.selectedProductSize.length == 0 ? 'mdi-ruler' : 'mdi-pencil-ruler'" 
                              @click:append-inner="input.selectedProductSize.length = 0" 
                              :items="display.productSizeOptions" 
                              :loading="loader.selectProductSearchParameters" 
                              :disabled="loader.selectProductSearchParameters" 
                              multiple 
                              chips 
                              item-title="productSizeName" 
                              item-value="productSizeID" 
                              label="Filtrar por tamaño del producto" 
                              variant="solo-filled" 
                              placeholder="Todos los tamaños del producto" 
                              density="compact"
                            ></v-autocomplete>
                          </template>
                          <span style="max-width: 400px; display: block;">El tamaño del producto hace referencia al tercer tipo de categorización opcional de un producto. Este espacio permite selección múltiple para filtrar por varios tamaños de producto en paralelo. Si ningún tamaño de producto es seleccionado, se buscarán todas las facturas sin importar los tamaños de los productos facturados en las mismas. Al presionar el ícono de la derecha se limpian los tamaños de producto seleccionados</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
              <br><br>
              <div v-if="loader.selectBillOnBillsView" style="text-align: center;">
                <br>
                <v-progress-circular color="primary" indeterminate></v-progress-circular>
                <br><br>
              </div>
              <div v-else>
                <v-btn @click="selectBillOnBillsView()" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive || loader.selectAgentByAgentLocalityIDAndByAgentIsActive || loader.selectProductBasicBasicInformationForBillsView || loader.selectBillOnBillsView" dark block height="38" color="primary">
                  <h3>BUSCAR</h3>
                  <v-icon style="margin-left: 20px;" dark right>mdi-magnify</v-icon>
                </v-btn>
                <br>
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-btn 
                      v-bind="props" 
                      @click="setDefaultValues()" 
                      :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive || loader.selectAgentByAgentLocalityIDAndByAgentIsActive || loader.selectProductBasicBasicInformationForBillsView || loader.selectBillOnBillsView" 
                      dark 
                      block 
                      height="38" 
                      color="#b3b3b3"
                    >
                      <h3>LIMPIAR BÚSQUEDA</h3>
                      <v-icon style="margin-left: 20px;" dark right>mdi-backspace</v-icon>
                    </v-btn>
                  </template>
                  <span style="max-width: 400px; display: block;">Al presionar este botón, se limpiarán los resultados de la búsqueda actual, y se reestablecerán todos los filtros de búsqueda a sus valores por defecto</span>
                </v-tooltip>
              </div>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <br><br>
    <v-card v-if="display.billsRows.length > 0 || input.billRowsSearchQuery != null" color="border" elevation="3" style="padding: 20px;">
      <div style="display: flex;">
        <div style="width: 49.5%; margin-right: 0.5%;">
          <v-tooltip open-delay="2000" location="top">
            <template v-slot:activator="{ props }">
              <v-text-field
                v-bind="props" 
                v-model="input.billRowsSearchQuery" 
                :append-inner-icon="(input.billRowsSearchQuery == null) || (input.billRowsSearchQuery.trim() == '')  ? 'mdi-magnify' : 'mdi-magnify-remove-outline'" 
                @click:append-inner="input.billRowsSearchQuery = null" 
                label="Búsqueda rápida" 
                variant="solo-filled" 
                density="compact"
              ></v-text-field>
            </template>
            <span style="max-width: 400px; display: block;">La búsqueda rápida permite buscar en toda la lista de facturas por estado, localidad, facturador, cliente, tipo, monto y método de pago. Se buscan todas las facturas que coincidan parcial o completamente con el criterio de búsqueda colocado. Al presionar el ícono de la derecha se elimina el criterio de búsqueda colocado</span>
          </v-tooltip>
        </div>
        <div style="width: 49.5%; margin-left: 0.5%;">
          <v-tooltip open-delay="2000" location="top">
            <template v-slot:activator="{ props }">
              <v-autocomplete
                v-bind="props" 
                v-model="input.selectedBillRowsSortOptions" 
                :append-inner-icon="input.selectedBillRowsSortOptions.length == 0 ? 'mdi-sort-variant' : 'mdi-sort-variant-remove'" 
                @click:append-inner="clearSelectedBillRowsSortOptions()" 
                :items="display.billRowsSortOptions" 
                multiple 
                chips 
                label="Ordenar por" 
                variant="solo-filled" 
                density="compact"
              ></v-autocomplete>
            </template>
            <span style="max-width: 400px; display: block;">Las facturas inicialmente se ordenan por identificador interno. Este espacio permite selección múltiple para ordenar por varios criterios en paralelo, siempre tomando en cuenta el órden de selección de los criterios de ordenamiento (por ejemplo, al seleccionar localidad y luego facturador, ordenará primero por localidad, y luego cada localidad se ordenará por facturador). Al presionar el ícono de la derecha se eliminan todos los criterios de ordenamiento seleccionados</span>
          </v-tooltip>
        </div>
      </div>
      <v-data-table :headers="display.billsHeaders" :items="display.billsRows" :items-per-page="250" style="max-height: 700px; overflow-y: auto;">
        <template v-slot:item.billState="{ item }">
          <v-progress-circular v-if="item.billStateLoader" color="primary" indeterminate></v-progress-circular>
          <v-tooltip v-else :text="item.billType == 'Recibo' ? 'Sin clave numérica' : (item.billTaxID || 'Sin clave numérica')" location="top">
            <template v-slot:activator="{ props }">
              <v-chip v-bind="props" :color="localStorage.billStateColors[item.billState].billStateColor" variant="flat" style="cursor: pointer;">
                <p @click="checkBillState(item)" :style="{color: localStorage.billStateColors[item.billState].billStateFontColor}">{{ item.billState }}{{ item.billCancelationID ? ` (${item.billCancelationID})` : '' }}</p>
              </v-chip>
            </template>
          </v-tooltip>
        </template>
        <template v-slot:item.localityName="{ item }">
          <v-chip :color="item.localityColor" variant="flat">
            <p :style="{color: item.localityFontColor}">{{ item.localityName }}</p>
          </v-chip>
        </template>
        <template v-slot:item.billAgentName="{ item }">
          <v-chip :color="item.billAgentColor" variant="flat">
            <p :style="{color: item.billAgentFontColor}">{{ item.billAgentName }}</p>
          </v-chip>
        </template>
        <template v-slot:item.billCreationDatetime="{ item }">
          {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.billCreationDatetime) }}
        </template>
        <template v-slot:item.billAmount="{ item }">
          <div v-if="item.billCurrency == '₡'">
            {{ item.billCurrency }}{{ $generalFunctions.default.methods.parseAmountToDisplay(item.billAmount) }}
          </div>
          <div v-else>
            <v-tooltip v-if="item.billExchangeRate" location="top">
              <template v-slot:activator="{ props }">
                <p v-bind="props" style="cursor: pointer;">{{$generalFunctions.default.methods.parseAmountToDisplay(item.billAmount / item.billExchangeRate)}}{{item.billCurrency}}</p>
              </template>
              <p>₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(item.billAmount) }}</p>
              <p>(1$ = ₡{{ item.billExchangeRate }})</p>
            </v-tooltip>
            <p v-else>{{$generalFunctions.default.methods.parseAmountToDisplay(item.billAmount)}}{{item.billCurrency}} </p>
          </div>
        </template>
        <template v-slot:item.billPaymentType="{ item }">
          <v-tooltip v-if="item.billPaymentType == 'Mixto'" location="top">
            <template v-slot:activator="{ props }">
              <p v-bind="props" style="cursor: pointer;">{{ item.billPaymentType }}</p>
            </template>
            <p v-if="item.billMixedPaymentCashAmount != null"><strong>Efectivo: </strong>{{ item.billCurrency == '₡' ? `${item.billCurrency} ${$generalFunctions.default.methods.parseAmountToDisplay(item.billMixedPaymentCashAmount)}` : `${$generalFunctions.default.methods.parseAmountToDisplay(item.billMixedPaymentCashAmount)} ${item.billCurrency}` }}</p>
            <p v-if="item.billMixedPaymentCardAmount != null"><strong>Tarjeta: </strong>{{ item.billCurrency == '₡' ? `${item.billCurrency} ${$generalFunctions.default.methods.parseAmountToDisplay(item.billMixedPaymentCardAmount)}` : `${$generalFunctions.default.methods.parseAmountToDisplay(item.billMixedPaymentCardAmount)} ${item.billCurrency}` }}</p>
            <p v-if="item.billMixedPaymentSINPEAmount != null"><strong>SINPE: </strong>{{ item.billCurrency == '₡' ? `${item.billCurrency} ${$generalFunctions.default.methods.parseAmountToDisplay(item.billMixedPaymentSINPEAmount)}` : `${$generalFunctions.default.methods.parseAmountToDisplay(item.billMixedPaymentSINPEAmount)} ${item.billCurrency}` }}</p>
            <p v-if="item.billMixedPaymentDepositAmount != null"><strong>Transferencia o depósito: </strong>{{ item.billCurrency == '₡' ? `${item.billCurrency} ${$generalFunctions.default.methods.parseAmountToDisplay(item.billMixedPaymentDepositAmount)}` : `${$generalFunctions.default.methods.parseAmountToDisplay(item.billMixedPaymentDepositAmount)} ${item.billCurrency}` }}</p>
          </v-tooltip>
          <p v-else>{{ item.billPaymentType }}</p>
        </template>
        <template v-slot:item.billActions="{ item }">
          <v-menu open-on-hover>
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" dark height="38" color="primary">
                <h3>ACCIONES</h3>
                <v-icon style="margin-left: 20px;" dark right>mdi-information</v-icon>
              </v-btn>
            </template>
            <v-list density="compact">
              <v-list-item @click="openBillDialog(item.billID)">
                <template v-slot:append>
                  <v-icon icon="mdi-help-circle"></v-icon>
                </template>
                <v-list-item-title>Información de la factura</v-list-item-title>
              </v-list-item>
              <v-list-item @click="printBill(item.billID)">
                <template v-slot:append>
                  <v-icon :color="navigation.thermalPrinterAvailable ? 'success' : 'error'" :icon="navigation.thermalPrinterAvailable ? 'mdi-printer' : 'mdi-printer-off'"></v-icon>
                </template>
                <v-list-item-title>Reimprimir factura (POS)</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="item.billType != 'Recibo'" @click="createBillPDF(item.billTaxID)">
                <template v-slot:append>
                  <v-icon icon="mdi-file-pdf-box"></v-icon>
                </template>
                <v-list-item-title>Ver factura (PDF)</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="item.billState != 'Factura cancelada' && accessCredential['/bills/cancelBill']" @click="openBillCancelationDialog(item.billID)">
                <template v-slot:append>
                  <v-icon icon="mdi-close-circle"></v-icon>
                </template>
                <v-list-item-title>Cancelar factura</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <div v-if="accessCredential['/bills/result'] && display.billsRows.length > 0">
      <br><br>
      <v-card color="border" style="padding: 20px;">
        <div style="display: flex;">
          <div style="width: 18%;">
            <p style="font-size: larger;"><strong>Efectivo: </strong></p>
            <p style="font-size: larger;"><strong>Tarjeta: </strong></p>
            <p style="font-size: larger;"><strong>SINPE: </strong></p>
            <p style="font-size: larger;"><strong>Transferencia o depósito: </strong></p>
            <p style="font-size: larger;"><strong>Página web: </strong></p>
            <p style="font-size: larger;"><strong>Uber: </strong></p>
            <p style="font-size: larger;"><strong>Rappi: </strong></p>
            <br>
            <p style="font-size: x-large;"><strong>TOTAL: </strong></p>
          </div>
          <div style="width: 30%; margin-left: 2%;">
            <p style="font-size: larger;">₡ {{ display.billsRows.filter(billRow => billRow.billPaymentType == 'Efectivo').reduce((total, row) => total + row.billAmount, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
            <p style="font-size: larger;">₡ {{ display.billsRows.filter(billRow => billRow.billPaymentType == 'Tarjeta').reduce((total, row) => total + row.billAmount, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
            <p style="font-size: larger;">₡ {{ display.billsRows.filter(billRow => billRow.billPaymentType == 'SINPE').reduce((total, row) => total + row.billAmount, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
            <p style="font-size: larger;">₡ {{ display.billsRows.filter(billRow => billRow.billPaymentType == 'Transferencia o depósito').reduce((total, row) => total + row.billAmount, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
            <p style="font-size: larger;">₡ {{ display.billsRows.filter(billRow => billRow.billPaymentType == 'Página web').reduce((total, row) => total + row.billAmount, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
            <p style="font-size: larger;">₡ {{ display.billsRows.filter(billRow => billRow.billPaymentType == 'Uber').reduce((total, row) => total + row.billAmount, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
            <p style="font-size: larger;">₡ {{ display.billsRows.filter(billRow => billRow.billPaymentType == 'Rappi').reduce((total, row) => total + row.billAmount, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
            <br>
            <p style="font-size: larger;">₡ {{ display.billsRows.reduce((total, row) => total + row.billAmount, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
          </div>
          <div style="width: 18%;">
            <p style="font-size: larger;"><strong>Tiquete electrónico: </strong></p>
            <p style="font-size: larger;"><strong>Factura electrónica: </strong></p>
            <p style="font-size: larger;"><strong>Recibo: </strong></p>
            <br>
            <p style="font-size: x-large;"><strong>TOTAL: </strong></p>
          </div>
          <div style="width: 30%; margin-left: 2%;">
            <p style="font-size: larger;">₡ {{ display.billsRows.filter(billRow => billRow.billType == 'Tiquete electrónico').reduce((total, row) => total + row.billAmount, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
            <p style="font-size: larger;">₡ {{ display.billsRows.filter(billRow => billRow.billType == 'Factura electrónica').reduce((total, row) => total + row.billAmount, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
            <p style="font-size: larger;">₡ {{ display.billsRows.filter(billRow => billRow.billType == 'Recibo').reduce((total, row) => total + row.billAmount, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
            <br>
            <p style="font-size: larger;">₡ {{ display.billsRows.reduce((total, row) => total + row.billAmount, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
          </div>
        </div>
        <br>
        <div v-if="loader.generateReportOnBillsView" style="text-align: center;">
          <br>
          <v-progress-circular color="success" indeterminate></v-progress-circular>
          <br>
        </div>
        <v-btn v-else @click="generateReportOnBillsView()" dark block height="38" color="success">
          <h3>GENERAR REPORTE</h3>
          <v-icon style="margin-left: 20px;" dark right>mdi-printer</v-icon>
        </v-btn>
      </v-card>
    </div>
    <br><br>
  </div>
</template>

<style scoped>
</style>

<script>
import { defineComponent } from 'vue';
import { viewMethodCaller } from '../viewMethodCaller.js';

export default defineComponent({
  name: 'BillsView',
  inject: ['$generalFunctions'],

  
  data: () => ({
    input: 
    {
      searchByClientSSN: false,
      stackableFilter: true,
      selectedClientSSN: null,
      billID: null,
      billTaxID: null,
      selectedLocalityID: [],
      selectedAgentID: [],
      selectedBillPaymentType: [],
      startDate: null,
      endDate: null,
      selectedBillType: [],
      selectedProductID: null,
      searchedProductID: null,
      selectedProductCategory: [],
      selectedProductBrand: [],
      selectedProductModel: [],
      selectedProductFlavor: [],
      selectedProductNicotineLevel: [],
      selectedProductSize: [],
      billRowsSearchQuery: null,
      selectedBillRowsSortOptions: []
    },

    navigation: 
    {
      searchVisible: 'searchVisible',
      startDatePickerIsVisible: false,
      endDatePickerIsVisible: false,
      selectBillOnBillsView: false,
      billDialogBillID: null,
      billXMLDialogIsVisible: false,
      billXML: null,
      thermalPrinterAvailable: false
    },

    loader: 
    {
      selectClientForClientSearch: false,
      selectLocalityIDAndLocalityNameByLocalityIsActive: false,
      selectAgentByAgentLocalityIDAndByAgentIsActive: false,
      selectProductBasicBasicInformationForBillsView: false,
      selectProductSearchParameters: false,
      selectBillOnBillsView: false,
      generateReportOnBillsView: false
    },

    display:
    {
      localityOptions: [],
      agentOptions: [],
      billPaymentTypeOptions: 
      [
        {title: 'Efectivo', value: 'Efectivo'},
        {title: 'Tarjeta', value: 'Tarjeta'},
        {title: 'SINPE', value: 'SINPE'},
        {title: 'Transferencia o depósito', value: 'Transferencia o depósito'},
        {title: 'Página web', value: 'Página web'},
        {title: 'Uber', value: 'Uber'},
        {title: 'Rappi', value: 'Rappi'},
        {title: 'Mixto', value: 'Mixto'}
      ],
      startDateFormatted: '',
      endDateFormatted: '',
      billTypeOptions: 
      [
        {title: 'Factura electrónica', value: 'Factura electrónica'},
        {title: 'Tiquete electrónico', value: 'Tiquete electrónico'},
        {title: 'Recibo', value: 'Recibo'}
      ],
      clientOptions: [],
      productOptions: [],
      productCategoryOptions: [],
      productBrandOptions: [],
      productModelOptions: [],
      productFlavorOptions: [],
      productNicotineLevelOptions: [],
      productSizeOptions: [],
      billRowsSortOptions: 
      [
        {title: 'Estado (A-Z)', value: 'billState'},
        {title: 'Estado (Z-A)', value: 'reverse_billState'},
        {title: 'Localidad (A-Z)', value: 'localityName'},
        {title: 'Localidad (Z-A)', value: 'reverse_localityName'},
        {title: 'Facturador (A-Z)', value: 'billAgentName'},
        {title: 'Facturador (Z-A)', value: 'reverse_billAgentName'},
        {title: 'Cliente (A-Z)', value: 'clientName'},
        {title: 'Cliente (Z-A)', value: 'reverse_clientName'},
        {title: 'Fecha (más reciente primero)', value: 'billCreationDatetime'},
        {title: 'Fecha (más antiguo primero)', value: 'reverse_billCreationDatetime'},
        {title: 'Tipo (A-Z)', value: 'billType'},
        {title: 'Tipo (Z-A)', value: 'reverse_billType'},
        {title: 'Monto (mayor primero)', value: 'billAmount'},
        {title: 'Monto (menor primero)', value: 'reverse_billAmount'},
        {title: 'Método (A-Z)', value: 'billPaymentType'},
        {title: 'Método (Z-A)', value: 'reverse_billPaymentType'}
      ],
      billsRows: [],
      backupBillsRows: [],
      billsHeaders: 
      [
        {key: 'billID', title: 'ID', sortable: false},
        {key: 'billState', title: 'ESTADO', sortable: false},
        {key: 'localityName', title: 'LOCALIDAD', sortable: false},
        {key: 'billAgentName', title: 'FACTURADOR', sortable: false},
        {key: 'clientName', title: 'CLIENTE', sortable: false},
        {key: 'billCreationDatetime', title: 'FECHA', sortable: false},
        {key: 'billType', title: 'TIPO', sortable: false},
        {key: 'billAmount', title: 'MONTO', sortable: false},
        {key: 'billPaymentType', title: 'MÉTODO', sortable: false},
        {key: 'billActions', title: '', sortable: false}
      ]
    },

    accessCredential: {},

    localStorage: 
    {
      agentID: null,
      localityID: null,
      billStateColors: null
    }

  }),

  watch: {
    'input.selectedLocalityID': async function(){
      this.selectAgentByAgentLocalityIDAndByAgentIsActive();
    },
    'input.startDate': async function() {
      this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB');
      this.navigation.startDatePickerIsVisible = false;
    },
    'input.endDate': async function() {
      this.display.endDateFormatted = new Date(this.input.endDate).toLocaleDateString('en-GB');
      this.navigation.endDatePickerIsVisible = false;
    },
    'input.searchByClientSSN': async function(){
      this.setDefaultValues();
    },
    'input.selectedClientSSN': async function(){
      if (this.input.selectedClientSSN){
        this.selectBillOnBillsViewForClient();
      }
    },
    'input.billRowsSearchQuery': async function(){
      const backupBillsRows = JSON.parse(JSON.stringify(this.display.backupBillsRows));
      const billRowsSearchQuery = this.input.billRowsSearchQuery ? this.input.billRowsSearchQuery.toLowerCase().trim() : null;
      if (!billRowsSearchQuery) {
        this.display.billsRows = backupBillsRows;
        return;
      }
      this.display.billsRows = backupBillsRows.filter(billRow => {
        return (
          [
            billRow.billState,
            billRow.localityName,
            billRow.billAgentName,
            billRow.clientName,
            billRow.billType,
            billRow.billAmount,
            billRow.billPaymentType,
            JSON.stringify(billRow.billProducts)
          ]
          .filter(value => value)
          .some(value => value.toString().toLowerCase().includes(billRowsSearchQuery)) 
        );
      });
    },
    'input.selectedBillRowsSortOptions': async function () {
      if (this.display.billsRows.length == 0) return;
      const lastAddedBillRowsSortOption = this.input.selectedBillRowsSortOptions[this.input.selectedBillRowsSortOptions.length - 1];
      let contradictoryBillRowsSortOption = null;
      if (lastAddedBillRowsSortOption && lastAddedBillRowsSortOption.startsWith("reverse_")) {
        const normalVersion = lastAddedBillRowsSortOption.replace("reverse_", "");
        if (this.input.selectedBillRowsSortOptions.includes(normalVersion)) {
          contradictoryBillRowsSortOption = normalVersion;
        }
      } else if (lastAddedBillRowsSortOption) {
        const reverseVersion = `reverse_${lastAddedBillRowsSortOption}`;
        if (this.input.selectedBillRowsSortOptions.includes(reverseVersion)) {
          contradictoryBillRowsSortOption = reverseVersion;
        }
      }
      const filteredSelectedBillRowsSortOptions = this.input.selectedBillRowsSortOptions.filter(option => option != contradictoryBillRowsSortOption);
      if (JSON.stringify(filteredSelectedBillRowsSortOptions) != JSON.stringify(this.input.selectedBillRowsSortOptions)) {
        this.input.selectedBillRowsSortOptions = filteredSelectedBillRowsSortOptions;
      }
      this.display.billsRows = this.display.billsRows.sort((billRowA, billRowB) => {
        return billRowB.billID - billRowA.billID;
      });
      this.display.billsRows = [...this.display.billsRows].sort((billRowA, billRowB) => {
        for (const selectedBillRowsSortOption of this.input.selectedBillRowsSortOptions) {
          const isReverse = selectedBillRowsSortOption.startsWith("reverse_");
          const sortKey = isReverse ? selectedBillRowsSortOption.replace("reverse_", "") : selectedBillRowsSortOption;
          let billRowAValue = billRowA[sortKey];
          let billRowBValue = billRowB[sortKey];
          if (sortKey == "billAmount") {
            billRowAValue = Number(billRowAValue);
            billRowBValue = Number(billRowBValue);
          } else if (sortKey == "billCreationDatetime") {
            billRowAValue = new Date(billRowAValue);
            billRowBValue = new Date(billRowBValue);
          } else {
            billRowAValue = String(billRowAValue).toLowerCase();
            billRowBValue = String(billRowBValue).toLowerCase();
          }
          let billRowComparison = null;
          if (sortKey == "billAmount" || sortKey == "billCreationDatetime") {
            billRowComparison = billRowBValue - billRowAValue;
          } else {
            billRowComparison = billRowAValue.localeCompare(billRowBValue); 
          }
          if (billRowComparison != 0) {
            return isReverse ? -billRowComparison : billRowComparison;
          }
        }
        return 0; 
      });
    }
  },

  methods: {
    getMinimumDate(){
      const offsetDays = (this.accessCredential['/bills/maxOffsetDays'] + 1) || 2;
      const minimumDate = new Date();
      minimumDate.setDate(minimumDate.getDate() - offsetDays);
      return minimumDate;
    },

    getMaximumDate(){
      return this.accessCredential['/bills/selectEndDate'] ? '' : new Date();
    },

    clearSelectedBillRowsSortOptions(){
      this.input.selectedBillRowsSortOptions.length = 0;
      this.display.billsRows = this.display.billsRows.sort((billRowA, billRowB) => {
        return billRowB.billID - billRowA.billID;
      });
    },

    async openBillDialog(billID){
      const billDialogRequestQuery = 
      {
        'billID': billID
      };
      this.$root.billDialog.openBillDialog(billDialogRequestQuery);
    },

    async createBillPDF(billTaxID){
      const createBillPDFRequestQuery =
      {
        'billTaxID': billTaxID
      };
      const createBillPDFResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/functions/createBillPDF', createBillPDFRequestQuery);
      if (createBillPDFResult.success){
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': createBillPDFResult.result.electronicDocumentPDFFile.data,
          'printablePDFDocumentName': `${billTaxID}.pdf`
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al reimprimir la factura (PDF), por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async printBill(billID){
      const createBillPDFDocumentRequestQuery = 
      {
        'billID': billID
      };
      const createBillPDFDocumentResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/functions/reprintBill', createBillPDFDocumentRequestQuery);
      if (createBillPDFDocumentResult.success){
        this.$root.printableBillDialog.showPrintableBillDialog(createBillPDFDocumentResult.result);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al reimprimir la factura (POS), por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async openBillCancelationDialog(billID){
      const billCancelationDialogRequestQuery =
      {
        'billID': billID
      };
      this.$root.billCancelationDialog.showlBillCancelationDialog(billCancelationDialogRequestQuery);
    },

    blurInput(inputReference){
      const blurInputInterval = setInterval(() => {
        if (this.$refs[inputReference]) {
          this.$refs[inputReference].blur();
          clearInterval(blurInputInterval);
        }
      }, 1);
    },

    selectProductByScanner(){
      const selectedProduct = this.display.productOptions.find(productOption => productOption.productID == this.input.searchedProductID);
      if (selectedProduct){
        this.blurInput('selectedProductIDInput');
        this.input.selectedProductID = selectedProduct.productID;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': null,
          'notificationDialogBody': 'El producto escaneado no ha sido encontrado, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
      }
    },

    cleanProductIDInput(){
      this.input.searchedProductID = null;
      this.input.selectedProductID = null;
      this.blurInput('selectedProductIDInput');
    },

    async checkBillState(bill){
      bill.billStateLoader = true;
      if (bill.billState != 'Factura cancelada'){
        if (bill.billType != 'Recibo'){
          if (bill.billState == 'Factura generada' || bill.billState == 'Esperando a Hacienda' || bill.billState == 'Aceptado' || bill.billState == 'Rechazado'){
            const checkBillStateRequestQuery = 
            {
              'billTaxID': bill.billTaxID
            };
            const checkBillStateResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/functions/checkBillState', checkBillStateRequestQuery);
            if (checkBillStateResult.success){
              bill.billState = checkBillStateResult.result.billState;
              this.navigation.billXML = checkBillStateResult.result.billXML;
              if (checkBillStateResult.result.billState == 'Aceptado'){
                this.$root.notificationDialog.showNotificationDialog({
                  'notificationDialogTitle': 'ÉXITO',
                  'notificationDialogBody': 'La factura ha sido aceptada',
                  'notificationDialogColor': 'success',
                  'notificationDialogIsPersistent': false
                });
              } else {
                this.$root.notificationDialog.showNotificationDialog({
                  'notificationDialogTitle': 'ERROR',
                  'notificationDialogBody': 'La factura ha sido rechaza. Por favor notifique a su administrador de sistema para realizar las correcciones requeridas',
                  'notificationDialogColor': 'error',
                  'notificationDialogIsPersistent': false
                });
              }
              if (this.accessCredential['/bills/seeXML']){
                this.navigation.billXMLDialogIsVisible = true;
              }
            } else {
              this.$root.notificationDialog.showNotificationDialog({
                'notificationDialogTitle': 'ERROR',
                'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar el estado de la factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
                'notificationDialogColor': 'error',
                'notificationDialogIsPersistent': false
              });
            }
          } else {
            this.$root.notificationDialog.showNotificationDialog({
              'notificationDialogTitle': null,
              'notificationDialogBody': `La factura se ya ha sido ${bill.billState == 'Aceptado' ? 'aceptada' : 'rechazada'}, no se puede consultar su estado`,
              'notificationDialogColor': 'warning',
              'notificationDialogIsPersistent': false
            });
          }
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': null,
            'notificationDialogBody': 'La factura se trata de un recibo, no se puede consultar su estado',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': null,
          'notificationDialogBody': 'La factura se encuentra cancelada, no se puede consultar su estado',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
      }
      bill.billStateLoader = false;
    },

    clearClientSearch(){
      this.input.selectedClientSSN = null;
      this.display.billsRows.length = 0;
      this.display.backupBillsRows.length = 0;
      this.loader.selectClientForClientSearch = false;
    },

    async selectBillOnBillsViewForClient(){
      this.loader.selectBillOnBillsView = true;
      this.display.billsRows.length = 0;
      const selectBillOnBillsViewForClientRequestQuery =
      {
        'clientSSN': this.input.selectedClientSSN
      };
      const selectBillOnBillsViewForClientResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/functions/selectBillOnBillsViewForClient', selectBillOnBillsViewForClientRequestQuery);
      if (selectBillOnBillsViewForClientResult.success){
        if (selectBillOnBillsViewForClientResult.result.length == 0){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': null,
            'notificationDialogBody': 'No se ha encontrado ninguna factura con las características especificadas, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        } else {
          this.display.billsRows = selectBillOnBillsViewForClientResult.result.map(billRow => ({
            ...billRow,
            billStateLoader: false,
            billXML: null
          }));
          this.display.backupBillsRows = JSON.parse(JSON.stringify(this.display.billsRows));
          this.navigation.searchVisible = '';
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las facturas, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectBillOnBillsView = false;
    },

    async generateReportOnBillsView(){
      this.loader.generateReportOnBillsView = true;
      const generateReportOnBillsViewRequestQuery = 
      {
        'stackableFilters': this.input.stackableFilter ? 'AND' : 'OR',
        'billID': this.input.billID,
        'billTaxID': this.input.billTaxID,
        'billLocalityID': this.accessCredential['/bills/selectLocality'] ? this.input.selectedLocalityID : [this.localStorage.localityID],
        'billAgentID': this.input.selectedAgentID,
        'billPaymentType': this.input.selectedBillPaymentType,
        'billCreationStartDatetime': this.input.startDate,
        'billCreationEndDatetime': this.input.endDate,
        'billType': this.input.selectedBillType,
        'productID': this.input.selectedProductID,
        'productCategoryID': this.input.selectedProductCategory,
        'productBrandID': this.input.selectedProductBrand,
        'productModelID': this.input.selectedProductModel,
        'productFlavorID': this.input.selectedProductFlavor,
        'productNicotineLevelID': this.input.selectedProductNicotineLevel,
        'productSizeID': this.input.selectedProductSize
      };
      const selectBillOnBillsViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/functions/generateReportOnBillsView', generateReportOnBillsViewRequestQuery);
      if (selectBillOnBillsViewResult.success){
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': null,
          'printablePDFDocumentName': null,
          'printableExcelDocumentFile': selectBillOnBillsViewResult.result.data,
          'printableExcelDocumentName': selectBillOnBillsViewResult.name
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el reporte, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.generateReportOnBillsView = false;
    },

    async selectBillOnBillsView(){
      this.loader.selectBillOnBillsView = true;
      this.display.billsRows.length = 0;
      this.display.backupBillsRows.length = 0;
      this.input.billRowsSearchQuery = null;
      this.input.selectedBillRowsSortOptions.length = 0;
      const selectBillOnBillsViewRequestQuery = 
      {
        'stackableFilters': this.input.stackableFilter ? 'AND' : 'OR',
        'billID': this.input.billID,
        'billTaxID': this.input.billTaxID,
        'billLocalityID': this.accessCredential['/bills/selectLocality'] ? this.input.selectedLocalityID : [this.localStorage.localityID],
        'billAgentID': this.input.selectedAgentID,
        'billPaymentType': this.input.selectedBillPaymentType,
        'billCreationStartDatetime': this.input.startDate,
        'billCreationEndDatetime': this.input.endDate,
        'billType': this.input.selectedBillType,
        'productID': this.input.selectedProductID,
        'productCategoryID': this.input.selectedProductCategory,
        'productBrandID': this.input.selectedProductBrand,
        'productModelID': this.input.selectedProductModel,
        'productFlavorID': this.input.selectedProductFlavor,
        'productNicotineLevelID': this.input.selectedProductNicotineLevel,
        'productSizeID': this.input.selectedProductSize
      };
      const selectBillOnBillsViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/functions/selectBillOnBillsView', selectBillOnBillsViewRequestQuery);
      if (selectBillOnBillsViewResult.success){
        if (selectBillOnBillsViewResult.result.length == 0){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': null,
            'notificationDialogBody': 'No se ha encontrado ninguna factura con las características especificadas, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        } else {
          this.display.billsRows = selectBillOnBillsViewResult.result.map(billRow => ({
            ...billRow,
            billStateLoader: false,
            billXML: null
          }));
          this.display.backupBillsRows = JSON.parse(JSON.stringify(this.display.billsRows));
          this.navigation.searchVisible = '';
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las facturas, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectBillOnBillsView = false;
    },

    async selectClientForClientSearch(){
      this.loader.selectClientForClientSearch = true;
      const selectClientForClientSearchResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/client/functions/selectClientForClientSearch');
      if (selectClientForClientSearchResult.success){
        this.display.clientOptions = selectClientForClientSearchResult.result;
        this.loader.selectClientForClientSearch = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los clientes, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectLocalityIDAndLocalityNameByLocalityIsActive(){
      this.loader.selectLocalityIDAndLocalityNameByLocalityIsActive = true;
      const selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery = 
      {
        'localityIsActive': true
      };
      const selectLocalityIDAndLocalityNameByLocalityIsActiveResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityNameByLocalityIsActive', selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery);
      if (selectLocalityIDAndLocalityNameByLocalityIsActiveResult.success){
        this.display.localityOptions = selectLocalityIDAndLocalityNameByLocalityIsActiveResult.result;
        this.loader.selectLocalityIDAndLocalityNameByLocalityIsActive = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectAgentByAgentLocalityIDAndByAgentIsActive(){
      this.loader.selectAgentByAgentLocalityIDAndByAgentIsActive = true;
      this.input.selectedAgentID.length = 0;
      const selectAgentByAgentLocalityIDAndByAgentIsActiveRequestQuery = 
      {
        'agentLocalityID': this.accessCredential['/bills/selectLocality'] ? this.input.selectedLocalityID : [this.localStorage.localityID],
        'agentIsActive': true
      };
      const selectAgentByAgentLocalityIDAndByAgentIsActiveResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/select/byAgentLocalityIDAndByAgentIsActive', selectAgentByAgentLocalityIDAndByAgentIsActiveRequestQuery);
      if (selectAgentByAgentLocalityIDAndByAgentIsActiveResult.success){
        this.display.agentOptions = selectAgentByAgentLocalityIDAndByAgentIsActiveResult.result;
        this.loader.selectAgentByAgentLocalityIDAndByAgentIsActive = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de facturadores, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductBasicBasicInformationForBillsView(){
      this.loader.selectProductBasicBasicInformationForBillsView = true;
      const selectProductBasicBasicInformationForBillsViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductBasicBasicInformationForBillsView');
      if (selectProductBasicBasicInformationForBillsViewResult.success){
        this.display.productOptions = selectProductBasicBasicInformationForBillsViewResult.result.map(productOption => ({
          'productID': productOption.i,
          'productSKU': productOption.s,
          'productName': productOption.n,
          'productAutocompleteTitle': productOption.s + ' - ' + productOption.n
        }));
        this.loader.selectProductBasicBasicInformationForBillsView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductSearchParameters(){
      this.loader.selectProductSearchParameters = true;
      const selectProductSearchParametersResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductSearchParameters');
      if (selectProductSearchParametersResult.success){
        this.display.productCategoryOptions = selectProductSearchParametersResult.result.productCategory;
        this.display.productBrandOptions = selectProductSearchParametersResult.result.productBrand;
        this.display.productModelOptions = selectProductSearchParametersResult.result.productModel;
        this.display.productFlavorOptions = selectProductSearchParametersResult.result.productFlavor;
        this.display.productNicotineLevelOptions = selectProductSearchParametersResult.result.productNicotineLevel;
        this.display.productSizeOptions = selectProductSearchParametersResult.result.productSize;
        this.loader.selectProductSearchParameters = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de características de los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async setDefaultValues(){
      this.input.stackableFilter = true;
      this.input.selectedClientSSN = null;
      this.input.billID = null;
      this.input.billTaxID = null;
      this.input.selectedLocalityID.length = 0;
      this.input.selectedAgentID.length = 0;
      this.input.selectedBillPaymentType.length = 0;
      this.input.startDate = new Date();
      this.input.endDate = new Date();
      this.input.selectedBillType.length = 0;
      this.input.selectedProductID = null;
      this.input.searchedProductID = null;
      this.input.selectedProductCategory.length = 0;
      this.input.selectedProductBrand.length = 0;
      this.input.selectedProductModel.length = 0;
      this.input.selectedProductFlavor.length = 0;
      this.input.selectedProductNicotineLevel.length = 0;
      this.input.selectedProductSize.length = 0;
      this.input.billRowsSearchQuery = null;
      this.input.selectedBillRowsSortOptions.length = 0;
      this.loader.selectBillOnBillsView = false;
      this.loader.selectClientForClientSearch = false;
      this.loader.generateReportOnBillsView = false;
      this.display.billsRows.length = 0;
      this.display.backupBillsRows.length = 0;
      this.navigation.billDialogBillID = null;
      this.navigation.billXMLDialogIsVisible = false;
    },

    async getLocalStorageData(){
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.billStateColors = this.$generalFunctions.default.data.billStateColors;
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
      this.navigation.thermalPrinterAvailable = (await this.$generalFunctions.default.methods.getThermalPrinterStatus()).success;
    },

    listenToViewMethodCaller(){
      viewMethodCaller.on('BillsView/methods/selectBillOnBillsView()', this.selectBillOnBillsView);
    },

  },

  async mounted(){
    this.listenToViewMethodCaller();
    this.setDefaultValues();
    await this.getLocalStorageData();
    this.selectClientForClientSearch();
    this.selectProductBasicBasicInformationForBillsView();
    this.selectProductSearchParameters();
    this.selectLocalityIDAndLocalityNameByLocalityIsActive();
    await this.selectAgentByAgentLocalityIDAndByAgentIsActive();
    
  }

});
</script>