<template>
  <div style="margin: 2% 3% 0% 3%;">
    <v-card color="border" elevation="3" style="padding: 20px;">
      <v-expansion-panels v-model="navigation.searchVisible">
        <v-expansion-panel value="searchVisible">
          <v-expansion-panel-title>
            <p style="font-size: large;">Búsqueda de tendencias de venta</p>
            <template v-slot:actions>
              <v-icon icon="mdi-magnify"></v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <br>
            <div style="display: flex;">
              <div style="width: 90%;">
                <v-select 
                  v-model="input.selectedLocalityIDS" 
                  :items="display.localityOptions" 
                  :loading="loader.selectLocalityIDAndLocalityName" 
                  multiple 
                  chips 
                  item-title="localityName" 
                  item-value="localityID" 
                  append-inner-icon="mdi-store" 
                  label="Localidad" 
                  variant="solo-filled"
                  density="compact"
                ></v-select>
              </div>
              <div style="width: 9%; margin-left: 1%;">
                <v-checkbox
                  @click="selectAllLocality()" 
                  v-model="input.allLocalitySelected" 
                  color="primary" 
                  label="Todas las localidades" 
                  hide-details
                ></v-checkbox>
              </div>
            </div>
            <v-select 
              v-model="input.productLimit" 
              :items="display.productLimitOptions" 
              append-inner-icon="mdi-podium" 
              label="Cantidad de productos a consultar" 
              variant="solo-filled"
              density="compact"
            ></v-select>
            <div style="display: flex;">
              <div style="width: 49.5%; margin-right: 0.5%">
                <v-text-field v-model="display.startDateFormatted" @click="navigation.startDatePickerIsVisible = true" readonly label="Filtrar por fecha inicial" append-inner-icon="mdi-calendar" variant="solo-filled" density="compact"></v-text-field>
                <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
                  <v-card style="display: flex;">
                    <v-date-picker v-model="input.startDate" hide-header color="primary" width="100%"></v-date-picker>
                    <v-chip @click="input.startDate = new Date()" class="selectTodayChip" color="primary" variant="flat">Hoy</v-chip>
                  </v-card>
                </v-dialog>
              </div>
              <div style="width: 49.5%; margin-left: 0.5%">
                <v-text-field v-model="display.endDateFormatted" @click="navigation.endDatePickerIsVisible = true" readonly label="Filtrar por fecha final" append-inner-icon="mdi-calendar" variant="solo-filled" density="compact"></v-text-field>
                <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
                  <v-card>
                    <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
                    <v-chip @click="input.endDate = new Date()" class="selectTodayChip" color="primary" variant="flat">Hoy</v-chip>
                  </v-card>
                </v-dialog>
              </div>
            </div>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-title>
                  Búsqueda avanzada por producto
                  <template v-slot:actions>
                    <v-icon icon="mdi-magnify"></v-icon>
                  </template>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <br>
                  <div style="display: flex;">
                    <div style="width: 49.5%; margin-right: 0.5%;">
                      <v-autocomplete v-model="input.selectedProductCategory" :items="display.productCategoryOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productCategoryName" item-value="productCategoryID" append-inner-icon="mdi-cog" label="Filtrar por categoría del producto" variant="solo-filled"></v-autocomplete>
                      <v-autocomplete v-model="input.selectedProductBrand" :items="display.productBrandOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productBrandName" item-value="productBrandID" append-inner-icon="mdi-bookshelf" label="Filtrar por marca del producto" variant="solo-filled"></v-autocomplete>
                      <v-autocomplete v-model="input.selectedProductModel" :items="display.productModelOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productModelName" item-value="productModelID" append-inner-icon="mdi-source-branch" label="Filtrar por modelo del producto" variant="solo-filled"></v-autocomplete>
                    </div>
                    <div style="width: 49.5%; margin-left: 0.5%;">
                      <v-autocomplete v-model="input.selectedProductFlavor" :items="display.productFlavorOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productFlavorName" item-value="productFlavorID" append-inner-icon="mdi-water" label="Filtrar por sabor del producto" variant="solo-filled"></v-autocomplete>
                      <v-autocomplete v-model="input.selectedProductNicotineLevel" :items="display.productNicotineLevelOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productNicotineLevelName" item-value="productNicotineLevelID" append-inner-icon="mdi-weather-cloudy" label="Filtrar por graduación de nicotina del producto" variant="solo-filled"></v-autocomplete>
                      <v-autocomplete v-model="input.selectedProductSize" :items="display.productSizeOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productSizeName" item-value="productSizeID" append-inner-icon="mdi-ruler" label="Filtrar por tamaño del producto" variant="solo-filled"></v-autocomplete>
                    </div>
                  </div>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
            <br><br>
            <div v-if="loader.selectBillProductOnProductTendencySaleView" style="text-align: center;">
              <v-progress-circular color="primary" indeterminate></v-progress-circular>
              <br><br>
            </div>
            <div v-else>
              <v-btn @click="selectBillProductOnProductTendencySaleView()" :disabled="loader.selectBillProductOnProductTendencySaleView" dark block height="38" color="primary">
                <h3>BUSCAR</h3>
                <v-icon style="margin-left: 20px;" dark right>mdi-magnify</v-icon>
              </v-btn>
              <br>
              <v-btn @click="setDefaultValues()" dark block height="38" color="#b3b3b3">
                <h3>LIMPIAR BÚSQUEDA</h3>
                <v-icon style="margin-left: 20px;" dark right>mdi-backspace</v-icon>
              </v-btn>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <br><br>
    <v-card v-if="display.productRows.length != 0" color="border" elevation="3" style="padding: 20px;">
      <v-expansion-panels v-for="productCategory in display.productRows" v-bind:key="productCategory" style="margin: 5px;">
        <v-expansion-panel>
          <v-expansion-panel-title>
            <div style="width: 100%; display: flex;">
              <h2>{{ productCategory.productCategoryName }}</h2>
              <h2 style="margin-left: auto; margin-right: 20px;">{{ productCategory.productCategoryAmount }}</h2>
            </div>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            
            <v-expansion-panels v-for="productBrand in productCategory['productBrand']" v-bind:key="productBrand" style="margin: 5px;">
              <v-expansion-panel>
                <v-expansion-panel-title>
                  <div style="width: 100%; display: flex;">
                    <h3>{{ productBrand.productBrandName }}</h3>
                    <h3 style="margin-left: auto; margin-right: 20px;">{{ productBrand.productBrandAmount }}</h3>
                  </div>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  
                  <v-expansion-panels v-for="productModel in productBrand['productModel']" v-bind:key="productModel" style="margin: 5px;">
                    <v-expansion-panel>
                      <v-expansion-panel-title>
                        <div style="width: 100%; display: flex;">
                          <h4>{{ productModel.productModelName }}</h4>
                          <h4 style="margin-left: auto; margin-right: 20px;">{{ productModel.productModelAmount }}</h4>
                        </div>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <div v-for="productFlavor in productModel['productFlavor']" v-bind:key="productFlavor" style="padding: 5px; margin: 5px; display: flex">
                          <v-chip color="purple" label style="margin-right: 20px; font-size: larger; cursor: pointer;">
                            <v-icon icon="mdi-medal" start></v-icon>
                            {{ productFlavor.productRanking }}
                          </v-chip>
                          <v-chip color="warning" label style="margin-right: 20px; font-size: larger; cursor: pointer;">
                            <v-icon @click="$generalFunctions.default.methods.navigateTo('/product/'+productFlavor.productID)" icon="mdi-bulletin-board" start></v-icon>
                          </v-chip>
                          <p><strong>{{ productFlavor.productFlavorName }}: </strong> {{ productFlavor.productFlavorAmount }} </p>
                        </div>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <br><br>
  </div>
</template>

<style scoped>
  .selectTodayChip {
    margin: 0% 5% 5% 5%;
    text-align: center;
    cursor: pointer;
  }
</style>


<script>
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'ProductTendencySaleView',
  inject: ['$generalFunctions'],

  data: () => ({
    input: 
    {
      selectedLocalityIDS: [],
      allLocalitySelected: false,
      productLimit: null,
      startDate: null,
      endDate: null,
      selectedProductCategory: null,
      selectedProductBrand: null,
      selectedProductModel: null,
      selectedProductFlavor: null,
      selectedProductNicotineLevel: null,
      selectedProductSize: null
    },

    display: 
    {
      localityOptions: [],
      productLimitOptions: 
      [
        {value: null, title: 'Todos los productos'},
        {value: 10, title: '10 productos'},
        {value: 25, title: '25 productos'},
        {value: 50, title: '50 productos'},
        {value: 100, title: '100 productos'},
        {value: 200, title: '200 productos'},
        {value: 500, title: '500 productos'},
        {value: 1000, title: '1000 productos'}
      ],
      startDateFormatted: null,
      endDateFormatted: null,
      productCategoryOptions: [],
      productBrandOptions: [],
      productModelOptions: [],
      productFlavorOptions: [],
      productNicotineLevelOptions: [],
      productSizeOptions: [],
      productRows: [],
      productHeaders: 
      [
        {key: 'productName', title: 'PRODUCTO'},
        {key: 'productCost', title: 'PRECIO DE COSTO'},
        {key: 'productPrice', title: 'PRECIO DE VENTA'},
        {key: 'productTotalSaleAmount', title: 'VENTAS TOTALES'}
      ],
    },

    navigation: 
    {
      searchVisible: 'searchVisible',
      startDatePickerIsVisible: false,
      endDatePickerIsVisible: false,
      productAdvancedFilter: null,
      productTendencyOrder: null,
    },

    loader:
    {
      selectLocalityIDAndLocalityName: false,
      selectProductSearchParameters: false,
      selectBillProductOnProductTendencySaleView: false
    },

    localStorage:
    {
      localityID: null
    },

    accessCredential: {}
  }),

  watch: {
    async '$route'() {
      this.setDefaultValues();
      await this.getLocalStorageData();
      await this.selectLocalityIDAndLocalityName();
      await this.selectProductSearchParameters();
    },

    'input.selectedLocalityIDS': async function (){
      this.input.allLocalitySelected = this.input.selectedLocalityIDS.length == this.display.localityOptions.length;
    },

    'input.startDate': async function() {
      this.display.startDateFormatted = this.input.startDate ? new Date(this.input.startDate).toLocaleDateString('en-GB') : null;
      this.navigation.startDatePickerIsVisible = false;
    },
    'input.endDate': async function() {
      this.display.endDateFormatted = this.input.endDate ? new Date(this.input.endDate).toLocaleDateString('en-GB') : null;
      this.navigation.endDatePickerIsVisible = false;
    }
  },

  methods: {
    async selectBillProductOnProductTendencySaleView(){
      this.loader.selectBillProductOnProductTendencySaleView = true;
      const selectBillProductOnProductTendencySaleViewRequestQuery =
      {
        'localityIDS': this.input.selectedLocalityIDS,
        'productLimit': this.input.productLimit,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate,
        'productCategoryID': this.input.selectedProductCategory,
        'productBrandID': this.input.selectedProductBrand,
        'productModelID': this.input.selectedProductModel,
        'productFlavorID': this.input.selectedProductFlavor,
        'productNicotineLevelID': this.input.selectedProductNicotineLevel,
        'productSizeID': this.input.selectedProductSize,
        'productTendencyOrder': this.navigation.productTendencyOrder
      };
      const selectBillProductOnProductTendencySaleViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/billProduct/functions/selectBillProductOnProductTendencySaleView', selectBillProductOnProductTendencySaleViewRequestQuery);
      if (selectBillProductOnProductTendencySaleViewResult.success){
        this.display.productRows = selectBillProductOnProductTendencySaleViewResult.result;
        this.navigation.searchVisible = '';
        if (this.display.productRows.length == 0){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': null,
            'notificationDialogBody': 'No se ha encontrado ningun producto con las características especificadas, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectBillProductOnProductTendencySaleView = false;
    },

    async selectLocalityIDAndLocalityName(){
      this.loader.selectLocalityIDAndLocalityName = true;
      const selectLocalityIDAndLocalityNameResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityName');
      if (selectLocalityIDAndLocalityNameResult.success){
        this.display.localityOptions = selectLocalityIDAndLocalityNameResult.result;
        this.input.allLocalitySelected = false;
        this.selectAllLocality();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectLocalityIDAndLocalityName = false;
    },

    async selectProductSearchParameters(){
      this.loader.selectProductSearchParameters = true;
      const selectProductSearchParametersResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductSearchParameters');
      if (selectProductSearchParametersResult.success){
        this.display.productCategoryOptions = [{'productCategoryName': 'Todas las categorías', 'productCategoryID': null}, ...selectProductSearchParametersResult.result.productCategory];
        this.display.productBrandOptions = [{'productBrandName': 'Todas las marcas', 'productBrandID': null}, ...selectProductSearchParametersResult.result.productBrand];
        this.display.productModelOptions = [{'productModelName': 'Todos los modelos', 'productModelID': null}, ...selectProductSearchParametersResult.result.productModel];
        this.display.productFlavorOptions = [{'productFlavorName': 'Todos los sabores', 'productFlavorID': null}, ...selectProductSearchParametersResult.result.productFlavor];
        this.display.productNicotineLevelOptions = [{'productNicotineLevelName': 'Todas las graduaciones de nicotina', 'productNicotineLevelID': null}, ...selectProductSearchParametersResult.result.productNicotineLevel];
        this.display.productSizeOptions = [{'productSizeName': 'Todos los tamaños', 'productSizeID': null}, ...selectProductSearchParametersResult.result.productSize];
        this.loader.selectProductSearchParameters = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de características de los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },


    selectAllLocality(){
      if (this.input.allLocalitySelected == false){
        this.input.selectedLocalityIDS = this.display.localityOptions.map(localityOption => localityOption.localityID);
      } else {
        this.input.selectedLocalityIDS.length = 0;
      }
    },

    setDefaultValues(){
      this.input.allLocalitySelected = false;
      this.selectAllLocality();
      this.input.productLimit = null;
      this.input.startDate = new Date();
      this.input.startDate.setDate(1);
      this.input.endDate = new Date();
      this.input.selectedProductCategory = null;
      this.input.selectedProductBrand = null;
      this.input.selectedProductModel = null;
      this.input.selectedProductFlavor = null;
      this.input.selectedProductNicotineLevel = null;
      this.input.selectedProductSize = null;
      this.display.productRows.length = 0;
      this.navigation.startDatePickerIsVisible = false;
      this.navigation.endDatePickerIsVisible = false;
      this.navigation.productAdvancedFilter = null;
      this.loader.selectLocalityIDAndLocalityName = false;
      this.loader.selectProductSearchParameters = false;
      this.loader.selectBillProductOnProductTendencySaleView = false;
    },
    
    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
      this.navigation.productTendencyOrder = this.$generalFunctions.default.methods.decodeURLActions()[1] == 'top' ? 'DESC' : 'ASC';
    },
  },
  
  async mounted(){
    this.setDefaultValues();
    await this.getLocalStorageData();
    await this.selectLocalityIDAndLocalityName();
    await this.selectProductSearchParameters();
  }
});
</script>
