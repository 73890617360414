<template>
  <div style="margin: 2% 3% 0% 3%;">
    <v-card color="border" elevation="3" style="padding: 20px;">
      <v-expansion-panels v-model="navigation.searchVisible">
        <v-expansion-panel value="searchVisible">
          <v-expansion-panel-title>
            <p style="font-size: large;">Búsqueda de cambios de producto</p>
            <template v-slot:actions>
              <v-icon icon="mdi-magnify"></v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <br>
            <div style="display: flex;">
              <div style="width: 49.5%; margin-right: 0.5%;">
                <v-text-field 
                  v-model="input.productChangeID" 
                  label="Buscar por identificador interno" 
                  append-inner-icon="mdi-barcode"
                  variant="solo-filled"
                  density="compact" 
                ></v-text-field>
                <v-autocomplete 
                  v-model="input.localityID" 
                  :append-inner-icon="input.localityID.length == 0 ? 'mdi-store' : 'mdi-store-off'" 
                  @click:append-inner="input.localityID.length = 0" 
                  :items="display.localityOptions" 
                  :loading="loader.selectLocalityIDAndLocalityNameByLocalityIsActive" 
                  :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive" 
                  multiple
                  chips
                  closable-chips
                  color="primary"
                  item-title="localityName" 
                  item-value="localityID" 
                  label="Filtrar por localidad" 
                  placeholder="Todas las localidades"
                  variant="solo-filled"
                  density="compact"
                >
                </v-autocomplete>
                <v-autocomplete 
                  v-model="input.productChangeState" 
                  multiple
                  chips
                  closable-chips
                  color="primary"
                  :items="['Cambio solicitado', 'Cambio aprobado', 'Cambio rechazado', 'Cambio en revisión']" 
                  append-inner-icon="mdi-text-box-outline" 
                  label="Filtrar por estado del cambio de producto" 
                  placeholder="Todos los estados del cambio de producto"
                  variant="solo-filled"
                  density="compact"
                ></v-autocomplete>
              </div>
              <div style="width: 49.5%; margin-left: 0.5%;">
                <v-text-field 
                  v-model="input.productChangeBillID" 
                  label="Buscar por identificador interno de la factura" 
                  append-inner-icon="mdi-barcode"
                  variant="solo-filled"
                  density="compact" 
                ></v-text-field>
                <v-text-field 
                  v-model="display.startDateFormatted" 
                  @click="navigation.startDatePickerIsVisible = true" 
                  readonly 
                  label="Filtrar por fecha inicial" 
                  append-inner-icon="mdi-calendar-start" 
                  variant="solo-filled"
                  density="compact"
                ></v-text-field>
                <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
                  <v-card style="display: flex;">
                    <v-date-picker v-model="input.startDate" hide-header color="primary" width="100%"></v-date-picker>
                    <v-chip @click="input.startDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
                  </v-card>
                </v-dialog>
                <v-text-field 
                  v-model="display.endDateFormatted" 
                  @click="navigation.endDatePickerIsVisible = true" 
                  readonly 
                  label="Filtrar por fecha final" 
                  append-inner-icon="mdi-calendar-end" 
                  variant="solo-filled"
                  density="compact"
                ></v-text-field>
                <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
                  <v-card>
                    <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
                    <v-chip @click="input.endDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
                  </v-card>
                </v-dialog>
              </div>
            </div>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-title>
                  Búsqueda avanzada por producto
                  <template v-slot:actions>
                    <v-icon icon="mdi-magnify"></v-icon>
                  </template>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <br>
                  <v-tooltip open-delay="2000" location="top">
                    <template v-slot:activator="{ props }">
                      <v-autocomplete 
                        v-bind="props"
                        v-model="input.productID" 
                        :append-inner-icon="input.productID ? 'mdi-alphabetical-variant-off' : 'mdi-alphabetical-variant'" 
                        @click:append-inner="input.productID = null" 
                        :items="display.productOptions" 
                        :loading="loader.selectProductBasicBasicInformationForBillsView" 
                        :disabled="loader.selectProductBasicBasicInformationForBillsView" 
                        ref="selectedProductIDInput" 
                        item-title="productAutocompleteTitle" 
                        item-value="productID" 
                        label="Filtrar por producto" 
                        placeholder="Todos los productos" 
                        variant="solo-filled" 
                        density="compact"
                      ></v-autocomplete>
                    </template>
                    <span style="max-width: 400px; display: block;">Este espacio permite la búsqueda o escaneo de un producto. Si ningún producto es seleccionado, se buscarán todos los cambios de producto sin importar los productos incluidos en los mismo. Al presionar el ícono de la derecha se limpia el producto seleccionado</span>
                  </v-tooltip>
                  <div style="display: flex;">
                    <div style="width: 49.5%; margin-right: 0.5%;">
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete 
                            v-bind="props"
                            v-model="input.productCategoryID" 
                            :append-inner-icon="input.productCategoryID.length == 0 ? 'mdi-cog' : 'mdi-cog-off'" 
                            @click:append-inner="input.productCategoryID.length = 0" 
                            :items="display.productCategoryOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            item-title="productCategoryName" 
                            item-value="productCategoryID" 
                            label="Filtrar por categoría del producto" 
                            placeholder="Todas las categorías del producto" 
                            variant="solo-filled" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">La categoría del producto hace referencia al primer nivel de categorización de un producto. Este espacio permite selección múltiple para filtrar por varias categorías de producto en paralelo. Si ninguna categoría de producto es seleccionada, se buscarán todos los cambios de producto sin importar las categorías de los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpian las categorías de producto seleccionadas</span>
                      </v-tooltip>
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete 
                            v-bind="props"
                            v-model="input.productBrandID" 
                            :append-inner-icon="input.productBrandID.length == 0 ? 'mdi-briefcase-variant' : 'mdi-briefcase-variant-off'" 
                            @click:append-inner="input.productBrandID.length = 0" 
                            :items="display.productBrandOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            item-title="productBrandName" 
                            item-value="productBrandID" 
                            label="Filtrar por marca del producto" 
                            placeholder="Todas las marcas del producto" 
                            variant="solo-filled" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">La marca del producto hace referencia al segundo nivel de categorización de un producto. Este espacio permite selección múltiple para filtrar por varias marcas de producto en paralelo. Si ninguna marca de producto es seleccionada, se buscarán todos los cambios de producto sin importar las marcas de los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpian las marcas de producto seleccionadas</span>
                      </v-tooltip>
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete 
                            v-bind="props"
                            v-model="input.productModelID" 
                            :append-inner-icon="input.productModelID.length == 0 ? 'mdi-source-branch' : 'mdi-source-branch-remove'" 
                            @click:append-inner="input.productModelID.length = 0" 
                            :items="display.productModelOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            item-title="productModelName" 
                            item-value="productModelID" 
                            label="Filtrar por modelo del producto" 
                            placeholder="Todos los modelos del producto" 
                            variant="solo-filled" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">El modelo del producto hace referencia al tercer nivel de categorización de un producto. Este espacio permite selección múltiple para filtrar por varios modelos de producto en paralelo. Si ningún modelo de producto es seleccionado, se buscarán todos los cambios de producto sin importar los modelos de los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpian los modelos de producto seleccionados</span>
                      </v-tooltip>
                    </div>
                    <div style="width: 49.5%; margin-left: 0.5%;">
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete 
                            v-bind="props"
                            v-model="input.productFlavorID" 
                            :append-inner-icon="input.productFlavorID.length == 0 ? 'mdi-water' : 'mdi-water-off'" 
                            @click:append-inner="input.productFlavorID.length = 0" 
                            :items="display.productFlavorOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            item-title="productFlavorName" 
                            item-value="productFlavorID" 
                            label="Filtrar por sabor del producto" 
                            placeholder="Todos los sabores del producto" 
                            variant="solo-filled" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">El sabor del producto hace referencia al primer tipo de categorización opcional de un producto. Este espacio permite selección múltiple para filtrar por varios sabores de producto en paralelo. Si ningún sabor de producto es seleccionado, se buscarán todos cambios de producto sin importar los sabores de los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpian los sabores de producto seleccionados</span>
                      </v-tooltip>
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete 
                            v-bind="props"
                            v-model="input.productNicotineLevelID" 
                            :append-inner-icon="input.productNicotineLevelID.length == 0 ? 'mdi-cloud-outline' : 'mdi-cloud-off-outline'" 
                            @click:append-inner="input.productNicotineLevelID.length = 0" 
                            :items="display.productNicotineLevelOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            item-title="productNicotineLevelName" 
                            item-value="productNicotineLevelID" 
                            label="Filtrar por graduación de nicotina del producto"  
                            placeholder="Todas las graduaciones de nicotina del producto" 
                            variant="solo-filled" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">La graduación de nicotina del producto hace referencia al segundo tipo de categorización opcional de un producto. Este espacio permite selección múltiple para filtrar por varias graduaciones de nicotina de producto en paralelo. Si ninguna graduación de nicotina de producto es seleccionada, se buscarán todos los cambios de producto sin importar las graduaciones de nicotina de los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpian las graduaciones de nicotina de producto seleccionadas</span>
                      </v-tooltip>
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete
                            v-bind="props" 
                            v-model="input.productSizeID" 
                            :append-inner-icon="input.productSizeID.length == 0 ? 'mdi-ruler' : 'mdi-pencil-ruler'" 
                            @click:append-inner="input.productSizeID.length = 0" 
                            :items="display.productSizeOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            item-title="productSizeName" 
                            item-value="productSizeID" 
                            label="Filtrar por tamaño del producto" 
                            variant="solo-filled" 
                            placeholder="Todos los tamaños del producto" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">El tamaño del producto hace referencia al tercer tipo de categorización opcional de un producto. Este espacio permite selección múltiple para filtrar por varios tamaños de producto en paralelo. Si ningún tamaño de producto es seleccionado, se buscarán todos los cambios de producto sin importar los tamaños de los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpian los tamaños de producto seleccionados</span>
                      </v-tooltip>
                    </div>
                  </div>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
            <br><br>
            <div v-if="loader.selectProductChangeOnProductChangeView" style="text-align: center;">
              <br>
              <v-progress-circular color="primary" indeterminate></v-progress-circular>
              <br><br>
            </div>
            <div v-else>
              <v-btn @click="selectProductChangeOnProductChangeView()" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive || loader.selectProductBasicBasicInformationForBillsView || loader.selectProductChangeOnProductChangeView" dark block height="38" color="primary">
                <h3>BUSCAR</h3>
                <v-icon style="margin-left: 20px;" dark right>mdi-magnify</v-icon>
              </v-btn>
              <br>
              <v-btn @click="setDefaultValues()" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive || loader.selectProductBasicBasicInformationForBillsView || loader.selectProductChangeOnProductChangeView" dark block height="38" color="#b3b3b3">
                <h3>LIMPIAR BÚSQUEDA</h3>
                <v-icon style="margin-left: 20px;" dark right>mdi-backspace</v-icon>
              </v-btn>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <br><br>
    <v-card v-if="display.productChangeRows.length > 0" color="border" elevation="3" style="max-height: 700px; overflow-y: auto; padding: 20px;">
      <v-data-table-virtual :headers="display.productChangeHeaders" :items="display.productChangeRows">
        <template v-slot:item.productChangeState="{ item }">
          <div style="display: flex; margin-top: 10px; margin-bottom: 10px;">
            <v-chip :color="localStorage.productChangeStateColors[item.productChangeState].productChangeStateColor" variant="flat">
              <p :style="{color: localStorage.productChangeStateColors[item.productChangeState].productChangeStateFontColor}">{{ item.productChangeState }}</p>
            </v-chip>
            <p v-if="item.approverAgentName" style="margin-left: 10px; margin-top: 5px;">por</p>
            <v-chip v-if="item.approverAgentName" :color="item.approverAgentColor" variant="flat" style="margin-left: 10px;">
              <p :style="{color: item.approverAgentFontColor}">{{ item.approverAgentName }}</p>
            </v-chip>
          </div>
          <p v-if="item.productChangeApprovedDatetime" style="margin-bottom: 10px;"><strong>Fecha:</strong> {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.productChangeApprovedDatetime) }}</p>
        </template>
        <template v-slot:item.billLocalityName="{ item }">
          <div v-if="item.productChangeBillID">
            <p style="margin-top: 10px;"><strong>ID de la factura:</strong> {{ item.productChangeBillID }}</p>
            <div style="display: flex; margin-top: 10px; margin-bottom: 10px;">
              <v-chip :color="item.billLocalityColor" variant="flat">
                <p :style="{color: item.billLocalityFontColor}">{{ item.billLocalityName }}</p>
              </v-chip>
              <p style="margin-left: 10px; margin-top: 5px;">por</p>
              <v-chip :color="item.billAgentColor" variant="flat" style="margin-left: 10px;">
                <p :style="{color: item.billAgentFontColor}">{{ item.billAgentName }}</p>
              </v-chip>
            </div>
            <p style="margin-bottom: 10px;"><strong>Fecha:</strong> {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.billCreationDatetime) }}</p>
          </div>
          <p>Sin factura</p>
        </template>
        <template v-slot:item.productChangeLocalityName="{ item }">
          <p style="margin-top: 10px;"><strong>ID del cambio de producto:</strong> {{ item.productChangeID }}</p>
          <div style="display: flex; margin-top: 10px; margin-bottom: 10px;">
            <v-chip :color="item.productChangeLocalityColor" variant="flat">
              <p :style="{color: item.productChangeLocalityFontColor}">{{ item.productChangeLocalityName }}</p>
            </v-chip>
            <p style="margin-left: 10px; margin-top: 5px;">por</p>
            <v-chip :color="item.requesterAgentColor" variant="flat" style="margin-left: 10px;">
              <p :style="{color: item.requesterAgentFontColor}">{{ item.requesterAgentName }}</p>
            </v-chip>
          </div>
          <p style="margin-bottom: 10px;"><strong>Fecha:</strong> {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.productChangeDatetime) }}</p>
        </template>
        <template v-slot:item.productChangeProducts="{ item }">
          <div style="display: flex;">
            <div style="max-width: 200px; margin-right: 10px;">
              <div v-for="productChangeOldProduct in item.productChangeProducts.filter(productChangeProduct => productChangeProduct.productChangeProductType == 'oldProduct')" :key="productChangeOldProduct">
                <p>{{ productChangeOldProduct.productName }}</p>
                <p><strong>{{ productChangeOldProduct.productID }}</strong></p>
                <br>
              </div>
            </div>
            <div style="display: flex; justify-content: center; align-items: center;">
              <v-icon color="info" icon="mdi-arrow-right-bold" size="large"></v-icon>
            </div>
            <div>
              </div>
              <div style="max-width: 200px; margin-left: 10px;">
              <div v-for="productChangeNewProduct in item.productChangeProducts.filter(productChangeProduct => productChangeProduct.productChangeProductType == 'newProduct')" :key="productChangeNewProduct">
                <p>{{ productChangeNewProduct.productName }}</p>
                <p><strong>{{ productChangeNewProduct.productID }}</strong></p>
                <p><strong>Stock: </strong>{{ productChangeNewProduct.productStockAmount }}</p>
                <br>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:item.productChangeAction="{ item }">
          <div v-if="item.loader" style="text-align: center;">
            <v-progress-circular color="success" indeterminate></v-progress-circular>
          </div>
          <div v-else>
            <div style="display: flex;">
              <div style="display: flex;" v-if="item.productChangeState == 'Cambio solicitado'">
                <v-btn :disabled="item.productChangeProducts.filter(productChangeProduct => productChangeProduct.productStockAmount < 1 && productChangeProduct.productChangeProductType == 'newProduct').length > 0" @click="updateProductChange(item.productChangeID, 'Aprobar cambio')" dark height="38" color="success">
                  <h3>APROBAR</h3>
                </v-btn>
                <v-btn @click="updateProductChange(item.productChangeID, 'Rechazar cambio')" dark height="38" color="error" style="margin-left: 10px;">
                  <h3>RECHAZAR</h3>
                </v-btn>
              </div>
              <v-icon @click="generateProductChangeApprovePDFDocument(item)" style="margin-left: 20px; margin-top: 10px;" color="success">mdi-printer</v-icon>
            </div>            
          </div>  
        </template>
      </v-data-table-virtual>
    </v-card>
    <br><br>
  </div>
</template>

<style scoped>
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProductChangeView',
  inject: ['$generalFunctions'],

  
  data: () => ({
    input: 
    {
      productChangeID: null,
      productChangeBillID: null,
      localityID: [],
      productChangeState: [],
      startDate: null,
      endDate: null,
      productID: null,
      productCategoryID: [],
      productBrandID: [],
      productModelID: [],
      productFlavorID: [],
      productNicotineLevelID: [],
      productSizeID: []
    },

    navigation: 
    {
      startDatePickerIsVisible: false,
      endDatePickerIsVisible: false,
      searchVisible: 'searchVisible'
    },

    loader: 
    {
      selectLocalityIDAndLocalityNameByLocalityIsActive: false,
      selectProductBasicBasicInformationForBillsView: false,
      selectProductSearchParameters: false,
      selectProductChangeOnProductChangeView: false
    },

    display:
    {
      localityOptions: [],
      startDateFormatted: '',
      endDateFormatted: '',
      productOptions: [],
      productCategoryOptions: [],
      productBrandOptions: [],
      productModelOptions: [],
      productFlavorOptions: [],
      productNicotineLevelOptions: [],
      productSizeOptions: [],
      
      productChangeRows: [],
      productChangeHeaders: 
      [
        {key: 'productChangeID', title: 'ID'},
        {key: 'productChangeState', title: 'ESTADO DE LA SOLICITUD'},
        {key: 'billLocalityName', title: 'FACTURA'},
        {key: 'productChangeLocalityName', title: 'SOLICITUD DE CAMBIO'},
        {key: 'productChangeProducts', title: 'PRODUCTOS'},
        {key: 'productChangeAction', title: null},
      ],
      productChangeActionOptions: 
      [
        {title: 'Aprobar cambio', icon: 'mdi-check-circle-outline'},
        {title: 'Rechazar cambio', icon: 'mdi-close-circle-outline'}

      ],
    },

    accessCredential: {},

    localStorage: 
    {
      agentID: null,
      localityID: null,
      productChangeStateColors: null
    }

  }),

  watch: {
    'input.startDate': async function() {
      this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB');
      this.navigation.startDatePickerIsVisible = false;
    },
    'input.endDate': async function() {
      this.display.endDateFormatted = new Date(this.input.endDate).toLocaleDateString('en-GB');
      this.navigation.endDatePickerIsVisible = false;
    }
  },

  methods: {
    async updateProductChange(productChangeID, productChangeState){
      this.loader.selectProductChangeOnProductChangeView = true;
      const updateProductChangeRequestQuery = 
      {
        'productChangeID': productChangeID,
        'productChangeApproverAgentID': this.localStorage.agentID,
        'productChangeState': productChangeState == 'Aprobar cambio' ? 'Cambio aprobado' : 'Cambio rechazado',
      };
      const updateProductChangeResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productChange/functions/updateProductChange', updateProductChangeRequestQuery);
      if (updateProductChangeResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': `El cambio ha sido ${productChangeState == 'Aprobar cambio' ? 'aprobado' : 'rechazado'} exitosamente`,
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': updateProductChangeResult.result.PDFDocumentFile.data,
          'printablePDFDocumentName': updateProductChangeResult.result.PDFDocumentName
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
        await this.selectProductChangeOnProductChangeView();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': `Ha ocurrido un error inesperado al ${productChangeState == 'Aprobar cambio' ? 'aprobar' : 'rechazar'} el cambio de producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema`,
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectProductChangeOnProductChangeView = false;
    },

    async selectProductChangeOnProductChangeView(){
      this.loader.selectProductChangeOnProductChangeView = true;
      const selectProductChangeOnProductChangeViewRequestQuery = 
      {
        'productChangeID': this.input.productChangeID,
        'productChangeBillID': this.input.productChangeBillID,
        'productChangeLocalityID': this.accessCredential['/productChange/selectLocality'] ? this.input.localityID : this.localStorage.localityID,
        'productChangeState': this.input.productChangeState,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate,
        'productID': this.input.productID,
        'productCategoryID': this.input.productCategoryID,
        'productBrandID': this.input.productBrandID,
        'productModelID': this.input.productModelID,
        'productFlavorID': this.input.productFlavorID,
        'productNicotineLevelID': this.input.productNicotineLevelID,
        'productSizeID': this.input.productSizeID
      };
      const selectProductChangeOnProductChangeViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productChange/functions/selectProductChangeOnProductChangeView', selectProductChangeOnProductChangeViewRequestQuery);
      if (selectProductChangeOnProductChangeViewResult.success){
        this.display.productChangeRows = selectProductChangeOnProductChangeViewResult.result;
        if (this.display.productChangeRows.length == 0){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': null,
            'notificationDialogBody': 'No se ha encontrado ningun cambio de producto con las características especificadas, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        } else {
          this.navigation.searchVisible = '';
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los cambios de producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectProductChangeOnProductChangeView = false;
    },


    async selectLocalityIDAndLocalityNameByLocalityIsActive(){
      this.loader.selectLocalityIDAndLocalityNameByLocalityIsActive = true;
      const selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery = {'localityIsActive': true};
      const selectLocalityIDAndLocalityNameByLocalityIsActiveResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityNameByLocalityIsActive', selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery);
      if (selectLocalityIDAndLocalityNameByLocalityIsActiveResult.success){
        console.log(selectLocalityIDAndLocalityNameByLocalityIsActiveResult.result);
        this.display.localityOptions = selectLocalityIDAndLocalityNameByLocalityIsActiveResult.result;
        this.loader.selectLocalityIDAndLocalityNameByLocalityIsActive = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductBasicBasicInformationForBillsView(){
      this.loader.selectProductBasicBasicInformationForBillsView = true;
      const selectProductBasicBasicInformationForBillsViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductBasicBasicInformationForBillsView');
      if (selectProductBasicBasicInformationForBillsViewResult.success){
        this.display.productOptions = selectProductBasicBasicInformationForBillsViewResult.result.map(productOption => ({
          'productID': productOption.i,
          'productSKU': productOption.s,
          'productName': productOption.n,
          'productAutocompleteTitle': productOption.s + ' - ' + productOption.n
        }));
        this.loader.selectProductBasicBasicInformationForBillsView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductSearchParameters(){
      this.loader.selectProductSearchParameters = true;
      const selectProductSearchParametersResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductSearchParameters');
      if (selectProductSearchParametersResult.success){
        this.display.productCategoryOptions = selectProductSearchParametersResult.result.productCategory;
        this.display.productBrandOptions = selectProductSearchParametersResult.result.productBrand;
        this.display.productModelOptions = selectProductSearchParametersResult.result.productModel;
        this.display.productFlavorOptions = selectProductSearchParametersResult.result.productFlavor;
        this.display.productNicotineLevelOptions = selectProductSearchParametersResult.result.productNicotineLevel;
        this.display.productSizeOptions = selectProductSearchParametersResult.result.productSize;
        this.loader.selectProductSearchParameters = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de características de los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async generateProductChangeApprovePDFDocument(productChange){
      productChange.loader = true;
      const generateProductChangeApprovePDFDocumentRequestQuery =
      {
        'productChangeID': productChange.productChangeID,
        'reprint': true
      };
      const generateProductChangeApprovePDFDocumentResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productChange/functions/generateProductChangeApprovePDFDocument', generateProductChangeApprovePDFDocumentRequestQuery);
      if (generateProductChangeApprovePDFDocumentResult.success){
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': generateProductChangeApprovePDFDocumentResult.result.PDFDocumentFile.data,
          'printablePDFDocumentName': generateProductChangeApprovePDFDocumentResult.result.PDFDocumentName
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el reporte del cambios de producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      productChange.loader = false;
    },

    async setDefaultValues(){
      this.input.productChangeID = null;
      this.input.localityID.length = 0;
      this.input.productChangeState.length = 0;
      this.input.productChangeBillID = null;
      const startDate = new Date();
      startDate.setDate(1);
      this.input.startDate = startDate;
      this.input.endDate = new Date();
      this.input.productID = null;
      this.input.productCategoryID.length = 0;
      this.input.productBrandID.length = 0;
      this.input.productModelID.length = 0;
      this.input.productFlavorID.length = 0;
      this.input.productNicotineLevelID.length = 0;
      this.input.productSizeID.length = 0;
      this.display.productChangeRows.length = 0;
    },

    async getLocalStorageData(){
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.productChangeStateColors = this.$generalFunctions.default.data.productChangeStateColors;
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    },

  },

  async mounted(){
    this.setDefaultValues();
    await this.getLocalStorageData();
    this.selectLocalityIDAndLocalityNameByLocalityIsActive();
    this.selectProductBasicBasicInformationForBillsView();
    this.selectProductSearchParameters();
  }

});

</script>
