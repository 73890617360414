<template>
  <div style="margin: 2% 3% 0% 3%;">
    <v-card color="border" elevation="3" style="padding: 20px;">
      <v-expansion-panels v-model="navigation.searchVisible">
        <v-expansion-panel value="searchVisible">
          <v-expansion-panel-title>
            <p style="font-size: large;">Búsqueda para unidades vendidas</p>
            <template v-slot:actions>
              <v-icon icon="mdi-magnify"></v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <br>
            <v-tooltip open-delay="2000" location="top">
              <template v-slot:activator="{ props }">
                <v-autocomplete 
                  v-bind="props"
                  v-model="input.localityID"
                  :append-inner-icon="input.localityID.length == 0 ? 'mdi-store' : 'mdi-store-off'" 
                  @click:append-inner="input.localityID.length = 0"  
                  :items="display.localityOptions" 
                  :loading="loader.selectLocalityIDAndLocalityNameByLocalityIsActive" 
                  :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive" 
                  multiple
                  chips
                  item-title="localityName" 
                  item-value="localityID" 
                  label="Filtrar por localidad" 
                  placeholder="Todas las localidades"
                  variant="solo-filled" 
                  density="compact"
                ></v-autocomplete>
              </template>
              <span style="max-width: 400px; display: block;">La localidad es el lugar desde donde se realizó la venta. Este espacio permite selección múltiple para filtrar por varias localidades en paralelo. Si ninguna localidad es seleccionada, se buscarán todas las ventas sin importar la localidad. Al presionar el ícono de la derecha se limpian las localidades seleccionadas</span>
            </v-tooltip>
            <div style="display: flex;">
              <v-text-field v-model="display.startDateFormatted" @click="navigation.startDatePickerIsVisible = true" readonly label="Filtrar por fecha inicial" append-inner-icon="mdi-calendar-start" variant="solo-filled" density="compact" style="width: 49.5%; margin-right: 0.5%;"></v-text-field>
              <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
                <v-card style="display: flex; border: 2px solid #343a40;">
                  <v-date-picker v-model="input.startDate" hide-header color="primary" width="100%" style="margin-bottom: 10px;"></v-date-picker>
                  <div style="display: flex; margin: 0px 10px 10px 10px;">
                    <v-tooltip open-delay="1000" location="top">
                      <template v-slot:activator="{ props }">
                        <v-chip v-bind="props" @click="input.startDate = new Date()" color="primary" variant="flat" style="display: flex; justify-content: center; align-items: center; cursor: pointer; width: 49.5%; margin-right: 0.5%;">Hoy (fecha inicial)</v-chip>
                      </template>
                      <span style="max-width: 400px; display: block;">Asigna la fecha de hoy como la fecha inicial</span>
                    </v-tooltip>
                    <v-tooltip open-delay="1000" location="top">
                      <template v-slot:activator="{ props }">
                        <v-chip v-bind="props" @click="input.startDate = new Date(); input.endDate = new Date()" color="primary" variant="flat" style="display: flex; justify-content: center; align-items: center; cursor: pointer; width: 49.5%; margin-left: 0.5%;">Hoy (ambas fechas)</v-chip>
                      </template>
                      <span style="max-width: 400px; display: block;">Asigna la fecha de hoy como la fecha inicial y la fecha final</span>
                    </v-tooltip>
                  </div>
                  <div style="display: flex; margin: 0px 10px 10px 10px;">
                    <v-tooltip open-delay="1000" location="top">
                      <template v-slot:activator="{ props }">
                        <v-chip v-bind="props" @click="input.startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1); input.endDate = new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0))" color="primary" variant="flat" style="display: flex; justify-content: center; align-items: center; cursor: pointer; width: 49.5%; margin-right: 0.5%;">Este mes</v-chip>
                      </template>
                      <span style="max-width: 400px; display: block;">Asigna el primer día del mes actual como la fecha inicial, y el último día del mes actual como la fecha final</span>
                    </v-tooltip>
                    <v-tooltip open-delay="1000" location="top">
                      <template v-slot:activator="{ props }">
                        <v-chip v-bind="props" @click="input.startDate = new Date(new Date().setDate(new Date().getDate() - 29)); input.endDate = new Date()" color="primary" variant="flat" style="display: flex; justify-content: center; align-items: center; cursor: pointer; width: 49.5%; margin-left: 0.5%;">Último mes</v-chip>                  
                      </template>
                      <span style="max-width: 400px; display: block;">Asigna 30 días en el pasado como la fecha inicial, y la fecha de hoy como la fecha final</span>
                    </v-tooltip>
                  </div>
                  <div style="display: flex; margin: 0px 10px 10px 10px;">
                    <v-tooltip open-delay="1000" location="top">
                      <template v-slot:activator="{ props }">
                        <v-chip v-bind="props" @click="input.startDate = new Date(new Date().getFullYear(), 0, 1); input.endDate = new Date(new Date().getFullYear(), 11, 31)" color="primary" variant="flat" style="display: flex; justify-content: center; align-items: center; cursor: pointer; width: 49.5%; margin-right: 0.5%;">Este año</v-chip>
                      </template>
                      <span style="max-width: 400px; display: block;">Asigna el primer día del año actual como la fecha inicial, y el último día del año actual como la fecha final</span>
                    </v-tooltip>
                    <v-tooltip open-delay="1000" location="top">
                      <template v-slot:activator="{ props }">
                        <v-chip v-bind="props" @click="input.startDate = new Date(new Date().setDate(new Date().getDate() - 364)); input.endDate = new Date()" color="primary" variant="flat" style="display: flex; justify-content: center; align-items: center; cursor: pointer; width: 49.5%; margin-left: 0.5%;">Último año</v-chip>
                      </template>
                      <span style="max-width: 400px; display: block;">Asigna 365 días en el pasado como la fecha inicial, y la fecha de hoy como la fecha final</span>
                    </v-tooltip>
                  </div>
                </v-card>
              </v-dialog>
              <v-text-field v-model="display.endDateFormatted" @click="navigation.endDatePickerIsVisible = true" readonly label="Filtrar por fecha final" append-inner-icon="mdi-calendar-end" variant="solo-filled" density="compact" style="width: 49.5%; margin-left: 0.5%;"></v-text-field>
              <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
                <v-card style="display: flex; border: 2px solid #343a40;">
                  <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%" style="margin-bottom: 10px;"></v-date-picker>
                  <div style="display: flex; margin: 0px 10px 10px 10px;">
                    <v-tooltip open-delay="1000" location="top">
                      <template v-slot:activator="{ props }">
                        <v-chip v-bind="props" @click="input.endDate = new Date()" color="primary" variant="flat" style="display: flex; justify-content: center; align-items: center; cursor: pointer; width: 49.5%; margin-right: 0.5%;">Hoy (fecha final)</v-chip>
                      </template>
                      <span style="max-width: 400px; display: block;">Asigna la fecha de hoy como la fecha final</span>
                    </v-tooltip>
                    <v-tooltip open-delay="1000" location="top">
                      <template v-slot:activator="{ props }">
                        <v-chip v-bind="props" @click="input.startDate = new Date(); input.endDate = new Date()" color="primary" variant="flat" style="display: flex; justify-content: center; align-items: center; cursor: pointer; width: 49.5%; margin-left: 0.5%;">Hoy (ambas fechas)</v-chip>
                      </template>
                      <span style="max-width: 400px; display: block;">Asigna la fecha de hoy como la fecha inicial y la fecha final</span>
                    </v-tooltip>
                  </div>
                  <div style="display: flex; margin: 0px 10px 10px 10px;">
                    <v-tooltip open-delay="1000" location="top">
                      <template v-slot:activator="{ props }">
                        <v-chip v-bind="props" @click="input.startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1); input.endDate = new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0))" color="primary" variant="flat" style="display: flex; justify-content: center; align-items: center; cursor: pointer; width: 49.5%; margin-right: 0.5%;">Este mes</v-chip>
                      </template>
                      <span style="max-width: 400px; display: block;">Asigna el primer día del mes actual como la fecha inicial, y el último día del mes actual como la fecha final</span>
                    </v-tooltip>
                    <v-tooltip open-delay="1000" location="top">
                      <template v-slot:activator="{ props }">
                        <v-chip v-bind="props" @click="input.startDate = new Date(new Date().setDate(new Date().getDate() - 29)); input.endDate = new Date()" color="primary" variant="flat" style="display: flex; justify-content: center; align-items: center; cursor: pointer; width: 49.5%; margin-left: 0.5%;">Último mes</v-chip>                  
                      </template>
                      <span style="max-width: 400px; display: block;">Asigna 30 días en el pasado como la fecha inicial, y la fecha de hoy como la fecha final</span>
                    </v-tooltip>
                  </div>
                  <div style="display: flex; margin: 0px 10px 10px 10px;">
                    <v-tooltip open-delay="1000" location="top">
                      <template v-slot:activator="{ props }">
                        <v-chip v-bind="props" @click="input.startDate = new Date(new Date().getFullYear(), 0, 1); input.endDate = new Date(new Date().getFullYear(), 11, 31)" color="primary" variant="flat" style="display: flex; justify-content: center; align-items: center; cursor: pointer; width: 49.5%; margin-right: 0.5%;">Este año</v-chip>
                      </template>
                      <span style="max-width: 400px; display: block;">Asigna el primer día del año actual como la fecha inicial, y el último día del año actual como la fecha final</span>
                    </v-tooltip>
                    <v-tooltip open-delay="1000" location="top">
                      <template v-slot:activator="{ props }">
                        <v-chip v-bind="props" @click="input.startDate = new Date(new Date().setDate(new Date().getDate() - 364)); input.endDate = new Date()" color="primary" variant="flat" style="display: flex; justify-content: center; align-items: center; cursor: pointer; width: 49.5%; margin-left: 0.5%;">Último año</v-chip>
                      </template>
                      <span style="max-width: 400px; display: block;">Asigna 365 días en el pasado como la fecha inicial, y la fecha de hoy como la fecha final</span>
                    </v-tooltip>
                  </div>
                </v-card>
              </v-dialog>
            </div>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-title>
                  Búsqueda avanzada por producto
                  <template v-slot:actions>
                    <v-icon icon="mdi-magnify"></v-icon>
                  </template>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <br>
                  <div style="display: flex;">
                    <div style="width: 49.5%; margin-right: 0.5%;">
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete 
                            v-bind="props"
                            v-model="input.productCategoryID" 
                            :append-inner-icon="input.productCategoryID.length == 0 ? 'mdi-cog' : 'mdi-cog-off'" 
                            @click:append-inner="input.productCategoryID.length = 0" 
                            :items="display.productCategoryOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            item-title="productCategoryName" 
                            item-value="productCategoryID" 
                            label="Filtrar por categoría del producto" 
                            placeholder="Todas las categorías del producto" 
                            variant="solo-filled" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">La categoría del producto hace referencia al primer nivel de categorización de un producto. Este espacio permite selección múltiple para filtrar por varias categorías de producto en paralelo. Si ninguna categoría de producto es seleccionada, se buscarán todas las ventas sin importar las categorías de los productos incluidos en las mismas. Al presionar el ícono de la derecha se limpian las categorías de producto seleccionadas</span>
                      </v-tooltip>
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete 
                            v-bind="props"
                            v-model="input.productBrandID" 
                            :append-inner-icon="input.productBrandID.length == 0 ? 'mdi-briefcase-variant' : 'mdi-briefcase-variant-off'" 
                            @click:append-inner="input.productBrandID.length = 0" 
                            :items="display.productBrandOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            item-title="productBrandName" 
                            item-value="productBrandID" 
                            label="Filtrar por marca del producto" 
                            placeholder="Todas las marcas del producto" 
                            variant="solo-filled" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">La marca del producto hace referencia al segundo nivel de categorización de un producto. Este espacio permite selección múltiple para filtrar por varias marcas de producto en paralelo. Si ninguna marca de producto es seleccionada, se buscarán todas las ventas sin importar las marcas de los productos incluidos en las mismas. Al presionar el ícono de la derecha se limpian las marcas de producto seleccionadas</span>
                      </v-tooltip>
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete 
                            v-bind="props"
                            v-model="input.productModelID" 
                            :append-inner-icon="input.productModelID.length == 0 ? 'mdi-source-branch' : 'mdi-source-branch-remove'" 
                            @click:append-inner="input.productModelID.length = 0" 
                            :items="display.productModelOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            item-title="productModelName" 
                            item-value="productModelID" 
                            label="Filtrar por modelo del producto" 
                            placeholder="Todos los modelos del producto" 
                            variant="solo-filled" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">El modelo del producto hace referencia al tercer nivel de categorización de un producto. Este espacio permite selección múltiple para filtrar por varios modelos de producto en paralelo. Si ningún modelo de producto es seleccionado, se buscarán todas las ventas sin importar los modelos de los productos incluidos en las mismas. Al presionar el ícono de la derecha se limpian los modelos de producto seleccionados</span>
                      </v-tooltip>
                    </div>
                    <div style="width: 49.5%; margin-left: 0.5%;">
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete 
                            v-bind="props"
                            v-model="input.productFlavorID" 
                            :append-inner-icon="input.productFlavorID.length == 0 ? 'mdi-water' : 'mdi-water-off'" 
                            @click:append-inner="input.productFlavorID.length = 0" 
                            :items="display.productFlavorOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            item-title="productFlavorName" 
                            item-value="productFlavorID" 
                            label="Filtrar por sabor del producto" 
                            placeholder="Todos los sabores del producto" 
                            variant="solo-filled" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">El sabor del producto hace referencia al primer tipo de categorización opcional de un producto. Este espacio permite selección múltiple para filtrar por varios sabores de producto en paralelo. Si ningún sabor de producto es seleccionado, se buscarán todas las ventas sin importar los sabores de los productos incluidos en las mismas. Al presionar el ícono de la derecha se limpian los sabores de producto seleccionados</span>
                      </v-tooltip>
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete 
                            v-bind="props"
                            v-model="input.productNicotineLevelID" 
                            :append-inner-icon="input.productNicotineLevelID.length == 0 ? 'mdi-cloud-outline' : 'mdi-cloud-off-outline'" 
                            @click:append-inner="input.productNicotineLevelID.length = 0" 
                            :items="display.productNicotineLevelOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            item-title="productNicotineLevelName" 
                            item-value="productNicotineLevelID" 
                            label="Filtrar por graduación de nicotina del producto"  
                            placeholder="Todas las graduaciones de nicotina del producto" 
                            variant="solo-filled" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">La graduación de nicotina del producto hace referencia al segundo tipo de categorización opcional de un producto. Este espacio permite selección múltiple para filtrar por varias graduaciones de nicotina de producto en paralelo. Si ninguna graduación de nicotina de producto es seleccionada, se buscarán todas las ventas sin importar las graduaciones de nicotina de los productos incluidos en las mismas. Al presionar el ícono de la derecha se limpian las graduaciones de nicotina de producto seleccionadas</span>
                      </v-tooltip>
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete
                            v-bind="props" 
                            v-model="input.productSizeID" 
                            :append-inner-icon="input.productSizeID.length == 0 ? 'mdi-ruler' : 'mdi-pencil-ruler'" 
                            @click:append-inner="input.productSizeID.length = 0" 
                            :items="display.productSizeOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            item-title="productSizeName" 
                            item-value="productSizeID" 
                            label="Filtrar por tamaño del producto" 
                            variant="solo-filled" 
                            placeholder="Todos los tamaños del producto" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">El tamaño del producto hace referencia al tercer tipo de categorización opcional de un producto. Este espacio permite selección múltiple para filtrar por varios tamaños de producto en paralelo. Si ningún tamaño de producto es seleccionado, se buscarán todas las ventas sin importar los tamaños de los productos incluidos en las mismas. Al presionar el ícono de la derecha se limpian los tamaños de producto seleccionados</span>
                      </v-tooltip>
                    </div>
                  </div>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
            <br><br>
            <div v-if="loader.selectBillProductOnSaleCountView" style="text-align: center;">
              <v-progress-circular color="primary" indeterminate></v-progress-circular>
              <br><br>
            </div>
            <div v-else>
              <v-btn @click="selectBillProductOnSaleCountView()" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive || loader.selectProductSearchParameters || loader.selectBillProductOnSaleCountView" dark block height="38" color="primary">
                <h3>BUSCAR</h3>
                <v-icon style="margin-left: 20px;" dark right>mdi-magnify</v-icon>
              </v-btn>
              <br>
              <v-tooltip open-delay="2000" location="top">
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" @click="setDefaultValues()" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive || loader.selectProductSearchParameters || loader.selectBillProductOnSaleCountView" dark block height="38" color="#b3b3b3">
                    <h3>LIMPIAR BÚSQUEDA</h3>
                    <v-icon style="margin-left: 20px;" dark right>mdi-backspace</v-icon>
                  </v-btn>
                </template>
                <span style="max-width: 400px; display: block;">Al presionar este botón, se limpiarán los resultados de la búsqueda actual, y se reestablecerán todos los filtros de búsqueda a sus valores por defecto</span>
              </v-tooltip> 
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <br><br>
    <v-card v-if="display.saleCount.length > 0" color="border" elevation="3" style="padding: 20px;">
      <div style="display: flex; margin-bottom: 20px;">
        <div style="width: 69.5%; margin-right: 0.5%;">
          <div style="display: flex;">
            <p style="font-size: x-large; margin-right: 20px;"><strong>TOTAL:</strong></p>
            <div v-for="locality in display.total" v-bind:key="locality">
              <v-tooltip :text="locality.localityName" location="top">
                <template v-slot:activator="{ props }">
                  <v-chip v-bind="props" :color="locality.localityColor" variant="flat" style="margin: 0px 5px; cursor: pointer;">
                    <p :style="{color: locality.localityFontColor}" style="font-size: larger; margin: 0;"><strong>{{ locality.amount }}</strong></p>
                  </v-chip>
                </template>
              </v-tooltip>
            </div>
            <v-tooltip text="TOTAL" location="top">
              <template v-slot:activator="{ props }">
                <v-chip v-bind="props" color="black" variant="flat" style="margin: 0px 5px; cursor: pointer;">
                  <p style="color: white; font-size: larger; margin: 0;"><strong>{{ display.total.reduce((totalAmount, locality) => (totalAmount + locality.amount), 0) }}</strong></p>
                </v-chip>
              </template>
            </v-tooltip>
          </div>
        </div>
        <div style="width: 29.5%; margin-left: 0.5%;">
          <v-tooltip open-delay="2000" location="top">
            <template v-slot:activator="{ props }">
              <v-select
                v-bind="props" 
                v-model="input.sortOption" 
                :append-inner-icon="input.sortOption == 'alphabetic' ? 'mdi-sort-variant' : 'mdi-sort-variant-remove'" 
                @click:append-inner="input.sortOption = 'alphabetic'" 
                :items="display.sortOptions" 
                label="Ordenar por" 
                variant="solo-filled"
                density="compact"
              ></v-select>
            </template>
            <span style="max-width: 400px; display: block;">Las ventas inicialmente se ordenan de forma alfabética (A-Z). Este espacio permite ordenar según el criterio deseado. Al presionar el ícono de la derecha se retorna al ordenamiento inicial de forma alfabética (A-Z)</span>
          </v-tooltip>
        </div>
      </div>
      <v-expansion-panels style="max-height: 700px; overflow-y: auto;">
        <v-expansion-panel v-for="productBrand in display.saleCount" :key="productBrand">
          <v-expansion-panel-title>
            <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
              <p style="font-size: larger; margin: 0;">{{ productBrand.productBrandName }}</p>
              <div style="display: flex; margin-right: 40px;">
                <div v-for="locality in productBrand.locality" v-bind:key="locality">
                  <v-tooltip :text="locality.localityName" location="top">
                    <template v-slot:activator="{ props }">
                      <v-chip v-bind="props" :color="locality.localityColor" variant="flat" style="margin: 0px 5px; cursor: pointer;">
                        <p :style="{color: locality.localityFontColor}" style="font-size: larger; margin: 0;"><strong>{{ locality.amount }}</strong></p>
                      </v-chip>
                    </template>
                  </v-tooltip>
                </div>
                <v-tooltip text="TOTAL" location="top">
                  <template v-slot:activator="{ props }">
                    <v-chip v-bind="props" color="black" variant="flat" style="margin: 0px 5px; cursor: pointer;">
                      <p style="color: white; font-size: larger; margin: 0;"><strong>{{ productBrand.locality.reduce((totalAmount, locality) => totalAmount + locality.amount, 0) }}</strong></p>
                    </v-chip>
                  </template>
                </v-tooltip>
              </div>
            </div>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-expansion-panels style="max-height: 500px; overflow-y: auto;">
              <v-expansion-panel v-for="productModel in productBrand.productModel" :key="productModel">
                <v-expansion-panel-title>
                  <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
                    <p style="font-size: large; margin: 0;">{{ productModel.productModelName }}</p>
                    <div style="display: flex; margin-right: 40px;">
                      <div v-for="locality in productModel.locality" v-bind:key="locality">
                        <v-tooltip :text="locality.localityName" location="top">
                          <template v-slot:activator="{ props }">
                            <v-chip v-bind="props" :color="locality.localityColor" variant="flat" style="margin: 0px 5px; cursor: pointer;">
                              <p :style="{color: locality.localityFontColor}" style="font-size: large; margin: 0;"><strong>{{ locality.amount }}</strong></p>
                            </v-chip>
                          </template>
                        </v-tooltip>
                      </div>
                      <v-tooltip text="TOTAL" location="top">
                        <template v-slot:activator="{ props }">
                          <v-chip v-bind="props" color="black" variant="flat" style="margin: 0px 5px; cursor: pointer;">
                            <p style="color: white; font-size: large; margin: 0;"><strong>{{ productModel.locality.reduce((totalAmount, locality) => totalAmount + locality.amount, 0) }}</strong></p>
                          </v-chip>
                        </template>
                      </v-tooltip>
                    </div>
                  </div>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <v-data-table :headers="display.saleCountHeaders" :items="productModel.product" hide-default-header :items-per-page="-1" style="max-height: 300px; overflow-y: auto;">
                    <template v-slot:item.productProperties="{ item }">
                      <div style="width: 250px; display: flex;">
                        <v-tooltip :text="`Ranking de producto${item.productRanking == 'NA' ? ' (sin compras registradas)' : ''}`" location="top">
                          <template v-slot:activator="{ props }">
                            <v-chip v-bind="props" color="purple" label style="margin-right: 20px; font-size: larger; font-weight: bold; cursor: pointer;">
                              <v-icon icon="mdi-medal" start></v-icon>
                              <p>{{ item.productRanking }}</p>
                            </v-chip>
                          </template>
                        </v-tooltip>
                        <v-tooltip text="Panel de producto" location="top">
                          <template v-slot:activator="{ props }">
                            <v-chip v-bind="props" v-if="accessCredential['/product']" color="warning" label style="margin-right: 20px; font-size: larger; cursor: pointer;">
                              <v-icon @click="$generalFunctions.default.methods.openNewTab('/product/'+item.productID)" icon="mdi-bulletin-board"></v-icon>
                            </v-chip>
                          </template>
                        </v-tooltip>
                        <v-tooltip text="Tracking de producto" location="top">
                          <template v-slot:activator="{ props }">
                            <v-chip v-bind="props" v-if="accessCredential['/productHistory']" color="indigo" label style="font-size: larger; cursor: pointer;">
                              <v-icon @click="$generalFunctions.default.methods.openNewTab('/productHistory/'+item.productID)" icon="mdi-update"></v-icon>
                            </v-chip>
                          </template>
                        </v-tooltip>
                      </div>
                    </template>
                    <template v-slot:item.productName="{ item }">
                      <p>{{ item.productName }}</p>
                      <p><strong>{{ item.productID }}</strong></p>
                    </template>
                    <template v-slot:item.locality="{ item }">
                      <div style="display: flex;">
                        <div v-for="locality in item.locality" v-bind:key="locality">
                          <v-tooltip :text="locality.localityName" location="top">
                            <template v-slot:activator="{ props }">
                              <v-chip v-bind="props" :color="locality.localityColor" variant="flat" style="margin: 0px 5px 0px 5px; cursor: pointer;">
                                <p :style="{color: locality.localityFontColor}"><strong>{{ locality.amount }}</strong></p>
                              </v-chip>
                            </template>
                          </v-tooltip>
                        </div>
                        <v-tooltip text="TOTAL" location="top">
                          <template v-slot:activator="{ props }">
                            <v-chip v-bind="props" color="black" variant="flat" style="margin: 0px 5px 0px 5px; cursor: pointer;">
                              <p style="color: white;"><strong>{{ item.locality.reduce((totalAmount, locality) => totalAmount + locality.amount, 0) }}</strong></p>
                            </v-chip>
                          </template>
                        </v-tooltip>
                      </div>
                    </template>
                    <template #bottom></template>
                  </v-data-table>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <br><br>
  </div>
</template>

<style scoped>
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SaleCountView',

  inject: ['$generalFunctions'],

  data: () => ({
    input: 
    {
      localityID: [],
      startDate: null,
      endDate: null,
      productCategoryID: [],
      productBrandID: [],
      productModelID: [],
      productFlavorID: [],
      productNicotineLevelID: [],
      productSizeID: [],
      sortOption: null
    },

    display: 
    {
      startDateFormatted: '',
      endDateFormatted: '',
      localityOptions: [],
      productOptions: [],
      productCategoryOptions: [],
      productBrandOptions: [],
      productModelOptions: [],
      productFlavorOptions: [],
      productNicotineLevelOptions: [],
      productSizeOptions: [],
      sortOptions: 
      [
        {title: 'Nombre (A-Z)', value: 'alphabetic'},
        {title: 'Nombre (Z-A)', value: 'reverse_alphabetic'},
        {title: 'Cantidad vendida (mayor primero)', value: 'numeric'},
        {title: 'Cantidad vendida (menor primero)', value: 'reverse_numeric'}
      ],
      total: [],
      saleCount: [],
      saleCountHeaders: 
      [
        {key: 'productProperties', title: null, sortable: false},
        {key: 'productName', title: null, sortable: false},
        {key: 'locality', title: null, sortable: false}
      ]
    },

    navigation: 
    {
      searchVisible: 'searchVisible'
    },

    loader:
    {
      selectLocalityIDAndLocalityName: false,
      selectProductSearchParameters: false,
      selectBillProductOnSaleCountView: false
    },

    localStorage:
    {
      localityID: null,
      agentID: null
    },

    accessCredential: {}
  }),

  watch: {
    'input.startDate': async function() {
      this.display.startDateFormatted = this.input.startDate ? (new Date(this.input.startDate).toLocaleDateString('en-GB')) : '';
      this.navigation.startDatePickerIsVisible = false;
    },
    'input.endDate': async function() {
      this.display.endDateFormatted = this.input.endDate ? (new Date(this.input.endDate).toLocaleDateString('en-GB')) : '';
      this.navigation.endDatePickerIsVisible = false;
    },

    'input.sortOption': async function () {
      this.sortSaleCountAlphabetic('asc');
      if (this.input.sortOption == 'alphabetic'){
        this.sortSaleCountAlphabetic('asc');
      }
      if (this.input.sortOption == 'reverse_alphabetic'){
        this.sortSaleCountAlphabetic('desc');
      }
      if (this.input.sortOption == 'numeric'){
        this.sortSaleCountNumeric('desc');
      }
      if (this.input.sortOption == 'reverse_numeric'){
        this.sortSaleCountNumeric('asc');
      }
    }
  },

  methods: {
    async selectBillProductOnSaleCountView(){
      this.loader.selectBillProductOnSaleCountView = true;
      this.input.sortOption = 'alphabetic';
      this.display.total.length = 0;
      this.display.saleCount.length = 0;
      const selectBillProductOnSaleCountViewRequestQuery =
      {
        'localityID': this.input.localityID,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate,
        'productCategoryID': this.input.productCategoryID,
        'productBrandID': this.input.productBrandID,
        'productModelID': this.input.productModelID,
        'productFlavorID': this.input.productFlavorID,
        'productNicotineLevelID': this.input.productNicotineLevelID,
        'productSizeID': this.input.productSizeID
      };
      const selectBillProductOnSaleCountViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/billProduct/functions/selectBillProductOnSaleCountView', selectBillProductOnSaleCountViewRequestQuery);
      if (selectBillProductOnSaleCountViewResult.success){
        this.display.total = selectBillProductOnSaleCountViewResult.result.total;
        this.display.saleCount = selectBillProductOnSaleCountViewResult.result.saleCount;
        this.navigation.searchVisible = '';
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectBillProductOnSaleCountView = false;
    },

    async selectLocalityIDAndLocalityName(){
      this.loader.selectLocalityIDAndLocalityName = true;
      const selectLocalityIDAndLocalityNameResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityName');
      if (selectLocalityIDAndLocalityNameResult.success){
        this.display.localityOptions = selectLocalityIDAndLocalityNameResult.result;
        this.loader.selectLocalityIDAndLocalityName = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductSearchParameters(){
      this.loader.selectProductSearchParameters = true;
      const selectProductSearchParametersResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductSearchParameters');
      if (selectProductSearchParametersResult.success){
        this.display.productCategoryOptions = selectProductSearchParametersResult.result.productCategory;
        this.display.productBrandOptions = selectProductSearchParametersResult.result.productBrand;
        this.display.productModelOptions = selectProductSearchParametersResult.result.productModel;
        this.display.productFlavorOptions = selectProductSearchParametersResult.result.productFlavor;
        this.display.productNicotineLevelOptions = selectProductSearchParametersResult.result.productNicotineLevel;
        this.display.productSizeOptions = selectProductSearchParametersResult.result.productSize;
        this.loader.selectProductSearchParameters = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de características de los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },
    
    sortSaleCountAlphabetic(order) {
      const comparision = (a, b) => order == 'asc' ? a.localeCompare(b) : b.localeCompare(a);
      this.display.saleCount.sort((productA, productB) => comparision(productA.productBrandName, productB.productBrandName));
      this.display.saleCount.forEach(productBrand => {
        productBrand.productModel.sort((productA, productB) => comparision(productA.productModelName, productB.productModelName));
        productBrand.productModel.forEach(productModel => {
          productModel.product.sort((productA, productB) => comparision(productA.productName, productB.productName));
        });
      });
    },

    sortSaleCountNumeric(order) {
      const comparision = (a, b) => {
        const sumA = a.locality.reduce((total, locality) => total + locality.amount, 0);
        const sumB = b.locality.reduce((total, locality) => total + locality.amount, 0);
        return order === 'asc' ? sumA - sumB : sumB - sumA;
      };
      this.display.saleCount.sort(comparision);
      this.display.saleCount.forEach(productBrand => {
        productBrand.productModel.sort(comparision);
        productBrand.productModel.forEach(productModel => {
          productModel.product.sort(comparision);
        });
      });
    },

    setDefaultValues(){
      this.input.localityID.length = 0;
      const startDate = new Date();
      startDate.setDate(1);
      this.input.startDate = startDate;
      this.input.endDate = new Date();
      this.input.productCategoryID.length = 0;
      this.input.productBrandID.length = 0;
      this.input.productModelID.length = 0;
      this.input.productFlavorID.length = 0;
      this.input.productNicotineLevelID.length = 0;
      this.input.productSizeID.length = 0;
      this.input.sortOption = 'alphabetic';
      this.display.total.length = 0;
      this.display.saleCount.length = 0;
      this.loader.selectProductStockOnProductStockView = false;
    },
    
    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    },

  },

  async mounted(){
    await this.getLocalStorageData();
    this.setDefaultValues();
    this.selectLocalityIDAndLocalityName();
    this.selectProductSearchParameters();
  },
});
</script>
