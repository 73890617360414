<template>
  <div style="margin: 2% 3% 0% 3%;">
    <v-dialog v-model="navigation.callcenterBillProductsDialogIsVisible" width="auto">
      <v-card width="800" style="padding: 20px;">
        <v-data-table-virtual :headers="display.callcenterBillProductHeaders" :items="display.callcenterBillProductRows">    
          <template v-slot:item.productName="{ item }">
            <p>{{ item.productName }}</p>
            <p><strong>{{ item.productID }}</strong></p>
          </template>  
          <template v-slot:item.productDiscount="{ item }">
            <p>{{ item.productDiscount }} %</p>
          </template>  
          <template v-slot:item.productPrice="{ item }">
            <p>₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(item.productPrice) }}</p>
          </template>  
        </v-data-table-virtual>
      </v-card>
    </v-dialog>
    <div v-if="loader.selectCallcenterBillFromCallcenterView" style="text-align: center;">
      <br><br>
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
    <v-card v-else color="border" elevation="3" style="padding: 20px;">
      <h2>Órdenes actuales:</h2>
      <br>
      <v-data-table-virtual :headers="display.callcenterBillHeaders" :items="display.callcenterBillRows" style="max-height: 700px; overflow-y: auto;">
        <template v-slot:item.callcenterBillAction="{ item }">
          <div v-if="item.loader == 'success' || item.loader == 'error'">
            <v-progress-circular :color="item.loader" indeterminate></v-progress-circular>
          </div>
          <div v-else>
            <v-icon @click="openBillView(item)" color="success" size="30">mdi-invoice-send</v-icon>
            <v-icon @click="updateCallcenterBillUpdateDatetimeFromCallcenterView(item, 'error')" color="error" size="30" style="margin-left: 10px;">mdi-invoice-remove</v-icon>
          </div>
        </template>
        <template v-slot:item.callcenterBillProducts="{ item }">
          <v-icon @click="openWhatsappInvoiceProductsDialog(JSON.parse(item.callcenterBillProducts))" color="info" size="30">mdi-cart</v-icon>
        </template>
        <template v-slot:item.callcenterBillCreationDatetime="{ item }">
          <div style="width: 150px;">
            {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.callcenterBillCreationDatetime) }}
          </div>
        </template>
        <template v-slot:item.callcenterBillClientName="{ item }">
          <div style="width: 500px;">
            <p><strong>Cédula:</strong> {{ item.callcenterBillClientSSN || 'No registrada' }}</p>
            <p><strong>Nombre:</strong> {{ item.callcenterBillClientName }}</p>
            <p><strong>Correo electrónico:</strong> {{ item.callcenterBillClientEmail || 'No registrado' }}</p>
            <p><strong>Número de teléfono:</strong> {{ item.callcenterBillClientPhoneNumber }}</p>
          </div>
        </template>
        <template v-slot:item.callcenterBillAmount="{ item }">
          <div style="width: 350px;">
            <p><strong>Monto:</strong> ₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.callcenterBillAmount) }}</p>
            <p><strong>Método de pago:</strong> {{ item.callcenterBillPaymentType }}</p>
            <p><strong>Estado del pago:</strong> {{ item.callcenterBillPaymentState }}</p>
            <p><strong>Método de envío:</strong> {{ item.callcenterBillShippingMethod }}</p>
          </div>
        </template>
      </v-data-table-virtual>
    </v-card>
    <br><br>
    <v-card color="border" elevation="3" style="padding: 20px;">
      <h2>Órdenes previas:</h2>
      <br>
      <div style="display: flex;">
        <div style="width: 49.5%; margin-right: 0.5%;">
          <v-text-field v-model="display.startDateFormatted" @click="navigation.startDatePickerIsVisible = true" readonly label="Filtrar por fecha inicial" append-inner-icon="mdi-calendar" variant="solo-filled" density="compact" style="margin-bottom: 0;"></v-text-field>
          <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
            <v-card style="display: flex;">
              <v-date-picker v-model="input.startDate" :min="getMinimumDate()" hide-header color="primary" width="100%"></v-date-picker>
              <v-chip @click="input.startDate = new Date()" color="primary" variant="flat" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;">Hoy</v-chip>
            </v-card>
          </v-dialog>
        </div>
        <div style="width: 49.5%; margin-left: 0.5%;">
          <v-text-field v-model="display.endDateFormatted" @click="navigation.endDatePickerIsVisible = true" readonly label="Filtrar por fecha final" append-inner-icon="mdi-calendar" variant="solo-filled" density="compact" style="margin-bottom: 0;"></v-text-field>                
          <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
            <v-card>
              <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
              <v-chip @click="input.endDate = new Date()" color="primary" variant="flat" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;">Hoy</v-chip>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <br>
      <div v-if="loader.selectPreviousCallcenterBillFromCallcenterView" style="text-align: center;">
        <br>
        <v-progress-circular color="primary" indeterminate></v-progress-circular>
        <br><br>
      </div>
      <div v-else>
        <v-btn @click="selectPreviousCallcenterBillFromCallcenterView()" :disabled="loader.selectPreviousCallcenterBillFromCallcenterView" dark block height="38" color="primary">
          <h3>BUSCAR</h3>
          <v-icon style="margin-left: 20px;" dark right>mdi-magnify</v-icon>
        </v-btn>
        <br>
        <v-tooltip open-delay="2000" location="top">
          <template v-slot:activator="{ props }">
            <v-btn 
              v-bind="props" 
              @click="setDefaultValues()" 
              :disabled="loader.selectPreviousCallcenterBillFromCallcenterView" 
              dark 
              block 
              height="38" 
              color="#b3b3b3"
            >
              <h3>LIMPIAR BÚSQUEDA</h3>
              <v-icon style="margin-left: 20px;" dark right>mdi-backspace</v-icon>
            </v-btn>
          </template>
          <span style="max-width: 400px; display: block;">Al presionar este botón, se limpiarán los resultados de la búsqueda actual, y se reestablecerán todos los filtros de búsqueda a sus valores por defecto</span>
        </v-tooltip>
      </div>
      <div v-if="(display.previousCallcenterBillRows.length > 0) || (input.searchQuery != null)">
        <br><br>
        <v-tooltip open-delay="2000" location="top">
          <template v-slot:activator="{ props }">
            <v-text-field
              v-bind="props" 
              v-model="input.searchQuery" 
              :append-inner-icon="(input.searchQuery == null) || (input.searchQuery.trim() == '')  ? 'mdi-magnify' : 'mdi-magnify-remove-outline'" 
              @click:append-inner="input.searchQuery = null" 
              label="Búsqueda rápida" 
              variant="solo-filled" 
              density="compact"
            ></v-text-field>
          </template>
          <span style="max-width: 400px; display: block;">La búsqueda rápida permite buscar en toda la lista de órdenes por nombre, número de teléfono, monto, método de pago, tipo, monto, método de pago, estado del pago, método de envío, código de producto o nombre de producto. Se buscan todas las órdenes que coincidan parcial o completamente con el criterio de búsqueda colocado. Al presionar el ícono de la derecha se elimina el criterio de búsqueda colocado</span>
        </v-tooltip>
        <v-data-table :headers="display.callcenterBillHeaders" :items="display.previousCallcenterBillRows" :items-per-page="250" style="max-height: 700px; overflow-y: auto;">
          <template v-slot:item.callcenterBillAction="{ item }">
            <div v-if="item.loader == 'success' || item.loader == 'error'">
              <v-progress-circular :color="item.loader" indeterminate></v-progress-circular>
            </div>
            <div v-else>
              <v-icon @click="openBillView(item)" color="success" size="30">mdi-invoice-send</v-icon>
            </div>
          </template>
          <template v-slot:item.callcenterBillProducts="{ item }">
            <v-icon @click="openWhatsappInvoiceProductsDialog(JSON.parse(item.callcenterBillProducts))" color="info" size="30">mdi-cart</v-icon>
          </template>
          <template v-slot:item.callcenterBillCreationDatetime="{ item }">
            <div style="width: 150px;">
              {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.callcenterBillCreationDatetime) }}
            </div>
          </template>
          <template v-slot:item.callcenterBillClientName="{ item }">
            <div style="width: 500px;">
              <p><strong>Cédula:</strong> {{ item.callcenterBillClientSSN || 'No registrada' }}</p>
              <p><strong>Nombre:</strong> {{ item.callcenterBillClientName }}</p>
              <p><strong>Correo electrónico:</strong> {{ item.callcenterBillClientEmail || 'No registrado' }}</p>
              <p><strong>Número de teléfono:</strong> {{ item.callcenterBillClientPhoneNumber }}</p>
            </div>
          </template>
          <template v-slot:item.callcenterBillAmount="{ item }">
            <div style="width: 350px;">
              <p><strong>Monto:</strong> ₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.callcenterBillAmount) }}</p>
              <p><strong>Método de pago:</strong> {{ item.callcenterBillPaymentType }}</p>
              <p><strong>Estado del pago:</strong> {{ item.callcenterBillPaymentState }}</p>
              <p><strong>Método de envío:</strong> {{ item.callcenterBillShippingMethod }}</p>
            </div>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <br><br>
  </div>
</template>

<style scoped>
</style>


<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CallcenterView',

  inject: ['$generalFunctions'],

  data: () => ({
    input: 
    {
      startDate: null,
      endDate: null
    },

    display: 
    {
      callcenterBillHeaders:
      [
        {key: 'callcenterBillAction', title: 'FACTURAR'},
        {key: 'callcenterBillProducts', title: 'CARRITO'},
        {key: 'callcenterBillID', title: 'ID'},
        {key: 'callcenterBillCreationDatetime', title: 'FECHA Y HORA'},
        {key: 'callcenterBillClientName', title: 'CLIENTE'},
        {key: 'callcenterBillAmount', title: 'PAGO'},
      ],
      callcenterBillRows: [],

      callcenterBillProductHeaders:
      [
        {key: 'productName', title: 'PRODUCTO'},
        {key: 'productAmount', title: 'CANTIDAD'},
        {key: 'productDiscount', title: 'DESCUENTO'},
        {key: 'productPrice', title: 'PRECIO'},
      ],
      callcenterBillProductRows: [],
      previousCallcenterBillRows: [],
      backupPreviousCallcenterBillRows: []
    },

    navigation: 
    {
      callcenterBillProductsDialogIsVisible: false,
      selectCallcenterBillFromCallcenterViewInterval: null
    },

    loader:
    {
      selectCallcenterBillFromCallcenterView: false,
      selectPreviousCallcenterBillFromCallcenterView: false
    },

    localStorage: 
    {
      localityID: null
    },

    accessCredential: {}
  }),

  watch: {
    'input.startDate': async function() {
      this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB');
      this.navigation.startDatePickerIsVisible = false;
    },
    'input.endDate': async function() {
      this.display.endDateFormatted = new Date(this.input.endDate).toLocaleDateString('en-GB');
      this.navigation.endDatePickerIsVisible = false;
    },

    'input.searchQuery': async function(){
      const backupPreviousCallcenterBillRows = JSON.parse(JSON.stringify(this.display.backupPreviousCallcenterBillRows));
      const searchQuery = this.input.searchQuery ? this.input.searchQuery.toLowerCase().trim() : null;
      if (!searchQuery) {
        this.display.previousCallcenterBillRows = backupPreviousCallcenterBillRows;
        return;
      }
      this.display.previousCallcenterBillRows = backupPreviousCallcenterBillRows.filter(callcenterBillRow => {
        return (
          [
            callcenterBillRow.callcenterBillClientName,
            callcenterBillRow.callcenterBillClientPhoneNumber,
            callcenterBillRow.callcenterBillPaymentType,
            callcenterBillRow.callcenterBillPaymentState,
            callcenterBillRow.callcenterBillShippingMethod,
            callcenterBillRow.callcenterBillAmount,
            callcenterBillRow.callcenterBillProducts
          ]
          .filter(value => value)
          .some(value => value.toString().toLowerCase().includes(searchQuery)) 
        );
      });
    }
  },

  methods: {
    openWhatsappInvoiceProductsDialog(callcenterBillProducts){
      this.display.callcenterBillProductRows = callcenterBillProducts;
      this.navigation.callcenterBillProductsDialogIsVisible = true;
    },

    async openBillView(callcenterBill){
      const updateCallcenterBillUpdateDatetimeFromCallcenterViewResult = await this.updateCallcenterBillUpdateDatetimeFromCallcenterView(callcenterBill, 'success');
      if (updateCallcenterBillUpdateDatetimeFromCallcenterViewResult.success){
        const encodedQuery = encodeURIComponent(JSON.stringify(callcenterBill));
        this.$router.push({path: '/bill', query: { 'callcenterBill': encodedQuery}});
      }
    },

    async updateCallcenterBillUpdateDatetimeFromCallcenterView(callcenterBill, loaderColor){
      callcenterBill.loader = loaderColor;
      const updateCallcenterBillUpdateDatetimeFromCallcenterViewRequestQuery = 
      {
        'callcenterBillID': callcenterBill.callcenterBillID
      };
      const updateCallcenterBillUpdateDatetimeFromCallcenterViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/callcenterBill/functions/updateCallcenterBillUpdateDatetimeFromCallcenterView', updateCallcenterBillUpdateDatetimeFromCallcenterViewRequestQuery);
      if (updateCallcenterBillUpdateDatetimeFromCallcenterViewResult.success){
        callcenterBill.loader = null;
        if (loaderColor == 'error'){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ÉXITO',
            'notificationDialogBody': `Ha ocurrido un error inesperado al cancelar la órden del facturador, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema`,
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
        await this.selectCallcenterBillFromCallcenterView(true);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': `Ha ocurrido un error inesperado al ${loaderColor == 'success' ? 'enviar la órden al facturador' : 'cancelar la órden del facturador'}, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema`,
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      return updateCallcenterBillUpdateDatetimeFromCallcenterViewResult;
    }, 

    async selectCallcenterBillFromCallcenterView(setLoader){
      this.loader.selectCallcenterBillFromCallcenterView = setLoader;
      const selectCallcenterBillFromCallcenterViewRequestQuery = 
      {
        'callcenterBillLocalityID': this.localStorage.localityID
      };
      const selectCallcenterBillFromCallcenterViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/callcenterBill/functions/selectCallcenterBillFromCallcenterView', selectCallcenterBillFromCallcenterViewRequestQuery);
      if (selectCallcenterBillFromCallcenterViewResult.success){
        this.display.callcenterBillRows = selectCallcenterBillFromCallcenterViewResult.result.reverse();
        this.loader.selectCallcenterBillFromCallcenterView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las órdenes del call center, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectPreviousCallcenterBillFromCallcenterView(){
      this.loader.selectPreviousCallcenterBillFromCallcenterView = true;
      const selectPreviousCallcenterBillFromCallcenterViewRequestQuery = 
      {
        'callcenterBillLocalityID': this.localStorage.localityID,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate
      };
      const selectPreviousCallcenterBillFromCallcenterViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/callcenterBill/functions/selectPreviousCallcenterBillFromCallcenterView', selectPreviousCallcenterBillFromCallcenterViewRequestQuery);
      if (selectPreviousCallcenterBillFromCallcenterViewResult.success){
        this.display.previousCallcenterBillRows = selectPreviousCallcenterBillFromCallcenterViewResult.result.reverse();
        this.display.backupPreviousCallcenterBillRows = JSON.parse(JSON.stringify(this.display.previousCallcenterBillRows));
        this.loader.selectPreviousCallcenterBillFromCallcenterView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las órdenes del call center, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    getMinimumDate(){
      const minimumDate = new Date();
      minimumDate.setDate(minimumDate.getDate() - 8);
      return minimumDate;
    },

    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
    },

    async setDefaultValues(){
      this.input.startDate = new Date();
      this.input.endDate = new Date();
      this.display.previousCallcenterBillRows.length = 0;
      this.display.backupPreviousCallcenterBillRows.length = 0;
    },
  },

  async mounted(){
    await this.getLocalStorageData();
    await this.setDefaultValues();
    await this.selectCallcenterBillFromCallcenterView(true);
    this.navigation.selectCallcenterBillFromCallcenterViewInterval = setInterval(async () => {
      await this.selectCallcenterBillFromCallcenterView(false);
    }, 6000);
  }
});
</script>
