<template>
  <v-dialog v-model="navigation.insertAgentDialogIsVisible" :persistent="true" width="auto">
    <v-card width="700" style="padding: 20px; text-align: center;">
      <div v-if="loader.insertAgent" style="text-align: center;">
        <v-progress-circular color="success" indeterminate></v-progress-circular>
        <br>
      </div>
      <div v-else>
        <v-checkbox v-model="input.insertingAgentIsActive" label="El agente estará activo desde su creación" color="success"></v-checkbox>
        <v-autocomplete v-if="accessCredential['/agent/selectLocality']" v-model="input.insertingAgentLocalityID" :items="display.localityOptions" :loading="loader.selectLocalityIDAndLocalityName" :disabled="loader.selectLocalityIDAndLocalityName" item-value="localityID" item-title="localityName" append-inner-icon="mdi-store" label="Localidad" variant="solo-filled"></v-autocomplete>
        <v-text-field @keyup="validateAgentSSNUniqueness()" v-model="input.insertingAgentSSN" :loading="loader.validateAgentSSNUniqueness" :error="navigation.validateAgentSSNUniqueness" prepend-inner-icon="mdi-card-account-details" label="SSN del agente" variant="solo-filled"></v-text-field>
        <v-select v-model="input.insertingAgentType" :items="display.agentTypeOptions" prepend-inner-icon="mdi-store" label="Tipo de agente" variant="solo-filled"></v-select>
        <br>
        <v-text-field v-model="input.insertingAgentFirstName" prepend-inner-icon="mdi-alpha-a-box" label="Primer nombre del agente" variant="solo-filled"></v-text-field>
        <v-text-field v-model="input.insertingAgentMiddleName" prepend-inner-icon="mdi-alpha-b-box" label="Segundo nombre del agente (opcional)" variant="solo-filled"></v-text-field>
        <v-text-field v-model="input.insertingAgentFirstSurname" prepend-inner-icon="mdi-alpha-c-box" label="Primer apellido del agente" variant="solo-filled"></v-text-field>
        <v-text-field v-model="input.insertingAgentSecondSurname" prepend-inner-icon="mdi-alpha-d-box" label="Segundo apellido del agente" variant="solo-filled"></v-text-field>
        <br>
        <v-text-field v-model="input.insertingAgentEmail" prepend-inner-icon="mdi-email" label="Email del agente" variant="solo-filled"></v-text-field>
        <v-text-field v-model="input.insertingAgentPhoneNumber" prepend-inner-icon="mdi-phone" label="Teléfono del agente" variant="solo-filled"></v-text-field>
        <br>
        <v-text-field @keyup="validateAgentUsernameUniqueness()" v-model="input.insertingAgentUsername" :loading="loader.validateAgentUsernameUniqueness" :error="navigation.validateAgentUsernameUniqueness" prepend-inner-icon="mdi-account" label="Nombre de usuario del agente" variant="solo-filled"></v-text-field>
        <v-text-field 
          v-model="input.insertingAgentPassword" 
          @click:append-inner="navigation.insertingAgentPasswordInputIsVisible = !this.navigation.insertingAgentPasswordInputIsVisible"
          @click:append="navigation.passwordGeneratorDialogIsVisible = true"
          :type="navigation.insertingAgentPasswordInputIsVisible == false ? 'password' : 'text'"
          :append-inner-icon="navigation.insertingAgentPasswordInputIsVisible == false ? 'mdi-eye' : 'mdi-eye-off'" 
          prepend-inner-icon="mdi-lock"
          append-icon="mdi-dice-5" 
          label="Contraseña del agente" 
          variant="solo-filled">
        </v-text-field>
        <br>
        <v-text-field @click="navigation.insertingAgentColorDialogIsVisible = true" v-model="input.insertingAgentColor" :bg-color="input.insertingAgentColor" readonly="true" prepend-inner-icon="mdi-palette" label="Color del agente" variant="solo-filled"></v-text-field>
        <v-dialog v-model="navigation.insertingAgentColorDialogIsVisible" width="auto">
          <v-card width="300">
            <v-color-picker v-model="input.insertingAgentColor" :v-model:mode="'hex'" hide-inputs></v-color-picker>
          </v-card>
        </v-dialog>
        <v-text-field @click="navigation.insertingAgentFontColorDialogIsVisible = true" v-model="input.insertingAgentFontColor" :bg-color="input.insertingAgentFontColor" readonly="true" prepend-inner-icon="mdi-palette" label="Color de letra del agente" variant="solo-filled"></v-text-field>
        <v-dialog v-model="navigation.insertingAgentFontColorDialogIsVisible" width="auto">
          <v-card width="300">
            <v-color-picker v-model="input.insertingAgentFontColor" :v-model:mode="'hex'" hide-inputs></v-color-picker>
          </v-card>
        </v-dialog>
        <br>
        <div v-if="loader.insertAgent" style="text-align: center;">
          <v-progress-circular color="success" indeterminate></v-progress-circular>
          <br><br>
        </div>
        <div v-else>
          <v-btn @click="insertAgent()" dark block height="38" color="success" style="margin-bottom: 15px;">
            <h3>CREAR</h3>
            <v-icon style="margin-left: 10px;" dark right>mdi-content-save</v-icon>
          </v-btn>
          <v-btn @click="setDefaultValues()" dark block height="38" color="error">
            <h3>CANCELAR</h3>
            <v-icon style="margin-left: 10px;" dark right>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
  <v-dialog v-model="navigation.passwordGeneratorDialogIsVisible" width="auto">
    <v-card width="500" style="padding:20px; text-align: center;">
      <p>¿Desea generar una nueva contraseña segura para el agente?</p>
      <br><br>
      <v-btn @click="generateInsertingAgentPassword()" dark block height="38" color="success">
        <h3>GENERAR</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-dice-5</v-icon>
      </v-btn>
      <br>
      <v-btn @click="navigation.passwordGeneratorDialogIsVisible = false" dark block height="38" color="error">
        <h3>CANCELAR</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-close</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>
  
<style scoped>
</style>
  

<script>

export default {
  name: "InsertAgentDialog",
  inject: ['$generalFunctions'],

  data: () => ({

    input:
    {
      insertingAgentLocalityID: null,
      insertingAgentSSN: null,
      insertingAgentType: null,
      insertingAgentEmail: null,
      insertingAgentPhoneNumber: null,
      insertingAgentUsername: null,
      insertingAgentPassword: null,
      insertingAgentFirstName: null,
      insertingAgentMiddleName: null,
      insertingAgentFirstSurname: null,
      insertingAgentSecondSurname: null,
      insertingAgentColor: null,
      insertingAgentFontColor: null,
      insertingAgentIsActive: true
    },

    loader: 
    {
      selectLocalityIDAndLocalityName: false,
      validateAgentSSNUniqueness: false,
      validateAgentUsernameUniqueness: false,
      insertAgent: false
    },
    
    navigation: 
    {
      insertAgentDialogIsVisible: false,
      insertingAgentPasswordInputIsVisible: false,
      passwordGeneratorDialogIsVisible: false,
      insertingAgentColorDialogIsVisible: false,
      insertingAgentFontColorDialogIsVisible: false,
      validateAgentSSNUniqueness: false,
      validateAgentSSNUniquenessInterval: null,
      validateAgentUsernameUniqueness: false,
      validateAgentUsernameUniquenessInterval: null,
    },

    display:
    {
      localityOptions: [],
      agentTypeOptions: []
    },

    localStorage: 
    {
      localityID: null,
      agentID: null
    },

    accessCredential: {}

  }),

  watch: {
  },

  methods: {
    async insertAgent(){
      this.loader.insertAgent = true;
      const insertAgentRequestQuery =
      {
        'agentLocalityID': this.accessCredential['/agent/selectLocality'] ? this.input.insertingAgentLocalityID : this.localStorage.localityID,
        'agentSSN': this.input.insertingAgentSSN,
        'agentFirstName': this.input.insertingAgentFirstName,
        'agentMiddlename': this.input.insertingAgentMiddleName,
        'agentFirstSurname': this.input.insertingAgentFirstSurname,
        'agentSecondSurname': this.input.insertingAgentSecondSurname,
        'agentEmail': this.input.insertingAgentEmail,
        'agentPhoneNumber': this.input.insertingAgentPhoneNumber,
        'agentUsername': this.input.insertingAgentUsername,
        'agentPassword': this.input.insertingAgentPassword,
        'agentColor': this.input.insertingAgentColor,
        'agentFontColor': this.input.insertingAgentFontColor,
        'agentIsActive': this.input.insertingAgentIsActive,
        'agentType': this.input.insertingAgentType
      };
      const insertAgentResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/insert', insertAgentRequestQuery);
      if (insertAgentResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'Se ha creado el agente exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        this.setDefaultValues();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al crear el agente, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async validateAgentSSNUniqueness(){
      clearInterval(this.navigation.validateAgentSSNUniquenessInterval);
      this.navigation.validateAgentSSNUniquenessInterval = setInterval(async () => {
        clearInterval(this.navigation.validateAgentSSNUniquenessInterval);
        this.loader.validateAgentSSNUniqueness = true;
        const validateAgentSSNUniquenessRequestQuery = 
        {
          'field': 'agentSSN',
          'value': this.input.insertingAgentSSN
        };
        const validateAgentSSNUniquenessResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/functions/validateUniqueness', validateAgentSSNUniquenessRequestQuery);
        if (validateAgentSSNUniquenessResult.success){
          if (validateAgentSSNUniquenessResult.result){
            this.navigation.validateAgentSSNUniqueness = false;
          } else {
            this.navigation.validateAgentSSNUniqueness = true;
            this.$root.notificationDialog.showNotificationDialog({
              'notificationDialogTitle': 'ERROR',
              'notificationDialogBody': 'El SSN del agente no se encuentra disponible',
              'notificationDialogColor': 'error',
              'notificationDialogIsPersistent': false
            });
          }
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la disponibilidad del SSN del agente, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
        this.loader.validateAgentSSNUniqueness = false;
      }, 1500);
    },

    async validateAgentUsernameUniqueness(){
      clearInterval(this.navigation.validateAgentUsernameUniquenessInterval);
      this.navigation.validateAgentUsernameUniquenessInterval = setInterval(async () => {
        clearInterval(this.navigation.validateAgentUsernameUniquenessInterval);
        this.loader.validateAgentUsernameUniqueness = true;
        const validateAgentUsernameUniquenessRequestQuery = 
        {
          'field': 'agentUsername',
          'value': this.input.insertingAgentUsername
        };
        const validateAgentUsernameUniquenessResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/functions/validateUniqueness', validateAgentUsernameUniquenessRequestQuery);
        if (validateAgentUsernameUniquenessResult.success){
          if (validateAgentUsernameUniquenessResult.result){
            this.navigation.validateAgentUsernameUniqueness = false;
          } else {
            this.navigation.validateAgentUsernameUniqueness = true;
            this.$root.notificationDialog.showNotificationDialog({
              'notificationDialogTitle': 'ERROR',
              'notificationDialogBody': 'El nombre de usuario del agente no se encuentra disponible',
              'notificationDialogColor': 'error',
              'notificationDialogIsPersistent': false
            });
          }
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la disponibilidad del nombre de usuario del agente, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
        this.loader.validateAgentUsernameUniqueness = false;
      }, 1500);
    },

    async selectLocalityIDAndLocalityName(){
      this.loader.selectLocalityIDAndLocalityName = true;
      const selectLocalityIDAndLocalityNameResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityName');
      if (selectLocalityIDAndLocalityNameResult.success){
        this.display.localityOptions = selectLocalityIDAndLocalityNameResult.result;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectLocalityIDAndLocalityName = false;
    },

    generateInsertingAgentPassword(){
      const generatedPasswordLength = 10;
      const lowecaseSymbols = 'abcdefghijklmnopqrstuvwxyz';
      const upperCaseSymbols = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const numericSymbols = '0123456789';
      const getRandomCharacter = (symbols) => symbols[Math.floor(Math.random() * symbols.length)];
      var generatedPassword = [getRandomCharacter(lowecaseSymbols), getRandomCharacter(upperCaseSymbols), getRandomCharacter(numericSymbols)];
      const allSymbols = lowecaseSymbols + upperCaseSymbols + numericSymbols;
      for (let i = 0; i < generatedPasswordLength; i++) {
        generatedPassword.push(getRandomCharacter(allSymbols));
      }
      generatedPassword = generatedPassword.join('');
      this.input.insertingAgentPassword = generatedPassword;
      this.navigation.insertingAgentPasswordInputIsVisible = true;
      this.navigation.passwordGeneratorDialogIsVisible = false;
    },
  
    setDefaultValues(){
      this.navigation.insertAgentDialogIsVisible = false;
      this.input.insertingAgentLocalityID = null;
      this.input.insertingAgentSSN = null;
      this.input.insertingAgentFirstName = null;
      this.input.insertingAgentMiddleName = null;
      this.input.insertingAgentFirstSurname = null;
      this.input.insertingAgentSecondSurname = null;
      this.input.insertingAgentPhoneNumber = null;
      this.input.insertingAgentUsername = null;
      this.input.insertingAgentPassword = null;
      this.input.insertingAgentColor = null;
      this.input.insertingAgentFontColor = null;
      this.input.insertingAgentIsActive = true;
      this.loader.validateAgentSSNUniqueness = false;
      this.loader.validateAgentUsernameUniqueness = false;
      this.loader.insertAgent = false;
      this.navigation.insertingAgentPasswordInputIsVisible = false;
      this.navigation.passwordGeneratorDialogIsVisible = false;
      this.navigation.insertingAgentColorDialogIsVisible = false;
      this.navigation.insertingAgentFontColorDialogIsVisible = false;
      this.navigation.validateAgentSSNUniqueness = false;
      this.navigation.validateAgentSSNUniquenessInterval = null;
      this.navigation.validateAgentUsernameUniqueness = false;
      this.navigation.validateAgentUsernameUniquenessInterval = null;
    },

    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    },

    prepareBasedOnAccessCredential(){
      this.display.agentTypeOptions = 
      [
        ...(this.accessCredential['/agent/insertAgentDialog/agentType/"Tienda"'] ? ['Tienda'] : []),
        ...(this.accessCredential['/agent/insertAgentDialog/agentType/"Facturador de tienda"'] ? ['Facturador de tienda'] : []),
        ...(this.accessCredential['/agent/insertAgentDialog/agentType/"Administrador de tienda"'] ? ['Administrador de tienda'] : []),
        ...(this.accessCredential['/agent/insertAgentDialog/agentType/"Agente de bodega"'] ? ['Agente de bodega'] : []),
        'Agente al por mayor',
        'Agente de oficina'
      ];
    },

    async openInsertAgentDialog(){
      this.setDefaultValues();
      await this.getLocalStorageData();
      this.prepareBasedOnAccessCredential();
      this.navigation.insertAgentDialogIsVisible = true;
      await this.selectLocalityIDAndLocalityName();
    }
  },


  mounted(){
  }
}
</script>