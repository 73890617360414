<template>
  <v-dialog v-model="navigation.insertTokenDialogIsVisible" :persistent="display.tokenValue ? false : true" width="auto">
    <v-card v-if="display.tokenValue" width="300" style="padding: 20px;">
      <div @click="copyTokenValueToClipboard()" style="width: 100%; text-align: center; cursor: pointer;">
        <p style="font-size: large;">Token generado: </p>
        <p style="font-size: large; font-weight: bold;">{{ display.tokenValue }}</p>
      </div>
    </v-card>
    <v-card v-else width="700" style="padding: 20px;">
      <v-select 
        v-model="input.tokenType" 
        :items="display.tokenTypeOptions" 
        append-inner-icon="mdi-lock" 
        label="Tipo de token" 
        variant="solo-filled" 
        density="compact"
      ></v-select>
      <v-select 
        v-model="input.tokenGrantedMinutes" 
        :items="display.tokenGrantedMinutesOptions" 
        append-inner-icon="mdi-clock-alert" 
        label="Tiempo de expiración del token" 
        variant="solo-filled" 
        density="compact"
      ></v-select>
      <v-select 
        v-model="input.tokenUseAmount" 
        :items="display.tokenUseAmountOptions" 
        append-inner-icon="mdi-numeric" 
        label="Cantidad de usos del token" 
        variant="solo-filled" 
        density="compact"
      ></v-select>
      <br><br>
      <div v-if="loader.insertToken" style="text-align: center;">
        <v-progress-circular color="primary" indeterminate></v-progress-circular>
        <br>
      </div>
      <div v-else>
        <v-btn @click="insertToken()" dark block height="38" color="success">
          <h3>GENERAR TOKEN</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-plus-circle-outline</v-icon>
        </v-btn>
        <br>
        <v-btn @click="setDefaultValues()" dark block height="38" color="error">
          <h3>CERRAR</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
        </v-btn>  
      </div>
    </v-card>
  </v-dialog>
</template>
  
<style scoped>
</style>
  

<script>

export default {
  name: "InsertTokenDialog",
  inject: ['$generalFunctions'],

  data: () => ({

    input:
    {
      tokenType: null,
      tokenGrantedMinutes: null,
      tokenUseAmount: null
    },

    loader: 
    {
      insertToken: false
    },
    
    navigation: 
    {
      insertTokenDialogIsVisible: false
    },

    display:
    {
      tokenTypeOptions: [],
      tokenGrantedMinutesOptions:
      [
        {value: 1, title: '1 minuto'},
        {value: 5, title: '5 minutos'},
        {value: 10, title: '10 minutos'},
        {value: 30, title: '30 minutos'},
        {value: 60, title: '1 hora'},
        {value: 120, title: '2 horas'},
        {value: 'undefined', title: 'Sin tiempo de expiración (hasta la cantidad de usos)'},
      ],
      tokenUseAmountOptions:
      [
        {value: 1, title: '1 uso'},
        {value: 2, title: '2 usos'},
        {value: 3, title: '3 usos'},
        {value: 4, title: '4 usos'},
        {value: 5, title: '5 usos'},
        {value: 'undefined', title: 'Indefinidos (hasta el tiempo de expiración)'},
      ],
      tokenValue: null
    },

    localStorage: 
    {
      agentID: null
    },

    accessCredential: {}

  }),

  watch: {
  },

  methods: {
    async insertToken(){
      this.loader.insertToken = true;
      const insertTokenRequestQuery =
      {
        'tokenAgentID': this.localStorage.agentID,
        'tokenType': this.input.tokenType,
        'tokenGrantedMinutes': this.input.tokenGrantedMinutes,
        'tokenUseAmount': this.input.tokenUseAmount
      };
      const insertTokenResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/token/insert', insertTokenRequestQuery);
      if (insertTokenResult.success){
        this.display.tokenValue = insertTokenResult.result;
        this.copyTokenValueToClipboard();
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'Se ha creado el token exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al crear el token, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.insertToken = false;
    },

    copyTokenValueToClipboard(){
      navigator.clipboard.writeText(this.display.tokenValue).then(() => {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': null,
          'notificationDialogBody': 'Token copiado al portapapeles',
          'notificationDialogColor': 'info',
          'notificationDialogIsPersistent': false
        });      
      }).catch(() => {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al copiar el token al portapapeles, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });      
      });
    },

    setDefaultValues(){
      this.navigation.insertTokenDialogIsVisible = false;
      this.input.tokenType = null;
      this.input.tokenGrantedMinutes = null;
      this.input.tokenUseAmount = null;
      this.display.tokenValue = null;
      this.loader.insertToken = false;
    },
   
    async getLocalStorageData(){
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    },

    prepareBasedOnAccessCredential(){
      this.display.tokenTypeOptions = 
      [
        ...(this.accessCredential['/agent/insertTokenDialog/tokenType/"Master"'] ? ['Master'] : []),
        ...(this.accessCredential['/agent/insertTokenDialog/tokenType/"Descuento"'] ? ['Descuento'] : []),
        ...(this.accessCredential['/agent/insertTokenDialog/tokenType/"Entrada de producto"'] ? ['Entrada de producto'] : []),
        ...(this.accessCredential['/agent/insertTokenDialog/tokenType/"Salida de producto"'] ? ['Salida de producto'] : [])
      ];
    },

    async openInsertTokenDialog(){
      this.setDefaultValues();
      await this.getLocalStorageData();
      this.prepareBasedOnAccessCredential();
      this.navigation.insertTokenDialogIsVisible = true;
    }
  },


  mounted(){
  }
}
</script>