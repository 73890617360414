<template>
  <div></div>
</template>


<style scoped>
  .printableBillDialogContainer {
    padding: 20px;
  }

</style>


<script>

export default {
  name: "PrintableBillDialog",

  inject: ['$generalFunctions'],

  data: () => ({

  }),

  methods: {
    showPrintableBillDialog(billInformation) {

      const billProducts = JSON.parse(billInformation.billProducts).map(currentBillProduct => {
        return {
          'billProductName': currentBillProduct.billProductName,
          'billProductPrice': currentBillProduct.billProductPrice,
          'billProductTaxMargin': currentBillProduct.billProductTaxMargin,
          'billProductDiscountMargin': currentBillProduct.billProductDiscountMargin,
          'billProductAmount': currentBillProduct.billProductAmount
        };
      });
      const billAmount = billProducts.reduce((billAmountTotal, billProduct) => (billAmountTotal + (billProduct.billProductPrice * billProduct.billProductAmount)), 0);

      const electronicDocumentPaymentMethods = Object.fromEntries(
          Object.entries({
            'Efectivo': (billInformation.billPaymentType == 'Efectivo') ? billAmount : ((billInformation.billMixedPaymentCashAmount == 0) ? null : billInformation.billMixedPaymentCashAmount),
            'Tarjeta': (billInformation.billPaymentType == 'Tarjeta') ? billAmount : ((billInformation.billMixedPaymentCardAmount == 0) ? null : billInformation.billMixedPaymentCardAmount),
            'SINPE': (billInformation.billPaymentType == 'SINPE') ? billAmount : ((billInformation.billMixedPaymentSINPEAmount == 0) ? null : billInformation.billMixedPaymentSINPEAmount),
            'Transferencia': (billInformation.billPaymentType == 'Transferencia o depósito') ? billAmount : ((billInformation.billMixedPaymentDepositAmount == 0) ? null : billInformation.billMixedPaymentDepositAmount),
            'Página web': (billInformation.billPaymentType == 'Página web') ? billAmount : null,
            'Uber': (billInformation.billPaymentType == 'Uber') ? billAmount : null,
            'Rappi': (billInformation.billPaymentType == 'Rappi') ? billAmount : null
          }).filter(([_, value]) => value !== null && _)
        );

      const printInformation = 
      {
        'billCopies': 1,
        'electronicDocumentNumericKey': billInformation.billTaxID,
        'billType': billInformation.billType.replace('ó', 'o'),
        'locality':
        {
          'localityName': billInformation.localityName,
          'localityNameOrSocialReason': 'J King Brothers SA',
          'localitySSN': '3054871',
          'localityPhoneNumber': '72527633',
          'localityEmail': 'kingvapecr@gmail.com',
          'localityExactLocation': billInformation.localityExactLocation
        },
        'items': billProducts.map(billProduct => {
          return {
            'itemName': billProduct.billProductName,
            'itemPrice': billProduct.billProductPrice,
            'itemAmount': billProduct.billProductAmount
          };
        }),
        'recipient':
        {
          'recipientSSN': billInformation.clientSSN || '',
          'recipientPhoneNumber': billInformation.clientPhoneNumber || '',
          'recipientEmail': billInformation.clientEmail || '',
          'recipientName': billInformation.clientName || ''
        },
        'localityAgentName': '',
        'electronicDocumentCreationDatetime': new Date(billInformation.billCreationDatetime).toISOString().replace('T', ' ').split('.')[0],
        'billID': billInformation.billID,
        'agentName': billInformation.agentName,
        "billIVA13Amount": billProducts.reduce((billIVA13Amount, billProduct) => (billProduct.billProductTaxMargin == 13 ? (billIVA13Amount + (billProduct.billProductPrice - (billProduct.billProductPrice * (1 + (billProduct.billProductTaxMargin / 100))))) : (billIVA13Amount + 0)), 0).toFixed(2) * -1,
        "billIVA4Amount": billProducts.reduce((billIVA4Amount, billProduct) => (billProduct.billProductTaxMargin == 4 ? (billIVA4Amount + (billProduct.billProductPrice - (billProduct.billProductPrice * (1 + (billProduct.billProductTaxMargin / 100))))) : (billIVA4Amount + 0)), 0).toFixed(2) * -1,
        "billIVA2Amount": billProducts.reduce((billIVA2Amount, billProduct) => (billProduct.billProductTaxMargin == 2 ? (billIVA2Amount + (billProduct.billProductPrice - (billProduct.billProductPrice * (1 + (billProduct.billProductTaxMargin / 100))))) : (billIVA2Amount + 0)), 0).toFixed(2) * -1,
        "billIVA1Amount": billProducts.reduce((billIVA1Amount, billProduct) => (billProduct.billProductTaxMargin == 1 ? (billIVA1Amount + (billProduct.billProductPrice - (billProduct.billProductPrice * (1 + (billProduct.billProductTaxMargin / 100))))) : (billIVA1Amount + 0)), 0).toFixed(2) * -1,
        "billSubtotal": billProducts.reduce((billSubtotalAmount, billProduct) => (billSubtotalAmount + (billProduct.billProductPrice * billProduct.billProductAmount)), 0).toFixed(2),
        "billDiscount": billProducts.reduce((billDiscountAmount, billProduct) => (billDiscountAmount + ((billProduct.billProductPrice * billProduct.billProductAmount) * billProduct.billProductDiscountMargin/100)), 0).toFixed(2),
        "billTotal": (billProducts.reduce((billSubtotalAmount, billProduct) => (billSubtotalAmount + (billProduct.billProductPrice * billProduct.billProductAmount)), 0) - billProducts.reduce((billDiscountAmount, billProduct) => (billDiscountAmount + ((billProduct.billProductPrice * billProduct.billProductAmount) * billProduct.billProductDiscountMargin/100)), 0)).toFixed(2),
        'billCurrency': billInformation.billCurrency == '$' ? `USD (${billInformation.billExchangeRate})` : 'CRC',
        "billSpecialTax": 0,
        "billServiceTax": 'N/A',
        'paymentMethods': electronicDocumentPaymentMethods,
        "billChange": billInformation.billCashPaymentChangeAmount ? billInformation.billCashPaymentChangeAmount.toFixed(2) : 'N/A',
        "billNote": '',
        "billFooter": '',
        "billResolution": 'Autorizada mediante resolucion No DGT-R-033-2019 del 20 de junio de 2019'
      };

      this.$generalFunctions.default.methods.printRequest(printInformation);


    }
  }
}
</script>