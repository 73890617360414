<template>
  <div style="margin: 2% 3% 0% 3%;">
    <v-card color="border" elevation="3" style="padding: 20px 20px 0px 20px;">
      <div style="display: flex;">
        <v-text-field 
          v-model="display.startDateFormatted" 
          @click="navigation.startDatePickerIsVisible = true" 
          readonly 
          label="Fecha inicial" 
          append-inner-icon="mdi-calendar-start" 
          variant="solo-filled"
          density="compact"
          style="width: 49.5%; margin-right: 0.5%;"
        ></v-text-field>
        <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
          <v-card style="display: flex;">
            <v-date-picker v-model="input.startDate" hide-header color="primary" width="100%"></v-date-picker>
            <v-chip @click="input.startDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
          </v-card>
        </v-dialog>
        <v-text-field 
          v-model="display.endDateFormatted" 
          @click="navigation.endDatePickerIsVisible = true" 
          readonly 
          label="Fecha final" 
          append-inner-icon="mdi-calendar-end" 
          variant="solo-filled"
          density="compact"
          style="width: 49.5%; margin-left: 0.5%;"
        ></v-text-field>
        <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
          <v-card>
            <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
            <v-chip @click="input.endDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
          </v-card>
        </v-dialog>
      </div>
      <div v-if="accessCredential['/localityExpense/showLocalityExpenseTotal']" style="text-align: center;">
        <br>
        <p style="font-size: xx-large;"><strong>TOTAL: </strong>₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(display.localityExpenseCategories.reduce((total, localityExpenseCategory) => total + localityExpenseCategory.localityExpenseCategoryAmount, 0)) }}</p>
        <br>
        <v-icon @click="generateLocalityExpensePDF()" size="40" color="success" dark right>mdi-printer</v-icon>
        <br><br>
      </div>  
    </v-card>
    <br><br>
    <div v-if="loader.selectOperationalExpenseFromOperationalExpenseView" style="width: 100%; text-align: center;">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
    <div v-else>
      <div v-if="display.information" style="display: flex;">
        <div style="width: 49%; margin-right: 1%;">
          <v-card color="border" elevation="3">
            <div style="display: flex; justify-content: space-between; padding: 20px;">
              <div style="display: flex; flex-direction: column;">
                <h1>CALL CENTER</h1>
                <p style="font-size: larger; margin-top: 10px;"><strong>TOTAL: </strong>₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(display.information['Call center'].amount) }}</p>
              </div>
              <div style="display: flex; flex-direction: column;">
                <div style="display: flex;">
                  <v-icon @click="openInsertOperationalExpenseDialog('Call center')" style="font-size: 50px;" color="success">mdi-plus-circle-outline</v-icon>
                  <v-icon @click="openConfigureOperationalExpenseDialog('Call center')" style="font-size: 50px; margin-left: 5px;" color="gray">mdi-cog</v-icon>
                </div>
              </div>
            </div>
            <div style="max-height: 700px; overflow-y: auto;">
              <div v-for="(expenseNameInformation, expenseName) in display.information['Call center'].expense" v-bind:key="expenseName" style="padding: 0px 20px 0px 20px;">
                <br>
                <div style="display: flex;">
                  <h4>{{ expenseName }}:</h4>
                  <h4 style="margin-left: auto;">₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(expenseNameInformation.amount) }}</h4>
                </div>
                <v-data-table-virtual :headers="display.expenseHeaders" :items="expenseNameInformation.expenseList" style="margin-top: 10px;">
                  <template v-slot:item.operationalExpenseAmount="{ item }">
                    ₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(item.operationalExpenseAmount) }}
                  </template>
                  <template v-slot:item.agentName="{ item }">
                    <v-chip :color="item.agentColor" variant="flat">
                      <p :style="{color: item.agentFontColor}">{{ item.agentName }}</p>
                    </v-chip>
                  </template>
                  <template v-slot:item.operationalExpenseRegisterDatetime="{ item }">
                    {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYY(item.operationalExpenseRegisterDatetime) }}
                  </template>
                  <template v-slot:item.operationalExpenseDeleteAction="{ item }">
                    <v-icon @click="openDeleteOperationalExpenseDialog(item.operationalExpenseID)" color="error">mdi-delete</v-icon>
                  </template>
                </v-data-table-virtual>
                <br>
              </div>
            </div>
          </v-card>
        </div>
        <div style="width: 49%; margin-left: 1%;">
          <v-card color="border" elevation="3">
            <div style="display: flex; justify-content: space-between; padding: 20px;">
              <div style="display: flex; flex-direction: column;">
                <h1>OPERATIVO</h1>
                <p style="font-size: larger; margin-top: 10px;"><strong>TOTAL: </strong>₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(display.information['Operativo'].amount) }}</p>
              </div>
              <div style="display: flex; flex-direction: column;">
                <div style="display: flex;">
                  <v-icon @click="openInsertOperationalExpenseDialog('Operativo')" style="font-size: 50px;" color="success">mdi-plus-circle-outline</v-icon>
                  <v-icon @click="openConfigureOperationalExpenseDialog('Operativo')" style="font-size: 50px; margin-left: 5px;" color="gray">mdi-cog</v-icon>
                </div>
              </div>
            </div>
            <div style="max-height: 700px; overflow-y: auto;">
              <div v-for="(expenseNameInformation, expenseName) in display.information['Operativo'].expense" v-bind:key="expenseName" style="padding: 0px 20px 0px 20px;">
                <br>
                <div style="display: flex;">
                  <h4>{{ expenseName }}:</h4>
                  <h4 style="margin-left: auto;">₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(expenseNameInformation.amount) }}</h4>
                </div>
                <v-data-table-virtual :headers="display.expenseHeaders" :items="expenseNameInformation.expenseList" style="margin-top: 15px; max-height: 300px; overflow-y: auto;">
                  <template v-slot:item.operationalExpenseAmount="{ item }">
                    ₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(item.operationalExpenseAmount) }}
                  </template>
                  <template v-slot:item.agentName="{ item }">
                    <v-chip :color="item.agentColor" variant="flat">
                      <p :style="{color: item.agentFontColor}">{{ item.agentName }}</p>
                    </v-chip>
                  </template>
                  <template v-slot:item.operationalExpenseRegisterDatetime="{ item }">
                    {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYY(item.operationalExpenseRegisterDatetime) }}
                  </template>
                  <template v-slot:item.operationalExpenseDeleteAction="{ item }">
                    <v-icon @click="openDeleteOperationalExpenseDialog(item.operationalExpenseID)" color="error">mdi-delete</v-icon>
                  </template>
                </v-data-table-virtual>
                <br>
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </div>
    <br><br>
  </div>

  <v-dialog v-model="navigation.insertOperationalExpenseDialogIsVisible" width="auto" :persistent="true">
    <v-card width="800" style="padding: 20px;">
      <div v-if="loader.insertOperationalExpense" style="width: 100%; text-align: center;">
        <v-progress-circular color="primary" indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <v-text-field v-model="display.operationalExpenseRegisterDatetimeFormatted" @click="navigation.operationalExpenseRegisterDatetimePickerIsVisible = true" readonly label="Fecha de registro del gasto" append-inner-icon="mdi-calendar" variant="solo-filled"></v-text-field>
        <v-dialog v-model="navigation.operationalExpenseRegisterDatetimePickerIsVisible" width="auto">
          <v-card style="display: flex;">
            <v-date-picker v-model="input.operationalExpenseRegisterDatetime" hide-header color="primary" width="100%"></v-date-picker>
            <v-chip @click="input.operationalExpenseRegisterDatetime = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
          </v-card>
        </v-dialog>
        <v-select v-if="input.operationalExpenseType == 'Call center'" v-model="input.operationalExpenseName" :items="display.callcenterExpenseNameOptions" append-inner-icon="mdi-chat-question" label="Tipo del gasto" variant="solo-filled"></v-select>
        <v-select v-if="input.operationalExpenseType == 'Operativo'" v-model="input.operationalExpenseName" :items="display.operationalExpenseNameOptions" append-inner-icon="mdi-chat-question" label="Tipo del gasto" variant="solo-filled"></v-select>
        <v-textarea v-model="input.operationalExpenseNote" rows="3" append-inner-icon="mdi-text-box-outline" label="Nota del gasto (opcional)" variant="solo-filled"></v-textarea>
        <v-text-field @wheel.prevent v-model="input.operationalExpenseAmount" append-inner-icon="mdi-cash" label="Monto del gasto" variant="solo-filled" type="number" hide-spin-buttons></v-text-field>
        <br><br>
        <v-btn @click="insertOperationalExpense()" dark block height="38" color="success">
          <h3>REGISTRAR GASTO</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-check-circle-outline</v-icon>
        </v-btn>
        <br>
        <v-btn @click="navigation.insertOperationalExpenseDialogIsVisible = false" dark block height="38" color="error">
          <h3>CANCELAR</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>

  <v-dialog v-model="navigation.configureOperationalExpenseDialogIsVisible" width="auto" :persistent="true">
    <v-card color="background" width="900" style="padding: 20px;">
      <div v-if="loader.selectOperationalExpenseConfiguration" style="width: 100%; text-align: center;">
        <v-progress-circular color="primary" indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <div v-if="display.operationalConfiguration">
          <div style="display: flex;">
            <h1>{{ navigation.operationalExpenseType.toUpperCase() }}:</h1>
            <h1 style="margin-left: auto; font-weight: normal;">₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(display.information[navigation.operationalExpenseType].amount) }}</h1>
          </div>
          <br>
          <v-data-table :headers="display.operationalConfigurationHeaders" :items="display.operationalConfiguration" :items-per-page="-1" style="max-height: 500px; overflow-y: auto;">
            <template v-slot:item.percentage="{ item }">
              <v-text-field @wheel.prevent v-model="item.percentage" append-inner-icon="mdi-percent-box-outline" variant="solo-filled" type="number" density="compact" hide-spin-buttons style="margin-top: 20px;"></v-text-field>
            </template>
            <template v-slot:item.amount="{ item }">
              <v-text-field @wheel.prevent v-model="item.amount" append-inner-icon="mdi-cash" variant="solo-filled" type="number" density="compact" hide-spin-buttons style="margin-top: 20px;"></v-text-field>
            </template>
            <template v-slot:item.updateOperationalConfigurationAction="{ item }">
              <v-icon @click="updateOperationalConfiguration(item)" color="success">mdi-content-save</v-icon>
            </template>
            <template #bottom></template>
          </v-data-table>
          <br>
          <v-btn @click="navigation.configureOperationalExpenseDialogIsVisible = false" dark block height="38" color="error">
            <h3>CERRAR</h3>
            <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>

</template>

<style scoped>
</style>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'OperationalExpenseView',

  inject: ['$generalFunctions'],

  data: () => ({
    input: 
    {
      startDate: null,
      endDate: null,
      operationalExpenseType: null,
      operationalExpenseName: null,
      operationalExpenseNote: null,
      operationalExpenseAmount: null,
      operationalExpenseRegisterDatetime: null
    },

    display: 
    {
      startDateFormatted: null,
      endDateFormatted: null,
      information: null,
      callcenterExpenseNameOptions: ['ELECTRICIDAD', 'AGUA', 'ALQUILER', 'INTERNET', 'TELÉFONO', 'IMPUESTOS', 'SEGUROS', 'PERSONAL', 'SUMINISTROS', 'EQUIPO', 'SISTEMAS', 'OTROS'],
      operationalExpenseNameOptions: ['ELECTRICIDAD', 'AGUA', 'ALQUILER', 'INTERNET', 'TELÉFONO', 'IMPUESTOS', 'SEGUROS', 'PERSONAL', 'SUMINISTROS', 'EQUIPO', 'SISTEMAS', 'OTROS'],
      operationalExpenseRegisterDatetimeFormatted: null,
      expenseHeaders: 
      [
        {key: 'operationalExpenseID', title: 'ID', sortable: false},
        {key: 'agentName', title: 'REGISTRADO POR'},
        {key: 'operationalExpenseRegisterDatetime', title: 'FECHA', sortable: false},
        {key: 'operationalExpenseAmount', title: 'MONTO', sortable: false},
        {key: 'operationalExpenseNote', title: 'NOTA', sortable: false},
        {key: 'operationalExpenseDeleteAction', title: 'ELIMINAR', sortable: false}
      ],
      operationalConfiguration: null,
      operationalConfigurationHeaders: 
      [
        {key: 'localityName', title: 'LOCALIDAD', sortable: false},
        {key: 'percentage', title: 'VALOR PORCENTUAL', sortable: false},
        {key: 'amount', title: 'VALOR NUMÉRICO FIJO', sortable: false},
        {key: 'updateOperationalConfigurationAction', title: null, sortable: false}
      ],
    },

    navigation: 
    {
      startDatePickerIsVisible: false,
      endDatePickerIsVisible: false,
      insertOperationalExpenseDialogIsVisible: false,
      operationalExpenseRegisterDatetimePickerIsVisible: false,
      deleteOperationalExpenseDialogIsVisible: false,
      deletingOperationalExpenseID: null,
      configureOperationalExpenseDialogIsVisible: false,
      operationalExpenseType: null
    },

    loader:
    {
      selectOperationalExpenseFromOperationalExpenseView: false,
      insertOperationalExpense: false,
      deleteOperationalExpense: false,
      selectOperationalExpenseConfiguration: false
    },

    localStorage: 
    {
      agentID: null
    },

    accessCredential: {}
  }),

  watch: {
    'input.startDate': async function() {
      if (this.input.startDate){
        this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB');
        this.navigation.startDatePickerIsVisible = false;
        await this.selectOperationalExpenseFromOperationalExpenseView();
      } else {
        this.display.startDateFormatted = null;
      }
    },
    'input.endDate': async function() {
      if (this.input.endDate){
        this.display.endDateFormatted = new Date(this.input.endDate).toLocaleDateString('en-GB');
        this.navigation.endDatePickerIsVisible = false;
        await this.selectOperationalExpenseFromOperationalExpenseView();
      } else {
        this.display.endDateFormatted = null;
      }
    },
    'input.operationalExpenseRegisterDatetime': async function() {
      if (this.input.operationalExpenseRegisterDatetime){
        this.display.operationalExpenseRegisterDatetimeFormatted = new Date(this.input.operationalExpenseRegisterDatetime).toLocaleDateString('en-GB');
        this.navigation.operationalExpenseRegisterDatetimePickerIsVisible = false;
      } else {
        this.display.operationalExpenseRegisterDatetimeFormatted = null;
      }
    },
  },

  methods: {
    async insertOperationalExpense(){
      this.loader.insertOperationalExpense = true;
      const insertOperationalExpenseRequestQuery = 
      {
        'operationalExpenseAgentID': this.localStorage.agentID,
        'operationalExpenseType': this.input.operationalExpenseType,
        'operationalExpenseName': this.input.operationalExpenseName,
        'operationalExpenseNote': this.input.operationalExpenseNote,
        'operationalExpenseAmount': this.input.operationalExpenseAmount,
        'operationalExpenseRegisterDatetime': this.input.operationalExpenseRegisterDatetime
      };
      const insertOperationalExpenseResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/operationalExpense/functions/insert', insertOperationalExpenseRequestQuery);
      if (insertOperationalExpenseResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'El gasto se ha registrado exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        this.navigation.insertOperationalExpenseDialogIsVisible = false;
        await this.selectOperationalExpenseFromOperationalExpenseView();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al registrar el gasto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.insertOperationalExpense = false;
    },

    async deleteOperationalExpense(){
      this.loader.deleteOperationalExpense = true;
      const deleteOperationalExpenseRequestQuery = 
      {
        'operationalExpenseID': this.navigation.deletingOperationalExpenseID
      };
      const deleteOperationalExpenseResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/operationalExpense/functions/delete', deleteOperationalExpenseRequestQuery);
      if (deleteOperationalExpenseResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'El gasto se ha eliminado exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        await this.selectOperationalExpenseFromOperationalExpenseView();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al eliminar el gasto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.deleteOperationalExpense = false;
      this.navigation.deleteOperationalExpenseDialogIsVisible = false;
    },

    async selectOperationalExpenseFromOperationalExpenseView(){
      this.loader.selectOperationalExpenseFromOperationalExpenseView = true;
      const selectOperationalExpenseFromOperationalExpenseRequestQuery = 
      {
        'startDate': this.input.startDate,
        'endDate': this.input.endDate
      };
      const selectOperationalExpenseFromOperationalExpenseViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/operationalExpense/functions/selectOperationalExpenseFromOperationalExpenseView', selectOperationalExpenseFromOperationalExpenseRequestQuery);
      if (selectOperationalExpenseFromOperationalExpenseViewResult.success){
        this.display.information = selectOperationalExpenseFromOperationalExpenseViewResult.result;
        this.loader.selectOperationalExpenseFromOperationalExpenseView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de gastos operativos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async updateOperationalConfiguration(locality){
      this.loader.selectOperationalExpenseConfiguration = true;
      var originalOperationConfiguration = this.display.operationalConfiguration.find(operationalConfiguration => operationalConfiguration.localityID == locality.localityID);
      originalOperationConfiguration['originalValue'][this.navigation.operationalExpenseType] = 
      {
        'percentage': parseFloat(locality.percentage),
        'amount': parseFloat(locality.amount)
      };
      const updateOperationalConfigurationRequestQuery =
      {
        'localityID': locality.localityID,
        'localityOperationalInformation': JSON.stringify(originalOperationConfiguration['originalValue'])
      };
      const updateOperationalConfigurationResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/operationalExpense/functions/updateOperationalExpenseConfiguration', updateOperationalConfigurationRequestQuery);
      if (updateOperationalConfigurationResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'La configuración de los gastos se ha actualizado exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al actualizar la configuración de los gastos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectOperationalExpenseConfiguration = false;
    },

    openInsertOperationalExpenseDialog(operationalExpenseType){
      this.input.operationalExpenseType = operationalExpenseType;
      this.input.operationalExpenseName = null;
      this.input.operationalExpenseNote = null;
      this.input.operationalExpenseAmount = null;
      this.input.operationalExpenseRegisterDatetime = new Date();
      this.loader.insertOperationalExpense = false;
      this.navigation.insertOperationalExpenseDialogIsVisible = true;
    },

    openDeleteOperationalExpenseDialog(operationalExpenseID){
      this.navigation.deletingOperationalExpenseID = operationalExpenseID;
      this.navigation.deleteOperationalExpenseDialogIsVisible = true;
      this.loader.deleteOperationalExpense = false;
    },

    async openConfigureOperationalExpenseDialog(operationalExpenseType){
      this.display.operationalConfiguration = null;
      this.navigation.configureOperationalExpenseDialogIsVisible = true;
      this.navigation.operationalExpenseType = operationalExpenseType;
      this.loader.selectOperationalExpenseConfiguration = true;
      const selectOperationalExpenseConfigurationResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/operationalExpense/functions/selectOperationalExpenseConfiguration');
      if (selectOperationalExpenseConfigurationResult.success){
        const operationalConfiguration = [];
        for (var localityIndex in selectOperationalExpenseConfigurationResult.result){
          const locality = selectOperationalExpenseConfigurationResult.result[localityIndex];
          const localityOperationalInformation = JSON.parse(locality.localityOperationalInformation);
          const localityToPush = 
          {
            'localityID': locality.localityID,
            'localityName': locality.localityName,
            'percentage': localityOperationalInformation[operationalExpenseType].percentage,
            'amount': localityOperationalInformation[operationalExpenseType].amount,
            'originalValue': localityOperationalInformation
          };
          operationalConfiguration.push(localityToPush);
        }
        this.display.operationalConfiguration = operationalConfiguration;
        this.loader.selectOperationalExpenseConfiguration = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la configuración de los gastos operativos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
        this.navigation.configureOperationalExpenseDialogIsVisible = false;
      }
    },

    async setDefaultValues(){
      this.input.startDate = new Date(new Date().setDate(1));
      this.input.endDate = new Date();
      this.input.operationalExpenseType = null;
      this.display.information = null;
      this.display.operationalConfiguration = null;
      this.navigation.startDatePickerIsVisible = false;
      this.navigation.endDatePickerIsVisible = false;
      this.navigation.insertOperationalExpenseDialogIsVisible = false;
      this.navigation.operationalExpenseRegisterDatetimePickerIsVisible = false;
      this.navigation.deleteOperationalExpenseDialogIsVisible = false;
      this.navigation.deletingOperationalExpenseID = null;
      this.navigation.configureOperationalExpenseDialogIsVisible = false;
      this.navigation.operationalExpenseType = null;
      this.loader.selectOperationalExpenseFromOperationalExpenseView = false;
      this.loader.insertOperationalExpense = false;
      this.loader.deleteOperationalExpense = false;
      this.loader.selectOperationalExpenseConfiguration = false;
    },

    async getLocalStorageData(){
      this.localStorage.agentID = localStorage.getItem('agentID');
    }

  },

  async mounted(){
    await this.setDefaultValues();
    await this.getLocalStorageData();
  }
});
</script>
