<template>
  <v-dialog v-model="navigation.insertProductChangeDialogIsVisible" :persistent="true" width="auto">
    <v-card width="900" style="padding: 20px;">
      <v-switch 
        v-if="accessCredential['/productChange/insertProductChangeDialog/withoutBill']" 
        v-model="input.productChangeWithBill" 
        :label="`Cambio ${input.productChangeWithBill ? 'con' : 'sin'} factura`" 
        color="primary" 
        variant="solo-filled" 
      ></v-switch>
      <v-text-field 
        v-if="input.productChangeWithBill"
        @keydown.enter.prevent="selectBillOnInsertProductChangeDialog()" 
        v-model="input.billID" 
        append-inner-icon="mdi-barcode" 
        label="Código de la factura" 
        variant="solo-filled" 
        density="compact"
      ></v-text-field>

      <div v-else>
        <v-autocomplete 
          v-model="input.localityID"
          :append-inner-icon="input.localityID == null ? 'mdi-store' : 'mdi-store-off'" 
          @click:append-inner="input.localityID = null"  
          :items="display.localityOptions" 
          :loading="loader.selectLocalityIDAndLocalityNameByLocalityIsActive" 
          :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive" 
          item-title="localityName" 
          item-value="localityID" 
          label="Localidad asociada" 
          variant="solo-filled" 
          density="compact"
        ></v-autocomplete>
          
        
        <div style="display: flex;">
          <v-autocomplete 
            v-if="input.localityID"
            v-model="input.oldProductID" 
            :append-inner-icon="input.oldProductID ? 'mdi-alphabetical-variant-off' : 'mdi-alphabetical-variant'" 
            @click:append-inner="input.oldProductID = null" 
            :items="display.productOptions" 
            :loading="loader.selectProductBasicInformationForBillViewByLocalityID" 
            :disabled="loader.selectProductBasicInformationForBillViewByLocalityID" 
            ref="oldProductID" 
            item-title="productAutocompleteTitle" 
            item-value="productID" 
            label="Producto dañado" 
            variant="solo-filled" 
            density="compact"
          ></v-autocomplete>
          <div v-if="input.oldProductID">
            <p style="margin-left: 10px; margin-top: 10px;"><strong>Stock: </strong>{{ display.productOptions.find(product => product.productID == input.oldProductID).productLocalityStockAmount }}</p>
          </div>
        </div>

        <div style="display: flex;">
          <v-autocomplete 
            v-if="input.localityID"
            v-model="input.newProductID" 
            :append-inner-icon="input.newProductID ? 'mdi-alphabetical-variant-off' : 'mdi-alphabetical-variant'" 
            @click:append-inner="input.newProductID = null" 
            :items="display.productOptions" 
            :loading="loader.selectProductBasicInformationForBillViewByLocalityID" 
            :disabled="loader.selectProductBasicInformationForBillViewByLocalityID" 
            ref="newProductID" 
            item-title="productAutocompleteTitle" 
            item-value="productID" 
            label="Producto entregado" 
            variant="solo-filled" 
            density="compact"
          ></v-autocomplete>
          <div v-if="input.newProductID">
            <p style="margin-left: 10px; margin-top: 10px;"><strong>Stock: </strong>{{ display.productOptions.find(product => product.productID == input.newProductID).productLocalityStockAmount }}</p>
          </div>
        </div>
        
        
        <v-textarea v-model="input.productChangeNote" rows="3" append-inner-icon="mdi-text-box-outline" label="Nota del cambio de producto" variant="solo-filled"></v-textarea>
        <v-text-field 
          v-model="input.tokenValue" 
          append-inner-icon="mdi-lock" 
          variant="solo-filled" 
          type="number" 
          label="Token" 
          hide-spin-buttons
          density="compact"
        ></v-text-field>
        <div v-if="loader.generateProductChange" style="text-align: center;">
          <v-progress-circular color="primary" indeterminate></v-progress-circular>
        </div>
        <div v-else>
          <br><br>
          <v-btn :disabled="!(input.localityID && input.oldProductID && input.newProductID && display.productOptions.find(product => product.productID == input.newProductID).productLocalityStockAmount > 0)" @click="generateProductChangeWithoutBill()" dark block height="38" color="success">
            <h3>GENERAR CAMBIO DE PRODUCTO</h3>
            <v-icon style="margin-left: 10px;" dark right>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </div>
      </div>
      <br>


      <div v-if="display.bill">
        <br>
        <v-textarea v-model="input.productChangeNote" rows="3" prepend-inner-icon="mdi-text-box-outline" label="Nota del cambio de producto" variant="solo-filled"></v-textarea>
        <br>
        <div style="display: flex;">
          <div style="width: 50%;">
            <p class="billDialogLabel"><strong>ID interno: </strong>{{ display.bill.billID }}</p>
            <p class="billDialogLabel"><strong>ID de facturación: </strong>{{ display.bill.billTaxID || 'Sin asignar' }}</p>
            <div class="billDialogChipContainer">
              <p class="billDialogLabel" style="position: relative; top: 10px;"><strong>Estado: </strong></p>
              <v-chip class="billDialogChip" :color="localStorage.billStateColors[display.bill.billState].billStateColor" variant="flat">
                <p :style="{color: localStorage.billStateColors[display.bill.billState].billStateFontColor}">{{ display.bill.billState }}</p>
              </v-chip>
            </div>
            <div class="billDialogChipContainer" style="margin-top: 10px;">
              <p class="billDialogLabel" style="position: relative; top: 10px;"><strong>Localidad: </strong></p>
              <v-chip class="billDialogChip" :color="display.bill.localityColor" variant="flat">
                <p :style="{color: display.bill.localityFontColor}">{{ display.bill.localityName }}</p>
              </v-chip>
            </div>
            <div class="billDialogChipContainer" style="margin-top: 10px;">
              <p class="billDialogLabel" style="position: relative; top: 10px;"><strong>Agente facturador: </strong></p>
              <v-chip class="billDialogChip" :color="display.bill.agentColor" variant="flat">
                <p :style="{color: display.bill.agentFontColor}">{{ display.bill.agentName }}</p>
              </v-chip>
            </div>
            <br><br>
            <p class="billDialogLabel"><strong>Fecha y hora de creación: </strong>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(display.bill.billCreationDatetime) }}</p>
            <p class="billDialogLabel"><strong>Tipo de factura: </strong>{{ display.bill.billType }}</p>
            <p class="billDialogLabel"><strong>Método de pago: </strong>{{ display.bill.billPaymentType }}</p>
          </div>
          <div style="width: 50%;">
            <div v-if="display.bill.clientID">
              <p v-if="display.bill.clientSSN" class="billDialogLabel"><strong>Tipo de documento del cliente: </strong>{{ display.bill.clientSSNType }}</p>
              <p v-if="display.bill.clientSSN" class="billDialogLabel"><strong>SSN del cliente: </strong>{{ display.bill.clientSSN }}</p>
              <p v-if="display.bill.clientName" class="billDialogLabel"><strong>Nombre del cliente: </strong>{{ display.bill.clientName }}</p>
              <p v-if="display.bill.clientEmail" class="billDialogLabel"><strong>Correo del cliente: </strong>{{ display.bill.clientEmail }}</p>
              <p v-if="display.bill.clientPhoneNumber" class="billDialogLabel"><strong>Número del cliente: </strong>{{ display.bill.clientPhoneNumber }}</p>
            </div>
            <div v-if="display.bill.billCashPaymentID">
              <br>
              <p class="billDialogLabel"><strong>Cantidad recibida en efectivo: </strong>₡ {{$generalFunctions.default.methods.parseAmountToDisplay(display.bill.billCashPaymentCashAmount)}}</p>
              <p class="billDialogLabel"><strong>Cantidad entregada como cambio: </strong>₡ {{$generalFunctions.default.methods.parseAmountToDisplay(display.bill.billCashPaymentChangeAmount)}}</p>
            </div>
              <br>
            <div v-if="display.bill.billMixedPaymentID">
              <p v-if="display.bill.billMixedPaymentCashAmount != 0" class="billDialogLabel"><strong>Cantidad en efectivo: </strong>₡ {{$generalFunctions.default.methods.parseAmountToDisplay(display.bill.billMixedPaymentCashAmount)}}</p>
              <p v-if="display.bill.billMixedPaymentCardAmount != 0" class="billDialogLabel"><strong>Cantidad en tarjeta: </strong>₡ {{$generalFunctions.default.methods.parseAmountToDisplay(display.bill.billMixedPaymentCardAmount)}}</p>
              <p v-if="display.bill.billMixedPaymentSINPEAmount != 0" class="billDialogLabel"><strong>Cantidad en SINPE: </strong>₡ {{$generalFunctions.default.methods.parseAmountToDisplay(display.bill.billMixedPaymentSINPEAmount)}}</p>
              <p v-if="display.bill.billMixedPaymentDepositAmount != 0" class="billDialogLabel"><strong>Cantidad en transferencia o depósito: </strong>₡ {{$generalFunctions.default.methods.parseAmountToDisplay(display.bill.billMixedPaymentDepositAmount)}}</p>
            </div>
          </div>
        </div>
        <br><br>
        <div class="billProductTableContainer">
          <v-data-table :headers="display.billProductHeaders" :items="display.bill.billProductRows" :items-per-page="250" style="max-height: 700px; overflow-y: auto;">
            <template v-slot:item="row">
              <tr :style="row.item.billProductSelected ? 'background-color: #c7e8fc' : ''" @contextmenu.prevent="row.item.billProductSelected = !row.item.billProductSelected">
                <td>
                  <v-checkbox @change="updateProductChangeRows()" v-model="row.item.billProductSelected" color="info" hide-details></v-checkbox>
                </td>
                <td>
                  {{ row.item.productID }}
                </td>
                <td>
                  <div style="width: 200px;">
                    {{ row.item.productName }}
                    <br>
                    <strong>{{ row.item.productSKU }}</strong>
                  </div>
                </td>
                <td>
                  ₡ {{$generalFunctions.default.methods.parseAmountToDisplay(row.item.billProductOriginalPrice)}}
                </td>
                <td>
                  <strong>% {{ row.item.billProductTaxMargin }}</strong>
                  <br>
                  ₡ {{$generalFunctions.default.methods.parseAmountToDisplay(row.item.billProductOriginalPrice * (row.item.billProductTaxMargin/100))}}
                </td>
                <td>
                  <strong>% {{ row.item.billProductDiscountMargin }}</strong>
                  <br>
                  ₡ {{$generalFunctions.default.methods.parseAmountToDisplay(row.item.billProductOriginalPrice * (row.item.billProductDiscountMargin/100))}}
                </td>
                <td>
                  ₡ {{$generalFunctions.default.methods.parseAmountToDisplay(row.item.billProductPrice)}}
                </td>
              </tr>
            </template>
          </v-data-table>
          <br>
          <p class="billDialogLabel"><strong>Unidad{{ display.bill.billProductRows.length > 1 ? `es` : '' }}: </strong>{{ display.bill.billProductRows.length }}</p>
          <p class="billDialogLabel"><strong>Subtotal: </strong>₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(display.bill.billProductRows.reduce((billSubtotal, billProductRow) => billSubtotal + (billProductRow.billProductOriginalPrice - (billProductRow.billProductOriginalPrice * (billProductRow.billProductTaxMargin / 100))), 0))}}</p>
          <p class="billDialogLabel"><strong>Impuesto: </strong>₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(display.bill.billProductRows.reduce((billTaxAmount, billProductRow) => billTaxAmount + (billProductRow.billProductOriginalPrice * (billProductRow.billProductTaxMargin / 100)), 0))}}</p>        
          <p v-if="display.bill.billProductRows.reduce((billDiscountAmount, billProductRow) => billDiscountAmount + (billProductRow.billProductOriginalPrice * (billProductRow.billProductDiscountMargin/100)), 0) > 0" class="billDialogLabel"><strong>Descuento: </strong>₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(display.bill.billProductRows.reduce((billDiscountAmount, billProductRow) => billDiscountAmount + (billProductRow.billProductOriginalPrice * (billProductRow.billProductDiscountMargin/100)), 0))}}</p>
          <p class="billDialogLabel"><strong>Total: </strong>₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(display.bill.billProductRows.reduce((billTotal, billProductRow) => billTotal + billProductRow.billProductPrice, 0))}}</p>        
        </div>
        <br>
        <div class="billProductTableContainer" v-if="display.productChangeRows.length > 0">
          <p class="billDialogLabel"><strong>Cambios de producto:</strong></p>
          <br>
          <v-data-table :headers="display.productChangeHeaders" :items="display.productChangeRows" :items-per-page="250" style="max-height: 700px; overflow-y: auto;">
            <template v-slot:item="row">
              <tr>
                <td>
                  <div style="width: 200px;">
                    {{ row.item.originalProductName }}
                    <br>
                    <strong>{{ row.item.originalProductSKU }}</strong>
                  </div>
                </td>
                <td>
                  ₡ {{$generalFunctions.default.methods.parseAmountToDisplay(row.item.originalProductPrice)}}
                </td>
                <td>
                  <v-autocomplete
                    v-model="row.item.newProductID"
                    v-model:search="row.item.newProductSearchValue"
                    @keydown.enter.prevent="selectProductChangeNewProduct(row.item)"
                    :items="display.productOptions"
                    :loading="loader.selectProductBasicInformationForBillViewByLocalityID"
                    :disabled="loader.selectProductBasicInformationForBillViewByLocalityID"
                    ref="productChangeNewProductInput"
                    item-title="productAutocompleteTitle" 
                    item-value="productID"
                    variant="solo-filled"
                    style="margin-top: 20px;"
                  >
                  </v-autocomplete>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <br>
        <div v-if="loader.generateProductChange" style="text-align: center;">
          <v-progress-circular color="info" indeterminate></v-progress-circular>
        </div>
        <div v-else>
          <br><br>
          <v-btn @click="generateProductChange()" dark block height="38" color="success">
            <h3>SOLICITAR CAMBIO DE PRODUCTO</h3>
            <v-icon style="margin-left: 10px;" dark right>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </div>
        <br>
      </div>

     
      <v-btn @click="setDefaulValues()" dark block height="38" color="error">
        <h3>CANCELAR</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>
  
<style scoped>
  .billDialogLabel {
    font-size: larger;
  }

  .billDialogChipContainer {
    display: flex;
  }

  .billDialogChip {
    margin-left: 10px;
    position: relative;
    top: 8px;
  }

  .billProductTableContainer {
    padding: 1%;
    background-color: #e9e8e8;
  }

  .billProductsTable {
    max-height: 400px;
    overflow-y: auto;
  }
</style>
  

<script>

export default {
  name: 'InsertProductChangeDialog',
  inject: ['$generalFunctions'],

  data: () => ({

    input:
    {
      billID: null,
      localityID: null,
      newProductID: null,
      oldProductID: null,
      productChangeWithBill: true,
      tokenValue: null,
      productChangeNote: null
    },

    loader: 
    {
      generateProductChange: false,
      selectProductBasicInformationForBillViewByLocalityID: false,
      selectLocalityIDAndLocalityNameByLocalityIsActive: false
    },
    
    navigation: 
    {
      insertProductChangeDialogIsVisible: false
    },

    display:
    {
      bill: null,
      billProductHeaders: 
      [
        {key: 'billProductSelected', title: null},
        {key: 'productID', title: 'ID INTERNO'},
        {key: 'productName', title: 'NOMBRE'},
        {key: 'billProductOriginalPrice', title: 'PRECIO ORIGINAL'},
        {key: 'billProductTaxMargin', title: 'IMPUESTO'},
        {key: 'billProductDiscountMargin', title: 'DESCUENTO'},
        {key: 'billProductPrice', title: 'PRECIO COBRADO'},
      ],
      productChangeRows: [],
      productChangeHeaders: 
      [
        {key: 'originalProductName', title: 'PRODUCTO QUE SE CAMBIA'},
        {key: 'originalProductPrice', title: 'PRECIO DEL PRODUCTO QUE SE CAMBIA'},
        {key: 'newProduct', title: 'PRODUCTO POR EL QUE SE CAMBIA'}
      ],
      productOptions: [],
      localityOptions: []
    },

    localStorage:
    {
      localityID: null,
      agentID: null,
      billStateColors: null
    },

    accessCredential: {}
  }),

  watch: {
    'input.productChangeWithBill'(){
      this.input.billID = null;
      this.input.localityID = null;
      this.input.oldProductID = null;
      this.input.newProductID = null;
      this.input.productChangeNote = null;
      this.input.tokenValue = null;
      this.display.bill = null;
      this.display.productChangeRows.length = 0;
    },

    'input.localityID'(){
      this.input.oldProductID = null;
      this.input.newProductID = null;
      this.selectProductBasicInformationForBillViewByLocalityID();
    }
  },

  methods: {
    async generateProductChange(){
      this.loader.generateProductChange = true;
      const productChangeProducts = this.display.productChangeRows.map(productChangeRow => ({
        'originalProductID': productChangeRow.originalProductID,
        'newProductID': productChangeRow.newProductID
      }));
      const generateProductChangeRequestQuery =
      {
        'productChangeTokenID': 107811,
        'productChangeBillID': this.display.bill.billID,
        'productChangeLocalityID': this.localStorage.localityID,
        'productChangeAgentID': this.localStorage.agentID,
        'productChangeNote': this.input.productChangeNote,
        'productChangeProducts': productChangeProducts
      };
      const generateProductChangeResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productChange/functions/generateProductChange', generateProductChangeRequestQuery);
      if (generateProductChangeResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': `Cambio de producto${this.display.productChangeRows.length > 1 ? 's' : ''} solicitado exitosamente exitosamente`,
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': generateProductChangeResult.result.PDFDocumentFile.data,
          'printablePDFDocumentName': generateProductChangeResult.result.PDFDocumentName
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
        this.setDefaulValues();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': `Ha ocurrido un error inesperado al generar la solicitud de cambio de producto${this.display.productChangeRows.length > 1 ? 's' : ''}, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema`,
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.generateProductChange = false;
    },

    async generateProductChangeWithoutBill(){
      this.loader.generateProductChange = true;
      
      const generateProductChangeWithoutBillRequestQuery =
      {
        'productChangeLocalityID': this.input.localityID,
        'productChangeAgentID': this.localStorage.agentID,
        'productChangeNote': this.input.productChangeNote,
        'productChangeOldProductID': this.input.newProductID,
        'productChangeNewProductID': this.input.oldProductID,
        'tokenValue': this.input.tokenValue
      };
      const generateProductChangeWithoutBillResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productChange/functions/generateProductChangeWithoutBill', generateProductChangeWithoutBillRequestQuery);
      if (generateProductChangeWithoutBillResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': `Cambio de producto generado exitosamente exitosamente`,
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        this.setDefaulValues();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': `Ha ocurrido un error inesperado al generar el cambio de producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema`,
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.generateProductChange = false;
    },



    selectProductChangeNewProduct(productChangeRow){
      const foundProduct = this.display.productOptions.find(productOption => productOption.productID == productChangeRow.newProductSearchValue);
      if (foundProduct){
        productChangeRow.newProductID = productChangeRow.newProductSearchValue;
        const blurInputInterval = setInterval(() => {
          if (this.$refs['productChangeNewProductInput']) {
            this.$refs['productChangeNewProductInput'].blur();
            clearInterval(blurInputInterval);
          }
        }, 1);
      } else { 
        alert('no')
      }
    },

    async updateProductChangeRows(){
      this.display.productChangeRows = this.display.bill.billProductRows.filter(billProductRow => billProductRow.billProductSelected).map(billProductRow => ({
        'originalProductID': billProductRow.productID,
        'originalProductSKU': billProductRow.productSKU,
        'originalProductName': billProductRow.productName,
        'originalProductPrice': billProductRow.billProductPrice,
        'newProductID': null,
        'newProductSearchValue': null
      }));
    },

    async selectBillOnInsertProductChangeDialog(){
      const selectBillOnInsertProductChangeDialogRequestQuery =
      {
        'billID': this.input.billID
      };
      const selectBillProductOnProductTendencySaleViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/functions/selectBillOnInsertProductChangeDialog', selectBillOnInsertProductChangeDialogRequestQuery);
      if (selectBillProductOnProductTendencySaleViewResult.success){
        if (selectBillProductOnProductTendencySaleViewResult.result == 1){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': `La factura "${this.input.billID}" no ha sido encontrada, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema`,
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        } else {
          this.display.bill = selectBillProductOnProductTendencySaleViewResult.result;
          this.display.bill.billProductRows = this.display.bill.billProductRows.flatMap(billProductRow => {
            return Array(billProductRow.billProductAmount).fill().map(() => ({
              'billProductSelected': false,
              ...billProductRow
            }))
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductBasicInformationForBillViewByLocalityID(){
      this.loader.selectProductBasicInformationForBillViewByLocalityID = true;
      const selectProductBasicInformationForBillViewByLocalityIDRequestQuery = {'localityID': this.input.localityID};
      const selectProductBasicInformationForBillViewByLocalityIDResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductBasicInformationForBillViewByLocalityID', selectProductBasicInformationForBillViewByLocalityIDRequestQuery);
      if (selectProductBasicInformationForBillViewByLocalityIDResult.success){
        this.display.productOptions = selectProductBasicInformationForBillViewByLocalityIDResult.result.map(productOption => ({
          'productID': productOption.i,
          'productSKU': productOption.s,
          'productName': productOption.n,
          'productPrice': productOption.p,
          'productTaxMargin': productOption.t,
          'productMaxDiscountMargin': productOption.d,
          'productStockAmount': productOption.b,
          'productLocalityStockAmount': productOption.a,
          'productAutocompleteTitle': productOption.s + ' - ' + productOption.n
        }));
        this.loader.selectProductBasicInformationForBillViewByLocalityID = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectLocalityIDAndLocalityNameByLocalityIsActive(){
      this.loader.selectLocalityIDAndLocalityNameByLocalityIsActive = true;
      const selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery = 
      {
        'localityIsActive': true
      };
      const selectLocalityIDAndLocalityNameByLocalityIsActiveResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityNameByLocalityIsActive', selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery);
      if (selectLocalityIDAndLocalityNameByLocalityIsActiveResult.success){
        this.display.localityOptions = selectLocalityIDAndLocalityNameByLocalityIsActiveResult.result;
        this.loader.selectLocalityIDAndLocalityNameByLocalityIsActive = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    setDefaulValues(){
      this.input.productChangeWithBill = true;
      this.input.billID = null;
      this.input.localityID = null;
      this.input.oldProductID = null;
      this.input.newProductID = null;
      this.input.tokenValue = null;
      this.input.productChangeNote = null;
      this.navigation.insertProductChangeDialogIsVisible = false;
      this.display.bill = null;
      this.display.productChangeRows.length = 0;
    },

    
    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.localStorage.billStateColors = this.$generalFunctions.default.data.billStateColors;
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    },

    closeInsertProductChangeDialog(){
      this.navigation.insertProductChangeDialogIsVisible = false;
    },

    async openInsertProductChangeDialog() {
      this.setDefaulValues();
      await this.getLocalStorageData();
      this.selectProductBasicInformationForBillViewByLocalityID();
      this.selectLocalityIDAndLocalityNameByLocalityIsActive();
      this.navigation.insertProductChangeDialogIsVisible = true;
    },
  },

  mounted(){
  }
}
</script>