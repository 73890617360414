<template>
  <v-dialog v-model="navigation.insertProformaDialogIsVisible" :persistent="true" width="auto">
    <v-card width="1500" style="padding: 20px;">
      <div v-if="loader.insertProforma" style="text-align: center;">
        <br>
        <v-progress-circular color="success" indeterminate></v-progress-circular>
        <br><br>
      </div>
      <div v-else>
        <v-autocomplete 
          v-model="input.proformaContactID" 
          :items="display.contactOptions" 
          :loading="loader.selectContactFromInsertProformaDialog" 
          :disabled="loader.selectContactFromInsertProformaDialog" 
          append-inner-icon="mdi-account" 
          label="Cliente asociado" 
          item-value="contactID" 
          item-title="contactName" 
          variant="solo-filled"
          density="compact"
        ></v-autocomplete>
        <div style="display: flex;">
          <v-select v-model="input.proformaCredit" :items="display.proformaCreditOptions" :disabled="navigation.contactHasCredit == false" append-inner-icon="mdi-account-cash" label="Tipo de pago" variant="solo-filled"></v-select>
          <v-switch v-model="input.proformaCurrency" true-value="$" false-value="₡" :label="input.proformaCurrency" hide-details color="success" style="margin-left: 2%; position: relative; top: -5px;"></v-switch>
        </div>
        <v-select v-model="input.proformaType" :items="display.proformaTypeOptions" append-inner-icon="mdi-source-branch" label="Tipo de factura" variant="solo-filled"></v-select>
        <div v-if="input.proformaPaymentType == 'Mixto'">
          <v-card style="background-color: #e9e8e8; padding: 20px 20px 0px 20px;">
            <v-select v-model="input.proformaPaymentType" :items="input.proformaPaymentType == 'Recibo' ? ['Efectivo'] : ['Efectivo', 'Tarjeta', 'SINPE', 'Transferencia o depósito', 'Mixto']" append-inner-icon="mdi-cash-multiple" label="Método de pago" variant="solo-filled"></v-select>
            <div style="display: flex;">
              <div style="width: 49%; margin-right: 1%;">
                <v-text-field v-model="input.proformaMixedPaymentCashAmount" append-inner-icon="mdi-cash" label="Cantidad en efectivo" variant="solo-filled" density="compact"></v-text-field>
                <v-text-field v-model="input.proformaMixedPaymentCardAmount" append-inner-icon="mdi-credit-card-outline" label="Cantidad en tarjeta" variant="solo-filled" density="compact"></v-text-field>
              </div>
              <div style="width: 49%; margin-left: 1%;">
                <v-text-field v-model="input.proformaMixedPaymentSINPEAmount" append-inner-icon="mdi-cellphone" label="Cantidad en SINPE" variant="solo-filled" density="compact"></v-text-field>
                <v-text-field v-model="input.proformaMixedPaymentDepositAmount" append-inner-icon="mdi-bank" label="Cantidad en transferencia o depósito" variant="solo-filled" density="compact"></v-text-field>
              </div>
            </div>
          </v-card>
          <br>
        </div>
        <div v-else>
          <v-select v-if="input.proformaType != null" v-model="input.proformaPaymentType" :items="input.proformaType == 'Recibo' ? ['Efectivo'] : ['Efectivo', 'Tarjeta', 'SINPE', 'Transferencia o depósito', 'Mixto']" append-inner-icon="mdi-cash-multiple" label="Método de pago" variant="solo-filled"></v-select>
        </div>
        <v-textarea v-model="input.proformaNote" rows="5" prepend-inner-icon="mdi-text-box-outline" label="Nota de la proforma" variant="solo-filled"></v-textarea>
        <v-text-field v-model="input.proformaShippingAmount" label="Monto del envío (opcional)" variant="solo-filled" type="number" hide-spin-buttons></v-text-field>
        <br><br>
        <v-card style="background-color: #e9e8e8; padding: 20px;">
          <v-autocomplete
            v-model="input.matchedProductIDOrName"
            v-model:search="input.searchedProductIDOrName"
            @keydown.enter.prevent="appendProductIntoCurrentBillProducts(input.searchedProductIDOrName)"
            @update:modelValue="appendProductIntoCurrentBillProducts(input.matchedProductIDOrName.productID)"
            :items="display.productOptions"
            :loading="loader.selectProductBasicInformationForBillViewByLocalityID"
            :disabled="loader.selectProductBasicInformationForBillViewByLocalityID"
            return-object
            item-title="productAutocompleteTitle" 
            item-value="productID"
            ref="productIDOrNameInputReference"
            append-inner-icon="mdi-magnify"
            label="Código o nombre del producto"
            variant="solo-filled"
            class="productIDOrNameInput">
          </v-autocomplete>
          <br>
          <v-card elevation="3">
            <v-data-table-virtual :headers="display.billProductHeaders" :items="display.currentBillProducts" style="max-height: 900px; overflow-y: auto;">
              <template v-slot:item="row">
                <tr :style="row.item.billProductSelectedAction ? 'background-color: #c7e8fc' : ''" @contextmenu.prevent="row.item.billProductSelectedAction = !row.item.billProductSelectedAction">
                  <td>
                    <v-checkbox v-model="row.item.billProductSelectedAction" color="info" style="margin-top: 20px;"></v-checkbox>
                    <div v-if="display.currentBillProducts.findIndex(currentBillProduct => currentBillProduct.billProductProductID == row.item.billProductProductID) == 0">
                      <v-checkbox v-model="input.allBillProductSelected" @click="selectAllBillProduct()" color="info" style="position: absolute; top: 0px;"></v-checkbox>
                    </div>
                  </td>
                  <td>
                    <div style="width: 200px;">
                      {{ row.item.billProductName }}
                      <br>
                      <strong>{{ row.item.billProductProductID }}</strong>
                    </div>
                  </td>
                  <td>
                    <div v-if="row.item.billProductLocalityStockAmount >= row.item.billProductAmount">
                      <p style="color: #009929; font-size: larger;"><strong>{{ row.item.billProductLocalityStockAmount }}</strong></p>
                    </div>
                    <div v-else>
                      <v-tooltip text="El producto no cuenta con stock suficiente en esta localidad" location="bottom">
                        <template v-slot:activator="{ props }">
                          <p v-bind="props" class="billProductAmountWarningLabel"><strong>{{ row.item.billProductLocalityStockAmount }}</strong></p>
                        </template>
                      </v-tooltip>
                    </div>
                  </td>
                  <td>
                    <div @click="openUpdateBillProductAmountDialog(row.item, row.item.billProductAmount)" style="width: 100px; cursor: pointer;">
                      {{row.item.billProductAmount}}
                    </div>
                  </td>
                  <td>
                    <div @click="openUpdateBillProductPriceDialog(row.item)" style="width: 100px; cursor: pointer;">
                      {{ row.item.billProductPrice.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}
                    </div>
                  </td>
                  <td>
                    <div @click="openUpdateBillProductDiscountMarginDialog(row.item)" style="width: 100px; cursor: pointer;">
                      {{row.item.billProductDiscountMargin.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}}
                    </div>
                  </td>
                  <td>
                    {{ row.item.billProductDiscount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}
                  </td>
                  <td>
                    {{ row.item.billProductSubtotal.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}
                  </td>
                  <td>
                    {{ row.item.billProductTotal.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}
                  </td>
                  <td>
                    <v-icon @click="openProductImageDialog(row.item.billProductProductID, row.item.billProductName)" style="margin-right: 2%;" color="info">mdi-image</v-icon>
                  </td>
                  <td>
                    <v-icon @click="deleteBillProduct(row.item.billProductProductID)" style="margin-right: 2%;" color="error">mdi-trash-can</v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table-virtual>
          </v-card>
          <div v-if="display.currentBillProducts.length != 0">
            <br>
            <p v-if="display.contact && display.contact.contactCreditAmount && input.proformaCredit == 'Crédito'" style="font-size: larger"><strong>Crédito disponible:</strong> ₡{{ display.contact.contactAvailableCreditAmount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
            <br>
            <p style="font-size: larger"><strong>{{ display.billUnitAmount == 1 ? 'Unidad:' : 'Unidades:' }}</strong> {{ display.billUnitAmount }}</p>
            <p style="font-size: larger;"><strong>Subtotal:</strong> {{input.proformaCurrency == '₡' ? '₡' : ''}}{{display.billSubtotal.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}}{{input.proformaCurrency == '$' ? '$' : ''}}</p>
            <p style="font-size: larger;" v-if="input.proformaPaymentType != 'Efectivo'"><strong>Impuesto:</strong> {{input.proformaCurrency == '₡' ? '₡' : ''}}{{display.billTaxAmount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}}{{input.proformaCurrency == '$' ? '$' : ''}}</p>
            <p v-if="display.billDiscountAmount != 0" style="font-size: larger;"><strong>Descuento:</strong> {{input.proformaCurrency == '₡' ? '₡' : ''}}{{display.billDiscountAmount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}}{{input.proformaCurrency == '$' ? '$' : ''}}</p>
            <p v-if="input.proformaShippingAmount && input.proformaShippingAmount != 0" style="font-size: larger;"><strong>Envío:</strong> {{input.proformaCurrency == '₡' ? '₡' : ''}}{{parseInt(input.proformaShippingAmount).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}}{{input.proformaCurrency == '$' ? '$' : ''}}</p>
            <p style="font-size: larger;"><strong>Total:</strong> {{input.proformaCurrency == '₡' ? '₡' : ''}}{{display.billTotal.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}}{{input.proformaCurrency == '$' ? '$' : ''}}</p>  
          </div>
        </v-card>
        <br><br>
        <v-btn v-if="display.currentBillProducts.length != 0" @click="insertProforma()" dark block height="38" color="success">
          <h3>GENERAR PROFORMA</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-invoice-text-plus</v-icon>
        </v-btn>
        <br>
        <v-btn v-if="display.currentBillProducts.length != 0" @click="createProformaPreviewPDFDocument()" dark block height="38" color="warning">
          <h3>GENERAR PDF DE LA PROFORMA</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-file-pdf-box</v-icon>
        </v-btn>
        <br>
        <v-btn @click="setDefaultValues()" dark block height="38" color="error">
          <h3>CANCELAR</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-close-circle</v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>

  <v-dialog v-model="navigation.updateBillProductPriceDialogIsVisible" width="auto">
    <v-card width="300">
      <v-text-field
        v-model="input.updatingBillProductPrice"
        @keydown.enter.prevent="updateBillProductPrice()"
        label="Precio del producto"
        variant="solo-filled"
        class="updatingBillProductInput"
        type="number"
        ref="updatingBillProductPriceInputReference"
        style="padding: 20px;"
        hide-spin-buttons
      ></v-text-field>
      <v-btn @click="updateBillProductPrice()" dark height="38" color="success" style="margin-bottom: 15px; margin-left: 15px; margin-right: 15px;">
        <h3>GUARDAR</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-check-bold</v-icon>
      </v-btn>
      <v-btn @click="navigation.updateBillProductPriceDialogIsVisible = false" dark height="38" color="error" style="margin-bottom: 15px; margin-left: 15px; margin-right: 15px;">
        <h3>CANCELAR</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>

  <v-dialog v-model="navigation.updateBillProductTaxMarginDialogIsVisible" width="auto">
    <v-card width="300">
      <v-text-field
        v-model="input.updatingBillProductTaxMargin"
        @keydown.enter.prevent="updateBillProductTaxMargin()"
        label="Márgen de impuesto del producto"
        variant="solo-filled"
        class="updatingBillProductInput"
        type="number"
        ref="updatingBillProductTaxMarginInputReference"
        style="padding: 20px;"
        hide-spin-buttons
      ></v-text-field>
      <v-btn @click="updateBillProductTaxMargin()" dark height="38" color="success" style="margin-bottom: 15px; margin-left: 15px; margin-right: 15px;">
        <h3>GUARDAR</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-check-bold</v-icon>
      </v-btn>
      <v-btn @click="navigation.updateBillProductTaxMarginDialogIsVisible = false" dark height="38" color="error" style="margin-bottom: 15px; margin-left: 15px; margin-right: 15px;">
        <h3>CANCELAR</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>

  <v-dialog v-model="navigation.updateBillProductDiscountMarginDialogIsVisible" width="auto">
    <v-card width="300">
      <v-text-field
        v-model="input.updatingBillProductDiscountMargin"
        @keydown.enter.prevent="updateBillProductDiscountMargin()"
        label="Márgen de descuento del producto"
        variant="solo-filled"
        class="updatingBillProductInput"
        type="number"
        ref="updatingBillProductDiscountMarginInputReference"
        style="padding: 20px;"
        hide-spin-buttons
      ></v-text-field>
      <v-btn @click="updateBillProductDiscountMargin()" dark height="38" color="success" style="margin-bottom: 15px; margin-left: 15px; margin-right: 15px;">
        <h3>GUARDAR</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-check-bold</v-icon>
      </v-btn>
      <v-btn @click="navigation.updateBillProductDiscountMarginDialogIsVisible = false" dark height="38" color="error" style="margin-bottom: 15px; margin-left: 15px; margin-right: 15px;">
        <h3>CANCELAR</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>

  <v-dialog v-model="navigation.updateBillProductAmountDialogIsVisible" width="auto">
    <v-card width="300">
      <v-text-field
        v-model="input.updatingBillProductAmount"
        @keydown.enter.prevent="updateBillProductAmount()"
        label="Cantidad del producto"
        variant="solo-filled"
        class="updatingBillProductInput"
        type="number"
        ref="updatingBillProductAmountInputReference"
        style="padding: 20px;"
      ></v-text-field>
      <v-btn @click="updateBillProductAmount()" dark height="38" color="success" style="margin-bottom: 15px; margin-left: 15px; margin-right: 15px;">
        <h3>GUARDAR</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-check-bold</v-icon>
      </v-btn>
      <v-btn @click="navigation.updateBillProductAmountDialogIsVisible = false" dark height="38" color="error" style="margin-bottom: 15px; margin-left: 15px; margin-right: 15px;">
        <h3>CANCELAR</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>
    
<style scoped>
  .billProductAmountWarningLabel {
    cursor: pointer;
    color: rgb(171, 0, 0);
    font-size: larger;
    animation: billProductAmountWarningLabelAnimation 2s infinite;
  }

  @keyframes billProductAmountWarningLabelAnimation {
    0% {transform: scale(1)}
    50% {transform: scale(1.4)}
    100% {transform: scale(1)}
  }
</style>

<script>

export default {
  name: 'InsertProformaDialog',
  inject: ['$generalFunctions'],

  data: () => ({

    input:
    {
      proformaContactID: null,
      proformaCredit: null,
      proformaType: null,
      proformaCurrency: '₡',
      proformaPaymentType: null,
      proformaMixedPaymentCashAmount: 0,
      proformaMixedPaymentCardAmount: 0,
      proformaMixedPaymentSINPEAmount: 0,
      proformaMixedPaymentDepositAmount: 0,
      proformaNote: null,
      proformaShippingAmount: 0,
      matchedProductIDOrName: null,
      searchedProductIDOrName: null,
      allBillProductSelected: false,
      updatingBillProduct: null,
      updatingBillProductMultipleSelected: null,
      updatingBillProductPrice: null,
      updatingBillProductTaxMargin: null,
      updatingBillProductDiscountMargin: null,
      updatingBillProductAmount: null
    },

    loader: 
    {
      selectContactFromInsertProformaDialog: false,
      selectProductBasicInformationForBillViewByLocalityID: false,
      insertProforma: false
    },
    
    navigation: 
    {
      insertProformaDialogIsVisible: false,
      contactHasCredit: false,
      updateBillProductPriceDialogIsVisible: false,
      updateBillProductTaxMarginDialogIsVisible: false,
      updateBillProductDiscountMarginDialogIsVisible: false,
      updateBillProductAmountDialogIsVisible: false
    },

    display:
    {
      contactOptions: [],
      contact: null,
      proformaCreditOptions: ['Contado', 'Crédito'],
      proformaTypeOptions: ['Factura electrónica', 'Tiquete electrónico', 'Recibo'],
      dolarExchangeRate: 500,
      billProductHeaders: 
      [
        {key: 'billProductSelectAction', title: ''},
        {key: 'billProductName', title: 'Nombre'},
        {key: 'billProductLocalityStockAmount', title: 'Stock'},
        {key: 'billProductAmount', title: 'Cantidad'},
        {key: 'billProductPrice', title: 'Precio'},
        {key: 'billProductDiscountMargin', title: 'Descuento(%)'},
        {key: 'billProductDiscount', title: 'Descuento'},
        {key: 'billProductSubtotal', title: 'Subtotal'},
        {key: 'billProductTotal', title: 'Total'},
        {key: 'billProductInformationAction', title: 'Imagen'},
        {key: 'billProductDeleteAction', title: 'Eliminar'}
      ],
      currentBillProducts: [],
      billUnitAmount: 0,
      billSubtotal: 0,
      billDiscountAmount: 0,
      billTaxAmount: 0,
      billTotal: 0
    },

    localStorage:
    {
      localityID: null,
      agentID: null,
      agentName: null
    },

    accessCredential: {}

  }),

  watch: {
    'input.proformaCredit'(){
      this.input.proformaType = null;
    },
    'input.proformaContactID'(){
      const contact = this.display.contactOptions.find(contactOption => contactOption.contactID == this.input.proformaContactID);
      if (contact){
        this.navigation.contactHasCredit = contact.contactCreditAmount != null;
        this.input.proformaCredit = (contact.contactCreditAmount != null) ? null : 'Contado';
        this.display.contact = contact;
      }
    },
    'input.proformaType'(){
      this.input.proformaPaymentType = null;
    },
    'input.proformaPaymentType'(){
      this.calculateBillResult();
    },
    'input.proformaShippingAmount'(){
      this.calculateBillResult();
    }
  },


  methods: {
    async validateContactAvailableCreditAmount(){
      if (this.input.proformaCredit == 'Crédito'){
        if (this.display.contact && (this.display.contact.contactAvailableCreditAmount >= this.display.billTotal)){
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },

    async createProformaPreviewPDFDocument(){
      this.loader.insertProforma = true;
      const proformaProducts = this.display.currentBillProducts.map(proformaProduct => ({
        'productSKU': proformaProduct.billProductProductID,
        'productName': proformaProduct.billProductName,
        'proformaProductOriginalPrice': proformaProduct.billProductPrice,
        'proformaProductPrice': proformaProduct.billProductSubtotal,
        'proformaProductDiscountMargin': proformaProduct.billProductDiscountMargin,
        'proformaProductAmount': proformaProduct.billProductAmount,
        'proformaProductTaxMargin': proformaProduct.billProductTaxMargin
      }));
      const insertProformaRequestQuery = 
      {
        'proformaCreationAgentID': this.localStorage.agentID,
        'proformaCreationAgentName': this.localStorage.agentName,
        'contactName': this.display.contactOptions.find(contact => contact.contactID == this.input.proformaContactID).contactName,
        'proformaType': this.input.proformaType,
        'proformaPaymentType': this.input.proformaPaymentType,
        'proformaNote': this.input.proformaNote,
        'proformaShippingAmount': this.input.proformaShippingAmount,
        'proformaProducts': proformaProducts
      };
      const createProformaPreviewPDFDocumentResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/proforma/functions/createProformaPreviewPDFDocument', insertProformaRequestQuery);
      if (createProformaPreviewPDFDocumentResult.success){
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': createProformaPreviewPDFDocumentResult.result.documentFile.data,
          'printablePDFDocumentName': createProformaPreviewPDFDocumentResult.result.documentName,
          'printableExcelDocumentFile': null,
          'printableExcelDocumentName': null
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al generar la vista previa de la proforma, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.insertProforma = false;
    },


    async insertProforma(){
      this.loader.insertProforma = true;
      if (await this.validateContactAvailableCreditAmount()){
        const insertProformaRequestQuery = 
        {
          'proformaCreationAgentID': this.localStorage.agentID,
          'proformaContactID': this.input.proformaContactID,
          'proformaIsCredit': this.input.proformaCredit == 'Crédito',
          'proformaType': this.input.proformaType,
          'proformaCurrency': this.input.proformaCurrency,
          'proformaPaymentType': this.input.proformaPaymentType,
          'proformaMixedPaymentCashAmount': this.input.proformaMixedPaymentCashAmount,
          'proformaMixedPaymentCardAmount': this.input.proformaMixedPaymentCardAmount,
          'proformaMixedPaymentSINPEAmount': this.input.proformaMixedPaymentSINPEAmount,
          'proformaMixedPaymentDepositAmount': this.input.proformaMixedPaymentDepositAmount,
          'proformaNote': this.input.proformaNote,
          'proformaShippingAmount': this.input.proformaShippingAmount,
          'proformaProducts': this.display.currentBillProducts
        };
        const insertProformaResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/proforma/functions/insertProforma', insertProformaRequestQuery);
        if (insertProformaResult.success){
          const printablePDFExcelDocumentDialogRequestQuery = 
          {
            'printablePDFDocumentFile': insertProformaResult.result.documentFile.data,
            'printablePDFDocumentName': insertProformaResult.result.documentName,
            'printableExcelDocumentFile': null,
            'printableExcelDocumentName': null
          };
          this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ÉXITO',
            'notificationDialogBody': 'La proforma se ha generado exitosamente',
            'notificationDialogColor': 'success',
            'notificationDialogIsPersistent': false
          });
          this.setDefaultValues();
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al generar la proforma, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'El contacto excede su monto de crédito al generar esta proforma, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.insertProforma = false;
    },

    async selectContactFromInsertProformaDialog(){
      this.loader.selectContactFromInsertProformaDialog = true;
      const selectContactFromInsertProformaDialogResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/contact/functions/selectContactFromInsertProformaDialog');
      if (selectContactFromInsertProformaDialogResult.success){
        this.display.contactOptions = selectContactFromInsertProformaDialogResult.result;
        this.loader.selectContactFromInsertProformaDialog = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los contactos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectContactFromInsertProformaDialog = false;
    },

    async selectProductBasicInformationForBillViewByLocalityID(){
      this.loader.selectProductBasicInformationForBillViewByLocalityID = true;
      const selectProductBasicInformationForBillViewByLocalityIDRequestQuery = 
      {
        'localityID': 5,
        'wholesale': true
      };
      const selectProductBasicInformationForBillViewByLocalityIDResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductBasicInformationForBillViewByLocalityID', selectProductBasicInformationForBillViewByLocalityIDRequestQuery);
      if (selectProductBasicInformationForBillViewByLocalityIDResult.success){
        this.display.productOptions = selectProductBasicInformationForBillViewByLocalityIDResult.result.map(productOption => ({
          'productID': productOption.i,
          'productSKU': productOption.s,
          'productName': productOption.n,
          'productWholesaleColonPrice': productOption.wpc,
          'productWholesaleDolarPrice': productOption.wpd,
          'productTaxMargin': productOption.t,
          'productMaxDiscountMargin': productOption.d,
          'productLocalityStockAmount': productOption.a,
          'productAutocompleteTitle': productOption.s + ' - ' + productOption.n
        }));
        this.loader.selectProductBasicInformationForBillViewByLocalityID = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async appendProductIntoCurrentBillProducts(productID){
      const regularExpressionChecker = /\S/;
      if (regularExpressionChecker.test(productID)){
        const product = this.display.productOptions.find(productOption => productOption.productID == productID);
        if (product){
          const productPrice = product.productWholesaleColonPrice ? product.productWholesaleColonPrice : product.productWholesaleDolarPrice;

          const billProductTax = productPrice * (product.productTaxMargin/100);
          const billProductPriceWithoutTax = productPrice - billProductTax;
          const billProductAlreadyOnCurrentBillProducts = this.display.currentBillProducts.find(currentBillProduct => currentBillProduct.billProductProductID == product.productID)
          if (billProductAlreadyOnCurrentBillProducts){
            billProductAlreadyOnCurrentBillProducts.billProductAmount = billProductAlreadyOnCurrentBillProducts.billProductAmount + 1;
            billProductAlreadyOnCurrentBillProducts.billProductTotal = productPrice * billProductAlreadyOnCurrentBillProducts.billProductAmount;
          } else {
            this.display.currentBillProducts.push({
              'billProductSelectedAction': false,
              'billProductProductID': product.productID,
              'billProductName': product.productName,
              'billProductPrice': productPrice,
              'billProductWithoutTax': billProductPriceWithoutTax,
              'billProductTaxMargin': product.productTaxMargin,
              'billProductMaxDiscountMargin': product.productMaxDiscountMargin,
              'billProductTax': billProductTax,
              'billProductDiscountMargin': 0,
              'billProductDiscount': 0,
              'billProductSubtotal': productPrice,
              'billProductAmount': 1,
              'billProductLocalityStockAmount': product.productLocalityStockAmount,
              'billProductTotal': productPrice
            });
          }
          this.input.searchedProductIDOrName = null;
          this.input.matchedProductIDOrName = null;
          this.calculateBillResult();
          this.focusOnInput('productIDOrNameInputReference');
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': null,
            'notificationDialogBody': 'No se ha encontrado el producto seleccionado, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque un código para el producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
      }
    },

    focusOnInput(inputReference){
      const blurInputInterval = setInterval(() => {
        if (this.$refs[inputReference]) {
          this.$refs[inputReference].blur();
          const focusInputInterval = setInterval(() => {
            if (this.$refs[inputReference]) {
              this.$refs[inputReference].focus();
              clearInterval(focusInputInterval);
            }
          }, 1);
          clearInterval(blurInputInterval);
        }
      }, 1);
    },

    updateBillProduct(){
      this.input.updatingBillProduct.forEach(updatingBillProduct => {
        updatingBillProduct.billProductTax = updatingBillProduct.billProductPrice * (updatingBillProduct.billProductTaxMargin / 100);
        updatingBillProduct.billProductDiscount = (updatingBillProduct.billProductPrice) * (updatingBillProduct.billProductDiscountMargin / 100);
        updatingBillProduct.billProductSubtotal = updatingBillProduct.billProductPrice - updatingBillProduct.billProductDiscount;
        updatingBillProduct.billProductTotal = updatingBillProduct.billProductSubtotal * updatingBillProduct.billProductAmount;
      });
      if (this.display.updatingBillProductMultipleSelected){
        this.display.currentBillProducts.forEach(currentBillProduct => {
          currentBillProduct.billProductSelectedAction = false;
        });
      }
    },

    openUpdateBillProductPriceDialog(billProduct){
      if (this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction).find(currentBillProduct => currentBillProduct.billProductProductID == billProduct.billProductProductID)){
        this.input.updatingBillProduct = this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction);
        if (this.input.updatingBillProduct.every(currentUpdatingBillProduct => currentUpdatingBillProduct.billProductPrice == this.input.updatingBillProduct[0].billProductPrice)){
          this.input.updatingBillProductPrice = this.input.updatingBillProduct[0].billProductPrice;
        } else {
          this.input.updatingBillProductPrice = 0;
        }
        this.display.updatingBillProductMultipleSelected = true;
      } else {
        this.input.updatingBillProduct = [billProduct];
        this.input.updatingBillProductPrice = billProduct.billProductPrice;
        this.display.updatingBillProductMultipleSelected = false;
      }
      this.focusOnInput('updatingBillProductPriceInputReference');
      this.navigation.updateBillProductPriceDialogIsVisible = true;
    },

    updateBillProductPrice(){
      this.input.updatingBillProductPrice = parseFloat(this.input.updatingBillProductPrice);
      this.input.updatingBillProduct.forEach(updatingBillProduct => {
        updatingBillProduct.billProductPrice = this.input.updatingBillProductPrice;
      });
      this.updateBillProduct();
      this.calculateBillResult();
      this.navigation.updateBillProductPriceDialogIsVisible = false;
    },

    openUpdateBillProductTaxMarginDialog(billProduct){
      if (this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction).find(currentBillProduct => currentBillProduct.billProductProductID == billProduct.billProductProductID)){
        this.input.updatingBillProduct = this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction);
        if (this.input.updatingBillProduct.every(currentUpdatingBillProduct => currentUpdatingBillProduct.billProductTaxMargin == this.input.updatingBillProduct[0].billProductTaxMargin)){
          this.input.updatingBillProductTaxMargin = this.input.updatingBillProduct[0].billProductTaxMargin;
        } else {
          this.input.updatingBillProductTaxMargin = 0;
        }
        this.display.updatingBillProductMultipleSelected = true;
      } else {
        this.input.updatingBillProduct = [billProduct];
        this.input.updatingBillProductTaxMargin = billProduct.billProductTaxMargin;
        this.display.updatingBillProductMultipleSelected = false;
      }
      this.focusOnInput('updatingBillProductTaxMarginInputReference');
      this.navigation.updateBillProductTaxMarginDialogIsVisible = true;      
    },

    updateBillProductTaxMargin(){
      this.input.updatingBillProductTaxMargin = parseFloat(this.input.updatingBillProductTaxMargin);
      this.input.updatingBillProduct.forEach(updatingBillProduct => {
        updatingBillProduct.billProductTaxMargin = this.input.updatingBillProductTaxMargin;
      });
      this.updateBillProduct();
      this.calculateBillResult();
      this.navigation.updateBillProductTaxMarginDialogIsVisible = false;
    },

    openUpdateBillProductDiscountMarginDialog(billProduct){
      if (this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction).find(currentBillProduct => currentBillProduct.billProductProductID == billProduct.billProductProductID)){
        this.input.updatingBillProduct = this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction);
        if (this.input.updatingBillProduct.every(currentUpdatingBillProduct => currentUpdatingBillProduct.billProductDiscountMargin == this.input.updatingBillProduct[0].billProductDiscountMargin)){
          this.input.updatingBillProductDiscountMargin = this.input.updatingBillProduct[0].billProductDiscountMargin;
        } else {
          this.input.updatingBillProductDiscountMargin = 0;
        }
        this.display.updatingBillProductMultipleSelected = true;
      } else {
        this.input.updatingBillProduct = [billProduct];
        this.input.updatingBillProductDiscountMargin = billProduct.billProductDiscountMargin;
        this.display.updatingBillProductMultipleSelected = false;
      }
      this.focusOnInput('updatingBillProductDiscountMarginInputReference');
      this.navigation.updateBillProductDiscountMarginDialogIsVisible = true;
    },

    updateBillProductDiscountMargin(){
      this.input.updatingBillProductDiscountMargin = parseFloat(this.input.updatingBillProductDiscountMargin);
      this.input.updatingBillProduct.forEach(updatingBillProduct => {
        updatingBillProduct.billProductDiscountMargin = this.input.updatingBillProductDiscountMargin;
      });
      this.updateBillProduct();
      this.calculateBillResult();
      this.navigation.updateBillProductDiscountMarginDialogIsVisible = false;
    },

    openUpdateBillProductAmountDialog(billProduct){
      if (this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction).find(currentBillProduct => currentBillProduct.billProductProductID == billProduct.billProductProductID)){
        this.input.updatingBillProduct = this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction);
        if (this.input.updatingBillProduct.every(currentUpdatingBillProduct => currentUpdatingBillProduct.billProductAmount == this.input.updatingBillProduct[0].billProductAmount)){
          this.input.updatingBillProductAmount = this.input.updatingBillProduct[0].billProductAmount;
        } else {
          this.input.updatingBillProductAmount = 0;
        }
        this.display.updatingBillProductMultipleSelected = true;
      } else {
        this.input.updatingBillProduct = [billProduct];
        this.input.updatingBillProductAmount = billProduct.billProductAmount;
        this.display.updatingBillProductMultipleSelected = false;
      }
      this.focusOnInput('updatingBillProductAmountInputReference');
      this.navigation.updateBillProductAmountDialogIsVisible = true;
    },

    updateBillProductAmount(){
      this.input.updatingBillProduct.forEach(updatingBillProduct => {
        updatingBillProduct.billProductAmount = parseInt(this.input.updatingBillProductAmount);
      });
      this.updateBillProduct();
      this.calculateBillResult();
      this.navigation.updateBillProductAmountDialogIsVisible = false;
    },

    openProductImageDialog(productID, productName){
      const productImageDialogRequestQuery = 
      {
        'productImageProductID': productID,
        'productName': productName
      };
      this.$root.productImageDialog.openProductImageDialog(productImageDialogRequestQuery);
    },

    deleteBillProduct(billProductProductID){
      if (this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction).find(currentBillProduct => currentBillProduct.billProductProductID == billProductProductID)){
        this.display.currentBillProducts = this.display.currentBillProducts.filter(currentBillProduct => !currentBillProduct.billProductSelectedAction);
      } else {
        this.display.currentBillProducts = this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductProductID != billProductProductID);
      }
      this.calculateBillResult();
    },

    calculateBillResult(){
      this.display.billUnitAmount = this.display.currentBillProducts.reduce((billUnitAmount, currentBillProduct) => billUnitAmount + currentBillProduct.billProductAmount, 0);
      this.display.billSubtotal = this.display.currentBillProducts.reduce((billSubtotalAmount, currentBillProduct) => billSubtotalAmount + currentBillProduct.billProductPrice * currentBillProduct.billProductAmount, 0);
      this.display.billTaxAmount = this.input.proformaPaymentType == 'Efectivo' ? 0 : (this.display.billSubtotal * 0.13);
      this.display.billDiscountAmount = this.display.currentBillProducts.reduce((billDiscountAmount, currentBillProduct) => billDiscountAmount + currentBillProduct.billProductDiscount * currentBillProduct.billProductAmount, 0);
      this.display.billTotal = (this.display.billSubtotal + this.display.billTaxAmount - this.display.billDiscountAmount) + (this.input.proformaShippingAmount ? parseInt(this.input.proformaShippingAmount) : 0);
    },
    
    selectAllBillProduct(){
      this.input.allBillProductSelected = !this.input.allBillProductSelected;
      this.display.currentBillProducts.forEach(currentBillProduct => {
        currentBillProduct.billProductSelectedAction = this.input.allBillProductSelected;
      });    
    },

    async setDefaultValues(){
      this.input.proformaContactID = null;
      this.input.proformaCredit = null;
      this.input.proformaType = null;
      this.input.proformaCurrency = '₡';
      this.input.proformaPaymentType = null;
      this.input.proformaMixedPaymentCashAmount = 0;
      this.input.proformaMixedPaymentCardAmount = 0;
      this.input.proformaMixedPaymentSINPEAmount = 0;
      this.input.proformaMixedPaymentDepositAmount = 0;
      this.input.proformaNote = null;
      this.input.proformaShippingAmount = 0;
      this.input.matchedProductIDOrName = null;
      this.input.searchedProductIDOrName = null;
      this.input.allBillProductSelected = false;
      this.input.updatingBillProduct = null;
      this.input.updatingBillProductMultipleSelected = null;
      this.input.updatingBillProductPrice = null;
      this.input.updatingBillProductTaxMargin = null;
      this.input.updatingBillProductDiscountMargin = null;
      this.input.updatingBillProductAmount = null;
      this.loader.selectContactFromInsertProformaDialog = false;
      this.loader.selectProductBasicInformationForBillViewByLocalityID = false;
      this.loader.insertProforma = false;
      this.navigation.insertProformaDialogIsVisible = false;
      this.navigation.contactHasCredit = false;
      this.navigation.updateBillProductPriceDialogIsVisible = false;
      this.navigation.updateBillProductTaxMarginDialogIsVisible = false;
      this.navigation.updateBillProductDiscountMarginDialogIsVisible = false;
      this.navigation.updateBillProductAmountDialogIsVisible = false;
      this.display.contact = null;
      this.display.currentBillProducts.length = 0;
      this.display.billUnitAmount = 0;
      this.display.billSubtotal = 0;
      this.display.billDiscountAmount = 0;
      this.display.billTaxAmount = 0;
      this.display.billTotal = 0;
    },

    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.localStorage.agentName = localStorage.getItem('agentName');
    },

    async openInsertProformaDialog(contactID){
      await this.setDefaultValues();
      await this.getLocalStorageData();
      await this.selectContactFromInsertProformaDialog();
      await this.selectProductBasicInformationForBillViewByLocalityID();
      this.input.proformaContactID = contactID ? parseInt(contactID) : null;
      this.navigation.insertProformaDialogIsVisible = true;
    }
  },

  async mounted(){
  }
}
</script>