<template>
  <div style="margin: 2% 3% 0% 3%;">
    <v-card color="border" elevation="3" style="padding: 20px;">
      <v-expansion-panels v-model="navigation.searchVisible">
        <v-expansion-panel value="searchVisible">
          <v-expansion-panel-title>
            <p style="font-size: large;">Búsqueda de ventas para reposición</p>
            <template v-slot:actions>
              <v-icon icon="mdi-magnify"></v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <br>
            <div v-if="accessCredential['/productDailySale/selectLocality']">
              <v-select 
                v-model="input.selectedLocalityIDS" 
                :items="display.localityOptions" 
                :loading="loader.selectLocalityIDAndLocalityName" 
                multiple 
                chips 
                item-title="localityName" 
                item-value="localityID" 
                append-inner-icon="mdi-store" 
                label="Localidad" 
                variant="solo-filled" 
                density="compact"
              ></v-select>
            </div>
            <v-text-field 
              v-model="display.startDateFormatted" 
              @click="navigation.startDatePickerIsVisible = true" readonly label="Fecha de consulta (inicial)" append-inner-icon="mdi-calendar" variant="solo-filled" density="compact"></v-text-field>
            <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
              <v-card style="display: flex;">
                <v-date-picker 
                  v-model="input.startDate"
                  :min="getMinimumDate()"
                  :max="new Date()"
                  hide-header 
                  color="primary" 
                  width="100%">
                </v-date-picker>
                <v-chip @click="input.startDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
              </v-card>
            </v-dialog>
            <v-text-field v-if="accessCredential['/productDailySale/selectEndDate']" v-model="display.endDateFormatted" @click="navigation.endDatePickerIsVisible = true" readonly label="Fecha de consulta (final)" append-inner-icon="mdi-calendar" variant="solo-filled" density="compact"></v-text-field>
            <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
              <v-card>
                <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
                <v-chip @click="input.endDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
              </v-card>
            </v-dialog>
            <br>
            <div v-if="loader.selectBillProductOnProductDailySaleView" style="text-align: center;">
              <v-progress-circular color="primary" indeterminate></v-progress-circular>
              <br><br>
            </div>
            <div v-else>
              <v-btn @click="selectBillProductOnProductDailySaleView()" :disabled="loader.selectBillProductOnProductDailySaleView" dark block height="38" color="primary">
                <h3>BUSCAR</h3>
                <v-icon style="margin-left: 20px;" dark right>mdi-magnify</v-icon>
              </v-btn>
              <br>
              <v-btn @click="setDefaultValues()" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive || loader.selectAgentByAgentLocalityIDAndByAgentIsActive || loader.selectProductBasicBasicInformationForBillsView || loader.selectProductBasicBasicInformationForBillsView" dark block height="38" color="#b3b3b3">
                <h3>LIMPIAR BÚSQUEDA</h3>
                <v-icon style="margin-left: 20px;" dark right>mdi-backspace</v-icon>
              </v-btn>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <div v-if="display.billProductRows.length != 0">
      <br><br>
      <v-card color="border" elevation="3" style="padding: 20px;">
        <br>
        <div v-if="loader.generateReportOnProductDailySaleView" style="text-align: center;">
          <v-progress-circular color="success" indeterminate></v-progress-circular>
          <br><br>
        </div>
        <div v-else>
          <v-btn @click="generateReportOnProductDailySaleView()" dark block height="38" color="success">
            <h3>GENERAR REPORTE</h3>
            <v-icon style="margin-left: 20px;" dark right>mdi-printer</v-icon>
          </v-btn>
          <br><br>
          <v-data-table :headers="display.billProductHeaders" :items="display.billProductRows" :items-per-page="-1" hide-default-footer style="max-height: 700px; overflow-y: auto;">
            <template v-slot:item="row">
              <tr :style="row.item.productIsReplacedDaily ? 'background-color: #9fc99d' : 'background-color: #b3b3b3'">
                <td>
                  <v-checkbox v-model="row.item.productSelected" color="info" hide-details></v-checkbox>
                </td>
                <td>
                  {{ row.item.productLocation }}
                </td>
                <td>
                  <p>{{ row.item.productName }}</p>
                  <p><strong>{{ row.item.productSKU }}</strong></p>
                </td>
                <td>
                  ₡ {{ row.item.productPrice.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}
                </td>
                <td>
                  <div style="display: flex">
                    <div v-for="productSaleByLocality in row.item.productSaleByLocalities" v-bind:key="productSaleByLocality">
                      <v-tooltip :text="productSaleByLocality.localityName" location="top">
                        <template v-slot:activator="{ props }">
                          <v-chip v-bind="props" :color="productSaleByLocality.localityColor" variant="flat" style="margin: 0px 5px 0px 5px; cursor: pointer;">
                            <p :style="{color: productSaleByLocality.localityFontColor}">{{ productSaleByLocality.productSaleByLocalityAmount }}</p>
                          </v-chip>
                        </template>
                      </v-tooltip>
                    </div>
                    <div v-if="Object.keys(row.item.productSaleByLocalities).length > 1">
                      <v-tooltip text="Total" location="top">
                        <template v-slot:activator="{ props }">
                          <v-chip v-bind="props" color="black" variant="flat" style="margin: 0px 5px 0px 5px; cursor: pointer;">
                            <p style="color: white">{{ row.item.productTotalSaleAmount }}</p>
                          </v-chip>
                        </template>
                      </v-tooltip>
                    </div>
                  </div>
                </td>
                <td>
                  <div style="display: flex">
                    <div v-for="productStockByLocality in row.item.productStockByLocalities" v-bind:key="productStockByLocality">
                      <v-tooltip :text="productStockByLocality.localityName" location="top">
                        <template v-slot:activator="{ props }">
                          <v-chip v-bind="props" :color="productStockByLocality.localityColor" variant="flat" style="margin: 0px 5px 0px 5px; cursor: pointer;">
                            <p :style="{color: productStockByLocality.localityFontColor}">{{ productStockByLocality.productStockAmount }}</p>
                          </v-chip>
                        </template>
                      </v-tooltip>
                    </div>
                    <div v-if="row.item.productStockByLocalities">
                      <div v-if="row.item.productStockByLocalities.length > 1">
                        <v-tooltip text="Total" location="top">
                          <template v-slot:activator="{ props }">
                            <v-chip v-bind="props" color="black" variant="flat" style="margin: 0px 5px 0px 5px; cursor: pointer;">
                              <p style="color: white">{{ row.item.productTotalStockAmount }}</p>
                            </v-chip>
                          </template>
                        </v-tooltip>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
            <template #bottom></template>
          </v-data-table>
        </div>
      </v-card>
      <br><br>
    </div>
  </div>
</template>

<style scoped>
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProductDailySaleView',

  inject: ['$generalFunctions'],

  data: () => ({
    input: 
    {
      selectedLocalityIDS: [],
      startDate: null,
      endDate: null
    },

    display: 
    {
      localityOptions: [],
      startDateFormatted: null,
      endDateFormatted: null,
      billProductRows: [],
      billProductHeaders: [],
      PDFDocumentFile: null,
      PDFDocumentName: null,
      excelDocumentFile: null,
      excelDocumentName: null
    },

    navigation: 
    {
      startDatePickerIsVisible: false,
      endDatePickerIsVisible: false,
      searchVisible: 'searchVisible'
    },

    loader:
    {
      selectLocalityIDAndLocalityName: false,
      selectBillProductOnProductDailySaleView: false,
      generateReportOnProductDailySaleView: false
    },

    localStorage:
    {
      localityID: null,
      agentID: null
    },

    accessCredential: {}
  }),

  watch: {
    'input.startDate': async function() {
      this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB');
      this.navigation.startDatePickerIsVisible = false;
      this.display.billProductRows.length = 0;
    },
    'input.endDate': async function() {
      this.display.endDateFormatted = new Date(this.input.endDate).toLocaleDateString('en-GB');
      this.navigation.endDatePickerIsVisible = false;
      this.display.billProductRows.length = 0;
    },
  },

  methods: {
    async generateReportOnProductDailySaleView(){
      this.loader.generateReportOnProductDailySaleView = true;
      var products = JSON.parse(JSON.stringify(this.display.billProductRows));
      products = products.filter(productRow => productRow.productSelected);
      const selectedLocalityNames = [];
      for (var selectedLocalityIndex in this.input.selectedLocalityIDS){
        const selectedLocalityID = this.input.selectedLocalityIDS[selectedLocalityIndex];
        const selectedLocalityName = this.display.localityOptions.find(localityOption => localityOption.localityID == selectedLocalityID).localityName;
        selectedLocalityNames.push(selectedLocalityName);
      }
      const generateReportOnProductDailySaleViewRequestQuery = 
      {
        'localityID': this.localStorage.localityID,
        'agentID': this.localStorage.agentID,
        'startDate': this.input.startDate,
        'endDate': this.accessCredential['/productDailySale/selectEndDate'] ? this.input.endDate : null,
        'products': products,
        'selectedLocalityNames': selectedLocalityNames
      };
      const generateReportOnProductDailySaleViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/billProduct/functions/generateReportOnProductDailySaleView', generateReportOnProductDailySaleViewRequestQuery);
      if (generateReportOnProductDailySaleViewResult.success){
        if (generateReportOnProductDailySaleViewResult.result.PDFDocument.success){
          this.display.PDFDocumentFile = generateReportOnProductDailySaleViewResult.result.PDFDocument.result.documentFile.data;
          this.display.PDFDocumentName = generateReportOnProductDailySaleViewResult.result.PDFDocument.result.documentName;
        } else {
          this.display.PDFDocumentFile = null;
          this.display.PDFDocumentName = null;
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el documento en PDF, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
        if (generateReportOnProductDailySaleViewResult.result.excelDocument.success){
          this.display.excelDocumentFile = generateReportOnProductDailySaleViewResult.result.excelDocument.result.documentFile.data;
          this.display.excelDocumentName = generateReportOnProductDailySaleViewResult.result.excelDocument.result.documentName;
        } else {
          this.display.excelDocumentFile = null;
          this.display.excelDocumentName = null;
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el documento en Excel, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': this.display.PDFDocumentFile,
          'printablePDFDocumentName': this.display.PDFDocumentName,
          'printableExcelDocumentFile': this.display.excelDocumentFile,
          'printableExcelDocumentName': this.display.excelDocumentName
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
      }
      this.loader.generateReportOnProductDailySaleView = false;
    },

    async selectLocalityIDAndLocalityName(){
      this.loader.selectLocalityIDAndLocalityName = true;
      const selectLocalityIDAndLocalityNameResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityName');
      if (selectLocalityIDAndLocalityNameResult.success){
        this.display.localityOptions = selectLocalityIDAndLocalityNameResult.result;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectLocalityIDAndLocalityName = false;
    },

    async selectBillProductOnProductDailySaleView(){
      this.loader.selectBillProductOnProductDailySaleView = true;
      this.display.billProductRows.length = 0;
      const selectBillProductOnProductDailySaleViewRequestQuery = 
      {
        'localityIDS': this.accessCredential['/productDailySale/selectLocality'] ? Object.values(this.input.selectedLocalityIDS) : [this.localStorage.localityID],
        'startDate': this.input.startDate,
        'endDate': this.accessCredential['/productDailySale/selectEndDate'] ? this.input.endDate : null
      };
      const selectBillProductOnProductDailySaleViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/billProduct/functions/selectBillProductOnProductDailySaleView', selectBillProductOnProductDailySaleViewRequestQuery);
      if (selectBillProductOnProductDailySaleViewResult.success){
        if (selectBillProductOnProductDailySaleViewResult.result.length > 0){
          this.navigation.searchVisible = '';
          this.display.billProductRows = selectBillProductOnProductDailySaleViewResult.result.map(billProductRow => ({
            ...billProductRow,
            'productTotalStockAmount': billProductRow.productStockByLocalities ? billProductRow.productStockByLocalities.reduce((productStockTotal, productStockByLocality) => productStockTotal + productStockByLocality.productStockAmount, 0) : null,
            'productSelected': billProductRow.productIsReplacedDaily == 1
          }));
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ATENCIÓN',
            'notificationDialogBody': 'No se ha encontrado ningún producto vendido con los filtros seleccionados, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los productos vendidos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectBillProductOnProductDailySaleView = false;
    },

    getMinimumDate(){
      var offsetDays = 0;
      this.accessCredential['/productDailySale/maxOffsetDays'] ? offsetDays = (this.accessCredential['/productDailySale/maxOffsetDays'] + 1) : offsetDays = 2;
      const minimumDate = new Date();
      minimumDate.setDate(minimumDate.getDate() - offsetDays);
      return minimumDate;
    },

    setDefaultValues(){
      this.input.selectedLocalityIDS.length = 0;
      this.input.startDate = new Date();
      this.input.endDate = new Date();
      this.display.billProductRows.length = 0;
      this.navigation.startDatePickerIsVisible = false;
      this.loader.selectBillProductOnProductDailySaleView = false;
    },
    
    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    },

    prepareBasedOnAccessCredential(){
      this.display.billProductHeaders = [
        {key: 'productSelected', title: ''},
        {key: 'productLocation', title: 'UBICACIÓN EN BODEGA'},
        {key: 'productName', title: 'NOMBRE'},
        {key: 'productPrice', title: 'PRECIO'},
        {key: 'productSaleByLocalities', title: 'CANTIDAD VENDIDA'},
        ...(this.accessCredential['/productDailySale/showStock'] ? [{key: 'productStockByLocalities', title: 'STOCK'}] : [])
      ];
    }
  },
  
  async mounted(){
    this.setDefaultValues();
    await this.getLocalStorageData();
    this.prepareBasedOnAccessCredential();
    await this.selectLocalityIDAndLocalityName();
  }
});
</script>
