<template>
  <div style="margin: 2% 3% 0% 3%;">
    <v-card color="border" elevation="3" style="padding: 20px;">
      <div style="display: flex;">
        <div :style="display.productImage ? 'width: 80%' : 'width: 100%'">
          <div style="display: flex;">
            <div style="width: 49.5%; margin-right: 0.5%;">
              <v-autocomplete v-if="accessCredential['/productHistory/selectLocality']" v-model="input.selectedLocalityID" :items="display.localityOptions" :loading="loader.selectLocalityIDAndLocalityName" :disabled="loader.selectLocalityIDAndLocalityName" item-value="localityID" item-title="localityName" append-inner-icon="mdi-store" label="Localidad" variant="solo-filled" density="compact"></v-autocomplete>
              <v-text-field v-if="accessCredential['/productHistory/selectStartDate']" v-model="display.startDateFormatted" @click="navigation.startDatePickerIsVisible = true" readonly label="Filtrar por fecha inicial" append-inner-icon="mdi-calendar" variant="solo-filled" density="compact"></v-text-field>
              <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
                <v-card style="display: flex;">
                  <v-date-picker v-model="input.startDate" hide-header color="primary" width="100%"></v-date-picker>
                  <v-chip @click="input.startDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
                </v-card>
              </v-dialog>
            </div>
            <div style="width: 49.5%; margin-left: 0.5%;">
              <v-autocomplete 
                @click="clearSelectProductIDInput()"
                @keydown.enter.prevent="selectProductID()" 
                v-model:search="input.searchedProductID"
                v-model="input.selectedProductID" 
                :items="display.productOptions" 
                :loading="loader.selectProductBasicBasicInformationForBillsView" 
                :disabled="loader.selectProductBasicBasicInformationForBillsView" 
                ref="selectedProductIDInput"
                item-title="productAutocompleteTitle" 
                item-value="productID" 
                append-inner-icon="mdi-magnify" 
                label="Código o nombre del producto" 
                variant="solo-filled" 
                density="compact"
              ></v-autocomplete>
              <v-text-field v-if="accessCredential['/productHistory/selectEndDate']" v-model="display.endDateFormatted" @click="navigation.endDatePickerIsVisible = true" readonly label="Filtrar por fecha final" append-inner-icon="mdi-calendar" variant="solo-filled" density="compact"></v-text-field>
              <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
                <v-card>
                  <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
                  <v-chip @click="input.endDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;"  color="primary" variant="flat">Hoy</v-chip>
                </v-card>
              </v-dialog>
            </div>
          </div>
          <br>
          <div v-if="loader.selectProductHistory" style="text-align: center;">
            <v-progress-circular color="primary" indeterminate></v-progress-circular>
            <br><br>
          </div>
          <div v-else>
            <v-btn @click="selectProductHistory()" :disabled="loader.selectProductHistory || loader.selectLocalityIDAndLocalityName || loader.selectProductBasicBasicInformationForBillsView" dark block height="38" color="primary">
              <h3>BUSCAR</h3>
              <v-icon style="margin-left: 20px;" dark right>mdi-magnify</v-icon>
            </v-btn>
            <br>
            <v-btn @click="setDefaultValues(true)" :disabled="loader.selectProductHistory || loader.selectLocalityIDAndLocalityName || loader.selectProductBasicBasicInformationForBillsView" dark block height="38" color="#b3b3b3">
              <h3>LIMPIAR BÚSQUEDA</h3>
              <v-icon style="margin-left: 20px;" dark right>mdi-backspace</v-icon>
            </v-btn>
          </div>
        </div>
        <div v-if="display.productImage" style="width: 18%; margin-left: 2%; display: flex; justify-content: center; align-items: center;">
          <img :src="`data:image/jpeg;base64,${display.productImage}`" style="width: 70%; border-radius: 30px;">
        </div>
        <v-chip v-if="display.productHistory && accessCredential['/product']" color="warning" label style="margin-right: 20px; font-size: larger; cursor: pointer;">
          <v-icon @click="$generalFunctions.default.methods.navigateTo('/product/'+input.selectedProductID)" icon="mdi-bulletin-board"></v-icon>
        </v-chip>
      </div>
    </v-card>
    <br><br>
    <v-card color="border" elevation="3" style="padding: 20px;" v-if="display.productHistoryRows.length > 0">
      <div style="text-align: center; width: 100%; display: flex; justify-content: center; overflow-x: auto; overflow-y: hidden; padding: 20px;">
        <apexchart 
          v-if="display.productHistoryChartOptions && display.productHistoryChartSeries"
          :options="display.productHistoryChartOptions"
          :series="display.productHistoryChartSeries"
          height="300" 
          type="line"
          style="width: 100%;"
        >
        </apexchart>
      </div>
      <br>
      <div style="display: flex;">
        <div style="width: 33.33%; text-align: center;">
          <p style="font-size: larger;"><strong>Stock total: </strong> {{ display.productHistory.productStockAmount }}</p>
        </div>
        <div style="width: 33.33%; text-align: center;">
          <p style="font-size: larger;"><strong>Stock en tránsito: </strong> {{ display.productHistory.productStockTransitAmount }}</p>
        </div>
        <div style="width: 33.33%; text-align: center;">
          <p style="font-size: larger;"><strong>Stock utilizable: </strong> {{ display.productHistory.productStockAmount - display.productHistory.productStockTransitAmount }}</p>
        </div>
      </div>
      <br><br>
      <v-data-table :headers="display.productHistoryHeaders" :items="display.productHistoryRows" :items-per-page="250" style="max-height: 700px; overflow-y: auto;">
        <template v-slot:item.transactionID="{ item }">
          {{ item.billProductBillID || item.productInAndOutProductProductInAndOutID || item.billCancelationProductBillCancelationID || item.productTransferProductProductTransferID || item.productChangeProductProductChangeID}}
        </template>
        <template v-slot:item.transactionType="{ item }">
          <p v-if="item.billProductBillID">
            Venta de producto {{ item.proformaID ? 'al por mayor' : '' }}
          </p>
          <p v-if="item.productInAndOutProductProductInAndOutID">{{ item.productInAndOutType }} de producto</p>
          <p v-if="item.billCancelationProductBillCancelationID">Cancelación de factura</p>
          <p v-if="item.productTransferProductProductTransferID">Traslado de producto</p>
          <p v-if="item.productChangeProductProductChangeID">Cambio de producto {{ item.billLocalityName == null ? '(sin factura)' : '(con factura)' }}</p>
        </template>
        <template v-slot:item.transactionInformation="{ item }">
          <div style="width: 400px;">
            <div v-if="item.billProductBillID" style="padding: 10px;">
              <div style="display: flex;">
                <div style="width: 40%;">
                  <p style="position: relative; top: 5px; font-weight: bold;">Facturado en:</p>
                </div>
                <div style="width: 60%; margin-left: 20px;">
                  <v-chip :color="item.localityColor" variant="flat">
                    <p :style="{color: item.localityFontColor}">{{ item.localityName }}</p>
                  </v-chip>
                </div>
              </div>
              <div style="display: flex; margin-top: 10px;">
                <div style="width: 40%;">
                  <p style="position: relative; top: 5px; font-weight: bold;">Facturado por:</p>
                </div>
                <div style="width: 60%; margin-left: 20px;">
                  <v-chip :color="item.agentColor" variant="flat">
                    <p :style="{color: item.agentFontColor}">{{ item.agentName }}</p>
                  </v-chip>
                </div>
              </div>
            </div>
            <div v-if="item.productInAndOutProductProductInAndOutID" style="padding: 10px;">
              <div style="display: flex;">
                <div style="width: 40%;">
                  <p style="position: relative; top: 5px; font-weight: bold;">Generado en:</p>
                </div>
                <div style="width: 60%; margin-left: 20px;">
                  <v-chip :color="item.generatedLocalityColor" variant="flat">
                    <p :style="{color: item.generatedLocalityFontColor}">{{ item.generatedLocalityName }}</p>
                  </v-chip>
                </div>
              </div>
              <div style="display: flex; margin-top: 10px;">
                <div style="width: 40%;">
                  <p style="position: relative; top: 5px; font-weight: bold;">Generado por:</p>
                </div>
                <div style="width: 60%; margin-left: 20px;">
                  <v-chip :color="item.agentColor" variant="flat">
                    <p :style="{color: item.agentFontColor}">{{ item.agentName }}</p>
                  </v-chip>
                </div>
              </div>
            </div>
            <div v-if="item.billCancelationBillID" style="padding: 10px;">
              <div style="display: flex;">
                <div style="width: 40%;">
                  <p style="position: relative; top: 5px; font-weight: bold;">Facturado en:</p>
                </div>
                <div style="width: 60%; margin-left: 20px;">
                  <v-chip :color="item.billerLocalityColor" variant="flat">
                    <p :style="{color: item.billerLocalityFontColor}">{{ item.billerLocalityName }}</p>
                  </v-chip>
                </div>
              </div>
              <div style="display: flex; margin-top: 10px;">
                <div style="width: 40%;">
                  <p style="position: relative; top: 5px; font-weight: bold;">Facturado por:</p>
                </div>
                <div style="width: 60%; margin-left: 20px;">
                  <v-chip :color="item.billerAgentColor" variant="flat">
                    <p :style="{color: item.billerAgentFontColor}">{{ item.billerAgentName }}</p>
                  </v-chip>
                </div>
              </div>
              <div style="display: flex; margin-top: 10px;">
                <div style="width: 40%;">
                  <p style="position: relative; top: 5px; font-weight: bold;">Cancelado en:</p>
                </div>
                <div style="width: 60%; margin-left: 20px;">
                  <v-chip :color="item.cancelationLocalityColor" variant="flat">
                    <p :style="{color: item.cancelationLocalityFontColor}">{{ item.cancelationLocalityName }}</p>
                  </v-chip>
                </div>
              </div>
              <div style="display: flex; margin-top: 10px;">
                <div style="width: 40%;">
                  <p style="position: relative; top: 5px; font-weight: bold;">Cancelado por:</p>
                </div>
                <div style="width: 60%; margin-left: 20px;">
                  <v-chip :color="item.cancelationAgentColor" variant="flat">
                    <p :style="{color: item.cancelationAgentFontColor}">{{ item.cancelationAgentName }}</p>
                  </v-chip>
                </div>
              </div>
            </div>
            <div v-if="item.productTransferProductProductTransferID" style="padding: 10px;">
              <div style="display: flex;">
                <div style="width: 40%;">
                  <p style="position: relative; top: 5px; font-weight: bold;">Trasladado desde:</p>
                </div>
                <div style="width: 60%; margin-left: 20px;">
                  <v-chip :color="item.sendLocalityColor" variant="flat">
                    <p :style="{color: item.sendLocalityFontColor}">{{ item.sendLocalityName }}</p>
                  </v-chip>
                </div>
              </div>
              <div style="display: flex; margin-top: 10px;">
                <div style="width: 40%;">
                  <p style="position: relative; top: 5px; font-weight: bold;">Generado por:</p>
                </div>
                <div style="width: 60%; margin-left: 20px;">
                  <v-chip :color="item.sendAgentColor" variant="flat">
                    <p :style="{color: item.sendAgentFontColor}">{{ item.sendAgentName }}</p>
                  </v-chip>
                </div>
              </div>
              <div style="display: flex; margin-top: 10px;">
                <div style="width: 40%;">
                  <p style="position: relative; top: 5px; font-weight: bold;">Trasladado hasta:</p>
                </div>
                <div style="width: 60%; margin-left: 20px;">
                  <v-chip :color="item.receiveLocalityColor" variant="flat">
                    <p :style="{color: item.receiveLocalityFontColor}">{{ item.receiveLocalityName }}</p>
                  </v-chip>
                </div>
              </div>
              <div style="display: flex; margin-top: 10px;">
                <div style="width: 40%;">
                  <p style="position: relative; top: 5px; font-weight: bold;">Recibido por:</p>
                </div>
                <div style="width: 60%; margin-left: 20px;">
                  <v-chip :color="item.receiveAgentColor" variant="flat" >
                    <p :style="{color: item.receiveAgentFontColor}">{{ item.receiveAgentName }}</p>
                  </v-chip>
                </div>
              </div>
            </div>
            <div v-if="item.productChangeProductProductChangeID" style="padding: 10px;">
              <div v-if="item.billLocalityName" style="display: flex;">
                <div style="width: 40%;">
                  <p style="position: relative; top: 5px; font-weight: bold;">Facturado en:</p>
                </div>
                <div style="width: 60%; margin-left: 20px;">
                  <v-chip :color="item.billLocalityColor" variant="flat">
                    <p :style="{color: item.billLocalityFontColor}">{{ item.billLocalityName }}</p>
                  </v-chip>
                </div>
              </div>
              <div v-if="item.billAgentName" style="display: flex; margin-top: 10px;">
                <div style="width: 40%;">
                  <p style="position: relative; top: 5px; font-weight: bold;">Facturado por:</p>
                </div>
                <div style="width: 60%; margin-left: 20px;">
                  <v-chip :color="item.billAgentColor" variant="flat">
                    <p :style="{color: item.billAgentFontColor}">{{ item.billAgentName }}</p>
                  </v-chip>
                </div>
              </div>
              <div v-if="item.billLocalityName" style="display: flex; margin-top: 10px;">
                <div style="width: 40%;">
                  <p style="position: relative; top: 5px; font-weight: bold;">Solicitado por:</p>
                </div>
                <div style="width: 60%; margin-left: 20px;">
                  <v-chip :color="item.requestAgentColor" variant="flat">
                    <p :style="{color: item.requestAgentFontColor}">{{ item.requestAgentName }}</p>
                  </v-chip>
                </div>
              </div>
              <div style="display: flex; margin-top: 10px;">
                <div style="width: 40%;">
                  <p style="position: relative; top: 5px; font-weight: bold;">Aprobado por:</p>
                </div>
                <div style="width: 60%; margin-left: 20px;">
                  <v-chip :color="item.approveAgentColor" variant="flat">
                    <p :style="{color: item.approveAgentFontColor}">{{ item.approveAgentName }}</p>
                  </v-chip>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:item.transactionDatetime="{ item }">
          <div v-if="item.billProductBillID">
            <p>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.transactionDatetime) }}</p>
          </div>
          <div v-if="item.productInAndOutProductProductInAndOutID">
            <p>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.transactionDatetime) }}</p>
          </div>
          <div v-if="item.billCancelationBillID">
            <div style="display: flex;">
              <p style="font-weight: bold; margin-right: 5px;">Facturado el:</p>
              <p>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.billCreationDatetime) }}</p>
            </div>
            <div style="display: flex; margin-top: 10px;">
              <p style="font-weight: bold; margin-right: 5px;">Cancelado el:</p>
              <p>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.transactionDatetime) }}</p>
            </div>
          </div>
          <div v-if="item.productTransferProductProductTransferID">
            <p>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.transactionDatetime) }}</p>
          </div>
          <div v-if="item.productChangeProductProductChangeID">
            <p>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.transactionDatetime) }}</p>
          </div>
        </template>
        <template v-slot:item.currentAmount="{ item }">
          <div v-if="item.productTransferProductProductTransferID">
            <p style="font-size: large;">{{ (accessCredential['/productHistory/selectLocality'] ? this.input.selectedLocalityID : this.localStorage.localityID) == item.sendLocalityID ? item.productTransferProductSendLocalityCurrentAmount : item.productTransferProductReceiveLocalityCurrentAmount }}</p>              
          </div>
          <div v-else>
            <p style="font-size: large;">{{ item.currentAmount }}</p>
          </div>
        </template>
        <template v-slot:item.amount="{ item }">
          <v-chip v-if="item.billProductBillID" color="error" variant="flat">
            <p style="font-size: large;">{{ `- ${item.amount}` }}</p>
          </v-chip>
          <v-chip v-if="item.productInAndOutProductProductInAndOutID" :color="item.productInAndOutType == 'Entrada' ? 'success' : 'error'" variant="flat">
            <p style="font-size: large;">{{ `${item.productInAndOutType == 'Entrada' ? '+' : '-' } ${item.amount}` }}</p>
          </v-chip>
          <v-chip v-if="item.billCancelationBillID" color="success" variant="flat">
            <p style="font-size: large;">{{ `+ ${item.amount}` }}</p>
          </v-chip>
          <v-chip v-if="item.productTransferProductProductTransferID" :color="(accessCredential['/productHistory/selectLocality'] ? this.input.selectedLocalityID : this.localStorage.localityID) == item.sendLocalityID ? 'error' : 'success'" variant="flat">
            <p style="font-size: large;">{{ `${(accessCredential['/productHistory/selectLocality'] ? this.input.selectedLocalityID : this.localStorage.localityID) == item.sendLocalityID ? '-' : '+'} ${item.amount}` }}</p>
          </v-chip>
          <v-chip v-if="item.productChangeProductProductChangeID" color="error" variant="flat">
            <p style="font-size: large;">- 1</p>
          </v-chip>
        </template>
        <template v-slot:item.result="{ item }">
          <p v-if="item.billProductBillID" style="font-size: large;">{{ item.currentAmount - item.amount }}</p>
          <p v-if="item.productInAndOutProductProductInAndOutID" style="font-size: large;">{{ item.productInAndOutType == 'Entrada' ? (item.currentAmount + item.amount) : (item.currentAmount - item.amount) }}</p>
          <p v-if="item.billCancelationBillID" style="font-size: large;">{{ item.currentAmount + item.amount }}</p>
          <p v-if="item.productTransferProductProductTransferID" style="font-size: large;">{{ (accessCredential['/productHistory/selectLocality'] ? this.input.selectedLocalityID : this.localStorage.localityID) == item.sendLocalityID ? item.productTransferProductSendLocalityCurrentAmount - item.amount : item.productTransferProductReceiveLocalityCurrentAmount + item.amount }}</p>
          <p v-if="item.productChangeProductProductChangeID" style="font-size: large;">{{ item.currentAmount - 1 }}</p>
        </template>
        <template v-slot:item.transactionAction="{ item }">
          <div v-if="item.transactionAction" style="text-align: center;">
            <v-progress-circular color="success" indeterminate></v-progress-circular>
          </div>
          <div v-else>
            <div v-if="item.billProductBillID" style="display: flex;">
              <v-icon @click="openBillDialog(item.billProductBillID)" color="info" dark right>mdi-magnify</v-icon>
              <v-icon @click="openPrintableBillDialog(item, item.billProductBillID)" style="margin-left: 10px;" color="success" dark right>mdi-printer</v-icon>
            </div>
            <div v-if="item.billCancelationBillID" style="display: flex;">
              <v-icon @click="openBillDialog(item.billCancelationBillID)" color="info" dark right>mdi-magnify</v-icon>
              <v-icon @click="openPrintableBillDialog(item, item.billCancelationBillID)" style="margin-left: 10px;" color="success" dark right>mdi-printer</v-icon>
            </div>
            <div v-if="item.productInAndOutProductProductInAndOutID" style="display: flex;">
              <v-icon @click="openProductInAndOutDialog(item.productInAndOutProductProductInAndOutID)" color="info" dark right>mdi-magnify</v-icon>
              <v-icon v-if="item.productInAndOutProductProductInAndOutID" @click="generateReportOnProductInAndOutView(item)" style="margin-left: 20px;" color="success" dark right>mdi-printer</v-icon>
            </div>
            <div v-if="item.productTransferProductProductTransferID" style="display: flex;">
              <v-icon @click="openProductTransferDialog(item.productTransferProductProductTransferID)" color="info" dark right>mdi-magnify</v-icon>
              <v-icon @click="createPDFDocumentForProductTransfer(item)" style="margin-left: 10px;" color="success" dark right>mdi-printer</v-icon>
            </div>  
            <div v-if="item.productChangeProductProductChangeID">
              <v-icon @click="openProductChangeDialog(item.productChangeProductProductChangeID)" color="info" dark right>mdi-magnify</v-icon>
              <v-icon @click="generateProductChangeApprovePDFDocument(item)" style="margin-left: 10px;" color="success" dark right>mdi-printer</v-icon>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <br><br>
  </div>
</template>

<style scoped>
</style>


<script>
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'ProductHistoryView',
  inject: ['$generalFunctions'],

  data: () => ({
    input: 
    {
      startDate: null,
      endDate: null,
      selectedLocalityID: null,
      selectedProductID: null,
      searchedProductID: null
    },

    display: 
    {
      startDateFormatted: null,
      endDateFormatted: null,
      productOptions: [],
      productHistory: null,
      productImage: null,
      productHistoryChartOptions: null,
      productHistoryChartSeries: null,
      productHistoryRows: [],
      productHistoryHeaders: 
      [
        {key: 'transactionID', title: 'ID DE LA TRANSACCIÓN'},
        {key: 'transactionType', title: 'TIPO DE TRANSACCIÓN'},
        {key: 'transactionInformation', title: 'INFORMACIÓN'},
        {key: 'transactionDatetime', title: 'FECHA Y HORA'},
        {key: 'currentAmount', title: 'CANTIDAD PREVIA'},
        {key: 'amount', title: 'CANTIDAD MODIFICADA'},
        {key: 'result', title: 'CANTIDAD RESULTANTE'},
        {key: 'transactionAction', title: 'REPORTE'}
      ]
    },

    navigation: 
    {
      startDatePickerIsVisible: false,
      endDatePickerIsVisible: false
    },

    loader:
    {
      selectLocalityIDAndLocalityName: false,
      selectProductBasicBasicInformationForBillsView: false,
      selectProductHistory: false
    },

    localStorage:
    {
      localityID: null,
      agentID: null
    },

    accessCredential: {}
  }),

  watch: {
    'input.startDate': async function() {
      this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB');
      this.navigation.startDatePickerIsVisible = false;
    },
    'input.endDate': async function() {
      this.display.endDateFormatted = new Date(this.input.endDate).toLocaleDateString('en-GB');
      this.navigation.endDatePickerIsVisible = false;
    }
  },

  methods: {
    async generateProductChangeApprovePDFDocument(productChange){
      productChange.transactionAction = true;
      const generateProductChangeApprovePDFDocumentRequestQuery =
      {
        'productChangeID': productChange.productChangeProductProductChangeID,
        'reprint': true
      };
      const generateProductChangeApprovePDFDocumentResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productChange/functions/generateProductChangeApprovePDFDocument', generateProductChangeApprovePDFDocumentRequestQuery);
      if (generateProductChangeApprovePDFDocumentResult.success){
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': generateProductChangeApprovePDFDocumentResult.result.PDFDocumentFile.data,
          'printablePDFDocumentName': generateProductChangeApprovePDFDocumentResult.result.PDFDocumentName
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el reporte del cambios de producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      productChange.transactionAction = false;
    },

    async createPDFDocumentForProductTransfer(productTransfer){
      productTransfer.transactionAction = true;
      const createPDFDocumentForProductTransferRequestQuery =
      {
        'productTransferID': productTransfer.productTransferProductProductTransferID,
        'reprint': true
      };
      const createPDFDocumentForProductTransferResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productTransfer/functions/createPDFDocumentForProductTransfer', createPDFDocumentForProductTransferRequestQuery);
      if (createPDFDocumentForProductTransferResult.success){
        productTransfer.PDFDocumentFile = createPDFDocumentForProductTransferResult.result.documentFile.data;
        productTransfer.PDFDocumentName = createPDFDocumentForProductTransferResult.result.documentName;
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': productTransfer.PDFDocumentFile,
          'printablePDFDocumentName': productTransfer.PDFDocumentName,
          'printableExcelDocumentFile': null,
          'printableExcelDocumentName': null
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el reporte del traslado de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      productTransfer.transactionAction = false;
    },


    async generateReportOnProductInAndOutView(productInAndOut){
      productInAndOut.transactionAction = true;
      const generateReportOnProductInAndOutViewRequestQuery = 
      {
        'productInAndOutID': productInAndOut.productInAndOutProductProductInAndOutID
      };
      const generateReportOnProductInAndOutViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productInAndOut/functions/generateReportOnProductInAndOutView', generateReportOnProductInAndOutViewRequestQuery);
      if (generateReportOnProductInAndOutViewResult.success){
        if (generateReportOnProductInAndOutViewResult.result.PDFDocument.success){
          productInAndOut.PDFDocumentFile = generateReportOnProductInAndOutViewResult.result.PDFDocument.result.documentFile.data;
          productInAndOut.PDFDocumentName = generateReportOnProductInAndOutViewResult.result.PDFDocument.result.documentName;
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el documento en PDF, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
        if (generateReportOnProductInAndOutViewResult.result.excelDocument.success){
          productInAndOut.excelDocumentFile = generateReportOnProductInAndOutViewResult.result.excelDocument.result.documentFile.data;
          productInAndOut.excelDocumentName = generateReportOnProductInAndOutViewResult.result.excelDocument.result.documentName;
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el documento en Excel, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': productInAndOut.PDFDocumentFile,
          'printablePDFDocumentName': productInAndOut.PDFDocumentName,
          'printableExcelDocumentFile': productInAndOut.excelDocumentFile,
          'printableExcelDocumentName': productInAndOut.excelDocumentName
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': `Ha ocurrido un error inesperado al generar el reporte de la ${productInAndOut.productInAndOutType.toLowerCase()} de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema`,
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      productInAndOut.transactionAction = false;
    },

    async openPrintableBillDialog(bill, billID){
      bill.transactionAction = true;
      const createBillPDFDocumentRequestQuery = 
      {
        'billID': billID
      };
      const createBillPDFDocumentResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/functions/createBillPDFDocument', createBillPDFDocumentRequestQuery);
      if (createBillPDFDocumentResult.success){
        this.$root.printableBillDialog.showPrintableBillDialog(createBillPDFDocumentResult.result);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al imprimir la factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      bill.transactionAction = false;
    },


    prepareProductHistoryChart(){
      let productHistoryChartCategories = [];
      let productHistoryData = [];

      const productHistoryRows = JSON.parse(JSON.stringify(this.display.productHistoryRows)).reverse();

      productHistoryRows.forEach(productHistoryRow => {
        if (productHistoryRow.billProductBillID){
          productHistoryChartCategories.push(`Venta de producto${productHistoryRow.proformaID ? ' al por mayor' : ''}`);
          productHistoryData.push(productHistoryRow.currentAmount - productHistoryRow.amount);
        } else if (productHistoryRow.productInAndOutProductProductInAndOutID){
          productHistoryChartCategories.push(`${productHistoryRow.productInAndOutType} de producto`);
          productHistoryData.push(productHistoryRow.productInAndOutType == 'Entrada' ? (productHistoryRow.currentAmount + productHistoryRow.amount) : (productHistoryRow.currentAmount - productHistoryRow.amount));
        } else if (productHistoryRow.billCancelationBillID){
          productHistoryChartCategories.push('Cancelación de factura');
          productHistoryData.push(productHistoryRow.currentAmount + productHistoryRow.amount);
        } else if (productHistoryRow.productTransferProductProductTransferID){
          productHistoryChartCategories.push('Traslado de producto');
          productHistoryData.push((this.accessCredential['/productHistory/selectLocality'] ? this.input.selectedLocalityID : this.localStorage.localityID) == productHistoryRow.sendLocalityID ? productHistoryRow.productTransferProductSendLocalityCurrentAmount - productHistoryRow.amount : productHistoryRow.productTransferProductReceiveLocalityCurrentAmount + productHistoryRow.amount);
        } else if (productHistoryRow.productChangeProductProductChangeID){
          productHistoryChartCategories.push('Cambio de producto');
          productHistoryData.push(productHistoryRow.currentAmount - 1);
        }
      });
      const productHistoryChartOptions = {'chart': {'zoom': {'enabled': false}}, 'markers': {'size': 4}, 'xaxis': {'categories': productHistoryChartCategories}};
      const productHistoryChartSeries = [{'name': '', 'data': productHistoryData}];
      this.display.productHistoryChartOptions = productHistoryChartOptions;
      this.display.productHistoryChartSeries = productHistoryChartSeries;
    },

    async selectProductHistory(){
      if (this.input.selectedProductID){
        this.display.productHistoryRows.length = 0
        this.display.productImage = null;
        this.display.productHistory = null;
        this.loader.selectProductHistory = true;
        const selectProductHistoryRequestQuery = 
        {
          'startDate': this.accessCredential['/productHistory/selectStartDate'] ? this.input.startDate : null,
          'endDate': this.accessCredential['/productHistory/selectEndDate'] ? this.input.endDate : null,
          'productID': this.input.selectedProductID,
          'localityID': this.accessCredential['/productHistory/selectLocality'] ? this.input.selectedLocalityID : this.localStorage.localityID 
        };
        const selectProductHistoryResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductHistory', selectProductHistoryRequestQuery);
        if (selectProductHistoryResult.success){
          if (selectProductHistoryResult.result != null){
            this.display.productImage = selectProductHistoryResult.result.productImage;
            this.display.productHistory = selectProductHistoryResult.result;
            this.display.productHistoryRows = selectProductHistoryResult.result.productHistory.map(productHistoryRow => ({
              ...productHistoryRow,
              'productHistoryAction': false
            }));
            this.prepareProductHistoryChart();
            this.$generalFunctions.default.methods.navigateTo(`/productHistory/${this.input.selectedProductID}`);
            if (this.display.productHistoryRows.length == 0){
              this.$root.notificationDialog.showNotificationDialog({
                'notificationDialogTitle': null,
                'notificationDialogBody': 'No se han encontrado movimientos asociados a este producto en esta localidad',
                'notificationDialogColor': 'warning',
                'notificationDialogIsPersistent': false
              });
            }
          } else {
            this.$root.notificationDialog.showNotificationDialog({
              'notificationDialogTitle': null,
              'notificationDialogBody': 'No se han encontrado movimientos asociados a este producto en esta localidad',
              'notificationDialogColor': 'warning',
              'notificationDialogIsPersistent': false
            });
          }
          this.loader.selectProductHistory = false;
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar el tracking del producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': null,
          'notificationDialogBody': 'Seleccione un producto para consultar el tracking del producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
      }
    },

    selectProductID(){
      const selectedProduct = this.display.productOptions.find(productOption => productOption.productSKU === this.input.searchedProductID);
      if (selectedProduct){
        const selectedProductID = selectedProduct.productID;
        this.input.selectedProductID = selectedProductID;
        this.blurInput('selectedProductIDInput');
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': null,
          'notificationDialogBody': 'El producto escaneado no ha sido encontrado, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
      }
    },
    
    async selectProductBasicBasicInformationForBillsView(){
      this.loader.selectProductBasicBasicInformationForBillsView = true;
      const selectProductBasicBasicInformationForBillsViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductBasicBasicInformationForBillsView');
      if (selectProductBasicBasicInformationForBillsViewResult.success){
        this.display.productOptions = selectProductBasicBasicInformationForBillsViewResult.result.map(productOption => ({
          'productID': productOption.i,
          'productSKU': productOption.s,
          'productName': productOption.n,
          'productAutocompleteTitle': productOption.s + ' - ' + productOption.n
        }));
        this.loader.selectProductBasicBasicInformationForBillsView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectLocalityIDAndLocalityName(){
      this.loader.selectLocalityIDAndLocalityName = true;
      const selectLocalityIDAndLocalityNameResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityName');
      if (selectLocalityIDAndLocalityNameResult.success){
        this.display.localityOptions = selectLocalityIDAndLocalityNameResult.result;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectLocalityIDAndLocalityName = false;
    },

    openBillDialog(billID){
      const billDialogRequestQuery = 
      {
        'billID': billID
      };
      this.$root.billDialog.openBillDialog(billDialogRequestQuery);
    },

    openProductTransferDialog(productTransferID){
      const productTransferDialogRequestQuery =
      {
        'productTransferID': productTransferID
      };
      this.$root.productTransferDialog.openProductTransferDialog(productTransferDialogRequestQuery);
    },

    openProductChangeDialog(productChangeID){
      const productChangeDialogRequestQuery =
      {
        'productChangeID': productChangeID
      };
      this.$root.productChangeDialog.openProductChangeDialog(productChangeDialogRequestQuery);
    },

    openProductInAndOutDialog(productInAndOutID){
      const productInAndOutDialogRequestQuery =
      {
        'productInAndOutID': productInAndOutID
      };
      this.$root.productInAndOutDialog.openProductInAndOutDialog(productInAndOutDialogRequestQuery);
    },

    blurInput(inputReference){
      const blurInputInterval = setInterval(() => {
        if (this.$refs[inputReference]) {
          this.$refs[inputReference].blur();
          clearInterval(blurInputInterval);
        }
      }, 1);
    },

    clearSelectProductIDInput(){
      this.input.selectedProductID = null;
      this.input.searchedProductID = null;
    },

    setDefaultValues(clearURL){
      if (clearURL){
        this.$generalFunctions.default.methods.navigateTo('/productHistory');
      }
      this.input.selectedLocalityID = null;
      this.input.startDate = null;
      this.display.startDateFormatted = null;
      this.input.endDate = null;
      this.display.endDateFormatted = null;
      this.input.selectedProductID = null;
      this.input.searchedProductID = null;
      this.display.productImage = null;
      this.display.productHistory = null;
      this.display.productHistoryRows.length = 0;
      this.loader.selectLocalityIDAndLocalityName = false;
      this.loader.selectProductBasicBasicInformationForBillsView = false;
      this.loader.selectProductHistory = false;
    },
    

    async parseURL(){
      const currentRoute = this.$route.fullPath;
      if (currentRoute.split('/').length == 3){
        const productID = currentRoute.split('/')[currentRoute.split('/').length - 1];
        this.input.selectedProductID = productID;
        this.input.selectedLocalityID = parseInt(this.localStorage.localityID);
        await this.selectProductHistory();
      }
    },

    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    },
  },
  
  async mounted(){
    this.setDefaultValues(false);
    await this.getLocalStorageData();
    await this.selectLocalityIDAndLocalityName();
    await this.selectProductBasicBasicInformationForBillsView();
    await this.parseURL();
  }
});
</script>
