<template>
  <div style="margin: 2% 3% 0% 3%;">
    <v-card color="border" elevation="3" style="padding: 20px;">
      <v-expansion-panels v-model="navigation.searchVisible">
        <v-expansion-panel value="searchVisible">
          <v-expansion-panel-title>
            <p style="font-size: large;">Búsqueda de productos con inventario bajo</p>
            <template v-slot:actions>
              <v-icon icon="mdi-magnify"></v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <br>
            <v-tooltip open-delay="2000" location="top">
              <template v-slot:activator="{ props }">
                <v-autocomplete 
                  v-bind="props"
                  v-model="input.localityID"
                  :append-inner-icon="input.localityID.length == 0 ? 'mdi-store' : 'mdi-store-off'" 
                  @click:append-inner="input.localityID.length = 0"  
                  :items="display.localityOptions" 
                  :loading="loader.selectLocalityIDAndLocalityNameByLocalityIsActive" 
                  :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive" 
                  multiple
                  chips
                  item-title="localityName" 
                  item-value="localityID" 
                  label="Filtrar por localidad" 
                  placeholder="Todas las localidades"
                  variant="solo-filled" 
                  density="compact"
                ></v-autocomplete>
              </template>
              <span style="max-width: 400px; display: block;">Este espacio permite selección múltiple para filtrar por varias localidades en paralelo. Si ninguna localidad es seleccionada, se buscarán todos los productos sin importar la localidad. Al presionar el ícono de la derecha se limpian las localidades seleccionadas</span>
            </v-tooltip>
            <v-tooltip open-delay="2000" location="top">
              <template v-slot:activator="{ props }">
                <v-autocomplete 
                  v-bind="props"
                  v-model="input.productID" 
                  v-model:search="input.searchedProductID"
                  :append-inner-icon="input.productID ? 'mdi-alphabetical-variant-off' : 'mdi-alphabetical-variant'" 
                  @click:append-inner="cleanProductIDInput()" 
                  :items="display.productOptions" 
                  :loading="loader.selectProductBasicBasicInformationForBillsView" 
                  :disabled="loader.selectProductBasicBasicInformationForBillsView" 
                  @keydown.enter.prevent="selectProductByScanner()"
                  ref="selectedProductIDInput" 
                  item-title="productAutocompleteTitle" 
                  item-value="productID" 
                  label="Filtrar por producto" 
                  placeholder="Todos los productos" 
                  variant="solo-filled" 
                  density="compact"
                ></v-autocomplete>
              </template>
              <span style="max-width: 400px; display: block;">Este espacio permite la búsqueda o escaneo de un producto. Si ningún producto es seleccionado, se buscarán todos los productos. Al presionar el ícono de la derecha se limpia el producto seleccionado</span>
            </v-tooltip>
            <div style="display: flex;">
              <div style="width: 49.5%; margin-right: 0.5%;">
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-autocomplete 
                      v-bind="props"
                      v-model="input.productCategoryID" 
                      :append-inner-icon="input.productCategoryID.length == 0 ? 'mdi-cog' : 'mdi-cog-off'" 
                      @click:append-inner="input.productCategoryID.length = 0" 
                      :items="display.productCategoryOptions" 
                      :loading="loader.selectProductSearchParameters" 
                      :disabled="loader.selectProductSearchParameters" 
                      multiple 
                      chips 
                      item-title="productCategoryName" 
                      item-value="productCategoryID" 
                      label="Filtrar por categoría del producto" 
                      placeholder="Todas las categorías del producto" 
                      variant="solo-filled" 
                      density="compact"
                    ></v-autocomplete>
                  </template>
                  <span style="max-width: 400px; display: block;">La categoría del producto hace referencia al primer nivel de categorización de un producto. Este espacio permite selección múltiple para filtrar por varias categorías de producto en paralelo. Si ninguna categoría de producto es seleccionada, se buscarán todos los productos sin importar su categoría. Al presionar el ícono de la derecha se limpian las categorías de producto seleccionadas</span>
                </v-tooltip>
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-autocomplete 
                      v-bind="props"
                      v-model="input.productBrandID" 
                      :append-inner-icon="input.productBrandID.length == 0 ? 'mdi-briefcase-variant' : 'mdi-briefcase-variant-off'" 
                      @click:append-inner="input.productBrandID.length = 0" 
                      :items="display.productBrandOptions" 
                      :loading="loader.selectProductSearchParameters" 
                      :disabled="loader.selectProductSearchParameters" 
                      multiple 
                      chips 
                      item-title="productBrandName" 
                      item-value="productBrandID" 
                      label="Filtrar por marca del producto" 
                      placeholder="Todas las marcas del producto" 
                      variant="solo-filled" 
                      density="compact"
                    ></v-autocomplete>
                  </template>
                  <span style="max-width: 400px; display: block;">La marca del producto hace referencia al segundo nivel de categorización de un producto. Este espacio permite selección múltiple para filtrar por varias marcas de producto en paralelo. Si ninguna marca de producto es seleccionada, se buscarán todos los productos sin importar su marca. Al presionar el ícono de la derecha se limpian las marcas de producto seleccionadas</span>
                </v-tooltip>
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-autocomplete 
                      v-bind="props"
                      v-model="input.productModelID" 
                      :append-inner-icon="input.productModelID.length == 0 ? 'mdi-source-branch' : 'mdi-source-branch-remove'" 
                      @click:append-inner="input.productModelID.length = 0" 
                      :items="display.productModelOptions" 
                      :loading="loader.selectProductSearchParameters" 
                      :disabled="loader.selectProductSearchParameters" 
                      multiple 
                      chips 
                      item-title="productModelName" 
                      item-value="productModelID" 
                      label="Filtrar por modelo del producto" 
                      placeholder="Todos los modelos del producto" 
                      variant="solo-filled" 
                      density="compact"
                    ></v-autocomplete>
                  </template>
                  <span style="max-width: 400px; display: block;">El modelo del producto hace referencia al tercer nivel de categorización de un producto. Este espacio permite selección múltiple para filtrar por varios modelos de producto en paralelo. Si ningún modelo de producto es seleccionado, se buscarán todos los productos sin importar su modelo. Al presionar el ícono de la derecha se limpian los modelos de producto seleccionados</span>
                </v-tooltip>
              </div>
              <div style="width: 49.5%; margin-left: 0.5%;">
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-autocomplete 
                      v-bind="props"
                      v-model="input.productFlavorID" 
                      :append-inner-icon="input.productFlavorID.length == 0 ? 'mdi-water' : 'mdi-water-off'" 
                      @click:append-inner="input.productFlavorID.length = 0" 
                      :items="display.productFlavorOptions" 
                      :loading="loader.selectProductSearchParameters" 
                      :disabled="loader.selectProductSearchParameters" 
                      multiple 
                      chips 
                      item-title="productFlavorName" 
                      item-value="productFlavorID" 
                      label="Filtrar por sabor del producto" 
                      placeholder="Todos los sabores del producto" 
                      variant="solo-filled" 
                      density="compact"
                    ></v-autocomplete>
                  </template>
                  <span style="max-width: 400px; display: block;">El sabor del producto hace referencia al primer tipo de categorización opcional de un producto. Este espacio permite selección múltiple para filtrar por varios sabores de producto en paralelo. Si ningún sabor de producto es seleccionado, se buscarán todos los productos sin importar su sabor. Al presionar el ícono de la derecha se limpian los sabores de producto seleccionados</span>
                </v-tooltip>
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-autocomplete 
                      v-bind="props"
                      v-model="input.productNicotineLevelID" 
                      :append-inner-icon="input.productNicotineLevelID.length == 0 ? 'mdi-cloud-outline' : 'mdi-cloud-off-outline'" 
                      @click:append-inner="input.productNicotineLevelID.length = 0" 
                      :items="display.productNicotineLevelOptions" 
                      :loading="loader.selectProductSearchParameters" 
                      :disabled="loader.selectProductSearchParameters" 
                      multiple 
                      chips 
                      item-title="productNicotineLevelName" 
                      item-value="productNicotineLevelID" 
                      label="Filtrar por graduación de nicotina del producto"  
                      placeholder="Todas las graduaciones de nicotina del producto" 
                      variant="solo-filled" 
                      density="compact"
                    ></v-autocomplete>
                  </template>
                  <span style="max-width: 400px; display: block;">La graduación de nicotina del producto hace referencia al segundo tipo de categorización opcional de un producto. Este espacio permite selección múltiple para filtrar por varias graduaciones de nicotina de producto en paralelo. Si ninguna graduación de nicotina de producto es seleccionada, se buscarán todos los productos sin importar su graduación de nicotina. Al presionar el ícono de la derecha se limpian las graduaciones de nicotina de producto seleccionadas</span>
                </v-tooltip>
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-autocomplete
                      v-bind="props" 
                      v-model="input.productSizeID" 
                      :append-inner-icon="input.productSizeID.length == 0 ? 'mdi-ruler' : 'mdi-pencil-ruler'" 
                      @click:append-inner="input.productSizeID.length = 0" 
                      :items="display.productSizeOptions" 
                      :loading="loader.selectProductSearchParameters" 
                      :disabled="loader.selectProductSearchParameters" 
                      multiple 
                      chips 
                      item-title="productSizeName" 
                      item-value="productSizeID" 
                      label="Filtrar por tamaño del producto" 
                      variant="solo-filled" 
                      placeholder="Todos los tamaños del producto" 
                      density="compact"
                    ></v-autocomplete>
                  </template>
                  <span style="max-width: 400px; display: block;">El tamaño del producto hace referencia al tercer tipo de categorización opcional de un producto. Este espacio permite selección múltiple para filtrar por varios tamaños de producto en paralelo. Si ningún tamaño de producto es seleccionado, se buscarán todos los productos sin importar su tamaño. Al presionar el ícono de la derecha se limpian los tamaños de producto seleccionados</span>
                </v-tooltip>
              </div>
            </div>
            <br><br>
            <div v-if="loader.selectProductStockOnProductLowStockView" style="text-align: center;">
              <v-progress-circular color="primary" indeterminate></v-progress-circular>
              <br><br>
            </div>
            <div v-else>
              <v-btn @click="selectProductStockOnProductLowStockView()" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive || loader.selectProductBasicBasicInformationForBillsView || loader.selectProductSearchParameters || loader.selectProductStockOnProductLowStockView" dark block height="38" color="primary">
                <h3>BUSCAR</h3>
                <v-icon style="margin-left: 20px;" dark right>mdi-magnify</v-icon>
              </v-btn>
              <br>
              <v-btn @click="setDefaultValues()" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive || loader.selectProductBasicBasicInformationForBillsView || loader.selectProductSearchParameters || loader.selectProductStockOnProductLowStockView" dark block height="38" color="#b3b3b3">
                <h3>LIMPIAR BÚSQUEDA</h3>
                <v-icon style="margin-left: 20px;" dark right>mdi-backspace</v-icon>
              </v-btn>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <br><br>
    <v-card v-if="display.productRows.length > 0" color="border" elevation="3" style="padding: 20px;">
      <v-data-table :headers="display.productHeaders" :items="display.productRows" :items-per-page="250" style="max-height: 700px; overflow-y: auto;">
        <template v-slot:item.productProperties="{ item }">
          <div style="width: 200px; display: flex;">
            <v-tooltip :text="`Ranking de producto${item.productRanking == 'NA' ? ' (sin compras registradas)' : ''}`" location="top">
              <template v-slot:activator="{ props }">
                <v-chip v-bind="props" color="purple" label style="margin-right: 20px; font-size: larger; font-weight: bold; cursor: pointer;">
                  <v-icon icon="mdi-medal" start></v-icon>
                  <p>{{ item.productRanking }}</p>
                </v-chip>
              </template>
            </v-tooltip>
            <v-tooltip text="Panel de producto" location="top">
              <template v-slot:activator="{ props }">
                <v-chip v-bind="props" v-if="accessCredential['/product']" color="warning" label style="margin-right: 20px; font-size: larger; cursor: pointer;">
                  <v-icon @click="$generalFunctions.default.methods.navigateTo('/product/'+item.productID)" icon="mdi-bulletin-board"></v-icon>
                </v-chip>
              </template>
            </v-tooltip>
            <v-tooltip text="Tracking de producto" location="top">
              <template v-slot:activator="{ props }">
                <v-chip v-bind="props" v-if="accessCredential['/productHistory']" color="indigo" label style="font-size: larger; cursor: pointer;">
                  <v-icon @click="$generalFunctions.default.methods.navigateTo('/productHistory/'+item.productID)" icon="mdi-update"></v-icon>
                </v-chip>
              </template>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:item.productName="{ item }">
          <div style="width: 500px;">
            <p>{{ item.productName }}</p>
            <p><strong>{{ item.productID }}</strong></p>
          </div>
        </template>
        <template v-slot:item.productPrice="{ item }">
          ₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(item.productPrice) }}
        </template>
        <template v-slot:item.productStock="{ item }">
          <div style="display: flex">
            <div v-for="productStockByLocality in item.productStock" v-bind:key="productStockByLocality">
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <v-chip v-bind="props" :color="productStockByLocality.localityColor" variant="flat" style="margin: 0px 5px 0px 5px; cursor: pointer;">
                    <p :style="{color: productStockByLocality.localityFontColor}" style="font-size: x-large;"><strong>{{ productStockByLocality.productStockAmount }}</strong></p>
                  </v-chip>
                </template>
                <div>
                  <p><strong>Localidad: </strong>{{ productStockByLocality.localityName }}</p>
                  <p><strong>Cantidad mínima requerida: </strong>{{ productStockByLocality.productStockGoalAmount }}</p>
                  <p><strong>Faltante: </strong>{{ productStockByLocality.productStockGoalAmount - productStockByLocality.productStockAmount }}</p>
                </div>
              </v-tooltip>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <br><br>
  </div>
</template>

<style scoped>
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProductLowStockView',
  inject: ['$generalFunctions'],

  data: () => ({
    input: 
    {
      localityID: [],
      productID: null,
      searchedProductID: null,
      productCategoryID: [],
      productBrandID: [],
      productModelID: [],
      productFlavorID: [],
      productNicotineLevelID: [],
      productSizeID: []
    },

    display: 
    {
      localityOptions: [],
      productOptions: [],
      productCategoryOptions: [],
      productBrandOptions: [],
      productModelOptions: [],
      productFlavorOptions: [],
      productNicotineLevelOptions: [],
      productSizeOptions: [],
      productHeaders: 
      [
        {key: 'productProperties', title: null, sortable: false},
        {key: 'productName', title: 'PRODUCTO', sortable: false},
        {key: 'productPrice', title: 'PRECIO DE VENTA', sortable: false},
        {key: 'productStock', title: 'STOCK', sortable: false}
      ],
      productRows: []
    },

    navigation: 
    {
      searchVisible: 'searchVisible'
    },

    loader:
    {
      selectLocalityIDAndLocalityNameByLocalityIsActive: false,
      selectProductBasicBasicInformationForBillsView: false,
      selectProductSearchParameters: false,
      selectProductStockOnProductLowStockView: false,
      generateReportOnProductStockView: false
    },

    localStorage:
    {
      localityID: null,
      agentID: null
    },

    accessCredential: {}
  }),

  watch: {},

  methods: {
    blurInput(inputReference){
      const blurInputInterval = setInterval(() => {
        if (this.$refs[inputReference]) {
          this.$refs[inputReference].blur();
          clearInterval(blurInputInterval);
        }
      }, 1);
    },

    selectProductByScanner(){
      const selectedProduct = this.display.productOptions.find(productOption => productOption.productID == this.input.searchedProductID);
      if (selectedProduct){
        this.blurInput('productIDInput');
        this.input.productID = selectedProduct.productID;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': null,
          'notificationDialogBody': 'El producto escaneado no ha sido encontrado, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
      }
    },

    cleanProductIDInput(){
      this.input.productID = null;
      this.input.searchedProductID = null;
      this.blurInput('productIDInput');
    },
    
    async selectProductStockOnProductLowStockView(){
      this.loader.selectProductStockOnProductLowStockView = true;
      this.display.productRows.length = 0;
      const selectProductStockOnProductLowStockViewRequestQuery =
      {
        'localityID': this.input.localityID,
        'productID': this.input.productID,
        'productCategoryID': this.input.productCategoryID,
        'productBrandID': this.input.productBrandID,
        'productModelID': this.input.productModelID,
        'productFlavorID': this.input.productFlavorID,
        'productNicotineLevelID': this.input.productNicotineLevelID,
        'productSizeID': this.input.productSizeID
      };
      const selectProductStockOnProductLowStockViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productStock/functions/selectProductStockOnProductLowStockView', selectProductStockOnProductLowStockViewRequestQuery);
      if (selectProductStockOnProductLowStockViewResult.success){
        this.display.productRows = selectProductStockOnProductLowStockViewResult.result.map(productRow => ({
          ...productRow,
        }));
        this.navigation.searchVisible = '';
        if (this.display.productRows.length == 0) {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ATENCIÓN',
            'notificationDialogBody': 'No se ha encontrado ningún producto con los filtros seleccionados, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectProductStockOnProductLowStockView = false;
    },

    async selectLocalityIDAndLocalityNameByLocalityIsActive(){
      this.loader.selectLocalityIDAndLocalityNameByLocalityIsActive = true;
      const selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery = 
      {
        'localityIsActive': true
      };
      const selectLocalityIDAndLocalityNameByLocalityIsActiveResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityNameByLocalityIsActive', selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery);
      if (selectLocalityIDAndLocalityNameByLocalityIsActiveResult.success){
        this.display.localityOptions = selectLocalityIDAndLocalityNameByLocalityIsActiveResult.result;
        this.loader.selectLocalityIDAndLocalityNameByLocalityIsActive = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductBasicBasicInformationForBillsView(){
      this.loader.selectProductBasicBasicInformationForBillsView = true;
      const selectProductBasicBasicInformationForBillsViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductBasicBasicInformationForBillsView');
      if (selectProductBasicBasicInformationForBillsViewResult.success){
        this.display.productOptions = selectProductBasicBasicInformationForBillsViewResult.result.map(productOption => ({
          'productID': productOption.i,
          'productSKU': productOption.s,
          'productName': productOption.n,
          'productAutocompleteTitle': productOption.s + ' - ' + productOption.n
        }));
        this.loader.selectProductBasicBasicInformationForBillsView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductSearchParameters(){
      this.loader.selectProductSearchParameters = true;
      const selectProductSearchParametersResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductSearchParameters');
      if (selectProductSearchParametersResult.success){
        this.display.productCategoryOptions = selectProductSearchParametersResult.result.productCategory;
        this.display.productBrandOptions = selectProductSearchParametersResult.result.productBrand;
        this.display.productModelOptions = selectProductSearchParametersResult.result.productModel;
        this.display.productFlavorOptions = selectProductSearchParametersResult.result.productFlavor;
        this.display.productNicotineLevelOptions = selectProductSearchParametersResult.result.productNicotineLevel;
        this.display.productSizeOptions = selectProductSearchParametersResult.result.productSize;
        this.loader.selectProductSearchParameters = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de características de los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    setDefaultValues(){
      this.input.localityID.length = 0;
      this.input.productID = null;
      this.input.searchedProductID = null;
      this.input.productCategoryID.length = 0;
      this.input.productBrandID.length = 0;
      this.input.productModelID.length = 0;
      this.input.productFlavorID.length = 0;
      this.input.productNicotineLevelID.length = 0;
      this.input.productSizeID.length = 0;
      this.display.productRows.length = 0;
      this.loader.selectProductStockOnProductLowStockView = false;
    },
    
    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    }
  },

  async mounted(){
    await this.getLocalStorageData();
    this.selectLocalityIDAndLocalityNameByLocalityIsActive();
    this.selectProductBasicBasicInformationForBillsView();
    this.selectProductSearchParameters();
  }
});
</script>