<template>
  <div style="margin: 2% 3% 0% 3%;">
    <v-dialog v-model="navigation.providerBillFileDialogIsVisible" width="auto">
      <v-card width="900" style="padding: 20px; text-align: center;">
        <div v-if="navigation.providerBill.providerBillFileType == 'application/pdf'">
          <vue-pdf-app style="height: 60vh" :pdf="`data:application/pdf;base64,${navigation.providerBill.providerBillFile}`"/>
        </div>
        <div v-else>
          <img :src="`data:image/jpeg;base64,${navigation.providerBill.providerBillFile}`" style="width: 100%;">
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="navigation.insertProviderBillDialogIsVisible" width="auto" :persistent="true">
      <v-card width="900" style="padding: 20px; text-align: center;">
        <div v-if="loader.insertProviderBill" style="text-align: center;">
          <v-progress-circular color="success" indeterminate></v-progress-circular>
        </div>
        <div v-else>
        <v-text-field v-model="display.providerBillDatetimeFormatted" @click="navigation.providerBillDatetimePickerIsVisible = true" readonly label="Fecha de la factura" append-inner-icon="mdi-calendar" variant="solo-filled"></v-text-field>
          <v-dialog v-model="navigation.providerBillDatetimePickerIsVisible" width="auto">
            <v-card style="display: flex;">
              <v-date-picker v-model="input.providerBillDatetime" hide-header color="primary" width="100%"></v-date-picker>
              <v-chip @click="input.providerBillDatetime = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;"  color="primary" variant="flat">Hoy</v-chip>
            </v-card>
          </v-dialog>
          <v-text-field v-model="input.providerBillNumber" append-inner-icon="mdi-barcode" label="Número de la factura" variant="solo-filled" type="number" hide-spin-buttons @wheel.prevent></v-text-field>
          <v-text-field v-model="input.providerBillAmount" append-inner-icon="mdi-cash" label="Monto de la factura" variant="solo-filled" type="number" hide-spin-buttons @wheel.prevent></v-text-field>
          <v-textarea v-model="input.providerBillNote" rows="3" append-inner-icon="mdi-text-box-outline" label="Nota de la factura" variant="solo-filled"></v-textarea>
          <input @change="processUploadedFile()" type="file" accept="image/*, application/pdf" id="uploadFile" ref="uploadFile" style="display: none;">       
          <v-text-field @click="uploadFile()" v-model="input.providerBillFileName" readonly append-inner-icon="mdi-file-upload" label="Archivo de la factura (opcional)" variant="solo-filled"></v-text-field>
          <br><br>
          <v-btn @click="insertProviderBill()" dark block height="38" color="success">
            <h3>REGISTRAR FACTURA</h3>
            <v-icon style="margin-left: 10px;" dark right>mdi-plus-circle-outline</v-icon>
          </v-btn>
          <br>
          <v-btn @click="navigation.insertProviderBillDialogIsVisible = false" dark block height="38" color="error">
            <h3>CANCELAR</h3>
            <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="navigation.insertProviderBillPaymentDialogIsVisible" width="auto" :persistent="true">
      <v-card width="900" style="padding: 20px; text-align: center;">
        <div v-if="loader.insertProviderBillPayment" style="text-align: center;">
          <v-progress-circular color="success" indeterminate></v-progress-circular>
        </div>
        <div v-else>
        <v-text-field v-model="display.providerBillPaymentDatetimeFormatted" @click="navigation.providerBillPaymentDatetimePickerIsVisible = true" readonly label="Fecha del abono" append-inner-icon="mdi-calendar" variant="solo-filled"></v-text-field>
          <v-dialog v-model="navigation.providerBillPaymentDatetimePickerIsVisible" width="auto">
            <v-card style="display: flex;">
              <v-date-picker v-model="input.providerBillPaymentDatetime" hide-header color="primary" width="100%"></v-date-picker>
              <v-chip @click="input.providerBillPaymentDatetime = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;"  color="primary" variant="flat">Hoy</v-chip>
            </v-card>
          </v-dialog>
          <v-text-field v-model="input.providerBillPaymentAmount" append-inner-icon="mdi-cash" label="Monto del abono" variant="solo-filled" type="number" hide-spin-buttons @wheel.prevent></v-text-field>
          <v-textarea v-model="input.providerBillPaymentNote" rows="3" append-inner-icon="mdi-text-box-outline" label="Nota del abono" variant="solo-filled"></v-textarea>
          <br><br>
          <v-btn @click="insertProviderBillPayment()" dark block height="38" color="success">
            <h3>REGISTRAR ABONO</h3>
            <v-icon style="margin-left: 10px;" dark right>mdi-plus-circle-outline</v-icon>
          </v-btn>
          <br>
          <v-btn @click="navigation.insertProviderBillPaymentDialogIsVisible = false" dark block height="38" color="error">
            <h3>CANCELAR</h3>
            <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>


    <v-dialog v-model="navigation.deleteProviderBillDialogIsVisible" width="auto" :persistent="true">
      <v-card width="400" style="padding: 20px; text-align: center;">
        <div v-if="loader.deleteProviderBill" style="text-align: center;">
          <v-progress-circular color="error" indeterminate></v-progress-circular>
        </div>
        <div v-else>
          <v-btn @click="deleteProviderBill()" dark block height="38" color="error">
            <h3>ELIMINAR COMPRA</h3>
            <v-icon style="margin-left: 10px;" dark right>mdi-trash-can</v-icon>
          </v-btn>
          <br>
          <v-btn @click="navigation.deleteProviderBillDialogIsVisible = false" dark block height="38" color="info">
            <h3>CANCELAR</h3>
            <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>


    <v-card color="border" elevation="3" style="padding: 20px 20px 0px 20px; width: 100%;">
      <v-autocomplete 
        @click="input.productProviderID = null"
        v-model="input.productProviderID" 
        :items="display.productProviderOptions" 
        :loading="loader.selectProductProviderOptionsFromProductProviderView" 
        :disabled="loader.selectProductProviderOptionsFromProductProviderView" 
        item-title="productProviderName" 
        item-value="productProviderID" 
        append-inner-icon="mdi-magnify" 
        label="Nombre del proveedor" 
        variant="solo-filled"
        density="compact"
      ></v-autocomplete>
      <div style="display: flex;">
        <v-text-field v-model="display.startDateFormatted" @click="navigation.startDatePickerIsVisible = true" readonly label="Filtrar por fecha inicial" append-inner-icon="mdi-calendar" variant="solo-filled" density="compact" style="margin-right: 0.5%;"></v-text-field>
        <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
          <v-card style="display: flex;">
            <v-date-picker v-model="input.startDate" hide-header color="primary" width="100%"></v-date-picker>
            <v-chip @click="input.startDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;"  color="primary" variant="flat">Hoy</v-chip>
          </v-card>
        </v-dialog>
        <v-text-field v-model="display.endDateFormatted" @click="navigation.endDatePickerIsVisible = true" readonly label="Filtrar por fecha final" append-inner-icon="mdi-calendar" variant="solo-filled" density="compact" style="margin-left: 0.5%;"></v-text-field>
        <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
          <v-card>
            <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
            <v-chip @click="input.endDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;"  color="primary" variant="flat">Hoy</v-chip>
          </v-card>
        </v-dialog>
      </div>
      <div v-if="loader.selectProductProviderFromProductProviderView" style="text-align: center;">
        <br>
        <v-progress-circular color="primary" indeterminate></v-progress-circular>
        <br><br>
      </div>
    </v-card>      
    <br><br>
    <v-card v-if="display.productProvider" color="border" elevation="3" style="padding: 30px; width: 100%;">
      <div v-if="loader.selectProductProviderFromProductProviderView" style="text-align: center;">
        <v-progress-circular color="info" indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <div style="display: flex;">
          <div>
            <h1>{{ display.productProvider.productProvider.productProviderName }}</h1>
            <v-tooltip text="Deuda total" location="top">
              <template v-slot:activator="{ props }">
                <v-chip v-bind="props" color="error" label style="font-size: larger; cursor: pointer; margin-top: 10px;">
                  <v-icon icon="mdi-cash" start></v-icon>
                  <p><strong>$</strong>{{ $generalFunctions.default.methods.parseAmountToDisplay(display.productProvider.providerBill.reduce((total, providerBill) => total + providerBill.providerBillCurrentAmount, 0)) }}</p>
                </v-chip>
              </template>
            </v-tooltip>
          </div>
          <div style="margin-left: auto; display: flex; justify-content: flex-end; width: 50%;">
            <v-card style="width: 30%; padding: 20px; margin-left: 20px;">
              <div style="text-align: center; margin-bottom: 20px;">
                <p style="font-size: larger;"><strong>Este mes: </strong></p>
              </div>
              <div style="display: flex;">
                <v-icon color="info" center>mdi-package</v-icon>
                <p style="margin-left: 10px;">{{ display.productProvider.providerBill.length }}</p>
              </div>
              <div style="display: flex;">
                <v-icon color="info" center>mdi-cash</v-icon>
                <p style="margin-left: 10px;"><strong>$</strong>{{ $generalFunctions.default.methods.parseAmountToDisplay(display.productProvider.providerBill.reduce((total, providerBill) => total + providerBill.providerBillAmount, 0)) }}</p>
              </div>
            </v-card>
            <v-card style="width: 30%; padding: 20px; margin-left: 20px;">
              <div style="text-align: center; margin-bottom: 20px;">
                <p style="font-size: larger;"><strong>Este año: </strong></p>
              </div>
              <div style="display: flex;">
                <v-icon color="info" center>mdi-package</v-icon>
                <p style="margin-left: 10px;">{{ display.productProvider.providerBill.length }}</p>
              </div>
              <div style="display: flex;">
                <v-icon color="info" center>mdi-cash</v-icon>
                <p style="margin-left: 10px;"><strong>$</strong>{{ $generalFunctions.default.methods.parseAmountToDisplay(display.productProvider.providerBill.reduce((total, providerBill) => total + providerBill.providerBillAmount, 0)) }}</p>
              </div>
            </v-card>
          </div>
        </div>
        
        <br><br>
        <div style="display: flex; align-items: center;">
          <h3>Compras asociadas:</h3>
          <div style="margin-left: auto; display: flex; gap: 10px;">
            <v-icon @click="openInsertProviderBillDialog()" size="40" color="success" style="cursor: pointer;">mdi-plus-circle-outline</v-icon>
            <v-icon @click="generateProviderBillReport()" size="40" color="warning" style="cursor: pointer;">mdi-printer</v-icon>
          </div>
        </div>
        <br>
        <v-data-table :headers="display.providerBillHeaders" :items="display.productProvider.providerBill" :items-per-page="-1" hide-default-footer style="max-height: 700px; overflow-y: auto;">
          <template v-slot:item="row">
            <tr>
              <td>
                <p>{{ row.item.providerBillID }}</p>
              </td>
              <td>
                <v-chip :color="row.item.agentColor" variant="flat" style="cursor: pointer;">
                  <p :style="{color: row.item.agentFontColor}">{{ row.item.agentName }}</p>
                </v-chip>                
              </td>
              <td>
                <p>Factura</p>     
                <p><strong>{{ row.item.providerBillNumber }}</strong></p>       
              </td>
              <td>
                <p>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(row.item.providerBillDatetime) }}</p>
              </td>
              <td>
                <p>{{ row.item.providerBillNote }}</p>            
              </td>
              <td>
                <p><strong>$</strong>{{ $generalFunctions.default.methods.parseAmountToDisplay(row.item.providerBillAmount) }}</p>             
              </td>
              <td>
                <p><strong>$</strong>{{ $generalFunctions.default.methods.parseAmountToDisplay(row.item.providerBillCurrentAmount) }}</p>             
              </td>
              <td>
                <v-icon @click="openInsertProviderBillPaymentDialog(row.item)" size="25" color="success">mdi-cash</v-icon>         
                <v-icon v-if="row.item.providerBillFile" @click="openProviderBillFile(row.item)" size="25" color="info" style="margin-left: 10px;">mdi-magnify</v-icon>      
                <v-icon @click="openDeleteProviderBillDialog(row.item.providerBillID)" size="25" color="error" style="margin-left: 10px;">mdi-trash-can</v-icon>  
              </td>
            </tr>
            <template v-if="row.item.providerBillPayments">
              <tr v-for="providerBillPayment in JSON.parse(row.item.providerBillPayments)" v-bind:key="providerBillPayment">
                <td>
                  <p>{{ providerBillPayment.providerBillPaymentID }}</p>
                </td>
                <td>
                  <v-chip :color="providerBillPayment.agentColor" variant="flat" style="cursor: pointer;">
                    <p :style="{color: providerBillPayment.agentFontColor}">{{ providerBillPayment.agentName }}</p>
                  </v-chip>  
                </td>
                <td>
                  <p>Abono</p>            
                </td>
                <td>
                  <p>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(providerBillPayment.providerBillPaymentDatetime) }}</p>
                </td>
                <td>
                  <p>{{ providerBillPayment.providerBillPaymentNote }}</p>
                </td>
                <td>
                  <p><strong>Cantidad previa: </strong><strong>$</strong>{{ $generalFunctions.default.methods.parseAmountToDisplay(providerBillPayment.providerBillPaymentPreviousAmount) }}</p>
                  <p><strong>Cantidad del abono: </strong><strong>$</strong>{{ $generalFunctions.default.methods.parseAmountToDisplay(providerBillPayment.providerBillPaymentAmount) }}</p>
                  <p><strong>Saldo restante: </strong><strong>$</strong>{{ $generalFunctions.default.methods.parseAmountToDisplay(providerBillPayment.providerBillPaymentResultingAmount) }}</p>
                </td>
                <td></td>
                <td></td>
              </tr>
            </template>
          </template>
        <template #bottom></template>
        </v-data-table> 
        <br><br>
        <div style="display: flex;">
          <div style="width: 49%; margin-right: 1%;">
            <h3>Productos asociados: </h3>
            <br>
            <v-data-table :headers="display.productStockHeaders" :items="Object.values(display.productProvider.productStock)" :items-per-page="-1" hide-default-footer style="max-height: 700px; overflow-y: auto;">
              <template v-slot:item.productName="{ item }">
                <div style="display: flex;">
                  <div style="margin-top: 10px; display: flex;">
                    <v-chip color="warning" label style="margin-right: 10px; font-size: larger; cursor: pointer;">
                      <v-icon @click="$generalFunctions.default.methods.navigateTo('/product/'+item.productID)" icon="mdi-bulletin-board" start></v-icon>
                    </v-chip>
                    <v-chip color="indigo" label style="margin-right: 10px; font-size: larger; cursor: pointer;">
                      <v-icon @click="$generalFunctions.default.methods.navigateTo('/productHistory/'+item.productID)" icon="mdi-update" start></v-icon>
                    </v-chip>
                  </div>
                  <div>
                    <p><strong>{{ item.productName }}</strong></p>
                    <p>{{ item.productSKU }}</p>
                  </div>
                </div>
              </template>
              <template v-slot:item.localities="{ item }">
                <div style="display: flex;">
                  <div v-for="locality in item.localities" v-bind:key="locality" style="margin-right: 5px;">
                    <v-tooltip :text="locality.localityName" location="top">
                      <template v-slot:activator="{ props }">
                        <v-chip v-bind="props" :color="locality.localityColor" variant="flat" style="cursor: pointer;">
                          <p :style="{color: locality.localityFontColor}">{{ locality.productStockAmount }}</p>
                        </v-chip>
                      </template>
                    </v-tooltip>
                  </div>
                  <v-tooltip text="TOTAL" location="top">
                    <template v-slot:activator="{ props }">
                      <v-chip v-bind="props" color="black" variant="flat" style="cursor: pointer;">
                        <p style="color: white;">{{ item.localities.reduce((total, locality) => total + locality.productStockAmount, 0) }}</p>
                      </v-chip>
                    </template>
                  </v-tooltip>
                </div>
              </template>
            <template #bottom></template>
            </v-data-table> 
          </div>
          <div style="width: 49%; margin-left: 1%;">
            <div style="display: flex; align-items: center;">
              <h3>Cambios asociados:</h3>
              <div style="margin-left: auto; display: flex; gap: 10px;">
                <h3 style="margin-left: auto;">{{ display.productProvider.productChange.length }}</h3>
                <v-icon @click="generateProviderProductChangeReport()" size="40" color="warning" style="cursor: pointer;">mdi-printer</v-icon>
              </div>
            </div>
            <br>
            <v-data-table :headers="display.productChangeHeaders" :items="display.productProvider.productChange" :items-per-page="-1" hide-default-footer style="max-height: 700px; overflow-y: auto;">
              <template v-slot:item.productName="{ item }">
                <div style="display: flex;">
                  <div style="margin-top: 10px; display: flex;">
                    <v-chip color="warning" label style="margin-right: 10px; font-size: larger; cursor: pointer;">
                      <v-icon @click="$generalFunctions.default.methods.navigateTo('/product/'+item.productID)" icon="mdi-bulletin-board" start></v-icon>
                    </v-chip>
                    <v-chip color="indigo" label style="margin-right: 10px; font-size: larger; cursor: pointer;">
                      <v-icon @click="$generalFunctions.default.methods.navigateTo('/productHistory/'+item.productID)" icon="mdi-update" start></v-icon>
                    </v-chip>
                  </div>
                  <div>
                    <p><strong>{{ item.productName }}</strong></p>
                    <p>{{ item.productSKU }}</p>
                  </div>
                </div>
              </template>
              <template v-slot:item.productChangeDatetime="{ item }">
                <p>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.productChangeDatetime) }}</p>
              </template>
              <template v-slot:item.localityName="{ item }">
                <v-chip :color="item.localityColor" variant="flat" style="cursor: pointer;">
                  <p :style="{color: item.localityFontColor}">{{ item.localityName }}</p>
                </v-chip>              
              </template>
              <template v-slot:item.agentName="{ item }">
                <v-chip :color="item.agentColor" variant="flat" style="cursor: pointer;">
                  <p :style="{color: item.agentFontColor}">{{ item.agentName }}</p>
                </v-chip>              
              </template>
            <template #bottom></template>
            </v-data-table> 
          </div>
        </div>
      </div>
    </v-card>
    <br><br>
  </div>
</template>

<style scoped>
</style>

<script>
import VuePdfApp from "vue3-pdf-app";
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProductProviderView',
  inject: ['$generalFunctions'],

  components: {
    VuePdfApp
  },

  data: () => ({
    input: 
    {
      productProviderID: null,
      startDate: null,
      endDate: null,
      providerBillDatetime: null,
      providerBillNumber: null,
      providerBillAmount: null,
      providerBillNote: null,

      providerBillFile: null,
      providerBillFileName: null,
      providerBillFileType: null,

      providerBillPaymentDatetime: null,
      providerBillPaymentAmount: null,
      providerBillPaymentNote: null
    },

    display: 
    {
      productProviderOptions: [],
      productProvider: null,
      startDateFormatted: null,
      endDateFormatted: null,

      providerBillHeaders: 
      [
        {key: 'providerBillID', title: 'ID'},
        {key: 'agentName', title: 'GENERADO POR'},
        {key: 'providerBillType', title: 'TIPO'},
        {key: 'providerBillDatetime', title: 'FECHA'},
        {key: 'providerBillNote', title: 'NOTA'},
        {key: 'providerBillAmount', title: 'MONTO'},
        {key: 'providerBillCurrentAmount', title: 'SALDO'},
        {key: 'providerBillAction', title: 'GENERAR ABONO'}
      ],

      productStockHeaders:
      [
        {key: 'productName', title: 'PRODUCTO'},
        {key: 'localities', title: 'STOCK'}
      ],

      productChangeHeaders:
      [
        {key: 'productChangeID', title: 'ID'},
        {key: 'productName', title: 'PRODUCTO'},
        {key: 'productChangeDatetime', title: 'SOLICITADO EL'},
        {key: 'localityName', title: 'SOLICITADO EN'},
        {key: 'agentName', title: 'SOLICITADO POR'}
      ],

      providerBillDatetimeFormatted: null,
      providerBillPaymentDatetimeFormatted: null

    },

    navigation: 
    {
      startDatePickerIsVisible: false,
      endDatePickerIsVisible: false,
      insertProviderBillDialogIsVisible: false,
      providerBillDatetimePickerIsVisible: false,

      insertProviderBillPaymentDialogIsVisible: false,
      providerBillPaymentDatetimePickerIsVisible: false,

      providerBillFileDialogIsVisible: false,
      deleteProviderBillDialogIsVisible: false,
      deletingProviderBillID: null,

      providerBill: null
    },

    loader:
    {
      selectProductProviderOptionsFromProductProviderView: false,
      selectProductProviderFromProductProviderView: false,
      insertProviderBill: false,
      insertProviderBillPayment: false,
      deleteProviderBill: false
    },

    localStorage:
    {
      agentID: null
    },

    accessCredential: {}
  }),

  watch: {
    'input.productProviderID'(){
      this.input.productProviderID != null && this.selectProductProviderFromProductProviderView();
    },
    'input.startDate': async function() {
      if (this.input.startDate){
        this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB');
        this.navigation.startDatePickerIsVisible = false;
        this.input.productProviderID != null && this.selectProductProviderFromProductProviderView();
      } else {
        this.display.startDateFormatted = null;
      }
    },
    'input.endDate': async function() {
      if (this.input.endDate){
        this.display.endDateFormatted = new Date(this.input.endDate).toLocaleDateString('en-GB');
        this.navigation.endDatePickerIsVisible = false;
        this.input.productProviderID != null && this.selectProductProviderFromProductProviderView();
      } else {
        this.display.endDateFormatted = null;
      }
    },
    'input.providerBillDatetime': async function() {
      if (this.input.providerBillDatetime){
        this.display.providerBillDatetimeFormatted = new Date(this.input.providerBillDatetime).toLocaleDateString('en-GB');
        this.navigation.providerBillDatetimePickerIsVisible = false;
      } else {
        this.display.providerBillDatetimeFormatted = null;
      }
    },
    'input.providerBillPaymentDatetime': async function() {
      if (this.input.providerBillPaymentDatetime){
        this.display.providerBillPaymentDatetimeFormatted = new Date(this.input.providerBillPaymentDatetime).toLocaleDateString('en-GB');
        this.navigation.providerBillPaymentDatetimePickerIsVisible = false;
      } else {
        this.display.providerBillPaymentDatetimeFormatted = null;
      }
    }
  },

  methods: {

    async generateProviderBillReport(){
      const generateProviderBillReportRequestQuery = 
      {
        'productProviderID': this.input.productProviderID,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate
      };
      const generateProviderBillReportResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productProvider/functions/generateProviderBillReport', generateProviderBillReportRequestQuery);
      if (generateProviderBillReportResult.success){
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': generateProviderBillReportResult.result.PDFDocumentFile.data,
          'printablePDFDocumentName': generateProviderBillReportResult.result.PDFDocumentName
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el reporte del proveedor, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async generateProviderProductChangeReport(){
      const generateProviderBillReportRequestQuery = 
      {
        'productProviderID': this.input.productProviderID,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate
      };
      const generateProviderBillReportResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productProvider/functions/generateProviderProductChangeReport', generateProviderBillReportRequestQuery);
      if (generateProviderBillReportResult.success){
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': generateProviderBillReportResult.result.PDFDocumentFile.data,
          'printablePDFDocumentName': generateProviderBillReportResult.result.PDFDocumentName
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el reporte del proveedor, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async deleteProviderBill(){
      this.loader.deleteProviderBill = true;
      const deleteProviderBillRequestQuery = 
      {
        'providerBillID': this.navigation.deletingProviderBillID
      };
      const deleteProviderBillResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productProvider/functions/deleteProviderBill', deleteProviderBillRequestQuery);
      if (deleteProviderBillResult.success){
        this.navigation.deleteProviderBillDialogIsVisible = false;
        this.selectProductProviderFromProductProviderView();
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'La compra asociada al proveedor se ha eliminado exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al eliminar la compra asociada al proveedor, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.deleteProviderBill = false;
    },

    openDeleteProviderBillDialog(providerBillID){
      this.navigation.deletingProviderBillID = providerBillID;
      this.navigation.deleteProviderBillDialogIsVisible = true;
    },


    async selectProductProviderOptionsFromProductProviderView(){
      this.loader.selectProductProviderOptionsFromProductProviderView = true;
      const selectProductProviderOptionsFromProductProviderViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productProvider/functions/selectProductProviderOptionsFromProductProviderView');
      if (selectProductProviderOptionsFromProductProviderViewResult.success){
        this.display.productProviderOptions = selectProductProviderOptionsFromProductProviderViewResult.result;
        this.loader.selectProductProviderOptionsFromProductProviderView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de proveedores, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductProviderFromProductProviderView(){
      this.loader.selectProductProviderFromProductProviderView = true;
      const selectProductProviderFromProductProviderViewRequestQuery = 
      {
        'productProviderID': this.input.productProviderID,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate
      };
      const selectProductProviderFromProductProviderViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productProvider/functions/selectProductProviderFromProductProviderView', selectProductProviderFromProductProviderViewRequestQuery);
      if (selectProductProviderFromProductProviderViewResult.success){
        this.display.productProvider = selectProductProviderFromProductProviderViewResult.result;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la información del proveedor, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectProductProviderFromProductProviderView = false;
    },

    async insertProviderBill(){
      this.loader.insertProviderBill = true;
      const insertProviderBillRequestQuery = 
      {
        'providerBillNumber': this.input.providerBillNumber,
        'providerBillProviderID': this.input.productProviderID,
        'providerBillAgentID': this.localStorage.agentID,
        'providerBillDatetime': this.input.providerBillDatetime,
        'providerBillNote': this.input.providerBillNote,
        'providerBillAmount': this.input.providerBillAmount,
        'providerBillFile': this.input.providerBillFile,
        'providerBillFileName': this.input.providerBillFileName,
        'providerBillFileType': this.input.providerBillFileType
      };

      const insertProviderBillResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productProvider/functions/insertProviderBill', insertProviderBillRequestQuery);
      if (insertProviderBillResult.success){
        this.navigation.insertProviderBillDialogIsVisible = false;
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'Factura del proveedor registrada exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        await this.selectProductProviderFromProductProviderView();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al registrar la factura del proveedor, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.insertProviderBill = false;
    },


    async insertProviderBillPayment(){
      this.loader.insertProviderBillPayment = true;
      const insertProviderBillPaymentRequestQuery = 
      {
        'providerBillPaymentProviderBillID': this.navigation.providerBill.providerBillID,
        'providerBillPaymentAgentID': this.localStorage.agentID,
        'providerBillPaymentDatetime': this.input.providerBillPaymentDatetime,
        'providerBillPaymentNote': this.input.providerBillPaymentNote,
        'providerBillPaymentPreviousAmount': this.navigation.providerBill.providerBillAmount,
        'providerBillPaymentAmount': this.input.providerBillPaymentAmount,
        'providerBillPaymentResultingAmount': parseFloat(this.navigation.providerBill.providerBillAmount) - parseFloat(this.input.providerBillPaymentAmount)
      };
      const insertProviderBillPaymentResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productProvider/functions/insertProviderBillPayment', insertProviderBillPaymentRequestQuery);
      if (insertProviderBillPaymentResult.success){
        this.navigation.insertProviderBillPaymentDialogIsVisible = false;
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'Abono de la factura del proveedor registrada exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        await this.selectProductProviderFromProductProviderView();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al registrar el abono de la factura del proveedor, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.insertProviderBillPayment = false;
    },

    openProviderBillFile(providerBill){      
      this.navigation.providerBill = providerBill;
      this.navigation.providerBillFileDialogIsVisible = true;
    },

    uploadFile(){
      document.getElementById('uploadFile').click();
    },

    processUploadedFile(){
      const previousFile = this.input.providerBillFile;
      try {
        this.input.providerBillFile = this.$refs.uploadFile.files[0];
        this.input.providerBillFileName = this.input.providerBillFile.name;
        this.input.providerBillFileType = this.input.providerBillFile.type;
        const fileReader = new FileReader();
        fileReader.readAsDataURL(this.input.providerBillFile);
        fileReader.onload = () => {
          this.input.providerBillFile = fileReader.result;
        };
        fileReader.onerror = function () {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al cargar el archivo de la factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        };
      } catch {
        this.input.providerBillFile = previousFile;
      }
    },


    openInsertProviderBillDialog(){
      this.input.providerBillDatetime = new Date();
      this.input.providerBillNumber = null;
      this.input.providerBillAmount = null;
      this.input.providerBillNote = null;
      this.input.providerBillFile = null;
      this.input.providerBillFileName = null;
      this.input.providerBillFileType = null;
      this.loader.insertProviderBill = false;
      this.navigation.insertProviderBillDialogIsVisible = true;
    },

    openInsertProviderBillPaymentDialog(providerBill){
      this.input.providerBillPaymentDatetime = new Date();
      this.input.providerBillPaymentAmount = null;
      this.input.providerBillPaymentNote = null;
      this.loader.insertProviderBillPayment = false;
      this.navigation.providerBill = providerBill;
      this.navigation.insertProviderBillPaymentDialogIsVisible = true;
    },

    async setDefaultValues(){
      this.input.productProviderID = null;
      const startDate = new Date();
      startDate.setMonth(1);
      startDate.setDate(1);
      this.input.startDate = startDate;
      this.input.endDate = new Date();
      this.display.productProvider = null;
      this.loader.selectProductProviderOptionsFromProductProviderView = false;
      this.loader.selectProductProviderFromProductProviderView = false;
    },

    async getLocalStorageData(){
      this.localStorage.agentID = localStorage.getItem('agentID');
    }

  },
  
  async mounted(){
    await this.setDefaultValues();
    await this.getLocalStorageData();
    await this.selectProductProviderOptionsFromProductProviderView();
  }
});
</script>
