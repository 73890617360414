<template>


  <v-dialog v-model="navigation.proformaFileDialogIsVisible" width="auto">
    <v-card style="padding: 20px;" elevation="3" width="500">
      <img :src="display.proformaFile"/>
    </v-card>
  </v-dialog>
  <v-dialog v-model="navigation.updateProformaDialogIsVisible" :persistent="true" width="auto">
    <v-card style="padding: 20px 20px 0px 20px;" elevation="3" width="500">
      <div v-if="loader.updateProformaState" style="text-align: center;">
        <v-progress-circular color="success" indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <v-select 
          v-model="input.updatingProformaState" 
          :items="['Proforma aprobada', 'Proforma cancelada']" 
          append-inner-icon="mdi-state-machine" 
          label="Estado de la proforma" 
          variant="solo-filled"
          density="compact"
        ></v-select>
        <div v-if="input.updatingProformaState == 'Proforma aprobada'" style="text-align: center;">
          <br>
          <input @change="processImage()" type="file" accept="image/*, .pdf" ref="uploadProformaPaymentImage" id="uploadProformaPaymentImage" style="display: none;">  
          <img v-if="input.updatingProformaImage == null" @click="uploadImage()" :src="assets.placeholderImage" style="cursor: pointer; width: 60%;">
          <img v-else @click="uploadImage()" :src="input.updatingProformaImage" style="cursor: pointer; width: 60%;">
          <br><br>
        </div>
        <div v-if="input.updatingProformaState">
          <br>
          <v-btn @click="updateProformaState()" dark block height="38" color="success">
            <h3>{{ `${input.updatingProformaState == 'Proforma aprobada' ? 'APROBAR' : 'CANCELAR'} PROFORMA` }}</h3>
            <v-icon style="margin-left: 10px;" dark right>mdi-check-circle</v-icon>
          </v-btn>
          <br>
          <v-btn @click="navigation.updateProformaDialogIsVisible = false" dark block height="38" color="error" style="margin-bottom: 20px;">
            <h3>CANCELAR</h3>
            <v-icon style="margin-left: 10px;" dark right>mdi-close-circle</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>

  <v-dialog v-model="navigation.dispatchProformaDialogIsVisible" :persistent="true" width="auto">
    <v-card color="background" style="padding: 20px;" elevation="3" width="850">
      <div v-if="loader.dispatchProforma" style="text-align: center;">
        <v-progress-circular color="success" indeterminate></v-progress-circular>
      </div>
      <div v-else>
 
        <v-data-table :headers="display.dispatchProformaProductHeaders" :items="display.proformaDrawer.proformaProducts" :items-per-page="-1" style="max-height: 400px; overflow-y: auto; overflow-x: hidden;">
          <template v-slot:item="row">
            <tr :style="(row.item.productStock < row.item.proformaProductAmount) ? 'background-color: #d9b6b6' : (row.item.proformaProductReady ? 'background-color: #bdd9c4' : '')" @contextmenu.prevent="row.item.proformaProductReady = !row.item.proformaProductReady">
              <td>
                <v-checkbox v-model="row.item.proformaProductReady" :disabled="row.item.productStock < row.item.proformaProductAmount" color="primary" hide-details></v-checkbox>
              </td>
              <td>
                <p>{{ row.item.productName }}</p>
                <p><strong>{{ row.item.productSKU }}</strong></p>
              </td>
              <td>
                <p>{{ row.item.proformaProductAmount }}</p>
              </td>
              <td>
                <div v-if="row.item.productStock >= row.item.proformaProductAmount">
                  <p style="color: #009929;"><strong>{{ row.item.productStock }}</strong></p>
                </div>
                <div v-else>
                  <v-tooltip text="El producto no cuenta con stock suficiente en esta localidad" location="top">
                    <template v-slot:activator="{ props }">
                      <p v-bind="props" class="stockWarningLabel"><strong>{{ row.item.productStock }}</strong></p>
                    </template>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
          <template #bottom></template>
        </v-data-table>          
        <br><br>
        <div style="text-align: center">
          <input @change="processImage()" type="file" accept="image/*" ref="uploadProformaPaymentImage" id="uploadProformaPaymentImage" style="display: none;">  
          <img v-if="input.updatingProformaImage == null" @click="uploadImage()" :src="assets.placeholderImage" style="cursor: pointer; width: 40%;">
          <img v-else @click="uploadImage()" :src="input.updatingProformaImage" style="cursor: pointer; width: 40%;">
        </div>
        <br><br><br>
        <v-btn @click="dispatchProforma()" dark block height="38" color="success">
          <h3>DESPACHAR PROFORMA</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-package</v-icon>
        </v-btn>
        <br>
        <v-btn @click="navigation.dispatchProformaDialogIsVisible = false" dark block height="38" color="error">
          <h3>CANCELAR</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-close-circle</v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>

  <v-navigation-drawer v-model="navigation.proformaDrawerIsVisible" absolute temporary class="bg-primary" location="right" style="min-width: 45%;">
      <div v-if="loader.selectProformaFromProformaDrawer" style="display: flex; justify-content: center; align-items: center; height: 100%;">
        <v-progress-circular color="white" indeterminate></v-progress-circular>
      </div>
      <div v-else style="padding: 30px;">
        <div v-if="display.proformaDrawer">
          <v-card color="background" style="padding: 20px;">
            <div style="display: flex; justify-content: space-between;">
              <div style="width: 70%;">
                <p><strong>Contacto: </strong> {{ display.proformaDrawer.contactName }}</p>
                <p style="margin-top: 10px;"><strong>SSN: </strong> {{ display.proformaDrawer.contactSSN }}</p>
              </div>
              <div style="text-align: right;">
                <v-tooltip location="left">
                  <div style="width: 600px; display: flex">
                    <div v-if="display.proformaDrawer.contactCommercialName || display.proformaDrawer.contactPhoneNumber || display.proformaDrawer.contactEmail" style="width: 49%; margin-right: 1%;">
                      <div v-if="display.proformaDrawer.contactCommercialName" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-store</v-icon>
                        <p style="margin-left: 10px; font-size: large;">{{ display.proformaDrawer.contactCommercialName }}</p>
                      </div>
                      <div v-if="display.proformaDrawer.contactPhoneNumber" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-phone</v-icon>
                        <p style="margin-left: 10px; font-size: large;">{{ display.proformaDrawer.contactPhoneNumber }}</p>
                      </div>
                      <div v-if="display.proformaDrawer.contactEmail" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-email</v-icon>
                        <p style="margin-left: 10px; font-size: large;">{{ display.proformaDrawer.contactEmail }}</p>
                      </div>
                    </div>
                    <div v-if="display.proformaDrawer.contactWebPage || display.proformaDrawer.contactCountry || display.proformaDrawer.contactProvince || display.proformaDrawer.contactCanton || display.proformaDrawer.contactDistrict" style="width: 49%; margin-left: 1%;">
                      <div v-if="display.proformaDrawer.contactWebPage" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-web</v-icon>
                        <p style="margin-left: 10px; font-size: large;">{{ display.proformaDrawer.contactWebPage }}</p>
                      </div>
                      <div v-if="display.proformaDrawer.contactCountry" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-earth</v-icon>
                        <p style="margin-left: 10px; font-size: large;">{{ display.proformaDrawer.contactCountry }}</p>
                      </div>
                      <div v-if="display.proformaDrawer.contactProvince || display.proformaDrawer.contactCanton || display.proformaDrawer.contactDistrict" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-map-marker</v-icon>
                        <p style="margin-left: 10px; font-size: large;">{{ [display.proformaDrawer.contactProvince, display.proformaDrawer.contactCanton, display.proformaDrawer.contactDistrict].filter(Boolean).join(', ') }}</p>
                      </div>
                    </div>
                  </div>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" color="info" size="30" style="cursor: pointer;">mdi-information</v-icon>
                  </template>
                </v-tooltip>
                <v-tooltip v-if="display.proformaDrawer.proformaDispatchedImage" location="left">                    
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" @click="openProformaFileDialog(display.proformaDrawer.proformaDispatchedImage)" color="info" size="30" style="cursor: pointer;">mdi-image</v-icon>
                  </template>
                  Documento de despacho
                </v-tooltip>
                <v-icon @click="createProformaPDFDocument(display.proformaDrawer)" color="warning" size="30" style="cursor: pointer;">mdi-printer</v-icon>
              </div>
            </div>
          </v-card>
          <br>
          <v-card color="background" style="padding: 20px;">
            <div style="display: flex; width: 100%;">
              <div style="width: 49.5%; margin-right: 0.5%;">
                <p><strong>ID de la proforma: </strong> {{ display.proformaDrawer.proformaID }}</p>
                <div style="display: flex; margin-top: 10px;">
                  <p><strong>Estado: </strong></p>
                  <v-chip :color="localStorage.proformaStateColors[display.proformaDrawer.proformaState].proformaStateColor" variant="flat" style="margin-left: 10px; position: relative; top: -2px;">
                    <p :style="{color: localStorage.proformaStateColors[display.proformaDrawer.proformaState].proformaStateFontColor}">{{ display.proformaDrawer.proformaState }}</p>
                  </v-chip>
                </div>
                <div style="display: flex; margin-top: 30px;">
                  <p><strong>Aprobado por: </strong></p>
                  <v-chip :color="display.proformaDrawer.approvedAgentColor || '#bababa'" variant="flat" style="margin-left: 10px; position: relative; top: -2px;">
                    <p :style="{ color: display.proformaDrawer.approvedAgentFontColor || 'black' }">
                      {{ display.proformaDrawer.approvedAgentName || 'No ha sido aprobada' }}
                    </p>
                  </v-chip>
                </div>
                <p style="margin-top: 10px;"><strong>Aprobado el: </strong> {{ display.proformaDrawer.proformaApprovedDatetime ? $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(display.proformaDrawer.proformaApprovedDatetime) : 'No ha sido aprobada' }}</p>
              </div>
              <div style="width: 49.5%; margin-left: 0.5%;">
                <p><strong>Generado el: </strong> {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(display.proformaDrawer.proformaCreationDatetime) }}</p>
                <div style="display: flex; margin-top: 10px;">
                  <p><strong>Generado por: </strong></p>
                  <v-chip :color="display.proformaDrawer.creationAgentColor" variant="flat" style="margin-left: 10px; position: relative; top: -2px;">
                    <p :style="{color: display.proformaDrawer.creationAgentFontColor}">{{ display.proformaDrawer.creationAgentName }}</p>
                  </v-chip>
                </div>
                <div style="display: flex; margin-top: 30px;">
                  <p><strong>Despachado por: </strong></p>
                  <v-chip :color="display.proformaDrawer.dispatchedAgentColor || '#bababa'" variant="flat" style="margin-left: 10px; position: relative; top: -2px;">
                    <p :style="{ color: display.proformaDrawer.dispatchedAgentFontColor || 'black' }">
                      {{ display.proformaDrawer.dispatchedAgentName || 'No ha sido despachada' }}
                    </p>
                  </v-chip>
                </div>
                <p style="margin-top: 10px;"><strong>Despachado el: </strong> {{ display.proformaDrawer.proformaDispatchedDatetime ? $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(display.proformaDrawer.proformaDispatchedDatetime) : 'No ha sido despachada' }}</p>
              </div>
            </div>            
          </v-card>
          <br>
          <v-card color="background" style="padding: 20px 20px 0px 20px;">
            <p><strong>Nota: </strong></p>
            <br>
            <v-textarea @click:append-inner="updateProformaNote()" v-model="display.proformaDrawer.proformaNote" rows="3" append-inner-icon="mdi-content-save" variant="solo-filled" density="compact"></v-textarea>
          </v-card>
          <br>
          <v-card color="background" style="padding: 20px;">
            <v-data-table-virtual :headers="display.proformaProductHeaders" :items="display.proformaDrawer.proformaProducts" style="max-height: 500px; overflow-y: auto;">
              <template v-slot:item.productName="{ item }">
                <p>{{ item.productName }}</p>
                <p><strong>{{ item.productSKU }}</strong></p>
              </template>
              <template v-slot:item.proformaProductOriginalPrice="{ item }">
                <p><strong>{{ display.proformaDrawer.proformaCurrency }}</strong>{{ $generalFunctions.default.methods.parseAmountToDisplay(item.proformaProductOriginalPrice)}}</p>
              </template>
              <template v-slot:item.proformaProductDiscountMargin="{ item }">
                <p><strong>{{ display.proformaDrawer.proformaCurrency }}</strong>{{ $generalFunctions.default.methods.parseAmountToDisplay(item.proformaProductOriginalPrice * (item.proformaProductDiscountMargin/100))}} ({{ item.proformaProductDiscountMargin }}%)</p>
              </template>
              <template v-slot:item.proformaProductPrice="{ item }">
                <p><strong>{{ display.proformaDrawer.proformaCurrency }}</strong>{{ $generalFunctions.default.methods.parseAmountToDisplay(item.proformaProductPrice * item.proformaProductAmount)}}</p>
              </template>
            </v-data-table-virtual>
            <br>
            <div style="display: flex; width: 100%;">
              <div style="display: flex; width: 50%;">
                <div style="width: 49%; margin-right: 1%;">
                  <p style="margin-left: 10px; font-size: large;"><strong>{{ `Unidad${(display.proformaDrawer.proformaProducts.reduce((proformaUnitAmount, proformaProduct) => (proformaUnitAmount + proformaProduct.proformaProductAmount), 0)) > 1 ? 'es' : ''}: ` }}</strong></p>
                  <p style="margin-left: 10px; font-size: large;"><strong>Subtotal: </strong></p>
                  <p v-if="display.proformaDrawer.proformaType != 'Recibo'" style="margin-left: 10px; font-size: large;"><strong>Impuesto: </strong></p>
                  <p v-if="(display.proformaDrawer.proformaProducts.reduce((proformaDiscountAmount, proformaProduct) => (proformaDiscountAmount + ((proformaProduct.proformaProductPrice * (proformaProduct.proformaProductDiscountMargin/100)) * proformaProduct.proformaProductAmount)), 0)) > 0" style="margin-left: 10px; font-size: large;"><strong>Descuento: </strong></p>
                  <p v-if="display.proformaDrawer.proformaShippingAmount > 0" style="margin-left: 10px; font-size: large;"><strong>Envío: </strong></p>
                  <br>
                  <p style="margin-left: 10px; font-size: x-large;"><strong>TOTAL: </strong></p>
                </div>
                <div style="width: 49%; margin-left: 1%;">
                  <p style="font-size: large;">{{ display.proformaDrawer.proformaProducts.reduce((proformaUnitAmount, proformaProduct) => (proformaUnitAmount + proformaProduct.proformaProductAmount), 0) }}</p>
                  <p style="font-size: large;"><strong>{{ display.proformaDrawer.proformaCurrency }}</strong>{{ $generalFunctions.default.methods.parseAmountToDisplay(display.proformaDrawer.proformaProducts.reduce((proformaSubtotalAmount, proformaProduct) => (proformaSubtotalAmount + (proformaProduct.proformaProductPrice * proformaProduct.proformaProductAmount)), 0)) }}</p>
                  <p v-if="display.proformaDrawer.proformaType != 'Recibo'" style="font-size: large;"><strong>{{ display.proformaDrawer.proformaCurrency }}</strong>{{ $generalFunctions.default.methods.parseAmountToDisplay(display.proformaDrawer.proformaProducts.reduce((proformaTaxAmount, proformaProduct) => (proformaTaxAmount + ((proformaProduct.proformaProductPrice * (proformaProduct.proformaProductTaxMargin/100)) * proformaProduct.proformaProductAmount)), 0)) }}</p>
                  <p v-if="display.proformaDrawer.proformaProducts.filter(proformaProduct => proformaProduct.proformaProductDiscountMargin > 0).length > 0" style="font-size: large;"><strong>{{ display.proformaDrawer.proformaCurrency }}</strong>{{ $generalFunctions.default.methods.parseAmountToDisplay(display.proformaDrawer.proformaProducts.reduce((proformaDiscountAmount, proformaProduct) => (proformaDiscountAmount + ((proformaProduct.proformaProductOriginalPrice * (proformaProduct.proformaProductDiscountMargin/100)) * proformaProduct.proformaProductAmount)), 0)) }}</p>
                  <p v-if="display.proformaDrawer.proformaShippingAmount > 0" style="font-size: large;"><strong>{{ display.proformaDrawer.proformaCurrency }}</strong>{{ $generalFunctions.default.methods.parseAmountToDisplay(display.proformaDrawer.proformaShippingAmount) }}</p>
                  <br>
                  <p style="font-size: x-large;"><strong>{{ display.proformaDrawer.proformaCurrency }}</strong>{{ $generalFunctions.default.methods.parseAmountToDisplay((display.proformaDrawer.proformaProducts.reduce((proformaTotalAmount, proformaProduct) => (proformaTotalAmount + (proformaProduct.proformaProductPrice * proformaProduct.proformaProductAmount)), 0)) + (display.proformaDrawer.proformaType == 'Recibo' ? 0 : (display.proformaDrawer.proformaProducts.reduce((proformaTaxAmount, proformaProduct) => (proformaTaxAmount + ((proformaProduct.proformaProductPrice * (proformaProduct.proformaProductTaxMargin/100)) * proformaProduct.proformaProductAmount)), 0))) + (display.proformaDrawer.proformaShippingAmount ? display.proformaDrawer.proformaShippingAmount : 0)) }}</p>
                </div>
              </div>
              <div style="width: 50%; text-align: right; ">
                <v-tooltip location="left">
                  <div style="min-width: 300px;">
                    <div style="display: flex; padding: 5px;">
                      <v-icon color="info" center>mdi-account-cash</v-icon>
                      <p style="margin-left: 10px; font-size: large;">{{ display.proformaDrawer.proformaIsCredit ? 'Proforma a crédito' : 'Proforma a contado' }}</p>
                    </div>
                    <div style="display: flex; padding: 5px;">
                      <v-icon color="info" center>mdi-source-branch</v-icon>
                      <p style="margin-left: 10px; font-size: large;">{{ display.proformaDrawer.proformaType }}</p>
                    </div>
                    <div style="display: flex; padding: 5px;">
                      <v-icon color="info" center>mdi-cash-multiple</v-icon>
                      <p style="margin-left: 10px; font-size: large;">{{ display.proformaDrawer.proformaPaymentType }}</p>
                    </div>
                    <div v-if="display.proformaDrawer.proformaMixedPaymentID">
                      <br>
                      <div v-if="display.proformaDrawer.proformaMixedPaymentCashAmount" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-cash</v-icon>
                        <p style="margin-left: 10px; font-size: large;">₡ {{ display.proformaDrawer.proformaMixedPaymentCashAmount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
                      </div>
                      <div v-if="display.proformaDrawer.proformaMixedPaymentCardAmount" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-credit-card-outline</v-icon>
                        <p style="margin-left: 10px; font-size: large;">₡ {{ display.proformaDrawer.proformaMixedPaymentCardAmount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
                      </div>
                      <div v-if="display.proformaDrawer.proformaMixedPaymentSINPEAmount" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-cellphone</v-icon>
                        <p style="margin-left: 10px; font-size: large;">₡ {{ display.proformaDrawer.proformaMixedPaymentSINPEAmount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
                      </div>
                      <div v-if="display.proformaDrawer.proformaMixedPaymentDepositAmount" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-bank</v-icon>
                        <p style="margin-left: 10px; font-size: large;">₡ {{ display.proformaDrawer.proformaMixedPaymentDepositAmount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
                      </div>
                    </div>
                  </div>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" color="info" size="30" style="cursor: pointer;">mdi-information</v-icon>
                  </template>
                </v-tooltip>
              </div>
            </div>
          </v-card>
          <br>
          <v-card style="padding: 20px;" v-if="((display.proformaDrawer.proformaState == 'Proforma generada') && accessCredential['/proformas/approveProforma']) || (display.proformaDrawer.proformaState == 'Proforma aprobada')">
            <div v-if="accessCredential['/proformas/approveProforma'] && display.proformaDrawer.proformaState == 'Proforma generada'">
              <v-btn @click="openUpdateProformaDialog('Proforma aprobada')" dark block height="38" color="success">
                <h3>APROBAR PROFORMA</h3>
                <v-icon style="margin-left: 10px;" dark right>mdi-check-circle</v-icon>
              </v-btn>
              <br>
            </div>
            <div v-if="accessCredential['/proformas/approveProforma'] && (display.proformaDrawer.proformaState == 'Proforma generada' || display.proformaDrawer.proformaState == 'Proforma aprobada')">
              <v-btn @click="openUpdateProformaDialog('Proforma cancelada')" dark block height="38" color="error">
                <h3>CANCELAR PROFORMA</h3>
                <v-icon style="margin-left: 10px;" dark right>mdi-close-circle</v-icon>
              </v-btn>
            </div>
            <div v-if="accessCredential['/proformas/dispatchProforma'] && display.proformaDrawer.proformaState == 'Proforma aprobada'">
              <br>
              <v-btn @click="openDispatchProformaDialog()" dark block height="38" color="warning">
                <h3>DESPACHAR PROFORMA</h3>
                <v-icon style="margin-left: 10px;" dark right>mdi-package</v-icon>
              </v-btn>
            </div>
          </v-card>
        </div>
      </div>
  </v-navigation-drawer>


  <div style="margin: 2% 3% 0% 3%;">
    <v-card color="border" elevation="3" style="padding: 20px;">
      <v-expansion-panels v-model="navigation.searchVisible">
        <v-expansion-panel value="searchVisible">
          <v-expansion-panel-title>
            <p style="font-size: large;">Búsqueda de proformas</p>
            <template v-slot:actions>
              <v-icon icon="mdi-magnify"></v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <br>
            <div style="display: flex;">
              <v-switch v-model="input.proformaCreationOrDispatch" true-value="proformaCreationDatetime" false-value="proformaDispatchedDatetime" :label="input.proformaCreationOrDispatch == 'proformaCreationDatetime' ? 'Filtrar por fecha de creación' : 'Filtrar por fecha de despacho'" hide-details color="primary" style="margin-left: auto;"></v-switch>
            </div>
            <br>
            <div style="display: flex;">
              <div style="width: 49.5%; margin-right: 0.5%;">
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-text-field 
                      v-bind="props"
                      @keydown.enter.prevent="selectProformaFromProformasView()"
                      v-model="input.proformaID" 
                      :append-inner-icon="input.proformaID == null ? 'mdi-barcode' : 'mdi-barcode-off'" 
                      @click:append-inner="input.proformaID = null" 
                      label="Buscar por identificador interno de la proforma" 
                      variant="solo-filled" 
                      density="compact"
                    ></v-text-field>
                  </template>
                  <span style="max-width: 400px; display: block;">El identificador interno de la proforma es el consecutivo único de la proforma que se genera de forma automática a nivel interno. Si se coloca un identificador interno de la proforma, se buscará la única proforma que coincida con el mismo. Al presionar el ícono de la derecha se limpia el identificador interno de la proforma colocado</span>
                </v-tooltip>
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-autocomplete 
                      v-bind="props"
                      v-model="input.proformaContactID" 
                      :append-inner-icon="input.proformaContactID.length == 0 ? 'mdi-account' : 'mdi-account-off'" 
                      @click:append-inner="input.proformaContactID.length = 0; this.blurInput('proformaContactID')" 
                      :items="display.contactOptions" 
                      :loading="loader.selectContactFromProformasView" 
                      :disabled="loader.selectContactFromProformasView" 
                      multiple
                      chips
                      closable-chips
                      color="primary"
                      ref="proformaContactID"
                      item-title="contactName" 
                      item-value="contactID" 
                      label="Filtrar por contacto" 
                      placeholder="Todos los contactos"
                      variant="solo-filled" 
                      density="compact"
                    ></v-autocomplete>
                  </template>
                  <span style="max-width: 400px; display: block;">El contacto es la persona o empresa a la que se asocia la proforma. Este espacio permite selección múltiple para filtrar por varios contactos en paralelo. Si ningún contacto es seleccionado, se buscarán todas las proformas sin importar el contacto. Al presionar el ícono de la derecha se limpian los contactos seleccionados</span>
                </v-tooltip>
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-autocomplete 
                      v-bind="props"
                      v-model="input.proformaCreationAgentID" 
                      :append-inner-icon="input.proformaCreationAgentID.length == 0 ? 'mdi-briefcase' : 'mdi-briefcase-off'" 
                      @click:append-inner="input.proformaCreationAgentID.length = 0; this.blurInput('proformaContactID')" 
                      :items="display.agentOptions" 
                      :loading="loader.selectAgentByAgentLocalityIDAndByAgentIsActive" 
                      :disabled="loader.selectAgentByAgentLocalityIDAndByAgentIsActive"
                      multiple
                      chips 
                      closable-chips
                      color="primary"
                      ref="proformaContactID"
                      item-title="agentName" 
                      item-value="agentID" 
                      label="Filtrar por vendedor" 
                      placeholder="Todos los vendedores"
                      variant="solo-filled" 
                      density="compact"
                    ></v-autocomplete>
                  </template>
                  <span style="max-width: 400px; display: block;">El vendedor es el colaborador que crea la proforma. Este espacio permite selección múltiple para filtrar por varios vendedores en paralelo. Si ningún vendedor es seleccionado, se buscarán todas las proformas sin importar el vendedor. Al presionar el ícono de la derecha se limpian los vendedores seleccionados</span>
                </v-tooltip>
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-autocomplete 
                      v-bind="props"
                      v-model="input.proformaDispatchedAgentID" 
                      :append-inner-icon="input.proformaDispatchedAgentID.length == 0 ? 'mdi-package-variant' : 'mdi-package-variant-remove'" 
                      @click:append-inner="input.proformaDispatchedAgentID.length = 0; this.blurInput('proformaDispatchedAgentID')" 
                      :items="display.agentOptions" 
                      :loading="loader.selectAgentByAgentLocalityIDAndByAgentIsActive" 
                      :disabled="loader.selectAgentByAgentLocalityIDAndByAgentIsActive"
                      multiple
                      chips 
                      closable-chips
                      color="primary"
                      ref="proformaDispatchedAgentID"
                      item-title="agentName" 
                      item-value="agentID" 
                      label="Filtrar por despachador" 
                      placeholder="Todos los despachadores"
                      variant="solo-filled" 
                      density="compact"
                    ></v-autocomplete>
                  </template>
                  <span style="max-width: 400px; display: block;">El despachador es el colaborador que se encarga de la preparación de los productos incluidos en la proforma. Este espacio permite selección múltiple para filtrar por varios despachadores en paralelo. Si ningún despachador es seleccionado, se buscarán todas las proformas sin importar el despachador. Al presionar el ícono de la derecha se limpian los despachadores seleccionados</span>
                </v-tooltip>
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-autocomplete 
                      v-bind="props"
                      v-model="input.proformaState" 
                      :append-inner-icon="input.proformaState.length == 0 ? 'mdi-sync' : 'mdi-sync-off'" 
                      @click:append-inner="input.proformaState.length = 0; this.blurInput('proformaState')" 
                      :items="['Proforma generada', 'Proforma aprobada', 'Proforma facturada', 'Proforma cancelada']" 
                      multiple
                      chips
                      closable-chips
                      color="primary"
                      ref="proformaState"
                      item-title="title" 
                      item-value="value" 
                      label="Filtrar por estado" 
                      placeholder="Todos los estados"
                      variant="solo-filled" 
                      density="compact"
                    ></v-autocomplete>
                  </template>
                  <span style="max-width: 400px; display: block;">El estado que describe la actividad de la proforma. Este espacio permite selección múltiple para filtrar por varios estados de la proforma en paralelo. Si ningún estado de la proforma es seleccionado, se buscarán todas las proformas sin importar el estado de la proforma. Al presionar el ícono de la derecha se limpian los estados de la proforma seleccionados</span>
                </v-tooltip>
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-autocomplete 
                      v-bind="props"
                      v-model="input.proformaPaymentType" 
                      :append-inner-icon="input.proformaPaymentType.length == 0 ? 'mdi-cash' : 'mdi-cash-off'" 
                      @click:append-inner="input.proformaPaymentType.length = 0; this.blurInput('proformaPaymentType')" 
                      :items="display.proformaPaymentTypeOptions" 
                      multiple 
                      chips 
                      closable-chips
                      color="primary"
                      ref="proformaPaymentType"
                      label="Filtrar por método de pago" 
                      placeholder="Todos los métodos de pago" 
                      variant="solo-filled" 
                      density="compact"
                    ></v-autocomplete>
                  </template>
                  <span style="max-width: 400px; display: block;">El método de pago con el que se creó la proforma. Este espacio permite selección múltiple para filtrar por varios métodos de pago en paralelo. Si ningún método de pago es seleccionado, se buscarán todas las proformas sin importar el método de pago. Al presionar el ícono de la derecha se limpian los métodos de pago seleccionados</span>
                </v-tooltip>    
              </div>
              <div style="width: 49.5%; margin-left: 0.5%;">
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-text-field 
                      v-bind="props"
                      @keydown.enter.prevent="selectProformaFromProformasView()"
                      v-model="input.billID" 
                      :append-inner-icon="input.billID == null ? 'mdi-barcode' : 'mdi-barcode-off'" 
                      @click:append-inner="input.billID = null" 
                      label="Buscar por identificador interno de la factura" 
                      variant="solo-filled" 
                      density="compact"
                    ></v-text-field>
                  </template>
                  <span style="max-width: 400px; display: block;">El identificador interno de la factura es el consecutivo único de la factura que se genera de forma automática a nivel interno cuando una proforma es despachada. Si se coloca un identificador interno de la factura, se buscará la única proforma que coincida con el mismo. Al presionar el ícono de la derecha se limpia el identificador interno de la factura colocado</span>
                </v-tooltip>
                <v-text-field v-model="display.startDateFormatted" @click="navigation.startDatePickerIsVisible = true" readonly label="Filtrar por fecha inicial" append-inner-icon="mdi-calendar" variant="solo-filled" density="compact"></v-text-field>
                <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
                  <v-card style="display: flex;">
                    <v-date-picker v-model="input.startDate" hide-header color="primary" width="100%"></v-date-picker>
                    <v-chip @click="input.startDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
                  </v-card>
                </v-dialog>
                <v-text-field v-model="display.endDateFormatted" @click="navigation.endDatePickerIsVisible = true" readonly label="Filtrar por fecha final" append-inner-icon="mdi-calendar" variant="solo-filled" density="compact"></v-text-field>
                <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
                  <v-card>
                    <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
                    <v-chip @click="input.endDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
                  </v-card>
                </v-dialog>
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-select 
                      v-bind="props"
                      v-model="input.proformaIsCredit" 
                      :append-inner-icon="input.proformaIsCredit == null ? 'mdi-credit-card' : 'mdi-credit-card-off'" 
                      @click:append-inner="input.proformaIsCredit = null; this.blurInput('proformaIsCredit')" 
                      :items="display.proformaIsCreditOptions" 
                      ref="proformaIsCredit"
                      item-title="title" 
                      item-value="value" 
                      label="Filtrar por crédito"
                      variant="solo-filled" 
                      density="compact"
                    ></v-select>
                  </template>
                  <span style="max-width: 400px; display: block;">El crédito es el tipo de pago bajo el cual se generó la proforma. Si ningún crédito de la proforma es seleccionado, se buscarán todas las proformas sin importar el crédito de la proforma. Al presionar el ícono de la derecha se limpia el crédito de la proforma seleccionado</span>
                </v-tooltip>
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-select 
                      v-bind="props"
                      v-model="input.proformaCurrency" 
                      :append-inner-icon="input.proformaCurrency == null ? 'mdi-currency-usd' : 'mdi-currency-usd-off'" 
                      @click:append-inner="input.proformaCurrency = null; this.blurInput('proformaCurrency')" 
                      :items="display.proformaCurrencyOptions"
                      ref="proformaCurrency" 
                      item-title="title" 
                      item-value="value" 
                      label="Filtrar por divisa"
                      variant="solo-filled" 
                      density="compact"
                    ></v-select>
                  </template>
                  <span style="max-width: 400px; display: block;">La divisa es la moneda en la que se generó la proforma. Si ninguna divisa de la proforma es seleccionada, se buscarán todas las proformas sin importar la divisa de la proforma. Al presionar el ícono de la derecha se limpia la divisa de la proforma seleccionada</span>
                </v-tooltip>
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-autocomplete 
                      v-bind="props"
                      v-model="input.proformaType" 
                      :append-inner-icon="input.proformaType.length == 0 ? 'mdi-text-box-outline' : 'mdi-text-box-minus-outline'" 
                      @click:append-inner="input.proformaType.length = 0; this.blurInput('proformaType')" 
                      :items="display.proformaTypeOptions" 
                      multiple 
                      chips 
                      closable-chips
                      color="primary"
                      ref="proformaType"
                      label="Filtrar por tipo de proforma" 
                      placeholder="Todos los tipos de proforma" 
                      variant="solo-filled" 
                      density="compact"
                    ></v-autocomplete>
                  </template>
                  <span style="max-width: 400px; display: block;">El tipo de proforma que fue seleccionado a la hora de realizar la proforma. Este espacio permite selección múltiple para filtrar por varios tipos de proforma en paralelo. Si ningún tipo de proforma es seleccionado, se buscarán todas las proformas sin importar el tipo de proforma. Al presionar el ícono de la derecha se limpian los tipos de proforma seleeccionados</span>
                </v-tooltip>
              </div>
            </div>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-title>
                  Búsqueda avanzada por producto
                  <template v-slot:actions>
                    <v-icon icon="mdi-magnify"></v-icon>
                  </template>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <br>
                  <v-tooltip open-delay="2000" location="top">
                    <template v-slot:activator="{ props }">
                      <v-autocomplete 
                        v-bind="props"
                        v-model="input.productID" 
                        v-model:search="input.searchedProductID"
                        :append-inner-icon="input.productID ? 'mdi-alphabetical-variant-off' : 'mdi-alphabetical-variant'" 
                        @click:append-inner="cleanProductIDInput()" 
                        :items="display.productOptions" 
                        :loading="loader.selectProductBasicBasicInformationForBillsView" 
                        :disabled="loader.selectProductBasicBasicInformationForBillsView" 
                        @keydown.enter.prevent="selectProductByScanner(input.searchedProductID)"
                        ref="productID" 
                        item-title="productAutocompleteTitle" 
                        item-value="productID" 
                        label="Filtrar por producto" 
                        placeholder="Todos los productos" 
                        variant="solo-filled" 
                        density="compact"
                      ></v-autocomplete>
                    </template>
                    <span style="max-width: 400px; display: block;">Este espacio permite la búsqueda o escaneo de un producto. Si ningún producto es seleccionado, se buscarán todos los movimientos de producto sin importar los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpia el producto seleccionado</span>
                  </v-tooltip>   
                  <div style="display: flex;">
                    <div style="width: 49.5%; margin-right: 0.5%;">
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete 
                            v-bind="props"
                            v-model="input.productCategoryID" 
                            :append-inner-icon="input.productCategoryID.length == 0 ? 'mdi-cog' : 'mdi-cog-off'" 
                            @click:append-inner="input.productCategoryID.length = 0; this.blurInput('productCategoryID')" 
                            :items="display.productCategoryOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            closable-chips
                            color="primary"
                            ref="productCategoryID"
                            item-title="productCategoryName" 
                            item-value="productCategoryID" 
                            label="Filtrar por categoría del producto" 
                            placeholder="Todas las categorías del producto" 
                            variant="solo-filled" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">La categoría del producto hace referencia al primer nivel de categorización de un producto. Este espacio permite selección múltiple para filtrar por varias categorías de producto en paralelo. Si ninguna categoría de producto es seleccionada, se buscarán todos los movimientos de producto sin importar las categorías de los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpian las categorías de producto seleccionadas</span>
                      </v-tooltip>
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete 
                            v-bind="props"
                            v-model="input.productBrandID" 
                            :append-inner-icon="input.productBrandID.length == 0 ? 'mdi-briefcase-variant' : 'mdi-briefcase-variant-off'" 
                            @click:append-inner="input.productBrandID.length = 0; this.blurInput('productBrandID')" 
                            :items="display.productBrandOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            closable-chips
                            color="primary"
                            ref="productBrandID"
                            item-title="productBrandName" 
                            item-value="productBrandID" 
                            label="Filtrar por marca del producto" 
                            placeholder="Todas las marcas del producto" 
                            variant="solo-filled" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">La marca del producto hace referencia al segundo nivel de categorización de un producto. Este espacio permite selección múltiple para filtrar por varias marcas de producto en paralelo. Si ninguna marca de producto es seleccionada, se buscarán todos los movimientos de producto sin importar las marcas de los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpian las marcas de producto seleccionadas</span>
                      </v-tooltip>
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete 
                            v-bind="props"
                            v-model="input.productModelID" 
                            :append-inner-icon="input.productModelID.length == 0 ? 'mdi-source-branch' : 'mdi-source-branch-remove'" 
                            @click:append-inner="input.productModelID.length = 0; this.blurInput('productModelID')" 
                            :items="display.productModelOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            closable-chips
                            color="primary"
                            ref="productModelID"
                            item-title="productModelName" 
                            item-value="productModelID" 
                            label="Filtrar por modelo del producto" 
                            placeholder="Todos los modelos del producto" 
                            variant="solo-filled" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">El modelo del producto hace referencia al tercer nivel de categorización de un producto. Este espacio permite selección múltiple para filtrar por varios modelos de producto en paralelo. Si ningún modelo de producto es seleccionado, se buscarán todos los movimientos sin importar los modelos de los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpian los modelos de producto seleccionados</span>
                      </v-tooltip>
                    </div>
                    <div style="width: 49.5%; margin-left: 0.5%;">
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete 
                            v-bind="props"
                            v-model="input.productFlavorID" 
                            :append-inner-icon="input.productFlavorID.length == 0 ? 'mdi-water' : 'mdi-water-off'" 
                            @click:append-inner="input.productFlavorID.length = 0; this.blurInput('productFlavorID')" 
                            :items="display.productFlavorOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            closable-chips
                            color="primary"
                            ref="productFlavorID"
                            item-title="productFlavorName" 
                            item-value="productFlavorID" 
                            label="Filtrar por sabor del producto" 
                            placeholder="Todos los sabores del producto" 
                            variant="solo-filled" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">El sabor del producto hace referencia al primer tipo de categorización opcional de un producto. Este espacio permite selección múltiple para filtrar por varios sabores de producto en paralelo. Si ningún sabor de producto es seleccionado, se buscarán todas los movimientos de producto sin importar los sabores de los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpian los sabores de producto seleccionados</span>
                      </v-tooltip>
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete 
                            v-bind="props"
                            v-model="input.productNicotineLevelID" 
                            :append-inner-icon="input.productNicotineLevelID.length == 0 ? 'mdi-cloud-outline' : 'mdi-cloud-off-outline'" 
                            @click:append-inner="input.productNicotineLevelID.length = 0; this.blurInput('productNicotineLevelID')" 
                            :items="display.productNicotineLevelOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            closable-chips
                            color="primary"
                            ref="productNicotineLevelID"
                            item-title="productNicotineLevelName" 
                            item-value="productNicotineLevelID" 
                            label="Filtrar por graduación de nicotina del producto"  
                            placeholder="Todas las graduaciones de nicotina del producto" 
                            variant="solo-filled" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">La graduación de nicotina del producto hace referencia al segundo tipo de categorización opcional de un producto. Este espacio permite selección múltiple para filtrar por varias graduaciones de nicotina de producto en paralelo. Si ninguna graduación de nicotina de producto es seleccionada, se buscarán todos los movimientos de producto sin importar las graduaciones de nicotina de los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpian las graduaciones de nicotina de producto seleccionadas</span>
                      </v-tooltip>
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete
                            v-bind="props" 
                            v-model="input.productSizeID" 
                            :append-inner-icon="input.productSizeID.length == 0 ? 'mdi-signal' : 'mdi-signal-off'" 
                            @click:append-inner="input.productSizeID.length = 0; this.blurInput('productSizeID')" 
                            :items="display.productSizeOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            closable-chips
                            color="primary"
                            ref="productSizeID"
                            item-title="productSizeName" 
                            item-value="productSizeID" 
                            label="Filtrar por tamaño del producto" 
                            variant="solo-filled" 
                            placeholder="Todos los tamaños del producto" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">El tamaño del producto hace referencia al tercer tipo de categorización opcional de un producto. Este espacio permite selección múltiple para filtrar por varios tamaños de producto en paralelo. Si ningún tamaño de producto es seleccionado, se buscarán todos los movimientos de producto sin importar los tamaños de los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpian los tamaños de producto seleccionados</span>
                      </v-tooltip>
                    </div>
                  </div>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
            <br><br>
            <div v-if="loader.selectProformaFromProformasView" style="text-align: center;">
              <br>
              <v-progress-circular color="primary" indeterminate></v-progress-circular>
              <br><br>
            </div>
            <div v-else>
              <v-btn @click="selectProformaFromProformasView()" :disabled="loader.selectContactFromProformasView || loader.selectAgentByAgentLocalityIDAndByAgentIsActive || loader.selectProductBasicBasicInformationForBillsView || loader.selectProductSearchParameters ||  loader.selectProformaFromProformasView" dark block height="38" color="primary">
                <h3>BUSCAR</h3>
                <v-icon style="margin-left: 10px;" dark right>mdi-magnify</v-icon>
              </v-btn>
              <br>
              <v-btn @click="setDefaultValues()" :disabled="loader.selectContactFromProformasView || loader.selectAgentByAgentLocalityIDAndByAgentIsActive || loader.selectProductBasicBasicInformationForBillsView || loader.selectProductSearchParameters ||  loader.selectProformaFromProformasView" dark block height="38" color="#b3b3b3">
                <h3>LIMPIAR BÚSQUEDA</h3>
                <v-icon style="margin-left: 10px;" dark right>mdi-backspace</v-icon>
              </v-btn>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <br><br>
    <div v-if="display.proformaRows.length > 0">
      <v-card color="border" elevation="3" style="padding: 20px;">
        <v-data-table :headers="display.proformaHeaders" :items="display.proformaRows" :items-per-page="250" style="max-height: 700px; overflow-y: auto;">
          <template v-slot:item="row">
            <tr @click="openProformaDrawer(row.item.proformaID)" style="cursor: pointer;">
              <td>
                <div style="display: flex;">
                  <v-tooltip :text="row.item.proformaIsCredit ? 'Proforma a crédito' : 'Proforma a contado'" location="right">
                    <template v-slot:activator="{ props }">
                      <v-icon v-bind="props" :color="row.item.proformaIsCredit ? 'error' : 'success'" size="25">{{ row.item.proformaIsCredit ? 'mdi-cash-clock' : 'mdi-cash' }}</v-icon>
                    </template>
                  </v-tooltip>
                  <v-tooltip text="Información del cliente" location="right">
                    <template v-slot:activator="{ props }">
                      <v-icon v-bind="props" @click="$generalFunctions.default.methods.navigateTo(`contact/${row.item.contactID}`)" color="info" style="margin-left: 10px;" size="25">mdi-information</v-icon>
                    </template>
                  </v-tooltip>
                </div>
              </td>
              <td>
                <p>{{ row.item.proformaID }}</p>
              </td>
              <td>
                <v-chip :color="localStorage.proformaStateColors[row.item.proformaState].proformaStateColor" variant="flat">
                  <p :style="{color: localStorage.proformaStateColors[row.item.proformaState].proformaStateFontColor}">{{ row.item.proformaState }}</p>
                </v-chip>
              </td>
              <td>
                <p>{{ row.item.contactName }}</p>
                <p><strong>{{ row.item.contactSSN }}</strong></p>
              </td>
              <td>
                <p><strong>{{ row.item.proformaCurrency }}</strong>{{ $generalFunctions.default.methods.parseAmountToDisplay(row.item.proformaAmount) }}</p>
              </td>
              <td v-if="accessCredential['/proformas/seeTotal']">
                <p><strong>₡</strong>{{ $generalFunctions.default.methods.parseAmountToDisplay(row.item.proformaUtility) }}</p>
              </td>
              <td>
                <v-chip :color="row.item.creationAgentColor" variant="flat">
                  <p :style="{color: row.item.creationAgentFontColor}">{{ row.item.creationAgentName }}</p>
                </v-chip>
              </td>
              <td>
                <p>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(row.item.proformaCreationDatetime) }}</p>
                <p><strong>{{ row.item.proformaCreationTimer }}</strong></p>
              </td>
              <td>
                <v-chip :color="row.item.approvedAgentColor || '#bababa'" variant="flat">
                  <p :style="{color: row.item.approvedAgentFontColor || 'black'}">{{ row.item.approvedAgentName || 'No ha sido aprobada' }}</p>
                </v-chip>
              </td>
              <td>
                <p>{{ row.item.proformaApprovedDatetime ? $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(row.item.proformaApprovedDatetime) : 'No ha sido aprobada' }}</p>
                <p v-if="row.item.proformaApprovedDatetime"><strong>{{ row.item.proformaApprovedTimer }}</strong></p>
              </td>
              <td>
                <v-chip :color="row.item.dispatchedAgentColor || '#bababa'" variant="flat">
                  <p :style="{color: row.item.dispatchedAgentFontColor || 'black'}">{{ row.item.dispatchedAgentName || 'No ha sido despachada' }}</p>
                </v-chip>
              </td>
              <td>
                <p>{{ row.item.proformaDispatchedDatetime ? $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(row.item.proformaDispatchedDatetime) : 'No ha sido despachada'}}</p>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <br><br>
  </div>
</template>

<style scoped>
  .stockWarningLabel {
    cursor: pointer;
    color: rgb(171, 0, 0);
    animation: stockWarningLabelAnimation 2s infinite;
  }

  @keyframes stockWarningLabelAnimation {
    0% {transform: scale(1)}
    50% {transform: scale(1.2)}
    100% {transform: scale(1)}
  }
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProformasView',
  inject: ['$generalFunctions'],

  
  data: () => ({
    input: 
    {
      proformaID: null,
      billID: null,
      proformaContactID: [],
      proformaCreationAgentID: [],
      proformaDispatchedAgentID: [],
      proformaState: [],
      proformaPaymentType: [],
      proformaIsCredit: null,
      proformaCurrency: null,
      proformaType: [],
      startDate: null,
      endDate: null,
      proformaCreationOrDispatch: 'proformaCreationDatetime',

      productID: null,
      searchedProductID: null,
      productCategoryID: [],
      productBrandID: [],
      productModelID: [],
      productFlavorID: [],
      productNicotineLevelID: [],
      productSizeID: [],

      updatingProformaState: null,
      updatingProformaImage: null
    },

    navigation: 
    {
      searchVisible: 'searchVisible',
      startDatePickerIsVisible: false,
      endDatePickerIsVisible: false,
      proformaDrawerIsVisible: false,
      updateProformaDialogIsVisible: false,
      dispatchProformaDialogIsVisible: false,
      updateInterval: null,
      proformaFileDialogIsVisible: false
    },

    loader: 
    {
      selectContactFromProformasView: false,
      selectAgentByAgentLocalityIDAndByAgentIsActive: false,
      selectProductBasicBasicInformationForBillsView: false,
      selectProductSearchParameters: false,
      selectProformaFromProformasView: false,
      selectProformaFromProformaDrawer: false,
      updateProformaState: false,
      dispatchProforma: false
    },

    display:
    {
      contactOptions: [],
      agentOptions: [],
      proformaIsCreditOptions:
      [
        {title: 'Todos los créditos', value: null},
        {title: 'Contado', value: 0},
        {title: 'Crédito', value: 1}
      ],
      proformaCurrencyOptions: 
      [
        {title: 'Todas las divisas', value: null},
        {title: '₡', value: '₡'},
        {title: '$', value: '$'}
      ],
      proformaPaymentTypeOptions: 
      [
        {title: 'Efectivo', value: 'Efectivo'},
        {title: 'Tarjeta', value: 'Tarjeta'},
        {title: 'SINPE', value: 'SINPE'},
        {title: 'Transferencia o depósito', value: 'Transferencia o depósito'},
        {title: 'Página web', value: 'Página web'},
        {title: 'Uber', value: 'Uber'},
        {title: 'Rappi', value: 'Rappi'},
        {title: 'Mixto', value: 'Mixto'}
      ],
      proformaTypeOptions: 
      [
        {title: 'Factura electrónica', value: 'Factura electrónica'},
        {title: 'Tiquete electrónico', value: 'Tiquete electrónico'},
        {title: 'Recibo', value: 'Recibo'}
      ],
      startDateFormatted: null,
      endDateFormatted: null,
      productOptions: [],
      productCategoryOptions: [],
      productBrandOptions: [],
      productModelOptions: [],
      productFlavorOptions: [],
      productNicotineLevelOptions: [],
      productSizeOptions: [],
      proformaHeaders: 
      [
        {key: 'proformaIsCredit', title: null},
        {key: 'proformaID', title: 'ID'},
        {key: 'proformaState', title: 'ESTADO'},
        {key: 'contactName', title: 'CONTACTO'},
        {key: 'proformaTotal', title: 'MONTO'},
        {key: 'creationAgentName', title: 'GENERADO POR'},
        {key: 'proformaCreationDatetime', title: 'GENERADO EL'},
        {key: 'approvedAgentName', title: 'APROBADO POR'},
        {key: 'proformaApprovedDatetime', title: 'APROBADO EL'},
        {key: 'dispatchedAgentName', title: 'DESPACHADA POR'},
        {key: 'proformaDispatchedDatetime', title: 'DESPACHADA EL'}
      ],
      proformaRows: [],
      proformaDrawer: null,
      proformaProductHeaders:
      [
        {key: 'productName', title: 'NOMBRE'},
        {key: 'proformaProductOriginalPrice', title: 'PRECIO'},
        {key: 'proformaProductDiscountMargin', title: 'DESCUENTO'},
        {key: 'proformaProductAmount', title: 'CANTIDAD'},
        {key: 'proformaProductPrice', title: 'SUBTOTAL'}
      ],
      dispatchProformaProductHeaders:
      [
        {key: 'proformaProductReady', title: null},
        {key: 'productName', title: 'NOMBRE'},
        {key: 'proformaProductAmount', title: 'CANTIDAD'},
        {key: 'productStock', title: 'STOCK'},
      ],
      proformaFile: null
    },

    localStorage: 
    {
      localityID: null,
      agentID: null,
      proformaStateColors: null
    },

    assets: 
    {
      placeholderImage: null
    },

    accessCredential: {}

  }),

  watch: {
    'input.startDate': function() {
      this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB');
      this.navigation.startDatePickerIsVisible = false;
    },
    'input.endDate': function() {
      this.display.endDateFormatted = new Date(this.input.endDate).toLocaleDateString('en-GB');
      this.navigation.endDatePickerIsVisible = false;
    },
    'input.updatingProformaState': function(){
      this.input.updatingProformaImage = null;
    }
  },

  methods: {
    openProformaFileDialog(proformaFile){
      this.display.proformaFile = proformaFile;
      this.navigation.proformaFileDialogIsVisible = true;
    },

    async dispatchProforma(){
      if (this.display.proformaDrawer.proformaProducts.filter(proformaProduct => proformaProduct.proformaProductReady).length == this.display.proformaDrawer.proformaProducts.length){
        this.loader.dispatchProforma = true;
        const dispatchProformaRequestQuery =
        {
          'proformaID': this.display.proformaDrawer.proformaID,
          'proformaDispatchedAgentID': this.localStorage.agentID,
          'proformaDispatchedImage': this.input.updatingProformaImage,
          'billLocalityID': 8, // x mayor
          'billStockLocalityID': 5, // bodega
        };
        const dispatchProformaResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/proforma/functions/dispatchProforma', dispatchProformaRequestQuery);
        if (dispatchProformaResult.success){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ÉXITO',
            'notificationDialogBody': 'Se ha despachado la proforma exitosamente',
            'notificationDialogColor': 'success',
            'notificationDialogIsPersistent': false
          });
          this.navigation.dispatchProformaDialogIsVisible = false;
          await this.selectProformaFromProformasView();
          await this.openProformaDrawer(this.display.proformaDrawer.proformaID);
          this.$root.printableBillDialog.showPrintableBillDialog(dispatchProformaResult.result);
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al despachar la proforma, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
        this.loader.dispatchProforma = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al despachar la proforma, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },
    
    async updateProformaNote(){
      this.loader.selectProformaFromProformaDrawer = true;
      const updateProformaNoteRequestQuery =
      {
        'proformaID': this.display.proformaDrawer.proformaID,
        'proformaNote': this.display.proformaDrawer.proformaNote
      };
      const updateProformaNoteResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/proforma/functions/updateProformaNote', updateProformaNoteRequestQuery);
      if (updateProformaNoteResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'Se ha actualizado la nota de la proforma exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        await this.openProformaDrawer(this.display.proformaDrawer.proformaID);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al actualizar la nota de la proforma, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectProformaFromProformaDrawer = false;
    },

    async updateProformaState(){
      this.loader.updateProformaState = true;
      const updateProformaStateRequestQuery =
      {
        'proformaID': this.display.proformaDrawer.proformaID,
        'proformaApprovedAgentID': this.localStorage.agentID,
        'proformaState': this.input.updatingProformaState,
        'proformaApprovedImage': this.input.updatingProformaImage
      };
      const updateProformaStateResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/proforma/functions/updateProformaState', updateProformaStateRequestQuery);
      if (updateProformaStateResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'Se ha actualizado el estado de la proforma exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        this.navigation.updateProformaDialogIsVisible = false;
        await this.selectProformaFromProformasView();
        await this.openProformaDrawer(this.display.proformaDrawer.proformaID);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al actualizar el estado de la proforma, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.updateProformaState = false;
    },

    async openProformaDrawer(proformaID){
      this.navigation.proformaDrawerIsVisible = true;
      this.loader.selectProformaFromProformaDrawer = true;
      const selectProformaFromProformaDrawerRequestQuery =
      {
        'proformaID': proformaID,
        'localityID': this.localStorage.localityID
      };
      const selectProformaFromProformaDrawerResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/proforma/functions/selectProformaFromProformaDrawer', selectProformaFromProformaDrawerRequestQuery);
      if (selectProformaFromProformaDrawerResult.success){
        this.display.proformaDrawer = selectProformaFromProformaDrawerResult.result;
        this.display.proformaDrawer.proformaLoader = false;
        this.display.proformaDrawer.proformaProducts = this.display.proformaDrawer.proformaProducts.map(proformaProduct => ({
          ...proformaProduct,
          'proformaProductReady': false
        }));
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la información de la proforma, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
        this.navigation.proformaDrawerIsVisible = false;
      }
      this.loader.selectProformaFromProformaDrawer = false;
    },

    async selectProformaFromProformasView(){
      this.loader.selectProformaFromProformasView = true;
      const selectProformaFromProformasViewRequestQuery =
      {
        'proformaID': this.input.proformaID,
        'billID': this.input.billID,
        'proformaContactID': this.input.proformaContactID,
        'proformaCreationAgentID': this.input.proformaCreationAgentID,
        'proformaDispatchedAgentID': this.input.proformaDispatchedAgentID,
        'proformaState': this.input.proformaState,
        'proformaPaymentType': this.input.proformaPaymentType,
        'proformaIsCredit': this.input.proformaIsCredit,
        'proformaCurrency': this.input.proformaCurrency,
        'proformaType': this.input.proformaType,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate,
        'proformaCreationOrDispatch': this.input.proformaCreationOrDispatch,
        'productID': this.input.productID,
        'productCategoryID': this.input.productCategoryID,
        'productBrandID': this.input.productBrandID,
        'productModelID': this.input.productModelID,
        'productFlavorID': this.input.productFlavorID,
        'productNicotineLevelID': this.input.productNicotineLevelID,
        'productSizeID': this.input.productSizeID
      };
      const selectProformaFromProformasViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/proforma/functions/selectProformaFromProformasView', selectProformaFromProformasViewRequestQuery);
      if (selectProformaFromProformasViewResult.success){
        this.display.proformaRows = selectProformaFromProformasViewResult.result.map(proformaRow => ({
          ...proformaRow,
          'proformaCreationTimer': null,
          'proformaApprovedTimer': null
        }));
        this.updateTimers();
        if (this.display.proformaRows.length == 0){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ATENCIÓN',
            'notificationDialogBody': 'No se ha encontrado ninguna proforma con los filtros seleccionados, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        } else {
          this.navigation.searchVisible = '';
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las proformas, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectProformaFromProformasView = false;
    },

    async createProformaPDFDocument(proforma){
      proforma.proformaLoader = true;
      const createProformaPDFDocumentRequestQuery =
      {
        'reprint': true,
        'proformaID': proforma.proformaID
      };
      const createProformaPDFDocumentResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/proforma/functions/createProformaPDFDocument', createProformaPDFDocumentRequestQuery);
      if (createProformaPDFDocumentResult.success){
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': createProformaPDFDocumentResult.result.documentFile.data,
          'printablePDFDocumentName': createProformaPDFDocumentResult.result.documentName,
          'printableExcelDocumentFile': null,
          'printableExcelDocumentName': null
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al reimprimir la proforma, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      proforma.proformaLoader = false;
    },

    async selectAgentByAgentLocalityIDAndByAgentIsActive(){
      this.loader.selectAgentByAgentLocalityIDAndByAgentIsActive = true;
      this.input.selectedProductInAndOutAgentID = null;
      const selectAgentByAgentLocalityIDAndByAgentIsActiveRequestQuery = 
      {
        'agentLocalityID': [], 
        'agentIsActive': true
      };
      const selectAgentByAgentLocalityIDAndByAgentIsActiveResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/select/byAgentLocalityIDAndByAgentIsActive', selectAgentByAgentLocalityIDAndByAgentIsActiveRequestQuery);
      if (selectAgentByAgentLocalityIDAndByAgentIsActiveResult.success){
        this.display.agentOptions = selectAgentByAgentLocalityIDAndByAgentIsActiveResult.result;
        this.loader.selectAgentByAgentLocalityIDAndByAgentIsActive = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los facturadores, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectContactFromProformasView(){
      this.loader.selectContactFromProformasView = true;
      const selectContactFromProformasViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/contact/functions/selectContactFromInsertProformaDialog');
      if (selectContactFromProformasViewResult.success){
        this.display.contactOptions = selectContactFromProformasViewResult.result;
        this.loader.selectContactFromProformasView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los contactos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductBasicBasicInformationForBillsView(){
      this.loader.selectProductBasicBasicInformationForBillsView = true;
      const selectProductBasicBasicInformationForBillsViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductBasicBasicInformationForBillsView');
      if (selectProductBasicBasicInformationForBillsViewResult.success){
        this.display.productOptions = selectProductBasicBasicInformationForBillsViewResult.result.map(productOption => ({
          'productID': productOption.i,
          'productSKU': productOption.s,
          'productName': productOption.n,
          'productAutocompleteTitle': productOption.s + ' - ' + productOption.n
        }));
        this.loader.selectProductBasicBasicInformationForBillsView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los facturadores, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductSearchParameters(){
      this.loader.selectProductSearchParameters = true;
      const selectProductSearchParametersResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductSearchParameters');
      if (selectProductSearchParametersResult.success){
        this.display.productCategoryOptions = selectProductSearchParametersResult.result.productCategory;
        this.display.productBrandOptions = selectProductSearchParametersResult.result.productBrand;
        this.display.productModelOptions = selectProductSearchParametersResult.result.productModel;
        this.display.productFlavorOptions = selectProductSearchParametersResult.result.productFlavor;
        this.display.productNicotineLevelOptions = selectProductSearchParametersResult.result.productNicotineLevel;
        this.display.productSizeOptions = selectProductSearchParametersResult.result.productSize;
        this.loader.selectProductSearchParameters = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de características de los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    openUpdateProformaDialog(updatingProformaState){
      this.input.updatingProformaState = updatingProformaState;
      this.input.updatingProformaImage = null;
      this.navigation.updateProformaDialogIsVisible = true;
    },

    openDispatchProformaDialog(){
      this.input.updatingProformaImage = null;
      this.loader.dispatchProforma = false;
      this.display.proformaDrawer.proformaProducts.forEach(proformaProduct => {
        proformaProduct.proformaProductReady = false
      });
      this.navigation.dispatchProformaDialogIsVisible = true;
    },

    uploadImage(){
      document.getElementById('uploadProformaPaymentImage').click();
    },


    processImage() {
  const previousImage = this.input.updatingProformaImage;
  try {
    const selectedFile = this.$refs.uploadProformaPaymentImage.files[0];
    
    // Verificar si se seleccionó un archivo
    if (!selectedFile) {
      throw new Error('No file selected');
    }

    const fileType = selectedFile.type;

    // Verificar si el archivo es una imagen o un PDF
    if (fileType.startsWith('image/')) {
      // Procesar imagen
      const imageFileReader = new FileReader();
      imageFileReader.readAsDataURL(selectedFile);
      imageFileReader.onload = () => {
        this.input.updatingProformaImage = imageFileReader.result;
      };
      imageFileReader.onerror = () => {
        this.$root.notificationDialog.showNotificationDialog({
          notificationDialogTitle: 'ERROR',
          notificationDialogBody: 'Ha ocurrido un error inesperado al cargar la imagen del pago de la proforma. Por favor intente nuevamente. Si el problema persiste, consulte a su administrador de sistema.',
          notificationDialogColor: 'error',
          notificationDialogIsPersistent: false,
        });
        throw new Error('Error when charging the image');
      };
    } else if (fileType === 'application/pdf') {
      // Procesar PDF
      const pdfFileReader = new FileReader();
      pdfFileReader.readAsDataURL(selectedFile);
      pdfFileReader.onload = () => {
        this.input.updatingProformaImage = pdfFileReader.result; // Guardar como base64 si es necesario
      };
      pdfFileReader.onerror = () => {
        this.$root.notificationDialog.showNotificationDialog({
          notificationDialogTitle: 'ERROR',
          notificationDialogBody: 'Ha ocurrido un error inesperado al cargar el archivo PDF. Por favor intente nuevamente. Si el problema persiste, consulte a su administrador de sistema.',
          notificationDialogColor: 'error',
          notificationDialogIsPersistent: false,
        });
        throw new Error('Error when charging the PDF');
      };
    } else {
      this.$root.notificationDialog.showNotificationDialog({
        notificationDialogTitle: 'ERROR',
        notificationDialogBody: 'Formato no compatible. Solo se aceptan imágenes o archivos PDF.',
        notificationDialogColor: 'error',
        notificationDialogIsPersistent: false,
      });
      throw new Error('Unsupported file format');
    }
  } catch (error) {
    this.input.updatingProformaImage = previousImage;
    console.error(error.message);
  }
    },

    updateTimers(){
      this.navigation.updateInterval = setInterval(() => {
        const formatNumber = (num) => String(num).padStart(2, '0');
        const calculateTimer = (startDate, endDate) => {
          const diff = Math.abs(new Date(endDate || new Date()) - new Date(startDate));
          return `${formatNumber(Math.floor(diff / (1000 * 60 * 60 * 24)))}:${formatNumber(Math.floor((diff / (1000 * 60 * 60)) % 24))}:${formatNumber(Math.floor((diff / (1000 * 60)) % 60))}:${formatNumber(Math.floor((diff / 1000) % 60))}`;
        };
        this.display.proformaRows.forEach(proforma => {
          proforma.proformaCreationTimer = calculateTimer(proforma.proformaCreationDatetime, proforma.proformaApprovedDatetime);
          if (proforma.proformaApprovedDatetime && proforma.proformaState != 'Proforma cancelada') {
            proforma.proformaApprovedTimer = calculateTimer(proforma.proformaApprovedDatetime, proforma.proformaDispatchedDatetime);
          }
        });
      }, 1000);
    },

    blurInput(inputReference){
      const blurInputInterval = setInterval(() => {
        if (this.$refs[inputReference]) {
          this.$refs[inputReference].blur();
          clearInterval(blurInputInterval);
        }
      }, 1);
    },

    selectProductByScanner(){
      const selectedProduct = this.display.productOptions.find(productOption => productOption.productID == this.input.searchedProductID);
      if (selectedProduct){
        this.blurInput('productID');
        this.input.productID = selectedProduct.productID;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': null,
          'notificationDialogBody': 'El producto escaneado no ha sido encontrado, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
      }
    },

    cleanProductIDInput(){
      this.input.searchedProductID = null;
      this.input.productID = null;
      this.blurInput('productID');
    },

    async setDefaultValues(){
      this.input.proformaID = null;
      this.input.billID = null;
      this.input.proformaContactID.length = 0;
      this.input.proformaCreationAgentID.length = 0;
      this.input.proformaDispatchedAgentID.length = 0;
      this.input.proformaState.length = 0;
      this.input.proformaPaymentType.length = 0;
      this.input.proformaIsCredit = null;
      this.input.proformaCurrency = null;
      this.input.updatingProformaState = null;
      this.input.proformaType.length = 0;
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - startDate.getDay() + 1);
      this.input.startDate = startDate;
      this.input.endDate = new Date();
      this.input.proformaCreationOrDispatch = 'proformaCreationDatetime';
      this.input.searchedProductID = null;
      this.input.productID = null;
      this.input.productCategoryID.length = 0;
      this.input.productBrandID.length = 0;
      this.input.productModelID.length = 0;
      this.input.productFlavorID.length = 0;
      this.input.productNicotineLevelID.length = 0;
      this.input.productSizeID.length = 0;
      this.input.updatingProformaImage = null;
      this.navigation.startDatePickerIsVisible = false;
      this.navigation.endDatePickerIsVisible = false;
      this.navigation.updateProformaDialogIsVisible = false;
      this.display.startDateFormatted = null;
      this.display.endDateFormatted = null;
      this.display.proformaRows.length = 0;
    },

    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.localStorage.proformaStateColors = this.$generalFunctions.default.data.proformaStateColors;
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
      this.assets.placeholderImage = this.$generalFunctions.default.data.placeholderImage;
    },

    prepareBasedOnAccessCredential(){
      this.display.proformaHeaders =
      [
        {key: 'proformaIsCredit', title: null},
        {key: 'proformaID', title: 'ID'},
        {key: 'proformaState', title: 'ESTADO'},
        {key: 'contactName', title: 'CONTACTO'},
        {key: 'proformaAmount', title: 'MONTO'},
        ...(this.accessCredential['/proformas/seeTotal'] ? [{key: 'proformaUtility', title: 'UTILIDAD', sortable: false}] : []),
        {key: 'creationAgentName', title: 'GENERADO POR'},
        {key: 'proformaCreationDatetime', title: 'GENERADO EL'},
        {key: 'approvedAgentName', title: 'APROBADO POR'},
        {key: 'proformaApprovedDatetime', title: 'APROBADO EL'},
        {key: 'dispatchedAgentName', title: 'DESPACHADA POR'},
        {key: 'proformaDispatchedDatetime', title: 'DESPACHADA EL'}
      ];
    },

  },

  async mounted(){
    await this.setDefaultValues();
    await this.getLocalStorageData();
    this.selectContactFromProformasView();
    this.selectAgentByAgentLocalityIDAndByAgentIsActive();
    this.selectProductBasicBasicInformationForBillsView();
    this.selectProductSearchParameters();
    this.prepareBasedOnAccessCredential();
  },

  beforeUnmount(){
    if (this.navigation.updateInterval){
      clearInterval(this.navigation.updateInterval);
    }
  }

});

</script>
