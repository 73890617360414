<template>
  <v-dialog v-model="navigation.billDialogIsVisible" width="auto" :persistent="createBillPDFDocument">
    <v-card width="1200" class="billDialogContainer">
      <div v-if="loader.selectBillForBillDialog || loader.createBillPDFDocument" style="text-align: center;">
        <v-progress-circular color="info" indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <p class="billDialogLabel"><strong>Identificador interno: </strong>{{ display.bill.billID }}</p>
        <p class="billDialogLabel"><strong>Clave numérica: </strong>{{ display.bill.billTaxID || 'Sin clave numérica' }}</p>
        <div class="billDialogChipContainer">
          <p class="billDialogLabel" style="position: relative; top: 10px;"><strong>Estado: </strong></p>
          <v-chip class="billDialogChip" :color="localStorage.billStateColors[display.bill.billState].billStateColor" variant="flat">
            <p :style="{color: localStorage.billStateColors[display.bill.billState].billStateFontColor}">{{ display.bill.billState }}</p>
          </v-chip>
        </div>
        <div class="billDialogChipContainer" style="margin-top: 10px;">
          <p class="billDialogLabel" style="position: relative; top: 10px;"><strong>Localidad: </strong></p>
          <v-chip class="billDialogChip" :color="display.bill.billLocalityColor" variant="flat">
            <p :style="{color: display.bill.billLocalityFontColor}">{{ display.bill.billLocalityName }}</p>
          </v-chip>
        </div>
        <div class="billDialogChipContainer" style="margin-top: 10px;">
          <p class="billDialogLabel" style="position: relative; top: 10px;"><strong>Agente facturador: </strong></p>
          <v-chip class="billDialogChip" :color="display.bill.billAgentColor" variant="flat">
            <p :style="{color: display.bill.billAgentFontColor}">{{ display.bill.billAgentName }}</p>
          </v-chip>
        </div>
        <br><br>
        <p class="billDialogLabel"><strong>Tipo de factura: </strong>{{ display.bill.billType }}</p>
        <p class="billDialogLabel"><strong>Método de pago: </strong>{{ display.bill.billPaymentType }}</p>
        <div v-if="display.bill.billCashPaymentID">
          <br>
          <p class="billDialogLabel"><strong>Cantidad recibida: </strong>{{ display.bill.billCurrency == '₡' ?  `${display.bill.billCurrency} ${$generalFunctions.default.methods.parseAmountToDisplay(display.bill.billCashPaymentCashAmount)}` : `${$generalFunctions.default.methods.parseAmountToDisplay(display.bill.billCashPaymentCashAmount)} ${display.bill.billCurrency}` }}</p>
          <p class="billDialogLabel"><strong>Cantidad entregada como cambio: </strong>{{ display.bill.billCurrency == '₡' ?  `${display.bill.billCurrency} ${$generalFunctions.default.methods.parseAmountToDisplay(display.bill.billCashPaymentChangeAmount)}` : `${$generalFunctions.default.methods.parseAmountToDisplay(display.bill.billCashPaymentChangeAmount)} ${display.bill.billCurrency}` }}</p>
          <br>
        </div>
        <div v-if="display.bill.billMixedPaymentID">
          <br>
          <p v-if="display.bill.billMixedPaymentCashAmount != 0" class="billDialogLabel"><strong>Cantidad en efectivo: </strong>{{ display.bill.billCurrency == '₡' ?  `${display.bill.billCurrency} ${$generalFunctions.default.methods.parseAmountToDisplay(display.bill.billMixedPaymentCashAmount)}` : `${$generalFunctions.default.methods.parseAmountToDisplay(display.bill.billMixedPaymentCashAmount)} ${display.bill.billCurrency}` }}</p>
          <p v-if="display.bill.billMixedPaymentCardAmount != 0" class="billDialogLabel"><strong>Cantidad en tarjeta: </strong>{{ display.bill.billCurrency == '₡' ?  `${display.bill.billCurrency} ${$generalFunctions.default.methods.parseAmountToDisplay(display.bill.billMixedPaymentCardAmount)}` : `${$generalFunctions.default.methods.parseAmountToDisplay(display.bill.billMixedPaymentCardAmount)} ${display.bill.billCurrency}` }}</p>
          <p v-if="display.bill.billMixedPaymentSINPEAmount != 0" class="billDialogLabel"><strong>Cantidad en SINPE: </strong>{{ display.bill.billCurrency == '₡' ?  `${display.bill.billCurrency} ${$generalFunctions.default.methods.parseAmountToDisplay(display.bill.billMixedPaymentSINPEAmount)}` : `${$generalFunctions.default.methods.parseAmountToDisplay(display.bill.billMixedPaymentSINPEAmount)} ${display.bill.billCurrency}` }}</p>
          <p v-if="display.bill.billMixedPaymentDepositAmount != 0" class="billDialogLabel"><strong>Cantidad en transferencia o depósito: </strong>{{ display.bill.billCurrency == '₡' ?  `${display.bill.billCurrency} ${$generalFunctions.default.methods.parseAmountToDisplay(display.bill.billMixedPaymentDepositAmount)}` : `${$generalFunctions.default.methods.parseAmountToDisplay(display.bill.billMixedPaymentDepositAmount)} ${display.bill.billCurrency}` }}</p>
          <br>
        </div>
        <p class="billDialogLabel"><strong>Fecha y hora de creación: </strong>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(display.bill.billCreationDatetime) }}</p>
        <div v-if="display.bill.clientSSN || display.bill.clientName || display.bill.clientEmail || display.bill.clientPhoneNumber">
          <br><br>
          <p v-if="display.bill.clientSSN" class="billDialogLabel"><strong>SSN del cliente: </strong>{{ display.bill.clientSSN }}</p>
          <p v-if="display.bill.clientName" class="billDialogLabel"><strong>Nombre del cliente: </strong>{{ display.bill.clientName }}</p>
          <p v-if="display.bill.clientEmail" class="billDialogLabel"><strong>Correo del cliente: </strong>{{ display.bill.clientEmail }}</p>
          <p v-if="display.bill.clientPhoneNumber" class="billDialogLabel"><strong>Número del cliente: </strong>{{ display.bill.clientPhoneNumber }}</p>
        </div>
        <div v-if="display.bill.billCancelationID">
          <br><br>
          <p v-if="display.bill.billCancelationID" class="billDialogLabel"><strong>ID de la cancelación: </strong>{{ display.bill.billCancelationID }}</p>
          <div class="billDialogChipContainer">
            <p class="billDialogLabel" style="position: relative; top: 10px;"><strong>Localidad de la cancelación: </strong></p>
            <v-chip class="billDialogChip" :color="display.bill.billCancelationLocalityColor" variant="flat">
              <p :style="{color: display.bill.billCancelationLocalityFontColor}">{{ display.bill.billCancelationLocalityName }}</p>
            </v-chip>
          </div>
          <div class="billDialogChipContainer">
            <p class="billDialogLabel" style="position: relative; top: 10px; margin-bottom: 10px;"><strong>Cancelado por: </strong></p>
            <v-chip class="billDialogChip" :color="display.bill.billCancelationAgentColor" variant="flat">
              <p :style="{color: display.bill.billCancelationAgentFontColor}">{{ display.bill.billCancelationAgentName }}</p>
            </v-chip>
          </div>
          <p class="billDialogLabel"><strong>Tipo de la cancelación: </strong>{{ display.bill.billCancelationType }}</p>
          <p class="billDialogLabel"><strong>Motivo de la cancelación: </strong>{{ display.bill.billCancelationReason }}</p>
          <p class="billDialogLabel" style="margin-top: 10px;"><strong>Nota de la cancelación: </strong></p>
          <v-textarea v-model="display.bill.billCancelationNote" style="margin-top: 10px;" rows="3" readonly variant="solo-filled"></v-textarea>
          <p class="billDialogLabel"><strong>Fecha y hora de la cancelación: </strong>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(display.bill.billCancelationDatetime) }}</p>
          <br>
        </div>
        <br><br>
        <div class="billProductTableContainer">
          <v-data-table-virtual :headers="display.billProductHeaders" :items="display.bill.billProducts" class="billProductsTable">
            <template v-slot:item.productName="{ item }">
              <div style="width: 300px;">
                {{ item.productName }}
                <br>
                <strong>{{ item.productSKU }}</strong>
              </div>
            </template>
            <template v-slot:item.billProductPrice="{ item }">
              {{ display.bill.billCurrency == '₡' ?  `${display.bill.billCurrency} ${$generalFunctions.default.methods.parseAmountToDisplay(item.billProductPrice)}` : `${$generalFunctions.default.methods.parseAmountToDisplay(item.billProductPrice / (display.bill.billExchangeRate || 1))} ${display.bill.billCurrency}` }}
            </template>
            <template v-slot:item.billProductTaxMargin="{ item }">
              {{ $generalFunctions.default.methods.parseAmountToDisplay(item.billProductTaxMargin) }} %
            </template>
            <template v-slot:item.billProductDiscountMargin="{ item }">
              {{ $generalFunctions.default.methods.parseAmountToDisplay(item.billProductDiscountMargin) }} %
            </template>
            <template v-slot:item.billProductSubtotal="{ item }">
              {{ display.bill.billCurrency == '₡' ?  `${display.bill.billCurrency} ${$generalFunctions.default.methods.parseAmountToDisplay(item.billProductPrice * item.billProductAmount)}` : `${$generalFunctions.default.methods.parseAmountToDisplay((item.billProductPrice * item.billProductAmount) / (display.bill.billExchangeRate || 1))} ${display.bill.billCurrency}` }}
            </template>
          </v-data-table-virtual>
          <br>
          <p v-if="display.bill.billExchangeRate != null" class="billDialogLabel"><strong>Tipo de cambio: </strong>1$ = ₡{{ display.bill.billExchangeRate }}</p>
          <p class="billDialogLabel" v-if="display.bill.billExchangeRate != null"><strong>TOTAL: </strong>{{ display.bill.billCurrency == '₡' ?  `${display.bill.billCurrency} ${$generalFunctions.default.methods.parseAmountToDisplay(display.bill.billAmount)}` : `${$generalFunctions.default.methods.parseAmountToDisplay(display.bill.billAmount / (display.bill.billExchangeRate || 1))} ${display.bill.billCurrency}` }}</p>

        </div>
        <br><br>
        <v-btn v-if="display.bill.billType == 'Factura electrónica'" @click="openSendEmailBillDialog()" height="38" color="warning">
          <h3>ENVIAR FACTURA POR CORREO</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-email</v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>

  <v-dialog v-model="navigation.sendEmailBillDialogIsVisible" width="auto" :persistent="true">
    <v-card width="600" style="padding: 20px; text-align: center;">
      <v-text-field v-model="input.sendEmailBillClientEmail" append-inner-icon="mdi-email" label="Correo del cliente" variant="solo-filled" density="compact"></v-text-field>
      <br>
      <v-btn @click="sendEmailBill()" dark block height="38" color="success">
        <h3>ENVIAR FACTURA POR CORREO</h3>
        <v-icon style="margin-left: 20px;" dark right>mdi-check</v-icon>
      </v-btn>
      <br>
      <v-btn @click="navigation.sendEmailBillDialogIsVisible=false" dark block height="38" color="error">
        <h3>SALIR</h3>
        <v-icon style="margin-left: 20px;" dark right>mdi-close</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<style scoped>
  .billDialogContainer {
    padding: 20px;
  }

  .billDialogLabel {
    font-size: larger;
  }

  .billDialogChipContainer {
    display: flex;
  }

  .billDialogChip {
    margin-left: 10px;
    position: relative;
    top: 8px;
  }

  .billProductTableContainer {
    padding: 1%;
    background-color: #e9e8e8;
  }

  .billProductsTable {
    max-height: 400px;
    overflow-y: auto;
  }
</style>

<script>
export default {
  name: "BillDialog",
  inject: ['$generalFunctions'],

  data: () => ({
    input:
    {
      sendEmailBillClientEmail: null
    },
    navigation: 
    {
      billID: null,
      billDialogIsVisible: false,
      sendEmailBillDialogIsVisible: false
    },
    display: 
    {
      bill: null,
      billProductHeaders: 
      [
      {key: 'productName', title: 'PRODUCTO'},
      {key: 'billProductPrice', title: 'PRECIO'},
      {key: 'billProductTaxMargin', title: 'IMPUESTO'},
      {key: 'billProductDiscountMargin', title: 'DESCUENTO'},
      {key: 'billProductAmount', title: 'CANTIDAD'},
      {key: 'billProductSubtotal', title: 'SUBTOTAL'}
      ],
    },
    loader:
    {
      selectBillForBillDialog: false,
      createBillPDFDocument: false,
    },
    localStorage: 
    {
      localityID: null,
      billStateColors: null
    }
  }),

  watch: {
    async 'navigation.billDialogIsVisible'(){
      if (!this.navigation.billDialogIsVisible){
        await this.$generalFunctions.default.methods.splitURLActions('/openBillDialog');
        await this.$generalFunctions.default.methods.updateURLParameter('billDialogBillID', null);
      }
    }
  },

  methods: {
    async sendEmailBill(){
      this.navigation.sendEmailBillDialogIsVisible = false;
      const sendEmailBillRequestQuery = 
      {
        'billTaxID': this.display.bill.billTaxID,
        'recipientEmail': this.input.sendEmailBillClientEmail
      };
      const sendEmailBillResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/functions/sendEmailBill', sendEmailBillRequestQuery);
      if (sendEmailBillResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'La factura electrónica ha sido enviada por correo a ' + this.input.sendEmailBillClientEmail,
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al enviar la factura electrónica por correo. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }      
    },

    async selectBillForBillDialog(){
      this.loader.selectBillForBillDialog = true;
      const selectBillForBillDialogRequestQuery = 
      {
        'billID': this.navigation.billID
      };
      const selectBillForBillDialogResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/functions/selectBillForBillDialog', selectBillForBillDialogRequestQuery);
      if (selectBillForBillDialogResult.success){
        this.display.bill = selectBillForBillDialogResult.result;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
        this.navigation.billDialogIsVisible = false;
      }
      this.loader.selectBillForBillDialog = false;
    },

    async openPrintableBillDialog(){
      this.loader.createBillPDFDocument = true;
      const createBillPDFDocumentRequestQuery = 
      {
        'billID': this.navigation.billID
      };
      const createBillPDFDocumentResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/functions/createBillPDFDocument', createBillPDFDocumentRequestQuery);
      if (createBillPDFDocumentResult.success){
        this.$root.printableBillDialog.showPrintableBillDialog(createBillPDFDocumentResult.result);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al imprimir la factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.createBillPDFDocument = false;
    },

    openSendEmailBillDialog(){
      this.input.sendEmailBillClientEmail = this.display.bill.clientEmail;
      this.navigation.sendEmailBillDialogIsVisible = true;
    },

    getLocalStorageData(){
      this.localStorage.billStateColors = this.$generalFunctions.default.data.billStateColors;
    },

    setDefaultValues(){
      this.navigation.billID = null;
      this.navigation.billDialogIsVisible = false;
      this.display.bill = null;
      this.loader.selectBillForBillDialog = false;
      this.loader.createBillPDFDocument = false;
      this.localStorage.localityID = null;
      this.localStorage.billStateColors = null;
    },

    openBillDialog(billDialogRequestQuery){
      this.setDefaultValues();
      this.navigation.billID = billDialogRequestQuery.billID;
      this.navigation.billDialogIsVisible = true;
      this.getLocalStorageData();
      this.selectBillForBillDialog();
    },

    closeBillDialog(){
      this.setDefaultValues();
    }

  },

  mounted(){
  }
}
</script>