<template>
  <v-app>
    <v-container fluid class="dashboard-container">
      <v-dialog v-model="navigation.insertTransitProductDialogIsVisible" :persistent="true" width="auto">
        <v-card width="500" style="padding: 20px 20px 20px 20px;">
          <v-combobox 
            v-model="input.transitProductName" 
            :items="display.productOptions" 
            :loading="loader.selectProductBasicBasicInformationForBillsView" 
            :disabled="loader.selectProductBasicBasicInformationForBillsView" 
            item-title="productAutocompleteTitle" 
            item-value="productID" 
            append-inner-icon="mdi-magnify" 
            label="Código o nombre del producto en tránsito" 
            variant="solo-filled"
          ></v-combobox>
          <v-text-field v-model="input.transitProductAmount" type="number" label="Cantidad del producto en tránsito" append-inner-icon="mdi-package" variant="solo-filled" hide-spin-buttons></v-text-field>
          <v-textarea v-model="input.transitProductNote" rows="4" append-inner-icon="mdi-text-box-outline" label="Nota del producto en tránsito (opcional)" variant="solo-filled"></v-textarea>
          <v-text-field v-model="display.transitProductExpetedDatetimeFormatted" @click="navigation.transitProductExpetedDatetimePickerIsVisible = true" readonly label="Fecha esperada para la llegada del producto en tránsito" append-inner-icon="mdi-calendar" variant="solo-filled"></v-text-field>
          <v-dialog v-model="navigation.transitProductExpetedDatetimePickerIsVisible" width="auto">
            <v-card style="display: flex;">
              <v-date-picker v-model="input.transitProductExpetedDatetime" hide-header color="primary" width="100%"></v-date-picker>
              <v-chip @click="input.transitProductExpetedDatetime = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
            </v-card>
          </v-dialog>
          <br><br>
          <v-btn @click="insertTransitProduct()" dark block height="38" color="success">
            <h3>CREAR PRODUCTO EN TRÁNSITO</h3>
            <v-icon style="margin-left: 10px;" dark right>mdi-content-save</v-icon>
          </v-btn>
          <br>
          <v-btn @click="navigation.insertTransitProductDialogIsVisible = false" dark block height="38" color="error">
            <h3>CANCELAR</h3>
            <v-icon style="margin-left: 10px;" dark right>mdi-close-circle</v-icon>
          </v-btn>
        </v-card>
      </v-dialog>
      <!-- Header con Selector de Fecha -->
      <v-row align="center" justify="space-between" class="mb-4">
        <v-col cols="12" md="3">
          <h3>Dashboard</h3>
        </v-col>

        <v-spacer />

        <v-col cols="12" sm="4" md="3">
          <v-select 
            v-model="input.localityID" 
            :items="display.localityOptions" 
            :loading="loader.selectLocalityOptions || loader.updateAgentLocalityID" 
            :disabled="loader.selectLocalityOptions || loader.updateAgentLocalityID" 
            item-title="localityName" 
            item-value="localityID"
            label="Localidad" 
            variant="outlined" 
            density="compact"
            menu-icon=""
            hide-details
          >
            <template v-slot:append-inner>
              <div class="icon-container-date">
                <v-icon color="white">mdi-store</v-icon>
              </div>
            </template>
          </v-select>
        </v-col>

        <v-col cols="6" sm="4" md="2">
          <v-text-field 
            v-model="display.startDateFormatted" 
            @click="navigation.startDatePickerIsVisible = true" 
            variant="outlined" 
            density="compact"
            menu-icon=""
            hide-details
            label="Fecha inicial">
            <template v-slot:prepend-inner>
              <div class="icon-container-date">
                <v-icon color="white">mdi-calendar</v-icon>
              </div>
            </template>
          </v-text-field>

          <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
            <v-card>
              <v-date-picker v-model="input.startDate" hide-header color="primary" width="100%"></v-date-picker>
              <v-chip @click="input.startDate = new Date()" class="mx-auto mt-2" color="primary" variant="flat">Hoy</v-chip>
            </v-card>
          </v-dialog>
        </v-col>

        <v-col cols="6" sm="4" md="2">
          <v-text-field 
            v-model="display.endDateFormatted" 
            @click="navigation.endDatePickerIsVisible = true" 
            variant="outlined" 
            density="compact"
            menu-icon=""
            hide-details
            label="Fecha final">
            <template v-slot:prepend-inner>
              <div class="icon-container-date">
                <v-icon color="white">mdi-calendar</v-icon>
              </div>
            </template>
          </v-text-field>

          <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
            <v-card>
              <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
              <v-chip @click="input.endDate = new Date()" class="mx-auto mt-2" color="primary" variant="flat">Hoy</v-chip>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <!-- Métricas principales -->
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <v-card elevation="0" class="metric-card">
            <div class="metric-content">
              <div class="icon-container purple">
                <v-icon size="30" color="purple">mdi-heart-outline</v-icon>
              </div>
              <div class="metric-info">
                <h3 v-if="showTickets" class="font-weight-bold mt-2">{{ totalTickets.toLocaleString() }}</h3>
                <v-btn variant="plain" v-else icon @click="toggleTickets">
                  <v-icon>{{ showTickets ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                </v-btn>
                <p class="text-grey">Tickets</p>
              </div>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-card elevation="0" class="metric-card">
            <div class="metric-content">
              <div class="icon-container green">
                <v-icon size="30" color="green">mdi-cart-outline</v-icon>
              </div>
              <div class="metric-info">
                
                <h3 v-if="showSales" class="font-weight-bold mt-2">
                  ₡ {{ totalSales.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                </h3>
                <v-btn variant="plain" v-else icon @click="toggleSales">
                  <v-icon>{{ showSales ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                </v-btn>
                <p class="text-grey">Ventas Totales</p>
              </div>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-card elevation="0" class="metric-card">
            <div class="metric-content">
              <div class="icon-container blue">
                <v-icon size="30" color="blue">mdi-chart-line</v-icon>
              </div>
              <div class="metric-info">
                <h3 v-if="showAverage" class="font-weight-bold mt-2">₡ {{ totalAverageTicket.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</h3>
                <v-btn variant="plain" v-else icon @click="toggleAvergae">
                  <v-icon>{{ showAverage ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                </v-btn>
                <p class="text-grey"> Ticket Promedio</p>
              </div>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-card elevation="0" class="metric-card">
            <div class="metric-content">
              <div class="icon-container orange">
                <v-icon size="30" color="orange">mdi-eye-outline</v-icon>
              </div>
              <div class="metric-info">
                <h3 v-if="showExpenses" class="font-weight-bold mt-2">₡ {{ totalExpenses.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</h3>
                <v-btn variant="plain" v-else icon @click="toggleExpenses">
                  <v-icon>{{ showAverage ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                </v-btn>
                <p class="text-grey">Gastos Totales</p>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <!-- Sección de gráficos -->
      <v-row>
        <v-col cols="12" md="3">
          <v-card elevation="0" class="chart-card" style="height: 100%;">
            <h3>Token</h3>
            <div class="d-flex flex-column align-center justify-center" v-if="navigation.token">
              <v-tooltip location="bottom">
                <template v-slot:activator="{ props }">
                  <v-progress-circular @click="copyTokenValueToClipboard()" 
                    :model-value="display.tokenIntervalProgress" 
                    v-bind="props" :rotate="360" :size="220" 
                    :width="15" color="info" style="cursor: pointer;">
                    <div style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                      <strong class="text-grey text-center mt-2" style="font-size: 16px;">
                        {{ navigation.token.tokenValue }}
                      </strong>
                      <p style="font-size: 10px; margin: 5px 0 0 0;" class="text-center text-grey">
                        Restante: <strong style="font-size: 12px;">{{ display.tokenIntervalDisplayProgress }}</strong>
                      </p>
                      <v-icon @click="createNewSystemToken()" color="secondary" style="cursor: pointer; margin-top: 5px;">
                        mdi-refresh
                      </v-icon>
                    </div>
                  </v-progress-circular>   
                </template>
                <span>Tiempo restante: {{ display.tokenIntervalDisplayProgress }}</span>
              </v-tooltip>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12" md="9">
          <v-card elevation="0" class="chart-card">
            <div style="text-align: center; margin-top: 20px;">
              <h2>Resumen de Ventas por Sucursal</h2>
            </div>
            <apexchart
              v-if="chartData"
              type="bar"
              :options="chartData.options"
              :series="chartData.series"
              height="350"
            ></apexchart>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <div v-if="display.information">
            <v-card elevation="0" class="chart-card">
              <v-card-title class="text-center text-h6 font-weight-bold">
                PRODUCTOS MÁS VENDIDOS
              </v-card-title>
              <v-data-table :headers="display.monthSaleByProductHeaders" :items="display.information.monthSaleByProduct" hide-default-footer hide-default-header density="compact" :items-per-page="-1">
                <template v-slot:item.productAction="{ item }">
                  <div style="display: flex;">
                    <v-tooltip :text="`Ranking de producto histórico${item.productRanking == 'NA' ? ' (sin compras registradas)' : ''}`" location="top">
                      <template v-slot:activator="{ props }">
                        <v-chip v-bind="props" color="purple" label style="margin-right: 10px; font-size: small; font-weight: bold; cursor: pointer;">
                          <v-icon icon="mdi-medal" start></v-icon>
                          <p>{{ item.productRanking }}</p>
                        </v-chip>
                      </template>
                    </v-tooltip>
                    <v-tooltip text="Panel de producto" location="top">
                      <template v-slot:activator="{ props }">
                        <v-chip v-bind="props" v-if="accessCredential['/product']" color="warning" label style="margin-right: 10px; font-size: small; cursor: pointer;">
                          <v-icon @click="$generalFunctions.default.methods.openNewTab('/product/'+item.productID)" icon="mdi-bulletin-board"></v-icon>
                        </v-chip>
                      </template>
                    </v-tooltip>
                    <v-tooltip text="Tracking de producto" location="top">
                      <template v-slot:activator="{ props }">
                        <v-chip v-bind="props" v-if="accessCredential['/productHistory']" color="indigo" label style="font-size: small; cursor: pointer;">
                          <v-icon @click="$generalFunctions.default.methods.openNewTab('/productHistory/'+item.productID)" icon="mdi-update"></v-icon>
                        </v-chip>
                      </template>
                    </v-tooltip>
                  </div>
                </template>
                <template v-slot:item.productName="{ item }">
                  <p>{{ item.productName }}</p>
                  <p><strong>{{ item.productID }}</strong></p>
                </template>
                <template #bottom></template>
              </v-data-table>
            </v-card>
          </div>
        </v-col>



        <v-col cols="12" md="6">
          <div v-if="display.information">
            <v-card elevation="0" class="chart-card">
              <v-card-title class="text-center text-h6 font-weight-bold">
                TICKETS POR HORA
              </v-card-title>
              <apexchart 
                :options="display.information.monthSaleByHourOptions"
                :series="display.information.monthSaleByHourSeries"
                height="350" 
                type="line"
                style="margin: 20px;"
              >
              </apexchart>
            </v-card>
          </div>
        </v-col>
        <!-- TOP VENDEDORES: Pie Chart -->
        <v-col cols="12" md="6">
          <div v-if="display.information">
            <v-card elevation="0" class="chart-card">
              <v-card-title class="text-center text-h6 font-weight-bold">
                TOP VENDEDORES
              </v-card-title>
              <apexchart 
                type="donut" 
                :options="chartOptionsVendedores" 
                :series="chartSeriesVendedores" 
                style="height: 100px; margin: 20px;">
              </apexchart>
            </v-card>
          </div>
        </v-col>

        <!-- TOP MENSAJEROS: Bar Chart -->
        <v-col cols="12" md="6">
          <div v-if="display.information">
            <v-card elevation="0" class="chart-card">
              <div style="text-align: center; margin-top: 20px;">
                <h2>TOP MENSAJEROS</h2>
              </div>
              <apexchart 
                type="bar" 
                :options="chartOptionsMensajeros" 
                :series="chartSeriesMensajeros" 
                style="height: 300px; margin: 20px;">
              </apexchart>
            </v-card>
          </div>
        </v-col>
        <v-col cols="12" md="12">
          <div v-if="display.information">
            <v-card elevation="0" class="chart-card">
              <div style="font-size:10px; text-align: center; display: flex; justify-content: center; align-items: center;">
                <h2>PRODUCTOS EN TRÁNSITO:</h2>
                <v-icon @click="openInsertTransitProductDialog()" color="success" size="35" style="cursor: pointer;">mdi-plus-circle-outline</v-icon>
              </div>
              <br>
              <v-data-table-virtual density="compact" :headers="display.transitProductHeaders" :items="display.information.transitProduct" hide-default-footer>
                <template v-slot:item.transitProductCreationDatetime="{ item }">
                  <p class="extra-small-text">{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.transitProductCreationDatetime) }}</p>
                </template>
                <template v-slot:item.agentName="{ item }">
                  <v-chip :color="item.agentColor" variant="flat">
                    <p class="extra-small-text" :style="{color: item.agentFontColor}">{{ item.agentName }}</p>
                  </v-chip>
                </template>
                <template v-slot:item.transitProductExpectedDatetime="{ item }">
                  <p class="extra-small-text">  {{ $generalFunctions.default.methods.parseDatetimeToTextDate(item.transitProductExpectedDatetime) }}</p>
                </template>
                
                <template v-slot:item.transitProductAction="{ item }">
                  <v-icon @click="deleteTransitProduct(item.transitProductID)" color="error" style="cursor: pointer;">mdi-delete</v-icon>
                </template>
                
              </v-data-table-virtual>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { defineComponent } from 'vue';
import { viewMethodCaller } from '../viewMethodCaller.js';
import VueApexCharts from 'vue3-apexcharts';

export default defineComponent({
  components: {
    apexchart: VueApexCharts
  },
  name: 'MasterDashboardView',
  inject: ['$generalFunctions'],

  
  data: () => ({datePickerOpen: false,
    // Datos para los vendedores (Pie Chart)
    chartSeriesVendedores: [],
    chartOptionsVendedores: {
      chart: { type: 'donut' },
      labels: [],
      colors: ['#FF5733', '#33FF57', '#3375FF', '#FF33D4', '#FFC300'], // Colores personalizables
      legend: { position: 'bottom' },
    },
    

    // Datos para los mensajeros (Bar Chart)
    chartSeriesMensajeros: [],
    chartOptionsMensajeros: {
      chart: { type: 'bar' },
      plotOptions: {
        bar: {
          horizontal: true, // Hace que las barras sean horizontales
          columnWidth: '50%',
        },
      },
      xaxis: { categories: [] },
      colors: ['#FF5733', '#33FF57', '#3375FF', '#FF33D4', '#FFC300'], // Colores personalizables
    },
    selectedDate: new Date().toISOString().split('T')[0], // 🔹 Convierte a YYYY-MM-DD
    displayDate: new Date().toLocaleDateString("es-ES", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }), // 🔹 Formato legible
    showSales: false,
    showTickets:false,
    showExpenses:false,
    showAverage:false,
    timeoutId: null,
    chartOptions: {
      chart: { type: "radialBar" },
      plotOptions: {
        radialBar: {
          hollow: { size: "70%" }
        }
      },
      labels: ["Revenue"]
    },

    salesChartOptions: {
      chart: { type: "bar" },
      xaxis: { categories: ["12:00", "15:00", "18:00", "21:00", "03:00", "06:00", "09:00"] }
    },

    salesSeries: [{ name: "Net Revenue", data: [400, 500, 700, 800, 600, 450, 300] }],

    chartView: "monthly",
    input: 
    {
      localityID: null,
      startDate: null,
      endDate: null,
      localityName: null,
      transitProductName: null,
      transitProductAmount: null,
      transitProductNote: null,
      transitProductExpetedDatetime: null
    },

    navigation: 
    {
      selectSystemTokenInterval: null,
      calculateTokenIntervalProgressInterval: null,
      token: null,
      startDatePickerIsVisible: false,
      endDatePickerIsVisible: false,
      insertTransitProductDialogIsVisible: null,
      transitProductExpetedDatetimePickerIsVisible: false
    },

    loader: 
    {
      selectLocalityOptions: false,
      selectProductBasicBasicInformationForBillsView: false,
      insertTransitProduct: false
    },

    display:
    {
      localityOptions: [],
      startDateFormatted: null,
      endDateFormatted: null,
      tokenIntervalProgress: 0,
      tokenIntervalDisplayProgress: 0,
      information: null,

      monthSaleByProductHeaders:
      [
        {key: 'productAction', title: null},
        {key: 'productName', title: 'PRODUCTO'},
        {key: 'productAmount', title: 'CANTIDAD VENDIDA'}
      ],

      transitProductHeaders:
      [
        {key: 'transitProductName', title: 'PRODUCTO'},
        {key: 'transitProductCreationDatetime', title: 'CREADO EL'},
        {key: 'agentName', title: 'CREADO POR'},
        {key: 'transitProductAmount', title: 'CANTIDAD'},
        {key: 'transitProductNote', title: 'NOTA'},
        {key: 'transitProductExpectedDatetime', title: 'ESPERADO PARA EL'},
        {key: 'transitProductAction', title: null}
      ],
      productOptions: [],
      transitProductExpetedDatetimeFormatted: null
    },

    accessCredential: {},

    localStorage: 
    {
      localityID: null,
      agentID: null
    }

  }),

  watch: {
    'input.transitProductExpetedDatetime': async function() {
      if (this.input.transitProductExpetedDatetime){
        this.display.transitProductExpetedDatetimeFormatted = new Date(this.input.transitProductExpetedDatetime).toLocaleDateString('en-GB');
        this.navigation.transitProductExpetedDatetimePickerIsVisible = false;
      }
    },

    'input.localityID': async function(){
      localStorage.setItem('localityID', this.input.localityID);
      this.localStorage.localityID = this.input.localityID;
      const localityName = this.display.localityOptions.find(localityOption => localityOption.localityID == this.input.localityID).localityName;
      localStorage.setItem('localityName', localityName);
      await this.updateAgentLocalityID();
    },

    'input.startDate': async function() {
      this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB');
      this.navigation.startDatePickerIsVisible = false;
      await this.selectInformation();
    },
    'input.endDate': async function() {
      this.display.endDateFormatted = new Date(this.input.endDate).toLocaleDateString('en-GB');
      this.navigation.endDatePickerIsVisible = false;
      await this.selectInformation();
    },
  },
  computed: {
  // Total de ventas
    chartData() {
      if (!this.display.information) return null;

      const localities = this.display.information.saleByLocality.map(l => l.localityName);
      const sales = this.display.information.saleByLocality.map(l => l.saleAmount);
      const tickets = this.display.information.ticketByLocality.map(t => t.ticketAmount);
      const averageTickets = this.display.information.averageTicketByLocality.map(a => a.averageTicketAmount);
      const expenses = this.display.information.expenseByLocality.map(e => e.expenseAmount);

      return {
        series: [
          { name: "Ventas (₡)", data: sales },
          { name: "Tickets", data: tickets },
          { name: "Ticket Promedio (₡)", data: averageTickets },
          { name: "Gastos (₡)", data: expenses }
        ],
        options: {
          chart: { type: "bar", stacked: false },
          xaxis: { 
            categories: localities,
            labels: {
              style: { color: "#ffffff" } // ✅ Texto blanco en eje X
            }
          },
          yaxis: [
            {
              title: { text: "Montos en ₡" },
              labels: {
                formatter: (value) => Math.round(value) // ✅ Remueve los decimales
              }
            },
            {
              opposite: true,
              title: { text: "Cantidad de Tickets" },
              labels: {
                formatter: (value) => Math.round(value) // ✅ Remueve los decimales
              }
            }
          ],
          plotOptions: { bar: { horizontal: false, columnWidth: "60%" } },
          colors: ["#2ecc71", "#3498db", "#9b59b6", "#e74c3c"], // Verde, Azul, Morado, Rojo
          dataLabels: { enabled: false },
          tooltip: {
            y: {
              formatter: (value) => {
                return `₡ ${value.toLocaleString('en-US', { maximumFractionDigits: 0 })}`; // ✅ Sin decimales en tooltips
              }
            }
          },
          legend: { position: "top" }
        }
      };
    },

    totalSales() {
      if (!this.display.information || !this.display.information.saleByLocality) return 0;
      return this.display.information.saleByLocality.reduce((sum, sale) => sum + sale.saleAmount, 0);
    },

    // Total de tickets
    totalTickets() {
      if (!this.display.information || !this.display.information.ticketByLocality) return 0;
      return this.display.information.ticketByLocality.reduce((sum, ticket) => sum + ticket.ticketAmount, 0);
    },

    // Promedio de ticket (ventas totales / tickets totales)
    totalAverageTicket() {
      if (this.totalTickets === 0) return 0;
      return this.totalSales / this.totalTickets;
    },

    // Total de gastos
    totalExpenses() {
      if (!this.display.information || !this.display.information.expenseByLocality) return 0;
      return this.display.information.expenseByLocality.reduce((sum, expense) => sum + expense.expenseAmount, 0);
    }
  },

  methods: {
    toggleSales() {
      this.showSales = true;

      // Si ya hay un temporizador corriendo, lo limpiamos
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      // Ocultar el monto después de 20 segundos
      this.timeoutId = setTimeout(() => {
        this.showSales = false;
      }, 10000);
    },
    toggleAvergae() {
      this.showAverage = true;

      // Si ya hay un temporizador corriendo, lo limpiamos
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      // Ocultar el monto después de 20 segundos
      this.timeoutId = setTimeout(() => {
        this.showAverage = false;
      }, 10000);
    },
    toggleExpenses() {
      this.showExpenses = true;

      // Si ya hay un temporizador corriendo, lo limpiamos
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      // Ocultar el monto después de 20 segundos
      this.timeoutId = setTimeout(() => {
        this.showExpenses = false;
      }, 10000);
    },
    toggleTickets() {
      this.showTickets = true;

      // Si ya hay un temporizador corriendo, lo limpiamos
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      // Ocultar el monto después de 20 segundos
      this.timeoutId = setTimeout(() => {
        this.showTickets = false;
      }, 10000);
    },
    openInsertTransitProductDialog(){
      this.input.transitProductName = null;
      this.input.transitProductAmount = null;
      this.input.transitProductNote = null;
      this.input.transitProductExpetedDatetime = null;
      this.display.transitProductExpetedDatetimeFormatted = null;
      this.navigation.insertTransitProductDialogIsVisible = true;
    },

    async createNewSystemToken(){
      const createNewSystemTokenResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/token/functions/createNewSystemToken');
      if (createNewSystemTokenResult.success == false){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al crear el nuevo token del sistema, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async updateAgentLocalityID(){
      this.loader.updateAgentLocalityID = true;
      const updateAgentLocalityIDRequestQuery = 
      {
        'agentID': this.localStorage.agentID,
        'localityID': this.input.localityID
      };
      const updateAgentLocalityIDResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/functions/updateAgentLocalityID', updateAgentLocalityIDRequestQuery);
      if (updateAgentLocalityIDResult.success){
        this.loader.updateAgentLocalityID = false;
        viewMethodCaller.emit('MasterDashboardView/methods/updateAgentLocalityID()');

      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al actualizar la localidad asignada, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async copyTokenValueToClipboard(){
      navigator.clipboard.writeText(this.navigation.token.tokenValue).then(() => {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': null,
          'notificationDialogBody': 'Token copiado al portapapeles',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });      
      }).catch(() => {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al copiar el token al portapapeles, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      });
    },

    async displayTokenIntervalProgress(seconds){
      if (seconds > 0){
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        this.display.tokenIntervalDisplayProgress = `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
      } else {
        this.display.tokenIntervalDisplayProgress = `00:00`;
      }
    },

    async calculateTokenIntervalProgress(){
      if (this.navigation.token){
        const tokenTime = new Date(this.navigation.token.tokenExpirationDatetime);
        const currentTime = new Date();
        const differenceInSeconds = (tokenTime - currentTime) / 1000;
        await this.displayTokenIntervalProgress(parseInt((tokenTime - currentTime) / 1000));
        this.display.tokenIntervalProgress = Math.max(0, Math.min(100, (differenceInSeconds / 120) * 100)) + 1;
      }
    },

    changeSelectedLocality(indexChange){
      const localities = Object.values(this.display.information.lastYearBillInformation);
      const currentSelectedLocalityIndex = localities.findIndex(locality => locality.selected);
      if (currentSelectedLocalityIndex != -1){
        const newSelectedLocalityIndex = (currentSelectedLocalityIndex + indexChange + localities.length) % localities.length;
        localities[newSelectedLocalityIndex].selected = true;
        localities[currentSelectedLocalityIndex].selected = false;
      }
    },

    async selectSystemToken(){
      const selectSystemTokenResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/token/functions/selectSystemToken');
      if (selectSystemTokenResult.success){
        this.navigation.token = selectSystemTokenResult.result;
        await this.calculateTokenIntervalProgress();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar el token del sistema, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductBasicBasicInformationForBillsView(){
      this.loader.selectProductBasicBasicInformationForBillsView = true;
      const selectProductBasicBasicInformationForBillsViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductBasicBasicInformationForBillsView');
      if (selectProductBasicBasicInformationForBillsViewResult.success){
        this.display.productOptions = selectProductBasicBasicInformationForBillsViewResult.result.map(productOption => ({
          'productID': productOption.i,
          'productSKU': productOption.s,
          'productName': productOption.n,
          'productAutocompleteTitle': productOption.s + ' - ' + productOption.n
        }));
        this.loader.selectProductBasicBasicInformationForBillsView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },


    async courierSales(){
      const courierSalesRequestQuery = 
      {
        'whatsappInvoiceLocalityID': null,
        'initialDate': this.input.startDate,
        'endDate': this.input.endDate
      };
      const courierSalesResult = await this.$generalFunctions.default.methods.callcenterAPI('/selectTodayDeliveredInvoicesByLocality', courierSalesRequestQuery);
      if (courierSalesResult.success){
        alert('success')
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las entregas por mensajero, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectLocalityIDAndLocalityNameByLocalityIsActive(){
      this.loader.selectLocalityOptions = true;
      const selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery = {'localityIsActive': true};
      const selectLocalityIDAndLocalityNameByLocalityIsActiveResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityNameByLocalityIsActive', selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery);
      if (selectLocalityIDAndLocalityNameByLocalityIsActiveResult.success){
        this.display.localityOptions = selectLocalityIDAndLocalityNameByLocalityIsActiveResult.result;
        this.input.localityID = parseInt(this.localStorage.localityID);
        this.loader.selectLocalityOptions = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async insertTransitProduct(){
      this.loader.insertTransitProduct = true;
      const insertTransitProductRequestQuery = 
      {
        'transitProductName': this.input.transitProductName.productName ? this.input.transitProductName.productName : this.input.transitProductName,
        'transitProductAgentID': this.localStorage.agentID,
        'transitProductAmount': this.input.transitProductAmount,
        'transitProductNote': this.input.transitProductNote,
        'transitProductExpectedDatetime': this.input.transitProductExpetedDatetime
      };
      const insertTransitProductResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/dashboard/master/functions/insertTransitProduct', insertTransitProductRequestQuery);
      if (insertTransitProductResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'Producto en tránsito creado exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        this.navigation.insertTransitProductDialogIsVisible = false;
        await this.selectInformation();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al crear el producto en tránsito, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.insertTransitProduct = false;
    },

    async deleteTransitProduct(transitProductID){
      const deleteTransitProductRequestQuery = 
      {
        'transitProductID': transitProductID
      };
      const deleteTransitProductResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/dashboard/master/functions/deleteTransitProduct', deleteTransitProductRequestQuery);
      if (deleteTransitProductResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'Producto en tránsito eliminado exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        await this.selectInformation();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al eliminar el producto en tránsito, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    setDataAsVisible(data){
      data.visible = true;
      setInterval(async () => {
        data.visible = false;
      }, 7000);
    },

    async selectSystemTokenInterval(){
      await this.selectSystemToken();
      this.navigation.selectSystemTokenInterval = setInterval(async () => {
        await this.selectSystemToken();
      }, 1000);

      this.navigation.calculateTokenIntervalProgressInterval = setInterval(async () => {
        await this.calculateTokenIntervalProgress();
      }, 50);
    },

    async generateChart(){
      this.display.information.monthSaleByAgentSeries = this.display.information.monthSaleByAgent.map(monthSaleByAgent => monthSaleByAgent.saleAmount);
      // Datos para el Pie Chart (Vendedores)
      this.chartSeriesVendedores = this.display.information.monthSaleByAgent.map(a => a.saleAmount);
      this.chartOptionsVendedores = {
          chart: {
              type: 'donut',
              height: 400 // 👈 Asegúrate de que la altura esté configurada aquí también
          },
          labels: this.display.information.monthSaleByAgent.map(a => a.agentName),
          colors: this.display.information.monthSaleByAgent.map(a => a.agentColor),
          legend: { position: 'bottom' }
      };
      // Datos para el Pie Chart (Vendedores)
      this.chartSeriesVendedores = this.display.information.monthSaleByAgent.map(a => a.saleAmount);
      this.chartOptionsVendedores.labels = this.display.information.monthSaleByAgent.map(a => a.agentName);

      // Datos para el Bar Chart (Mensajeros)
      this.chartSeriesMensajeros = [{
        name: 'Ventas Mensajeros',
        data: this.display.information.monthSaleByAgent.map(a => a.saleAmount),
    }];
    this.chartOptionsMensajeros = {
        chart: {
            type: 'bar',
            height: 350 // 👈 También reduce la altura aquí para los mensajeros
        },
        plotOptions: {
            bar: {
                horizontal: true,
                columnWidth: '50%'
            }
        },
        xaxis: {
            categories: this.display.information.monthSaleByAgent.map(a => a.agentName)
        },
        colors: this.display.information.monthSaleByAgent.map(a => a.agentColor)
    };
      

      this.display.information.providerInformationSeries = this.display.information.providerInformation.map(provider => provider.providerBillAmount);
      this.display.information.providerInformationOptions = 
      {
        chart: 
        {
          type: 'pie'
        },
        labels: this.display.information.providerInformation.map(provider => provider.productProviderName)
      };


      
      this.display.information.monthSaleByHourSeries = [{name: '', data: Object.values(this.display.information.monthSaleByHour).map(monthSaleByHour => monthSaleByHour.ticketAmount)}];
      this.display.information.monthSaleByHourOptions = 
      {
        chart: {}, 
        markers: {size: 4}, 
        xaxis: {categories: ['8 AM', '9 AM', '10 AM', '11 AM', '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM', '7 PM', '8 PM', '9 PM']}
      };

      const monthArray = Array.from(new Set(Object.values(this.display.information.lastYearBillInformation).flatMap(locality => Object.keys(locality.saleByMonth))));
      const totalSeries = {};
      const totalColors = [];

      Object.values(this.display.information.lastYearBillInformation).forEach(locality => {
        locality.series = [{name: '', data: Object.values(locality.saleByMonth)}];
        locality.options = 
        {
          chart: {},
          colors: Array(Object.keys(locality.saleByMonth).length).fill(locality.localityColor),
          xaxis: {categories: Object.keys(locality.saleByMonth)}
        };
        
        if (!totalSeries[locality.localityName]){
          totalSeries[locality.localityName] = 
          {
            'name': locality.localityName,
            'data': []
          };
        }

        for (var monthIndex in monthArray){
          const month = monthArray[monthIndex];
          totalSeries[locality.localityName].data.push(locality.saleByMonth[month] ? locality.saleByMonth[month] : 0);
        }
        totalColors.push(locality.localityColor);

      });
      
      this.display.information.lastYearBillInformation['TOTAL'] =
      {
        'localityName': 'TOTAL',
        'selected': true,
        'series': Object.values(totalSeries),
        'options':
        {
          chart: 
          {
            stacked: true
          },
          colors: totalColors,
          xaxis: {categories: monthArray}
        }
      };
      
      


    },

    async selectInformation(){
      this.display.information = null;
      const selectInformationRequestQuery = 
      {
        'startDate': this.input.startDate,
        'endDate': this.input.endDate
      };
      const selectInformationResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/dashboard/master/functions/selectInformation', selectInformationRequestQuery);
      if (selectInformationResult.success){
        this.display.information = selectInformationResult.result;
         this.generateChart();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la información del dashboard, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
      if (!this.accessCredential['/dashboard/master']){
        this.$generalFunctions.default.methods.navigateTo(localStorage.getItem('agentLandingPage'));
      }
    },

    async setDefaultValues(){
      this.input.startDate = new Date();
      this.input.endDate = new Date();
    },
  
  },

  async beforeUnmount () {
    clearInterval(this.navigation.selectSystemTokenInterval)
  },

  async mounted(){
    this.getLocalStorageData();
    this.setDefaultValues();
    this.selectLocalityIDAndLocalityNameByLocalityIsActive();
    this.selectSystemTokenInterval();
    this.selectProductBasicBasicInformationForBillsView();
    // this.courierSales();
  }

});

</script>


<style scoped>
.custom-table ::v-deep(.v-data-table-header__content) {
  font-size: 12px !important; /* 🔹 Reduce tamaño de encabezados */
}

.custom-table ::v-deep(.v-data-table__td) {
  font-size: 12px !important; /* 🔹 Reduce tamaño del texto */
  padding: 4px 8px !important;
}

.custom-table ::v-deep(.v-data-table__tr) {
  height: 32px !important; /* 🔹 Reduce altura de las filas */
}

.v-avatar {
  width: 30px !important; /* 🔹 Reduce el tamaño del avatar */
  height: 30px !important;
}

.small-text {
  font-size: 12px !important;
}

.extra-small-text {
  font-size: 10px !important;
  color: gray;
}

/* Estilo del selector de fecha */
.date-picker {
  max-width: 170px;
  background: white;
  border-radius: 8px;
}

/* Tarjetas de métricas */
.metric-card {
  text-align: center;
  padding: 15px;
  border-radius: 12px;
}

.icon-container-date {
  background-color: #343A40; /* 🔹 Color oscuro del fondo */
  width: 40px; /* 🔹 Ajusta el tamaño exacto */
  height: 100%; /* 🔹 Ocupa todo el alto */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 8px 8px 0; /* 🔹 Redondeo solo en el lado derecho */
  position: absolute;
  right: 0;
}


/* Tarjetas de gráficos */
.chart-card {
  padding: 20px;
  border-radius: 12px;
}
.metric-card {
  display: flex;
  align-items: center; /* 🔹 Alinea el contenido en una sola línea */
  justify-content: space-between; /* 🔹 Espaciado entre texto e icono */
  text-align: left;
  padding: 20px;
  border-radius: 12px;
  position: relative;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px; /* 🔹 Tamaño del círculo */
  height: 50px;
  border-radius: 50%;
  position: absolute;
  right: 20px; /* 🔹 Espaciado desde el borde derecho */
}

/* Colores de los íconos */
.purple { background-color: rgba(142, 68, 173, 0.2); } /* Morado */
.green { background-color: rgba(46, 204, 113, 0.2); } /* Verde */
.blue { background-color: rgba(52, 152, 219, 0.2); } /* Azul */
.orange { background-color: rgba(243, 156, 18, 0.2); } /* Naranja */


</style>
