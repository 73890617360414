<template>
  <v-dialog v-model="navigation.insertLocalityExpenseDialogIsVisible" :persistent="true" width="auto">
    <v-card width="1000" style="padding: 20px;">
      <div v-if="loader.insertLocalityExpense" style="text-align: center;">
        <v-progress-circular color="success" indeterminate></v-progress-circular>
        <br>
      </div>
      <div v-else>
        <div style="width: 100%; text-align: center;">
          <p style="font-size: larger;"><strong>Agregando gasto a: </strong>{{ navigation.localityName }}</p>
        </div>
        <br>
        <v-text-field 
          v-model="display.localityExpenseRegisterDatetimeFormatted" 
          @click="navigation.localityExpenseRegisterDatetimePickerIsVisible = true" 
          readonly 
          label="Fecha del gasto" 
          append-inner-icon="mdi-calendar" 
          variant="solo-filled"
          density="compact"
        ></v-text-field>
        <v-dialog v-model="navigation.localityExpenseRegisterDatetimePickerIsVisible" width="auto">
          <v-card style="display: flex;">
            <v-date-picker v-model="input.localityExpenseRegisterDatetime" hide-header color="primary" width="100%"></v-date-picker>
            <v-chip @click="input.localityExpenseRegisterDatetime = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
          </v-card>
        </v-dialog>
        <v-select 
          v-if="navigation.localityExpenseCategory.localityExpenseSubcategories" 
          v-model="input.localityExpenseSubcategory" 
          :items="navigation.localityExpenseCategory.localityExpenseSubcategories" 
          append-inner-icon="mdi-source-branch" 
          label="Tipo del gasto" 
          item-value="localityExpenseSubcategoryName" 
          item-title="localityExpenseSubcategoryName" 
          variant="solo-filled"
          density="compact"
        ></v-select>
        <v-select 
          v-if="navigation.localityExpenseCategory.localityExpenseCategoryName == 'MOTOS'" 
          v-model="input.localityExpenseInformation.localityExpenseAgentID" 
          :items="display.motosOptions" 
          append-inner-icon="mdi-account" 
          label="Agente relacionado al gasto" 
          item-value="agentID" 
          item-title="agentName" 
          variant="solo-filled"
          density="compact"
        ></v-select>
        <v-text-field 
          v-if="navigation.localityExpenseCategory.localityExpenseCategoryName == 'MOTOS' && input.localityExpenseSubcategory == 'Gasolina'"
          v-model="input.localityExpenseInformation.localityExpenseKilometer" 
          append-inner-icon="mdi-speedometer" 
          type="number" 
          label="Cantidad de kilómetros recorridos" 
          variant="solo-filled" 
          hide-spin-buttons
          density="compact"
        ></v-text-field>
        <v-select 
          v-if="navigation.localityExpenseCategory.localityExpenseCategoryName == 'PERSONAL'" 
          v-model="input.localityExpenseInformation.localityExpenseAgentID" 
          :items="display.personalOptions" 
          append-inner-icon="mdi-account" 
          label="Agente relacionado al gasto" 
          item-value="agentID" 
          item-title="agentName" 
          variant="solo-filled"
          density="compact"
        ></v-select>
        <v-select 
          v-if="navigation.localityExpenseCategory.localityExpenseCategoryType == 'monthly'" 
          v-model="input.localityExpenseInformation.localityExpenseMonth" 
          :items="display.monthOptions" 
          append-inner-icon="mdi-calendar" 
          label="Mes del gasto" 
          item-value="value" 
          item-title="title" 
          variant="solo-filled"
          density="compact"
        ></v-select>
        <v-text-field 
          v-if="navigation.localityExpenseCategory.localityExpenseCategoryType == 'ocassional'" 
          v-model="input.localityExpenseInformation.localityExpenseName" 
          append-inner-icon="mdi-label" 
          label="Nombre del gasto"
          variant="solo-filled"
        ></v-text-field>
        <v-text-field 
          v-if="navigation.localityExpenseCategory.localityExpenseCategoryName == 'TELÉFONO'" 
          v-model="input.localityExpenseInformation.localityExpensePhoneNumber" 
          append-inner-icon="mdi-phone" 
          label="Número de teléfono del gasto"
          variant="solo-filled"
          density="compact"
        ></v-text-field>
        <v-select 
          v-if="navigation.localityExpenseCategory.localityExpenseCategoryName == 'TELÉFONO'" 
          v-model="input.localityExpenseInformation.localityExpenseProvider" 
          :items="display.phoneNumberLineOptions" 
          append-inner-icon="mdi-sim" 
          label="Línea telefónica del gasto" 
          item-value="value" 
          item-title="title" 
          variant="solo-filled"
          density="compact"
        ></v-select>
        <v-select 
          v-if="navigation.localityExpenseCategory.localityExpenseCategoryName == 'INTERNET'" 
          v-model="input.localityExpenseInformation.localityExpenseProvider" 
          :items="display.internetProviderOptions" 
          append-inner-icon="mdi-web" 
          label="Proveedor del gasto" 
          item-value="value" 
          item-title="title" 
          variant="solo-filled"
          density="compact"
        ></v-select>
        <v-text-field 
          v-model="input.localityExpenseAmount" 
          append-inner-icon="mdi-cash" 
          type="number" 
          label="Monto del gasto" 
          variant="solo-filled" 
          hide-spin-buttons
          density="compact"
        ></v-text-field>
        <v-textarea 
          v-model="input.localityExpenseNote" 
          rows="3" 
          append-inner-icon="mdi-text-box-outline" 
          label="Nota del gasto (opcional)" 
          variant="solo-filled"
          density="compact"
        ></v-textarea>
        <input @change="processUploadedFile()" type="file" accept="image/*" id="uploadFile" ref="uploadFile" style="display: none;">       
        <v-text-field 
          @click="uploadFile()" 
          v-model="input.localityExpenseFileName" 
          readonly 
          append-inner-icon="mdi-file-upload" 
          label="Archivo del gasto (opcional)" 
          variant="solo-filled"
          density="compact"
        ></v-text-field>
        <br>
        <div v-if="input.localityExpenseFile" style="text-align: center;">
          <img @click="uploadFile()" :src="input.localityExpenseFile" style="cursor: pointer; height: 300px; width: auto;">
        </div>
        <br><br>
        <v-btn @click="insertLocalityExpense()" dark block height="38" color="success">
          <h3>REGISTRAR GASTO</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-plus-circle-outline</v-icon>
        </v-btn>
        <br>
        <v-btn @click="setDefaultValues()" dark block height="38" color="error">
          <h3>CANCELAR</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
  
<style scoped>
</style>
  

<script>
import { viewMethodCaller } from '../viewMethodCaller.js';

export default {
  name: 'InsertLocalityExpenseDialog',
  inject: ['$generalFunctions'],

  data: () => ({

    input:
    {
      localityExpenseInformation:
      {
        localityExpenseMonth: null,
        localityExpenseName: null,
        localityExpensePhoneNumber: null,
        localityExpenseProvider: null,
        localityExpenseAgentID: null,
        localityExpenseKilometer: null
      },

      localityExpenseSubcategory: null,
      localityExpenseAmount: null,
      localityExpenseNote: null,
      localityExpenseFile: null,
      localityExpenseFileName: null,

      localityExpenseRegisterDatetime: null
    },

    loader: 
    {
      insertLocalityExpense: false
    },
    
    navigation: 
    {
      localityID: null,
      localityName: null,
      localityExpenseCategory: null,
      insertLocalityExpenseDialogIsVisible: false,
      localityExpenseRegisterDatetimePickerIsVisible: false
    },

    display:
    {
      monthOptions: 
      [
        { value: 1, title: 'Enero' },
        { value: 2, title: 'Febrero' },
        { value: 3, title: 'Marzo' },
        { value: 4, title: 'Abril' },
        { value: 5, title: 'Mayo' },
        { value: 6, title: 'Junio' },
        { value: 7, title: 'Julio' },
        { value: 8, title: 'Agosto' },
        { value: 9, title: 'Septiembre' },
        { value: 10, title: 'Octubre' },
        { value: 11, title: 'Noviembre' },
        { value: 12, title: 'Diciembre' }
      ],
      phoneNumberLineOptions: ['ICE', 'Movistar', 'Claro'],
      internetProviderOptions: ['American Data', 'Telecable'],

      motosOptions: [],
      personalOptions: [],

      localityExpenseRegisterDatetimeFormatted: null
    },

    localStorage:
    {
      agentID: null
    },


  }),

  watch: {
    'input.localityExpenseRegisterDatetime': async function() {
      if (this.input.localityExpenseRegisterDatetime){
        this.display.localityExpenseRegisterDatetimeFormatted = new Date(this.input.localityExpenseRegisterDatetime).toLocaleDateString('en-GB');
        this.navigation.localityExpenseRegisterDatetimePickerIsVisible = false;
      } else {
        this.display.localityExpenseRegisterDatetimeFormatted = null;
      }
    },

  },

  methods: {

    async composeLocalityExpenseInformation(){
      const composedLocalityExpenseInformation = {};
      for (var localityExpenseInformationName in this.input.localityExpenseInformation){
        if (this.input.localityExpenseInformation[localityExpenseInformationName] != null){
          composedLocalityExpenseInformation[localityExpenseInformationName] = this.input.localityExpenseInformation[localityExpenseInformationName];
        }
      }
      return composedLocalityExpenseInformation;
    },

    async insertLocalityExpense(){

      console.log(await this.composeLocalityExpenseInformation());

    
      const insertLocalityExpenseRequestQuery =
      {
        'localityExpenseLocalityID': this.navigation.localityID,
        'localityExpenseAgentID': this.localStorage.agentID,
        'localityExpenseCategoryName': this.navigation.localityExpenseCategory.localityExpenseCategoryName,
        'localityExpenseSubcategoryName': this.input.localityExpenseSubcategory,
        'localityExpenseNote': this.input.localityExpenseNote,
        'localityExpenseFile': this.input.localityExpenseFile,
        'localityExpenseFileName': this.input.localityExpenseFileName,
        'localityExpenseInformation': await this.composeLocalityExpenseInformation(),
        'localityExpenseAmount': this.input.localityExpenseAmount,
        'localityExpenseRegisterDatetime': this.input.localityExpenseRegisterDatetime
      };
      const insertLocalityExpenseResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/localityExpense/functions/insert', insertLocalityExpenseRequestQuery);
      if (insertLocalityExpenseResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'Se ha registrado el gasto exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        this.setDefaultValues();
        viewMethodCaller.emit('LocalityExpenseView/methods/selectLocalityExpenseFromLocalityExpenseView()');
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al registrar el gasto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectLocalityAgentList(){
      const selectMotosOptionsRequestQuery = 
      {
        'agentLocalityID': this.navigation.localityID, 
        'agentIsActive': true,
        'accessCredentialName': '/locality/localityExpense/localityExpenseAgentID/"Motos"'
      };
      const selectMotosOptionsResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/select/agentIDAndAgentName/byAgentLocalityIDAndByAgentIsActiveAndByAccessCredentialName', selectMotosOptionsRequestQuery);
      if (selectMotosOptionsResult.success){
        this.display.motosOptions = selectMotosOptionsResult.result;
        const selectPersonalOptionsRequestQuery = 
        {
          'agentLocalityID': this.navigation.localityID, 
          'agentIsActive': true,
          'accessCredentialName': '/locality/localityExpense/localityExpenseAgentID/"Personal"'
        };
        const selectPersonalOptionsResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/select/agentIDAndAgentName/byAgentLocalityIDAndByAgentIsActiveAndByAccessCredentialName', selectPersonalOptionsRequestQuery);
        if (selectPersonalOptionsResult.success){
          this.display.personalOptions = selectPersonalOptionsResult.result;
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de agentes, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de agentes, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    uploadFile(){
      document.getElementById('uploadFile').click();
    },

    processUploadedFile(){
      const previousFile = this.input.localityExpenseFile;
      try {
        this.input.localityExpenseFile = this.$refs.uploadFile.files[0];
        this.input.localityExpenseFileName = this.input.localityExpenseFile.name;
        const fileReader = new FileReader();
        fileReader.readAsDataURL(this.input.localityExpenseFile);
        fileReader.onload = () => {
          this.input.localityExpenseFile = fileReader.result;
        };
        fileReader.onerror = function () {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al cargar el archivo del gasto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        };
      } catch {
        this.input.localityExpenseFile = previousFile;
      }
    },

    async setDefaultValues(){
      this.input.localityExpenseInformation.localityExpenseMonth = null;
      this.input.localityExpenseInformation.localityExpenseName = null;
      this.input.localityExpenseInformation.localityExpensePhoneNumber = null;
      this.input.localityExpenseInformation.localityExpenseProvider = null;
      this.input.localityExpenseInformation.localityExpenseAgentID = null;
      this.input.localityExpenseInformation.localityExpenseKilometer = null;
      this.input.localityExpenseSubcategory = null;
      this.input.localityExpenseAmount = null;
      this.input.localityExpenseNote = null;
      this.input.localityExpenseFile = null;
      this.input.localityExpenseFileName = null;
      this.input.localityExpenseRegisterDatetime = new Date();
      this.display.localityExpenseRegisterDatetimeFormatted = null;
      this.loader.insertLocalityExpense = null;
      this.navigation.insertLocalityExpenseDialogIsVisible = false;
      this.navigation.localityExpenseRegisterDatetimePickerIsVisible = false;
    },


    async getLocalStorageData(){
      this.localStorage.agentID = localStorage.getItem('agentID');
    },
    

    async openInsertLocalityExpenseDialog(insertLocalityExpenseDialogRequestQuery) {
      await this.setDefaultValues();
      await this.getLocalStorageData();
      this.navigation.localityID = insertLocalityExpenseDialogRequestQuery.localityID;
      this.navigation.localityName = insertLocalityExpenseDialogRequestQuery.localityName;
      this.navigation.localityExpenseCategory = insertLocalityExpenseDialogRequestQuery.localityExpenseCategory;
      this.navigation.insertLocalityExpenseDialogIsVisible = true;
    },
  },


  async mounted(){
    await this.selectLocalityAgentList();
  }
}
</script>