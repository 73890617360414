import router from '@/router'; 
import axios from 'axios';
import placeholderImage from './assets/placeholderImage.png';
import userPlaceholderImage from './assets/userPlaceholderImage.png';


export default {
  data: {
    callcenterColor: '#F9E530',
    placeholderImage: placeholderImage,
    userPlaceholderImage: userPlaceholderImage,
    billStateColors:
    {
      'Factura generada': {billStateColor: '#bababa', billStateFontColor: '#000000'},
      'Factura cancelada': {billStateColor: '#8a0101', billStateFontColor: '#ffffff'},
      'Aceptado': {billStateColor: '#8ab074', billStateFontColor: '#ffffff'},
      'Rechazado': {billStateColor: '#8a0101', billStateFontColor: '#ffffff'},
      'Esperando a Hacienda': {billStateColor: '#bababa', billStateFontColor: '#000000'}
    },

    productChangeStateColors:
    {
      'Cambio solicitado': {productChangeStateColor: '#bababa', productChangeStateFontColor: '#000000'},
      'Cambio aprobado': {productChangeStateColor: '#ffab3d', productChangeStateFontColor: '#000000'},
      'Cambio rechazado': {productChangeStateColor: '#8a0101', productChangeStateFontColor: '#ffffff'},
      'Cambio en revisión': {productChangeStateColor: '#ffab3d', productChangeStateFontColor: '#ffffff'}
    },

    productTransferStateColors:
    {
      'Traslado solicitado': {productTransferStateColor: '#bababa', productTransferStateFontColor: '#000000'},
      'Traslado aprobado': {productTransferStateColor: '#ffab3d', productTransferStateFontColor: '#000000'},
      'Traslado rechazado': {productTransferStateColor: '#8a0101', productTransferStateFontColor: '#ffffff'},
      'Traslado en revisión': {productTransferStateColor: '#ffab3d', productTransferStateFontColor: '#ffffff'}
    },

    proformaStateColors:
    {
      'Proforma generada': {proformaStateColor: '#bababa', proformaStateFontColor: '#000000'},
      'Proforma aprobada': {proformaStateColor: '#ffab3d', proformaStateFontColor: '#000000'},
      'Proforma facturada': {proformaStateColor: '#97cc91', proformaStateFontColor: '#000000'},
      'Proforma cancelada': {proformaStateColor: '#8a0101', proformaStateFontColor: '#ffffff'}
    },



    localityAccessCredential:
    [

    ],

    defaulAccessCredential: 
    [
      {
        title: 'Configuración general', 
        accessCredentials: 
        [
          {
            title: '/login', 
            description: 'Permite iniciar sesión en el sistema', 
            type: 'boolean',
            value: false
          },
          {
            title: '/task', 
            description: 'Permite acceder al sistema de tareas', 
            type: 'boolean',
            value: false
          }
        ]
      },
      {
        title: 'Facturar', 
        accessCredentials: 
        [
          {
            title: '/bill', 
            description: 'Permite acceder a la vista /bill', 
            type: 'boolean',
            value: false
          },
          {
            title: '/bill/billAgentID', 
            description: 'Permite aparecer como agente facturador en la vista de /bill', 
            type: 'boolean',
            value: false
          },
          {
            title: '/bill/billNote', 
            description: 'Permite agregar notas dentro de la vista /bill', 
            type: 'boolean',
            value: false
          },
          {
            title: '/bill/updateBillProductPrice', 
            description: 'Permite modificar el precio de venta de los productos manualmente', 
            type: 'boolean',
            value: false
          },
          {
            title: '/bill/updateBillProductTaxMargin', 
            description: 'Permite modificar el márgen de impuesto de los productos manualmente', 
            type: 'boolean',
            value: false
          },
          {
            title: '/bill/maxDiscountMargin', 
            description: 'Limita el márgen de descuento máximo que se puede aplicar a los productos', 
            type: 'integer',
            value: 20
          },
          {
            title: '/bill/generateBillWithoutStock', 
            description: 'Permite facturar un producto que no tenga stock en la localidad', 
            type: 'boolean', 
            value: false
          },
          {
            title: '/bill/generateDolarBill', 
            description: 'Permite facturar en dólares', 
            type: 'boolean', 
            value: false
          }
        ]
      },
      {
        title: 'Proformas', 
        accessCredentials: 
        [
          {
            title: '/proformas/insertProformaDialog', 
            description: 'Permite acceder a la vista /proformas/insertProformaDialog', 
            type: 'boolean',
            value: false
          },
          {
            title: '/proformas', 
            description: 'Permite acceder a la vista /proformas', 
            type: 'boolean',
            value: false
          },
          {
            title: '/proformas/seeTotal', 
            description: 'Permite ver la utilidad desde la vista /proformas', 
            type: 'boolean',
            value: false
          },
          {
            title: '/proformas/approveProforma', 
            description: 'Permite acceder a la ventana de aprobación de proformas', 
            type: 'boolean',
            value: false
          },
          {
            title: '/proformas/dispatchProforma', 
            description: 'Permite acceder a la ventana de despacho de proformas', 
            type: 'boolean',
            value: false
          }
        ]
      },
      {
        title: 'Contactos', 
        accessCredentials: 
        [
          {
            title: '/contacts', 
            description: 'Permite acceder a la vista /contacts', 
            type: 'boolean',
            value: false
          },
          {
            title: '/pendingPayments', 
            description: 'Permite acceder a la vista /pendingPayments', 
            type: 'boolean',
            value: false
          },
          {
            title: '/contacts/contactDialog/insertContact', 
            description: 'Permite acceder a la ventana de creación de contactos', 
            type: 'boolean',
            value: false
          },
          {
            title: '/contacts/contactDialog/updateContact', 
            description: 'Permite acceder a la ventana de edición de contactos', 
            type: 'boolean',
            value: false
          },
          {
            title: '/contacts/contactDialog/contactAgentID', 
            description: 'Permite aparecer como agente encargado del contacto en la ventana de /contacts/contactDialog', 
            type: 'boolean',
            value: false
          },
          {
            title: '/contacts/contactDialog/contactCredit', 
            description: 'Permite habilitar y modificar el crédito de los contactos en la ventana de /contacts/contactDialog', 
            type: 'boolean',
            value: false
          }
        ]
      },
      {
        title: 'Facturas previas', 
        accessCredentials: 
        [
          {
            title: '/bills',
            description: 'Permite acceder a la vista de /bills',
            type: 'boolean',
            value: false
          },
          {
            title: '/bills/result', 
            description: 'Permite ver el total de las facturas', 
            type: 'boolean', 
            value: false
          },
          {
            title: '/bills/seeXML', 
            description: 'Permite ver el XML de respuesta de las facturas', 
            type: 'boolean', 
            value: false
          },
          {
            title: '/bills/cancelBill', 
            description: 'Permite la cancelación de facturas', 
            type: 'boolean', 
            value: false
          },
          {
            title: '/bills/cancelBill/"Sin devolución de inventario',
            description: 'Permite cancelar facturas sin devolución de inventario',
            type: 'boolean',
            value: false
          },
          {
            title: '/bills/selectLocality',
            description: 'Permite seleccionar localidades para realizar consultas de facturas previas', 
            type: 'boolean',
            value: false
          },
          {
            title: '/bills/selectEndDate',
            description: 'Permite seleccionar rangos de fechas para realizar consultas de facturas previas', 
            type: 'boolean',
            value: false
          },
          {
            title: '/bills/maxOffsetDays', 
            description: 'Limita la cantidad de días previos que pueden ser consultados para realizar consultas de facturas previas', 
            type: 'integer', 
            value: 2
          }
        ]
      },
      {
        title: 'Facturas de Uber / Rappi', 
        accessCredentials: 
        [
          {
            title: '/uberBills',
            description: 'Permite acceder a la vista de /uberBills',
            type: 'boolean',
            value: false
          }
        ]
      },
      {
        title: 'Call center', 
        accessCredentials: 
        [
          {
            title: '/callCenter',
            description: 'Permite acceder a la vista de /callcenter',
            type: 'boolean',
            value: false
          }
        ]
      },
      {
        title: 'Cierre de caja', 
        accessCredentials: 
        [
          {
            title: '/close',
            description: 'Permite acceder a la vista de /close',
            type: 'boolean',
            value: false
          },
          {
            title: '/close/seeTotal',
            description: 'Permite visualizar los totales facturados antes de generar un cierre',
            type: 'boolean',
            value: false
          },
          {
            title: '/close/generatePDF',
            description: 'Permite imprimir y descargar PDF al generar un cierre',
            type: 'boolean',
            value: false
          },
          {
            title: '/deliveryClose',
            description: 'Permite acceder a la vista de /deliveryClose',
            type: 'boolean',
            value: false
          },
          {
            title: '/deliveryClose/generalReport',
            description: 'Permite imprimir y descargar el reporte general del cierre de mensajeros',
            type: 'boolean',
            value: false
          },
          {
            title: '/closes',
            description: 'Permite acceder a la vista de /closes',
            type: 'boolean',
            value: false
          },
          {
            title: '/closes/seeTotal',
            description: 'Permite ver la tabla totalizadora desde la vista /closes',
            type: 'boolean',
            value: false
          }
        ]
      },
      {
        title: 'Proveedores', 
        accessCredentials: 
        [
          {
            title: '/productProvider',
            description: 'Permite acceder a la vista de /productProvider',
            type: 'boolean',
            value: false
          }
        ]
      },
      {

        title: 'Creación de producto', 
        accessCredentials: 
        [
          {
            title: '/product/insertMainProductDialog',
            description: 'Permite acceder a la ventana de creación de productos madre',
            type: 'boolean',
            value: false
          },
          {
            title: '/product/insertProductDialog',
            description: 'Permite acceder a la ventana de creación de productos',
            type: 'boolean',
            value: false
          }
        ]
      },
      {
        title: 'Ventas por producto general', 
        accessCredentials: 
        [
          {
            title: '/productSale',
            description: 'Permite acceder a la vista de /productSale',
            type: 'boolean',
            value: false
          },
          {
            title: '/productSale/seeDetail',
            description: 'Permite seleccionar todas las ventas generales, no solo la marca IQOS',
            type: 'boolean',
            value: false
          },
        ]
      },
      {
        title: 'Ventas por producto para reposición', 
        accessCredentials: 
        [
          {
            title: '/productDailySale',
            description: 'Permite acceder a la vista de /productDailySale',
            type: 'boolean',
            value: false
          },
          {
            title: '/productDailySale/selectLocality',
            description: 'Permite seleccionar localidades para realizar consultas de ventas por producto para reposición', 
            type: 'boolean',
            value: false
          },
          {
            title: '/productDailySale/selectEndDate',
            description: 'Permite seleccionar rangos de fechas para realizar consultas de ventas por producto para reposición', 
            type: 'boolean',
            value: false
          },
          {
            title: '/productDailySale/maxOffsetDays',
            description: 'Limita la cantidad de días previos que pueden ser consultados para realizar consultas de ventas por producto para reposición', 
            type: 'integer',
            value: 2
          },
          {
            title: '/productDailySale/showStock',
            description: 'Muestra la cantidad de stock en todas las localidades', 
            type: 'boolean',
            value: false
          }
        ]
      },
      {
        title: 'Tendencia de venta', 
        accessCredentials: 
        [
          {
            title: '/productTopSale',
            description: 'Permite acceder a la vista de /productTopSale',
            type: 'boolean',
            value: false
          },
          {
            title: '/productLowSale',
            description: 'Permite acceder a la vista de /productLowSale',
            type: 'boolean',
            value: false
          }
        ]
      },
      {
        title: 'Inventario de productos', 
        accessCredentials: 
        [
          {
            title: '/productStock',
            description: 'Permite acceder a la vista de /productStock',
            type: 'boolean',
            value: false
          },
          {
            title: '/productStock/selectLocality',
            description: 'Permite seleccionar localidades para consultar el stock',
            type: 'boolean',
            value: false
          },
          {
            title: '/productStock/generateReport',
            description: 'Permite generar e imprimir reportes de la tabla de stock por producto',
            type: 'boolean',
            value: false
          },
          {
            title: '/productStock/showProductCost',
            description: 'Muestra el costo de los productos en la tabla de stock por producto',
            type: 'boolean',
            value: false
          },
          {
            title: '/productStock/seeTotal',
            description: 'Permite ver la tabla totalizadora desde la vista /productStock',
            type: 'boolean',
            value: false
          }
        ]
      },
      {
        title: 'Inventario bajo', 
        accessCredentials: 
        [
          {
            title: '/productLowStock',
            description: 'Permite acceder a la vista de /productLowStock',
            type: 'boolean',
            value: false
          }
        ]
      },
      {
        title: 'Unidades vendidas', 
        accessCredentials: 
        [
          {
            title: '/saleCount',
            description: 'Permite acceder a la vista de /saleCount',
            type: 'boolean',
            value: false
          }
        ]
      },
      {
        title: 'Tracking de producto', 
        accessCredentials: 
        [
          {
            title: '/productHistory',
            description: 'Permite acceder a la vista de /productHistory',
            type: 'boolean',
            value: false
          },
          {
            title: '/productHistory/selectLocality',
            description: 'Permite seleccionar localidades para realizar consultas de tracking de producto',
            type: 'boolean',
            value: false
          },
          {
            title: '/productHistory/selectStartDate',
            description: 'Permite seleccionar una fecha inicial para realizar consultas de tracking de producto',
            type: 'boolean',
            value: false
          },
          {
            title: '/productHistory/selectEndDate',
            description: 'Permite seleccionar una fecha final para realizar consultas de tracking de producto',
            type: 'boolean',
            value: false
          }
        ]
      },
      {
        title: 'Comparación', 
        accessCredentials: 
        [
          {
            title: '/productCompare',
            description: 'Permite acceder a la vista de /productCompare',
            type: 'boolean',
            value: false
          }
        ]
      },
      {
        title: 'Lista de productos', 
        accessCredentials: 
        [
          {
            title: '/products',
            description: 'Permite acceder a la vista de /products',
            type: 'boolean',
            value: false
          }
        ]
      },
      {
        title: 'Panel de producto', 
        accessCredentials: 
        [
          {
            title: '/product',
            description: 'Permite acceder a la vista de /product',
            type: 'boolean',
            value: false
          }
        ]
      },
      {
        title: 'Entrada/Salida', 
        accessCredentials: 
        [
          {
            title: '/productInAndOut',
            description: 'Permite acceder a la vista de /productInAndOut',
            type: 'boolean',
            value: false
          },
          {
            title: '/productInAndOut/seeTotal',
            description: 'Permite ver la tabla totalizadora desde la vista /productInAndOut',
            type: 'boolean',
            value: false
          },
          {
            title: '/productInAndOut/insertProductInAndOutDialog',
            description: 'Permite acceder a la ventana de generación de entradas y salidas de productos',
            type: 'boolean',
            value: false
          },
          {
            title: '/productInAndOut/insertProductInAndOutDialog/withoutToken',
            description: 'Permite generar entradas y salidas de productos sin la necesidad de token',
            type: 'boolean',
            value: false
          },
          {
            title: '/productInAndOut/insertProductInAndOutDialog/showStock',
            description: 'Permite mostrar el stock de todas las localidades al generar una entrada o salida de productos',
            type: 'boolean',
            value: false
          }
        ]
      },
      {
        title: 'Traslados', 
        accessCredentials: 
        [
          {
            title: '/productTransfer',
            description: 'Permite acceder a la vista de /productTransfer',
            type: 'boolean',
            value: false
          },
          {
            title: '/productTransfer/insertProductTransferDialog',
            description: 'Permite acceder a la ventana de generación de traslados de productos',
            type: 'boolean',
            value: false
          }
        ]
      },
      {
        title: 'Cambios de producto', 
        accessCredentials: 
        [
          {
            title: '/productChange',
            description: 'Permite acceder a la vista de /productChange',
            type: 'boolean',
            value: false
          },
          {
            title: '/productChange/insertProductChangeDialog',
            description: 'Permite acceder a la ventana de generación de cambios de producto',
            type: 'boolean',
            value: false
          },
          {
            title: '/productChange/selectLocality',
            description: 'Permite seleccionar localidades para realizar consultas de cambios de producto',
            type: 'boolean',
            value: false
          },
          {
            title: '/productChange/insertProductChangeDialog/withoutBill',
            description: 'Permite generar cambios de producto sin factura',
            type: 'boolean',
            value: false
          }
        ]
      },
      {
        title: 'Localidades', 
        accessCredentials: 
        [
          {
            title: '/locality', 
            description: 'Permite acceder a la vista de /locality',
            type: 'boolean',
            value: false
          }
        ]
      },
      {
        title: 'Gastos de localidad', 
        accessCredentials: 
        [
          {
            title: '/localityExpense', 
            description: 'Permite acceder a la vista de /localityExpenses',
            type: 'boolean',
            value: false
          },
          {
            title: '/localityExpense/showLocalityExpenseTotal', 
            description: 'Permite visualizar el total de gastos por localidad',
            type: 'boolean',
            value: false
          },
          {
            title: '/locality/localityExpense/localityExpenseAgentID/"Motos"', 
            description: 'Permite aparecer como agente relacionado al gasto "Motos" en la vista de /localityExpense',
            type: 'boolean',
            value: false
          },
          {
            title: '/locality/localityExpense/localityExpenseAgentID/"Personal"', 
            description: 'Permite aparecer como agente relacionado al gasto "Personal" en la vista de /localityExpense',
            type: 'boolean',
            value: false
          }
        ]
      },
      {
        title: 'Gastos operativos', 
        accessCredentials: 
        [
          {
            title: '/operationalExpense', 
            description: 'Permite acceder a la vista de /operationalExpense',
            type: 'boolean',
            value: false
          }
        ]
      },
      {
        title: 'Agentes', 
        accessCredentials: 
        [
          {
            title: '/agent', 
            description: 'Permite acceder a la vista de /agent',
            type: 'boolean',
            value: false
          },
          {
            title: '/agent/insertAgentDialog', 
            description: 'Permite acceder a la ventana de creación de agentes',
            type: 'boolean',
            value: false
          },
          {
            title: '/agent/updateAgentDialog', 
            description: 'Permite acceder a la ventana de actualización de agentes',
            type: 'boolean',
            value: false
          },
          {
            title: '/agent/updateAgentDialog/showAll', 
            description: 'Permite visualizar todos los espacios al actualizar un agente',
            type: 'boolean',
            value: false
          },
          {
            title: '/agent/selectLocality', 
            description: 'Permite seleccionar la localidad desde la vista de /agent',
            type: 'boolean',
            value: false
          },
          {
            title: '/agent/showAccessCredential', 
            description: 'Permite acceder a las credenciales de acceso desde la vista de /agent',
            type: 'boolean',
            value: false
          }
        ]
      },
      {
        title: 'Aprobación de pagos', 
        accessCredentials: 
        [
          {
            title: '/billPaymentValidation', 
            description: 'Permite acceder a la vista de /billPaymentValidation',
            type: 'boolean',
            value: false
          }
        ]
      },
      {
        title: 'Dashboard', 
        accessCredentials: 
        [
          {
            title: '/dashboard/master', 
            description: 'Permite acceder a la vista de /dashboard/master',
            type: 'boolean',
            value: false
          },
          {
            title: '/dashboard/operation', 
            description: 'Permite acceder a la vista de /dashboard/operation',
            type: 'boolean',
            value: false
          },
          {
            title: '/dashboard/agentSale', 
            description: 'Permite acceder a la vista de /dashboard/agentSale',
            type: 'boolean',
            value: false
          },
          {
            title: '/dashboard/storeAdmin', 
            description: 'Permite acceder a la vista de /dashboard/storeAdmin',
            type: 'boolean',
            value: false
          },
          {
            title: '/dashboard/warehouse', 
            description: 'Permite acceder a la vista de /dashboard/warehouse',
            type: 'boolean',
            value: false
          },
          {
            title: '/dashboard/wholesale', 
            description: 'Permite acceder a la vista de /dashboard/wholesale',
            type: 'boolean',
            value: false
          },
          {
            title: '/dashboard/office', 
            description: 'Permite acceder a la vista de /dashboard/office',
            type: 'boolean',
            value: false
          }
        ]
      },
      {
        title: 'Tokens', 
        accessCredentials: 
        [
          {
            title: '/token', 
            description: 'Permite acceder a la vista de /token',
            type: 'boolean',
            value: false
          },
          {
            title: '/token/insertTokenDialog', 
            description: 'Permite acceder a la ventana de creación de tokens',
            type: 'boolean',
            value: false
          },
          {
            title: '/agent/insertTokenDialog/tokenType/"Master"', 
            description: 'Permite crear tokens de tipo "Master", que habilitan todas las acciones requeridas por token',
            type: 'boolean',
            value: false
          },
          {
            title: '/agent/insertTokenDialog/tokenType/"Descuento"', 
            description: 'Permite crear tokens de tipo "Descuento"',
            type: 'boolean',
            value: false
          },
          {
            title: '/agent/insertTokenDialog/tokenType/"Entrada de producto"', 
            description: 'Permite crear tokens de tipo "Entrada de producto"',
            type: 'boolean',
            value: false
          },
          {
            title: '/agent/insertTokenDialog/tokenType/"Salida de producto"', 
            description: 'Permite crear tokens de tipo "Salida de producto"',
            type: 'boolean',
            value: false
          },
        ]
      },
    ]
  },

  methods: {
    excludedJWTHttpRequestRoutes:
    [
      '/agent/functions/login',
      '/agent/functions/logout',
      '/agent/functions/recoverPassword',
      '/agent/functions/validateRecoverPassword'
    ],

    JWTHttpRequestMiddleware(httpRequestRoute, httpRequestQuery){
      if (!this.excludedJWTHttpRequestRoutes.includes(httpRequestRoute)){
        const JWTValue = localStorage.getItem('JWTValue');
        const JWTAgentID = localStorage.getItem('JWTAgentID');
        if (JWTValue && JWTAgentID){
          httpRequestQuery['JWTValue'] = JWTValue;
          httpRequestQuery['JWTAgentID'] = JWTAgentID;
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },

    JWTHttpResponseMiddleware(httpResponse){
      const httpPostRequestSuccess = httpResponse.data.success;
      const httpPostRequestResult = httpResponse.data.result;
      if ((httpPostRequestSuccess == false) && (httpPostRequestResult == 'JWT')){
        return false;
      } else {
        return true;
      }
    },

    encodeURLParameters(URLParameters){
      const encodedURLParameters = btoa(JSON.stringify(URLParameters));
      router.push({'path': router.currentRoute.value.path, 'query': {'data': encodedURLParameters}});
    },

    updateURLParameter(URLParameterKey, URLParameterValue){
      const decodedURLParameters = this.decodeURLParameters();
      if (decodedURLParameters){
        decodedURLParameters[URLParameterKey] = URLParameterValue;
        this.encodeURLParameters(decodedURLParameters);
      } else {
        const URLParameters = {};
        URLParameters[URLParameterKey] = URLParameterValue;
        this.encodeURLParameters(URLParameters);
      }
    },

    decodeURLParameters(){
      const encodedURLParameters = router.currentRoute.value.query.data;
      if (encodedURLParameters) {
        try {
          const decodedURLParameters = JSON.parse(atob(encodedURLParameters));
          return decodedURLParameters;
        } catch (error) {
          return null;
        }
      }
      return null;
    },

    decodeURLActions(){
      const URLActions = router.currentRoute.value.path;
      return URLActions.split('/').filter(URLSegment => URLSegment !== '');
    },

    async encodeURLActions(URLActions){
      const encodedURLParameters = router.currentRoute.value.query.data;
      await router.push({'path': URLActions, 'query': {'data': encodedURLParameters}});
    },

    async splitURLActions(URLActionToSplit){
      const URLActions = router.currentRoute.value.path.split(URLActionToSplit)[0];
      const encodedURLParameters = router.currentRoute.value.query.data;
      await router.push({'path': URLActions, 'query': {'data': encodedURLParameters}});
    },
    
    parseSecondsToMMSS(seconds){
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    },

    getCurrentDatetimeAndDatetimeOffsetInMinutes(datetime){
      const date = new Date(datetime);
      const currentDate = new Date();
      const offset = date - currentDate;
      return Math.floor(offset / 1000) / 60;
    },

    getCurrentDatetimeAndDatetimeOffsetInDays(datetime){
      const date = new Date(datetime);
      const currentDate = new Date();
      const offset = currentDate - date;
      return Math.floor(offset / (1000 * 60 * 60 * 24));
    },

    parseSecondsToDDHHMMSS(seconds) {
      const days = Math.floor(seconds / (24 * 3600));
      seconds %= (24 * 3600);
      const hours = Math.floor(seconds / 3600);
      seconds %= 3600;
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${String(days).padStart(2, '0')}:${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    },

    parseDatetimeToDDMMYYYHHMMSS(datetime){
      const date = new Date(datetime);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${day}-${month}-${year}, ${hours}:${minutes}:${seconds}`;
    },

    parseDatetimeToDDMMYYYHHMMSSAuxiliar(datetime){
      const date = new Date(datetime);
      date.setHours(date.getHours() - 6);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${day}-${month}-${year}, ${hours}:${minutes}:${seconds}`;
    },

    parseDatetimeToDDMMYYYY(datetime){
      const date = new Date(datetime);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },

    parseDatetimeToTextDate(datetime){
      const date = new Date(datetime);
      const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
      const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio','Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
      const dayName = days[date.getDay()];
      const day = date.getDate();
      const monthName = months[date.getMonth()];
      const year = date.getFullYear();
      return `${dayName} ${day} de ${monthName} ${year}`;
    },

    parseAmountToDisplay(amount){
      return `${parseFloat(amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}`;
    },

    async executeHttpPostRequest(httpRequestRoute, httpRequestQuery = {}){
      if (this.JWTHttpRequestMiddleware(httpRequestRoute, httpRequestQuery)){
        return new Promise((executeHttpPostRequestPromiseResolve) => {
          axios.post(process.env.VUE_APP_BACKEND_HTTP_REQUEST_SERVER_CONNECTION_URL + httpRequestRoute, httpRequestQuery).then((httpResponse) => {
            if (this.JWTHttpResponseMiddleware(httpResponse)){              
              const httpPostRequestSuccess = httpResponse.data.success;
              const httpPostRequestResult = httpResponse.data.result;
              executeHttpPostRequestPromiseResolve({success: httpPostRequestSuccess, result: httpPostRequestResult});
            } else {
              //this.sessionExpired();
            }
          })
          .catch((error) => {
            executeHttpPostRequestPromiseResolve({success: false, result: error});            
          });
        });
      } else {
        //this.sessionExpired();
      }
    },

    async printRequest(printingInformation){
      return new Promise((printRequestPromiseResolve) => {
        axios.post('http://localhost:5001/imprimir', printingInformation).then(() => {
          printRequestPromiseResolve({success: true, result: null});
        })
        .catch((error) => {
          printRequestPromiseResolve({success: false, result: error});            
        });
      });
    },
    
    async printCounterRequest(printingInformation){
      return new Promise((printRequestPromiseResolve) => {
        axios.post('http://localhost:5001/imprimir-cierre', printingInformation).then(() => {
          printRequestPromiseResolve({success: true, result: null});
        })
        .catch((error) => {
          printRequestPromiseResolve({success: false, result: error});            
        });
      });
    },

    async getThermalPrinterStatus(){
      return new Promise((printRequestPromiseResolve) => {
        axios.post('http://localhost:5001/status').then(() => {
          printRequestPromiseResolve({success: true, result: null});
        })
        .catch(() => {
          printRequestPromiseResolve({success: false, result: null});            
        });
      });
    },

    async selectDolarExchangeRate(){
      return new Promise((selectDolarExchangeRatePromiseResolve) => {
        axios.get('https://api.hacienda.go.cr/indicadores/tc/dolar').then((result) => {
          const dolarExchangeRate = parseInt(result.data.venta.valor) - 5;
          selectDolarExchangeRatePromiseResolve(dolarExchangeRate);
        })
        .catch((error) => {
          selectDolarExchangeRatePromiseResolve({success: false, result: error});            
        });
      });
    },


    async validateCredentialAccess(agentID){
      const selectAccessCredentialByAccessCredentialAgentIDRequestQuery = {'accessCredentialAgentID': agentID};
      const selectAccessCredentialByAccessCredentialAgentIDResult = await this.executeHttpPostRequest('/agent/accessCredential/select/byAccessCredentialAgentID', selectAccessCredentialByAccessCredentialAgentIDRequestQuery);
      if (selectAccessCredentialByAccessCredentialAgentIDResult.success){
        const validatedAccessCredentials = {};
        for (var accessCredentialIndex in selectAccessCredentialByAccessCredentialAgentIDResult.result){
          const accessCredential = selectAccessCredentialByAccessCredentialAgentIDResult.result[accessCredentialIndex];
          if (accessCredential.accessCredentialBooleanValue){
            validatedAccessCredentials[accessCredential.accessCredentialName] = accessCredential.accessCredentialBooleanValue;    
          } else if (accessCredential.accessCredentialIntegerValue){
            validatedAccessCredentials[accessCredential.accessCredentialName] = accessCredential.accessCredentialIntegerValue;    
          } else if (accessCredential.accessCredentialStringValue){
            validatedAccessCredentials[accessCredential.accessCredentialName] = accessCredential.accessCredentialStringValue;    
          }
        }
        return validatedAccessCredentials;
      } 
      return null;
    },

    navigateTo(routeToNavigate) {
      router.push(routeToNavigate);
    },

    openNewTab(routeToNavigate) {
      const url = router.resolve(routeToNavigate).href;
      window.open(url, '_blank');
    },

    sessionExpired() {
      /*
      localStorage.clear();
      router.push('/sessionExpired');
      */
    }

  }
}