<template>
  <div style="margin: 2% 3% 0% 3%;">
    <v-card color="border" elevation="3" style="padding: 20px;">
      <v-expansion-panels v-model="navigation.searchVisible">
        <v-expansion-panel value="searchVisible">
          <v-expansion-panel-title>
            <p style="font-size: large;">Búsqueda de tokens</p>
            <template v-slot:actions>
              <v-icon icon="mdi-magnify"></v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>        
            <br>
            <div style="display: flex;">
              <div style="width: 49.5%; margin-right: 0.5%;">
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-text-field 
                      v-bind="props"
                      v-model="input.tokenID" 
                      :append-inner-icon="input.tokenID ? 'mdi-barcode-off' : 'mdi-barcode'" 
                      @click:append-inner="input.tokenID = null" 
                      label="Buscar por identificador interno" 
                      variant="solo-filled" 
                      density="compact"
                    ></v-text-field>
                  </template>
                  <span style="max-width: 400px; display: block;">El identificador interno es el consecutivo único del token que se genera de forma automática a nivel interno. Si se coloca un identificador interno, se buscará el único token que coincida con el mismo. Al presionar el ícono de la derecha se limpia el identificador interno colocado</span>
                </v-tooltip>
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-autocomplete 
                      v-if="accessCredential['/bills/selectLocality']" 
                      v-bind="props"
                      v-model="input.selectedLocalityID"
                      :append-inner-icon="input.selectedLocalityID.length == 0 ? 'mdi-store' : 'mdi-store-off'" 
                      @click:append-inner="input.selectedLocalityID.length = 0"  
                      :items="display.localityOptions" 
                      :loading="loader.selectLocalityIDAndLocalityNameByLocalityIsActive" 
                      :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive" 
                      multiple
                      chips
                      item-title="localityName" 
                      item-value="localityID" 
                      label="Filtrar por localidad" 
                      placeholder="Todas las localidades"
                      variant="solo-filled" 
                      density="compact"
                    ></v-autocomplete>
                  </template>
                  <span style="max-width: 400px; display: block;">La localidad es el lugar desde donde se realizó la factura. Al seleccionar una o varias localidades, la lista de facturadores se actualiza con la lista de los facturadores asignados a la o las localidades seleccionadas. Este espacio permite selección múltiple para filtrar por varias localidades en paralelo. Si ninguna localidad es seleccionada, se buscarán todas las facturas sin importar la localidad. Al presionar el ícono de la derecha se limpian las localidades seleccionadas</span>
                </v-tooltip>
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-autocomplete 
                      v-bind="props"
                      v-model="input.selectedAgentID" 
                      :append-inner-icon="input.selectedAgentID.length == 0 ? 'mdi-account' : 'mdi-account-off'" 
                      @click:append-inner="input.selectedAgentID.length = 0" 
                      :items="display.agentOptions" 
                      :loading="loader.selectAgentByAgentLocalityIDAndByAgentIsActive" 
                      :disabled="loader.selectAgentByAgentLocalityIDAndByAgentIsActive" 
                      multiple 
                      chips 
                      item-title="agentName" 
                      item-value="agentID" 
                      label="Filtrar por facturador" 
                      placeholder="Todos los facturadores" 
                      variant="solo-filled" 
                      density="compact"
                    ></v-autocomplete>
                  </template>
                  <span style="max-width: 400px; display: block;">El facturador es el colaborador que fue seleccionado como vendedor a la hora de realizar la factura. Este espacio permite selección múltiple para filtrar por varios facturadores en paralelo. Si ningún facturador es seleccionado, se buscarán todas las facturas sin importar el facturador. Al presionar el ícono de la derecha se limpian los facturadores seleccionados</span>
                </v-tooltip>
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-select 
                      v-bind="props"
                      v-model="input.selectedBillPaymentType" 
                      :items="display.billPaymentTypeOptions" 
                      label="Filtrar por método de pago" 
                      append-inner-icon="mdi-cash"
                      placeholder="Todos los métodos de pago" 
                      variant="solo-filled" 
                      density="compact"
                    ></v-select>
                  </template>
                  <span style="max-width: 400px; display: block;">El método de pago con el que fue cancelada la factura. Este espacio permite selección entre facturas de Uber y facturas de Rappi</span>
                </v-tooltip>                    
              </div>
              <div style="width: 49.5%; margin-left: 0.5%;">
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-text-field 
                      v-bind="props"
                      v-model="input.billTaxID" 
                      :append-inner-icon="input.billTaxID ? 'mdi-qrcode-remove' : 'mdi-qrcode'" 
                      @click:append-inner="input.billTaxID = null" 
                      label="Buscar por clave numérica" 
                      variant="solo-filled" 
                      density="compact"
                    ></v-text-field>
                  </template>
                  <span style="max-width: 400px; display: block;">La clave numérica es la clave única de 20 dígitos que el Ministerio de Hacienda asigna a cada documento electrónico que se envía para su validación. Puede ser encontrado en el tiquete de caja después del total de venta de la factura. Si se coloca una clave numérica, se buscará la única factura que coincida con la misma. Al presionar el ícono de la derecha se limpia la clave numérica colocada</span>
                </v-tooltip>
                <v-text-field v-model="display.startDateFormatted" @click="navigation.startDatePickerIsVisible = true" readonly label="Filtrar por fecha inicial" append-inner-icon="mdi-calendar" variant="solo-filled" density="compact"></v-text-field>
                <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
                  <v-card style="display: flex;">
                    <v-date-picker v-model="input.startDate" :min="getMinimumDate()" hide-header color="primary" width="100%"></v-date-picker>
                    <v-chip @click="input.startDate = new Date()" color="primary" variant="flat" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;">Hoy</v-chip>
                  </v-card>
                </v-dialog>
                <v-text-field v-model="display.endDateFormatted" @click="navigation.endDatePickerIsVisible = true" readonly label="Filtrar por fecha final" append-inner-icon="mdi-calendar" variant="solo-filled" density="compact"></v-text-field>
                <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
                  <v-card>
                    <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
                    <v-chip @click="input.endDate = new Date()" color="primary" variant="flat" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;">Hoy</v-chip>
                  </v-card>
                </v-dialog>
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-autocomplete 
                      v-bind="props"
                      v-model="input.selectedBillType" 
                      :append-inner-icon="input.selectedBillType.length == 0 ? 'mdi-text-box-outline' : 'mdi-text-box-minus-outline'" 
                      @click:append-inner="input.selectedBillType.length = 0" 
                      :items="display.billTypeOptions" 
                      multiple 
                      chips 
                      label="Filtrar por tipo de factura" 
                      placeholder="Todos los tipos de factura" 
                      variant="solo-filled" 
                      density="compact"
                    ></v-autocomplete>
                  </template>
                  <span style="max-width: 400px; display: block;">El tipo de factura que fue seleccionado a la hora de realizar la factura. Este espacio permite selección múltiple para filtrar por varios tipos de factura en paralelo. Si ningún tipo de factura es seleccionado, se buscarán todas las facturas sin importar el tipo de factura. Al presionar el ícono de la derecha se limpian los tipos de factura seleeccionados</span>
                </v-tooltip>
              </div>
            </div>
            <br><br>
            <div v-if="loader.selectBillOnBillsView" style="text-align: center;">
              <br>
              <v-progress-circular color="primary" indeterminate></v-progress-circular>
              <br><br>
            </div>
            <div v-else>
              <v-btn @click="selectBillOnBillsView()" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive || loader.selectAgentByAgentLocalityIDAndByAgentIsActive || loader.selectProductBasicBasicInformationForBillsView || loader.selectBillOnBillsView" dark block height="38" color="primary">
                <h3>BUSCAR</h3>
                <v-icon style="margin-left: 20px;" dark right>mdi-magnify</v-icon>
              </v-btn>
              <br>
              <v-tooltip open-delay="2000" location="top">
                <template v-slot:activator="{ props }">
                  <v-btn 
                    v-bind="props" 
                    @click="setDefaultValues()" 
                    :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive || loader.selectAgentByAgentLocalityIDAndByAgentIsActive || loader.selectProductBasicBasicInformationForBillsView || loader.selectBillOnBillsView" 
                    dark 
                    block 
                    height="38" 
                    color="#b3b3b3"
                  >
                    <h3>LIMPIAR BÚSQUEDA</h3>
                    <v-icon style="margin-left: 20px;" dark right>mdi-backspace</v-icon>
                  </v-btn>
                </template>
                <span style="max-width: 400px; display: block;">Al presionar este botón, se limpiarán los resultados de la búsqueda actual, y se reestablecerán todos los filtros de búsqueda a sus valores por defecto</span>
              </v-tooltip>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <v-card color="border" elevation="3" style="padding: 20px; max-height: 700px; overflow-y: auto;">
      <v-data-table-virtual :headers="display.tokenHeaders" :items="display.token">
        <template v-slot:item.agentName="{ item }">
          <v-chip :color="item.agentColor" variant="flat">
            <p :style="{color: item.agentFontColor}">{{ item.agentName }}</p>
          </v-chip>
        </template>
        <template v-slot:item.tokenCreationDatetime="{ item }">
          <p>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.tokenCreationDatetime) }}</p>
        </template>
        <template v-slot:item.tokenExpirationDatetime="{ item }">
          <p>{{ item.tokenExpirationDatetime ? $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.tokenExpirationDatetime) : 'Sin fecha de expiración'}}</p>
        </template>
        <template v-slot:item.tokenUseAmount="{ item }">
          <p>{{ item.tokenUseAmount || 'Sin límite de usos' }}</p>
        </template>
        <template v-slot:item.tokenRemainingUseAmount="{ item }">
          <p>{{ item.tokenRemainingUseAmount || 'Sin límite de usos' }}</p>
        </template>
      </v-data-table-virtual>  
    </v-card>
  </div>
</template>

<style scoped>
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TokenView',

  inject: ['$generalFunctions'],

  data: () => ({
    input: 
    {
      startDate: null,
      endDate: null
    },

    display: 
    {
      token: [],
      tokenHeaders: 
      [
        {key: 'tokenID', title: 'ID', sortable: false},
        {key: 'tokenValue', title: 'VALOR', sortable: false},
        {key: 'tokenType', title: 'TIPO', sortable: false},
        {key: 'agentName', title: 'GENERADO POR', sortable: false},
        {key: 'tokenCreationDatetime', title: 'GENERADO EL', sortable: false},
        {key: 'tokenExpirationDatetime', title: 'EXPIRA EL', sortable: false},
        {key: 'tokenUseAmount', title: 'USOS ORIGINALES', sortable: false},
        {key: 'tokenRemainingUseAmount', title: 'USOS RESTANTES', sortable: false}
      ]
    },

    navigation: {},

    loader:
    {
      selectTokenFromTokenView: false
    },

    localStorage: {},
    accessCredential: {}
  }),

  watch: {
  }, 

  methods: {
    async selectTokenFromTokenView(){
      const selectTokenFromTokenViewRequetQuery =
      {
        'startDate': this.input.startDate,
        'endDate': this.input.endDate
      };
      const selectTokenFromTokenViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/token/functions/selectTokenFromTokenView', selectTokenFromTokenViewRequetQuery);
      if (selectTokenFromTokenViewResult.success){
        this.display.token = selectTokenFromTokenViewResult.result;
        this.loader.selectTokenFromTokenView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de tokens, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    }
  },

  async mounted(){
    await this.selectTokenFromTokenView();
  }
});
</script>
