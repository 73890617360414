<template>
  <div style="margin: 2% 3% 0% 3%;">
    <v-card color="border" style="padding: 20px;" elevation="3">
      <v-expansion-panels v-model="navigation.searchVisible">
        <v-expansion-panel value="searchVisible">
          <v-expansion-panel-title>
            <p style="font-size: large;">Búsqueda de cierres de caja</p>
            <template v-slot:actions>
              <v-icon icon="mdi-magnify"></v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <br>
            <v-tooltip open-delay="2000" location="top">
              <template v-slot:activator="{ props }">
                <v-text-field 
                  v-bind="props"
                  @keydown.enter.prevent="selectCloseFromClosesView()"
                  v-model="input.closingID" 
                  :append-inner-icon="input.closingID ? 'mdi-barcode-off' : 'mdi-barcode'" 
                  @click:append-inner="input.closingID = null" 
                  label="Buscar por identificador interno" 
                  variant="solo-filled" 
                  density="compact"
                ></v-text-field>
              </template>
              <span style="max-width: 400px; display: block;">El identificador interno es el consecutivo único del cierre que se genera de forma automática a nivel interno. Puede ser encontrado en el tiquete de caja bajo el nombre de "ID interno", o en el PDF en junto al nombre. Si se coloca un identificador interno, se buscará el único cierre que coincida con el mismo. Al presionar el ícono de la derecha se limpia el identificador interno seleccionado</span>
            </v-tooltip>
            <v-tooltip open-delay="2000" location="top">
              <template v-slot:activator="{ props }">
                <v-select 
                  v-bind="props"
                  v-model="input.closingResult"
                  :append-inner-icon="input.closingResult ? 'mdi-cash-off' : 'mdi-cash'" 
                  @click:append-inner="input.closingResult = null; this.blurInput('closingResult')"
                  :items="display.closingResultOptions" 
                  ref="closingResult"
                  item-title="closingResultName" 
                  item-value="closingResultValue" 
                  label="Filtrar por resultado"
                  placeholder="Todos los resultados"
                  variant="solo-filled" 
                  density="compact"
                ></v-select>
              </template>
              <span style="max-width: 400px; display: block;">El resultado es el resultante en dinero al totalizar el cierre. Al presionar el ícono de la derecha se limpia el resultado seleccionado</span>
            </v-tooltip>
            <div style="display: flex;">
              <div style="width: 49.5%; margin-right: 0.5%;">
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-autocomplete 
                      v-bind="props"
                      @keydown.enter.prevent="selectCloseFromClosesView()"
                      v-model="input.localityID"
                      :append-inner-icon="input.localityID.length == 0 ? 'mdi-store' : 'mdi-store-off'" 
                      @click:append-inner="input.localityID.length = 0; this.blurInput('localityID')"
                      :items="display.localityOptions" 
                      :loading="loader.localityOptions" 
                      :disabled="loader.localityOptions"
                      multiple
                      chips
                      closable-chips
                      color="primary"
                      ref="localityID"
                      item-title="localityName" 
                      item-value="localityID" 
                      label="Filtrar por localidad"
                      placeholder="Todas las localidades"
                      variant="solo-filled" 
                      density="compact"
                    ></v-autocomplete>
                  </template>
                  <span style="max-width: 400px; display: block;">La localidad es el lugar desde donde se realizó el cierre. Al seleccionar una o varias localidades, la lista de generadores se actualiza con la lista de los generadores asignados a la o las localidades seleccionadas. Este espacio permite selección múltiple para filtrar por varias localidades en paralelo. Si ninguna localidad es seleccionada, se buscarán todos los cierres sin importar la localidad. Al presionar el ícono de la derecha se limpian las localidades seleccionadas</span>
                </v-tooltip>
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-autocomplete 
                      v-bind="props"
                      v-model="input.agentID" 
                      :append-inner-icon="input.agentID.length == 0 ? 'mdi-account' : 'mdi-account-off'" 
                      @click:append-inner="input.agentID.length = 0; this.blurInput('agentID')"
                      :items="display.agentOptions" 
                      :loading="loader.agentOptions" 
                      :disabled="loader.agentOptions" 
                      multiple
                      chips
                      closable-chips
                      color="primary"
                      ref="agentID"
                      item-title="agentName" 
                      item-value="agentID" 
                      label="Filtrar por generador" 
                      placeholder="Todas los generadores"
                      variant="solo-filled" 
                      density="compact"
                    ></v-autocomplete>
                  </template>
                  <span style="max-width: 400px; display: block;">El generador es el colaborador que realizó el cierre. Este espacio permite selección múltiple para filtrar por varios generadores en paralelo. Si ningún generador es seleccionado, se buscarán todas los cierres sin importar el generador. Al presionar el ícono de la derecha se limpian los generadores seleccionados</span>
                </v-tooltip>
              </div>
              <div style="width: 49.5%; margin-left: 0.5%;">
                <v-text-field v-model="display.startDateFormatted" @click="navigation.startDatePickerIsVisible = true" readonly label="Filtrar por fecha inicial" append-inner-icon="mdi-calendar-start" variant="solo-filled" density="compact"></v-text-field>
                <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
                  <v-card style="display: flex; border: 2px solid #343a40;">
                    <v-date-picker v-model="input.startDate" hide-header color="primary" width="100%" style="margin-bottom: 10px;"></v-date-picker>
                    <div style="display: flex; margin: 0px 10px 10px 10px;">
                      <v-tooltip open-delay="1000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-chip v-bind="props" @click="input.startDate = new Date()" color="primary" variant="flat" style="display: flex; justify-content: center; align-items: center; cursor: pointer; width: 49.5%; margin-right: 0.5%;">Hoy (fecha inicial)</v-chip>
                        </template>
                        <span style="max-width: 400px; display: block;">Asigna la fecha de hoy como la fecha inicial</span>
                      </v-tooltip>
                      <v-tooltip open-delay="1000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-chip v-bind="props" @click="input.startDate = new Date(); input.endDate = new Date()" color="primary" variant="flat" style="display: flex; justify-content: center; align-items: center; cursor: pointer; width: 49.5%; margin-left: 0.5%;">Hoy (ambas fechas)</v-chip>
                        </template>
                        <span style="max-width: 400px; display: block;">Asigna la fecha de hoy como la fecha inicial y la fecha final</span>
                      </v-tooltip>
                    </div>
                    <div style="display: flex; margin: 0px 10px 10px 10px;">
                      <v-tooltip open-delay="1000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-chip v-bind="props" @click="input.startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1); input.endDate = new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0))" color="primary" variant="flat" style="display: flex; justify-content: center; align-items: center; cursor: pointer; width: 49.5%; margin-right: 0.5%;">Este mes</v-chip>
                        </template>
                        <span style="max-width: 400px; display: block;">Asigna el primer día del mes actual como la fecha inicial, y el último día del mes actual como la fecha final</span>
                      </v-tooltip>
                      <v-tooltip open-delay="1000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-chip v-bind="props" @click="input.startDate = new Date(new Date().setDate(new Date().getDate() - 29)); input.endDate = new Date()" color="primary" variant="flat" style="display: flex; justify-content: center; align-items: center; cursor: pointer; width: 49.5%; margin-left: 0.5%;">Último mes</v-chip>                  
                        </template>
                        <span style="max-width: 400px; display: block;">Asigna 30 días en el pasado como la fecha inicial, y la fecha de hoy como la fecha final</span>
                      </v-tooltip>
                    </div>
                    <div style="display: flex; margin: 0px 10px 10px 10px;">
                      <v-tooltip open-delay="1000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-chip v-bind="props" @click="input.startDate = new Date(new Date().getFullYear(), 0, 1); input.endDate = new Date(new Date().getFullYear(), 11, 31)" color="primary" variant="flat" style="display: flex; justify-content: center; align-items: center; cursor: pointer; width: 49.5%; margin-right: 0.5%;">Este año</v-chip>
                        </template>
                        <span style="max-width: 400px; display: block;">Asigna el primer día del año actual como la fecha inicial, y el último día del año actual como la fecha final</span>
                      </v-tooltip>
                      <v-tooltip open-delay="1000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-chip v-bind="props" @click="input.startDate = new Date(new Date().setDate(new Date().getDate() - 364)); input.endDate = new Date()" color="primary" variant="flat" style="display: flex; justify-content: center; align-items: center; cursor: pointer; width: 49.5%; margin-left: 0.5%;">Último año</v-chip>
                        </template>
                        <span style="max-width: 400px; display: block;">Asigna 365 días en el pasado como la fecha inicial, y la fecha de hoy como la fecha final</span>
                      </v-tooltip>
                    </div>
                  </v-card>
                </v-dialog>
                <v-text-field v-model="display.endDateFormatted" @click="navigation.endDatePickerIsVisible = true" readonly label="Filtrar por fecha final" append-inner-icon="mdi-calendar-end" variant="solo-filled" density="compact"></v-text-field>
                <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
                  <v-card style="display: flex; border: 2px solid #343a40;">
                    <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%" style="margin-bottom: 10px;"></v-date-picker>
                    <div style="display: flex; margin: 0px 10px 10px 10px;">
                      <v-tooltip open-delay="1000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-chip v-bind="props" @click="input.endDate = new Date()" color="primary" variant="flat" style="display: flex; justify-content: center; align-items: center; cursor: pointer; width: 49.5%; margin-right: 0.5%;">Hoy (fecha final)</v-chip>
                        </template>
                        <span style="max-width: 400px; display: block;">Asigna la fecha de hoy como la fecha final</span>
                      </v-tooltip>
                      <v-tooltip open-delay="1000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-chip v-bind="props" @click="input.startDate = new Date(); input.endDate = new Date()" color="primary" variant="flat" style="display: flex; justify-content: center; align-items: center; cursor: pointer; width: 49.5%; margin-left: 0.5%;">Hoy (ambas fechas)</v-chip>
                        </template>
                        <span style="max-width: 400px; display: block;">Asigna la fecha de hoy como la fecha inicial y la fecha final</span>
                      </v-tooltip>
                    </div>
                    <div style="display: flex; margin: 0px 10px 10px 10px;">
                      <v-tooltip open-delay="1000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-chip v-bind="props" @click="input.startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1); input.endDate = new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0))" color="primary" variant="flat" style="display: flex; justify-content: center; align-items: center; cursor: pointer; width: 49.5%; margin-right: 0.5%;">Este mes</v-chip>
                        </template>
                        <span style="max-width: 400px; display: block;">Asigna el primer día del mes actual como la fecha inicial, y el último día del mes actual como la fecha final</span>
                      </v-tooltip>
                      <v-tooltip open-delay="1000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-chip v-bind="props" @click="input.startDate = new Date(new Date().setDate(new Date().getDate() - 29)); input.endDate = new Date()" color="primary" variant="flat" style="display: flex; justify-content: center; align-items: center; cursor: pointer; width: 49.5%; margin-left: 0.5%;">Último mes</v-chip>                  
                        </template>
                        <span style="max-width: 400px; display: block;">Asigna 30 días en el pasado como la fecha inicial, y la fecha de hoy como la fecha final</span>
                      </v-tooltip>
                    </div>
                    <div style="display: flex; margin: 0px 10px 10px 10px;">
                      <v-tooltip open-delay="1000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-chip v-bind="props" @click="input.startDate = new Date(new Date().getFullYear(), 0, 1); input.endDate = new Date(new Date().getFullYear(), 11, 31)" color="primary" variant="flat" style="display: flex; justify-content: center; align-items: center; cursor: pointer; width: 49.5%; margin-right: 0.5%;">Este año</v-chip>
                        </template>
                        <span style="max-width: 400px; display: block;">Asigna el primer día del año actual como la fecha inicial, y el último día del año actual como la fecha final</span>
                      </v-tooltip>
                      <v-tooltip open-delay="1000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-chip v-bind="props" @click="input.startDate = new Date(new Date().setDate(new Date().getDate() - 364)); input.endDate = new Date()" color="primary" variant="flat" style="display: flex; justify-content: center; align-items: center; cursor: pointer; width: 49.5%; margin-left: 0.5%;">Último año</v-chip>
                        </template>
                        <span style="max-width: 400px; display: block;">Asigna 365 días en el pasado como la fecha inicial, y la fecha de hoy como la fecha final</span>
                      </v-tooltip>
                    </div>
                  </v-card>
                </v-dialog>
              </div>
            </div>
            <br>
            <div v-if="loader.selectCloseFromClosesView" style="text-align: center;">
              <br>
              <v-progress-circular color="primary" indeterminate></v-progress-circular>
              <br><br>
            </div>
            <div v-else>
              <v-btn @click="selectCloseFromClosesView()" dark block height="38" color="primary">
                <h3>BUSCAR</h3>
                <v-icon style="margin-left: 20px;" dark right>mdi-magnify</v-icon>
              </v-btn>
              <br>
              <v-tooltip open-delay="2000" location="top">
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" @click="setDefaultValues()" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive || loader.selectProductSearchParameters || loader.selectBillProductOnSaleCountView" dark block height="38" color="#b3b3b3">
                    <h3>LIMPIAR BÚSQUEDA</h3>
                    <v-icon style="margin-left: 20px;" dark right>mdi-backspace</v-icon>
                  </v-btn>
                </template>
                <span style="max-width: 400px; display: block;">Al presionar este botón, se limpiarán los resultados de la búsqueda actual, y se reestablecerán todos los filtros de búsqueda a sus valores por defecto</span>
              </v-tooltip> 
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <div v-if="display.closing.length > 0">
      <br><br>
      <v-card color="border" elevation="3" style="padding: 20px;">
        <v-tooltip open-delay="2000" location="top">
          <template v-slot:activator="{ props }">
            <v-autocomplete
              v-bind="props" 
              v-model="input.closingSortOptions" 
              :append-inner-icon="input.closingSortOptions.length == 0 ? 'mdi-sort-variant' : 'mdi-sort-variant-remove'" 
              @click:append-inner="clearClosingSortOptions()" 
              :items="display.closingSortOptions" 
              multiple 
              chips 
              closable-chips
              color="primary"
              label="Ordenar por" 
              variant="solo-filled" 
              density="compact"
            ></v-autocomplete>
          </template>
          <span style="max-width: 400px; display: block;">Los cierres inicialmente se ordenan por identificador interno. Este espacio permite selección múltiple para ordenar por varios criterios en paralelo, siempre tomando en cuenta el órden de selección de los criterios de ordenamiento (por ejemplo, al seleccionar localidad y luego generador, ordenará primero por localidad, y luego cada localidad se ordenará por generador). Al presionar el ícono de la derecha se eliminan todos los criterios de ordenamiento seleccionados</span>
        </v-tooltip>
        <v-data-table :headers="display.closingHeaders" :items="display.closing" :items-per-page="250" style="max-height: 700px; overflow-y: auto;">
          <template v-slot:item.closingID="{ item }">
            <p @click="copyToClipboard(item.closing, 'ID del cierre')" style="cursor: pointer;">{{ item.closingID }}</p>
          </template>
          <template v-slot:item.localityName="{ item }">
            <v-chip :color="item.localityColor" variant="flat" style="cursor: default;">
              <p :style="{color: item.localityFontColor}">{{ item.localityName }}</p>
            </v-chip>
          </template>
          <template v-slot:item.agentName="{ item }">
            <v-chip :color="item.agentColor" variant="flat" style="cursor: default;">
              <p :style="{color: item.agentFontColor}">{{ item.agentName }}</p>
            </v-chip>
          </template>
          <template v-slot:item.closingDatetime="{ item }">
            <p>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.closingDatetime) }}</p>
          </template>
          <template v-slot:item.closingRequiredAmount="{ item }">
            <p><strong>₡</strong>{{ $generalFunctions.default.methods.parseAmountToDisplay(item.closingRequiredAmount) }}</p>
          </template>
          <template v-slot:item.closingExpenseAmount="{ item }">
            <p><strong>₡</strong>{{ $generalFunctions.default.methods.parseAmountToDisplay(item.closingExpenseAmount) }}</p>
          </template>
          <template v-slot:item.closingOffsetAmount="{ item }">
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <div v-if="item.closingOffsetAmount > 0">
                  <v-chip v-bind="props" color="#ff6e69" variant="flat" style="margin: 0px 5px 0px 5px; cursor: pointer;">
                    <p style="color: white"> - ₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.closingOffsetAmount) }}</p>
                  </v-chip>
                </div>
                <div v-else-if="parseInt(item.closingOffsetAmount) < 0">
                  <v-chip v-bind="props" color="#97cc91" variant="flat" style="margin: 0px 5px 0px 5px; cursor: pointer;">
                    <p style="color: white"> + ₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.closingOffsetAmount * -1) }}</p>
                  </v-chip>
                </div>
                <div v-else>
                  <v-chip v-bind="props" variant="flat" style="margin: 0px 5px 0px 5px; cursor: pointer;">
                    <p style="color: white">₡0</p>
                  </v-chip>
                </div>
              </template>
              <div style="display: flex; width: 300px;">
                <div style="width: 49.5%; margin-right: 0.5%;">
                  <p style="font-size: large; font-weight: bold;">Vendido: </p>
                  <br>
                  <p><strong>EF: </strong>₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.closingRequiredCashAmount) }}</p>
                  <p><strong>TJ: </strong>₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.closingRequiredCardAmount) }}</p>
                  <p><strong>SN: </strong>₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.closingRequiredSINPEAmount) }}</p>
                  <p><strong>TR: </strong>₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.closingRequiredDepositAmount) }}</p>
                  <p><strong>WP: </strong>₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.closingRequiredWebpageAmount) }}</p>
                  <p><strong>RP: </strong>₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.closingRequiredRappiAmount) }}</p>
                  <p><strong>UB: </strong>₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.closingRequiredUberAmount) }}</p>
                  <hr style="width: 85%; margin-top: 10px; margin-bottom: 10px;">
                  <p> = ₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.closingRequiredAmount) }}</p>
                </div>
                <div style="width: 49.5%; margin-left: 0.5%;">
                  <p style="font-size: large; font-weight: bold;">Reportado: </p>
                  <br>
                  <p><strong>EF: </strong>₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.closingCashAmount) }}</p>
                  <p><strong>TJ: </strong>₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.closingCardAmount) }}</p>
                  <p><strong>SN: </strong>₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.closingSINPEAmount) }}</p>
                  <p><strong>TR: </strong>₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.closingDepositAmount) }}</p>
                  <p><strong>WP: </strong>₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.closingWebpageAmount) }}</p>
                  <p><strong>RP: </strong>₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.closingRappiAmount) }}</p>
                  <p><strong>UB: </strong>₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.closingUberAmount) }}</p>
                  <hr style="width: 85%; margin-top: 10px; margin-bottom: 10px;">
                  <p> = ₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.closingRequiredAmount - item.closingOffsetAmount) }}</p>
                </div>
              </div>
            </v-tooltip>
          </template>
          <template v-slot:item.closingAction="{ item }">
            <div style="width: 30px;">
              <v-progress-circular v-if="item.closingPrintLoader" color="success" indeterminate></v-progress-circular>
              <v-icon v-else @click="createPDFDocumentForClosing(item)" color="success" style="cursor: pointer;">mdi-printer</v-icon>
            </div>
          </template>
        </v-data-table>
        <div v-if="accessCredential['/closes/seeTotal']">
          <br>
          <v-data-table :headers="display.closingTotalHeaders" :items="[0]"  style="max-height: 700px; overflow-y: auto;">
            <template v-slot:item.closingLength>
              <p>{{ display.closing.length }}</p>
            </template>
            <template v-slot:item.closingRequiredAmount>
              <p><strong>₡</strong>{{ $generalFunctions.default.methods.parseAmountToDisplay(display.closing.reduce((total, closing) => total + closing.closingRequiredAmount, 0)) }}</p>
            </template>
            <template v-slot:item.closingExpenseAmount>
              <p><strong>₡</strong>{{ $generalFunctions.default.methods.parseAmountToDisplay(display.closing.reduce((total, closing) => total + closing.closingExpenseAmount, 0)) }}</p>
            </template>
            <template v-slot:item.closingNegativeOffsetAmount>
              <v-chip color="#ff6e69" variant="flat" style="margin: 0px 5px 0px 5px; cursor: default;">
                <p style="color: white;">- ₡{{ $generalFunctions.default.methods.parseAmountToDisplay(display.closing.filter(closing => closing.closingOffsetAmount > 0).reduce((total, closing) => total + closing.closingOffsetAmount, 0)) }}</p>
              </v-chip>   
            </template>
            <template v-slot:item.closingPositiveOffsetAmount>
              <v-chip color="#97cc91" variant="flat" style="margin: 0px 5px 0px 5px; cursor: default;">
                <p style="color: white;">+ ₡{{ $generalFunctions.default.methods.parseAmountToDisplay(display.closing.filter(closing => closing.closingOffsetAmount < 0).reduce((total, closing) => total + closing.closingOffsetAmount * -1, 0)) }}</p>
              </v-chip>
            </template>
            <template #bottom></template>
          </v-data-table>
        </div>
      </v-card>
      <br><br>
    </div>
  </div>
</template>

<style scoped>
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ClosesView',
  inject: ['$generalFunctions'],

  data: () => ({
    input: 
    {
      closingID: null,
      localityID: [],
      agentID: [],
      closingResult: null,
      startDate: null,
      endDate: null,
      closingSearchQuery: null,
      closingSortOptions: []
    },
    navigation: 
    {
      searchVisible: 'searchVisible',
      startDatePickerIsVisible: false,
      endDatePickerIsVisible: false
    },
    loader: 
    {
      localityOptions: false,
      agentOptions: false,
      selectCloseFromClosesView: false
    },
    display:
    {
      localityOptions: [],
      agentOptions: [],
      closingResultOptions:
      [
        {
          'closingResultName': 'Todos los resultados',
          'closingResultValue': null
        },
        {
          'closingResultName': 'Cierre cuadrado (faltante y sobrante igual a 0)',
          'closingResultValue': '='
        },
        {
          'closingResultName': 'Cierre faltante (faltante mayor a 0)',
          'closingResultValue': '>'
        },
        {
          'closingResultName': 'Cierre sobrante (sobrante mayor a 0)',
          'closingResultValue': '<'
        }
      ],
      startDateFormatted: null,
      endDateFormatted: null,
      closingSortOptions: 
      [
        {title: 'Fecha (más reciente primero)', value: 'closingDatetime'},
        {title: 'Fecha (más antiguo primero)', value: 'reverse_closingDatetime'},
        {title: 'Localidad (A-Z)', value: 'localityName'},
        {title: 'Localidad (Z-A)', value: 'reverse_localityName'},
        {title: 'Generador (A-Z)', value: 'agentName'},
        {title: 'Generador (Z-A)', value: 'reverse_agentName'},
        {title: 'Vendido (mayor primero)', value: 'closingRequiredAmount'},
        {title: 'Vendido (menor primero)', value: 'reverse_closingRequiredAmount'},
        {title: 'Gastos (mayor primero)', value: 'closingExpenseAmount'},
        {title: 'Gastos (menor primero)', value: 'reverse_closingExpenseAmount'}
      ],
      closing: [],
      closingHeaders: 
      [
        {key: 'closingID', title: 'ID', sortable: false},
        {key: 'closingDatetime', title: 'GENERADO EL', sortable: false},
        {key: 'localityName', title: 'GENERADO EN', sortable: false},
        {key: 'agentName', title: 'GENERADO POR', sortable: false},
        {key: 'closingRequiredAmount', title: 'VENDIDO', sortable: false},
        {key: 'closingExpenseAmount', title: 'GASTO', sortable: false},
        {key: 'closingOffsetAmount', title: 'FALTANTE / SOBRANTE', sortable: false},
        {key: 'closingAction', title: null, sortable: false}
      ],
      closingTotalHeaders:
      [
        {key: 'closingLength', title: 'CANTIDAD DE CIERRES', sortable: false},
        {key: 'closingRequiredAmount', title: 'VENDIDO (TOTAL)', sortable: false},
        {key: 'closingExpenseAmount', title: 'GASTO (TOTAL)', sortable: false},
        {key: 'closingNegativeOffsetAmount', title: 'FALTANTE (TOTAL)', sortable: false},
        {key: 'closingPositiveOffsetAmount', title: 'SOBRANTE (TOTAL)', sortable: false}
      ]
    },
    localStorage: {},
    accessCredential: {}
  }),

  watch: {
    'input.localityID': async function(){
      await this.selectAgentByAgentLocalityIDAndByAgentIsActive();
    },
    'input.startDate': async function() {
      this.display.startDateFormatted = this.input.startDate ? new Date(this.input.startDate).toLocaleDateString('en-GB') : null;
      this.navigation.startDatePickerIsVisible = false;
    },
    'input.endDate': async function() {
      this.display.endDateFormatted = this.input.endDate ? new Date(this.input.endDate).toLocaleDateString('en-GB') : null;
      this.navigation.endDatePickerIsVisible = false;
    },

    'input.closingSortOptions': async function () {
      if (this.display.closing.length == 0) return;
      const lastAddedClosingSortOption = this.input.closingSortOptions[this.input.closingSortOptions.length - 1];
      let contradictoryClosingSortOption = null;
      if (lastAddedClosingSortOption && lastAddedClosingSortOption.startsWith('reverse_')) {
        const normalVersion = lastAddedClosingSortOption.replace('reverse_', '');
        if (this.input.closingSortOptions.includes(normalVersion)) {
          contradictoryClosingSortOption = normalVersion;
        }
      } else if (lastAddedClosingSortOption) {
        const reverseVersion = `reverse_${lastAddedClosingSortOption}`;
        if (this.input.closingSortOptions.includes(reverseVersion)) {
          contradictoryClosingSortOption = reverseVersion;
        }
      }
      const filteredClosingSortOptions = this.input.closingSortOptions.filter(option => option != contradictoryClosingSortOption);
      if (JSON.stringify(filteredClosingSortOptions) != JSON.stringify(this.input.closingSortOptions)) {
        this.input.closingSortOptions = filteredClosingSortOptions;
      }
      this.display.closing = this.display.closing.sort((closingA, closingB) => {
        return closingB.closingID - closingA.closingID;
      });
      this.display.closing = [...this.display.closing].sort((closingA, closingB) => {
        for (const selectedClosingSortOption of this.input.closingSortOptions) {
          const isReverse = selectedClosingSortOption.startsWith('reverse');
          const sortKey = isReverse ? selectedClosingSortOption.replace('reverse_', '') : selectedClosingSortOption;
          let closingAValue = closingA[sortKey];
          let closingBValue = closingB[sortKey];
          if (['closingRequiredAmount', 'closingExpenseAmount'].includes(sortKey)) {
            closingAValue = Number(closingAValue);
            closingBValue = Number(closingBValue);
          } else if (sortKey == 'closingDatetime') {
            closingAValue = new Date(closingAValue);
            closingBValue = new Date(closingBValue);
          } else {
            closingAValue = String(closingAValue).toLowerCase();
            closingBValue = String(closingBValue).toLowerCase();
          }
          let billRowComparison = null;
          if ( ['closingDatetime', 'closingRequiredAmount', 'closingExpenseAmount'].includes(sortKey)) {
            billRowComparison = closingBValue - closingAValue;
          } else {
            billRowComparison = closingAValue.localeCompare(closingBValue); 
          }
          if (billRowComparison != 0) {
            return isReverse ? -billRowComparison : billRowComparison;
          }
        }
        return 0; 
      });
    }
  },

  methods: {
    async createPDFDocumentForClosing(closing){
      closing.closingPrintLoader = true;
      const createPDFDocumentForClosingRequestQuery = 
      {
        'closingID': closing.closingID,
        'isReprint': true
      };
      const createPDFDocumentForClosingResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/functions/createPDFDocumentForClosing', createPDFDocumentForClosingRequestQuery);
      if (createPDFDocumentForClosingResult.success){
       
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': createPDFDocumentForClosingResult.result.documentFile.data,
          'printablePDFDocumentName': createPDFDocumentForClosingResult.result.documentName,
          'printableExcelDocumentFile': null,
          'printableExcelDocumentName': null
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al reimprimir el cierre de caja, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      closing.closingPrintLoader = false;
    },

    async selectCloseFromClosesView(){
      this.loader.selectCloseFromClosesView = true;
      this.display.closing.length = 0;
      const selectCloseFromClosesViewRequestQuery = 
      {
        'closingID': this.input.closingID,
        'closingLocalityID': this.input.localityID, 
        'closingAgentID': this.input.agentID,
        'closingResult': this.input.closingResult,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate
      };
      const selectCloseFromClosesViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/functions/selectCloseFromClosesView', selectCloseFromClosesViewRequestQuery);
      if (selectCloseFromClosesViewResult.success){
        if (selectCloseFromClosesViewResult.result.length == 0){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': null,
            'notificationDialogBody': 'No se ha encontrado ningún cierre con las características especificadas, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        } else { 
          this.display.closing = selectCloseFromClosesViewResult.result;
          this.navigation.searchVisible = '';
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar el historial de cierres de caja, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectCloseFromClosesView = false;
    },

    async selectLocalityIDAndLocalityNameByLocalityIsActive(){
      this.loader.localityOptions = true;
      const selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery = 
      {
        'localityIsActive': true
      };
      const selectLocalityIDAndLocalityNameByLocalityIsActiveResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityNameByLocalityIsActive', selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery);
      if (selectLocalityIDAndLocalityNameByLocalityIsActiveResult.success){
        this.display.localityOptions = selectLocalityIDAndLocalityNameByLocalityIsActiveResult.result
        this.loader.localityOptions = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectAgentByAgentLocalityIDAndByAgentIsActive(){
      this.loader.agentOptions = true;
      this.input.agentID.length = 0;
      const selectAgentByAgentLocalityIDAndByAgentIsActiveRequestQuery = 
      {
        'agentLocalityID': this.input.localityID, 
        'agentIsActive': true
      };
      const selectAgentByAgentLocalityIDAndByAgentIsActiveResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/select/byAgentLocalityIDAndByAgentIsActive', selectAgentByAgentLocalityIDAndByAgentIsActiveRequestQuery);
      if (selectAgentByAgentLocalityIDAndByAgentIsActiveResult.success){
        this.display.agentOptions = selectAgentByAgentLocalityIDAndByAgentIsActiveResult.result;
        this.loader.agentOptions = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de facturadores, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    clearClosingSortOptions(){
      this.input.closingSortOptions.length = 0;
      this.display.closing = this.display.closing.sort((closingA, closingB) => {
        return closingB.closingID - closingA.closingID;
      });
    },

    blurInput(inputReference){
      const blurInputInterval = setInterval(() => {
        if (this.$refs[inputReference]) {
          this.$refs[inputReference].blur();
          clearInterval(blurInputInterval);
        }
      }, 1);
    },

    copyToClipboard(value, valueName){
      navigator.clipboard.writeText(value).then(() => {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': null,
          'notificationDialogBody': `${valueName} copiado al portapapeles`,
          'notificationDialogColor': 'info',
          'notificationDialogIsPersistent': false
        });      
      }).catch(() => {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': `Ha ocurrido un error inesperado al copiar el ${valueName} al portapapeles, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema`,
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });      
      });
    },

    async setDefaultValues(){
      this.input.closingID = null;
      this.input.localityID.length = 0;
      this.input.agentID.length = 0;
      this.input.closingResult = null;
      const startDate = new Date();
      startDate.setDate(1);
      this.input.startDate = startDate;
      this.input.endDate = new Date();
      this.display.startDateFormatted = null;
      this.display.endDateFormatted = null;
      this.display.closing.length = 0;
      this.input.closingSortOptions.length = 0;
      this.navigation.startDatePickerIsVisible = false;
      this.navigation.endDatePickerIsVisible = false;
    },

    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    }
  },

  async mounted(){
    this.setDefaultValues();
    await this.getLocalStorageData(); 
    await this.selectLocalityIDAndLocalityNameByLocalityIsActive();
    await this.selectAgentByAgentLocalityIDAndByAgentIsActive();
  }
});
</script>
