<template>
  <div style="margin: 2% 3% 0% 3%;">
    <v-card color="border" elevation="3" style="padding: 20px;">
      <v-expansion-panels v-model="navigation.searchVisible">
        <v-expansion-panel value="searchVisible">
          <v-expansion-panel-title>
            <p style="font-size: large;">Búsqueda de traslados de producto</p>
            <template v-slot:actions>
              <v-icon icon="mdi-magnify"></v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <br>
            <v-tooltip open-delay="2000" location="top">
              <template v-slot:activator="{ props }">
                <v-text-field 
                  v-bind="props"
                  @keydown.enter.prevent="selectProductTransferOnProductTransferView()"
                  v-model="input.productTransferID" 
                  :append-inner-icon="input.productTransferID == null ? 'mdi-barcode' : 'mdi-barcode-off'" 
                  @click:append-inner="input.productTransferID = null" 
                  label="Buscar por identificador interno" 
                  variant="solo-filled" 
                  density="compact"
                ></v-text-field>
              </template>
              <span style="max-width: 400px; display: block;">El identificador interno es el consecutivo único del traslado de producto que se genera de forma automática a nivel interno. Si se coloca un identificador interno, se buscará el único traslado de producto que coincida con el mismo. Al presionar el ícono de la derecha se limpia el identificador interno colocado</span>
            </v-tooltip>
            <div style="display: flex;">
              <div style="width: 49.5%; margin-right: 0.5%;">
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-autocomplete 
                      v-bind="props"
                      v-model="input.productTransferSendLocalityID"
                      :append-inner-icon="input.productTransferSendLocalityID.length == 0 ? 'mdi-store' : 'mdi-store-off'" 
                      @click:append-inner="input.productTransferSendLocalityID.length = 0; this.blurInput('productTransferSendLocalityID')"  
                      :items="display.localityOptions" 
                      :loading="loader.selectLocalityIDAndLocalityName" 
                      :disabled="loader.selectLocalityIDAndLocalityName"
                      multiple
                      chips
                      closable-chips
                      color="primary"
                      ref="productTransferSendLocalityID"
                      item-title="localityName" 
                      item-value="localityID" 
                      label="Filtrar por localidad que envía" 
                      placeholder="Todas las localidades que envían"
                      variant="solo-filled" 
                      density="compact"
                    ></v-autocomplete>
                  </template>
                  <span style="max-width: 400px; display: block;">La localidad que envía es el lugar desde donde salieron los productos del traslado. Este espacio permite selección múltiple para filtrar por varias localidades en paralelo. Si ninguna localidad es seleccionada, se buscarán todas los traslados de producto sin importar la localidad que envía. Al presionar el ícono de la derecha se limpian las localidades seleccionadas</span>
                </v-tooltip>


                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-autocomplete 
                      v-bind="props"
                      v-model="input.productTransferSendAgentID"
                      :append-inner-icon="input.productTransferSendAgentID.length == 0 ? 'mdi-account' : 'mdi-account-off'" 
                      @click:append-inner="input.productTransferSendAgentID.length = 0; this.blurInput('productTransferSendAgentID')"  
                      :items="display.localityOptions" 
                      :loading="loader.selectLocalityIDAndLocalityName" 
                      :disabled="loader.selectLocalityIDAndLocalityName"
                      multiple
                      chips
                      closable-chips
                      color="primary"
                      ref="productTransferSendAgentID"
                      item-title="localityName" 
                      item-value="localityID" 
                      label="Filtrar por agente que envía" 
                      placeholder="Todas los agentes que envían"
                      variant="solo-filled" 
                      density="compact"
                    ></v-autocomplete>
                  </template>
                  <span style="max-width: 400px; display: block;">La localidad que envía es el lugar desde donde salieron los productos del traslado. Este espacio permite selección múltiple para filtrar por varias localidades en paralelo. Si ninguna localidad es seleccionada, se buscarán todas los traslados de producto sin importar la localidad que envía. Al presionar el ícono de la derecha se limpian las localidades seleccionadas</span>
                </v-tooltip>

                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-autocomplete 
                      v-bind="props"
                      v-model="input.productTransferReceiveLocalityID" 
                      :append-inner-icon="input.productTransferReceiveLocalityID.length == 0 ? 'mdi-store' : 'mdi-store-off'" 
                      @click:append-inner="input.productTransferReceiveLocalityID.length = 0; this.blurInput('productTransferReceiveLocalityID')"  
                      :items="display.localityOptions" 
                      :loading="loader.selectLocalityIDAndLocalityName" 
                      :disabled="loader.selectLocalityIDAndLocalityName"
                      multiple
                      chips
                      closable-chips
                      color="primary"
                      ref="productTransferReceiveLocalityID"
                      item-title="localityName" 
                      item-value="localityID" 
                      label="Filtrar por localidad de destino" 
                      placeholder="Todas las localidades de destino"
                      variant="solo-filled" 
                      density="compact"
                    ></v-autocomplete>
                  </template>
                  <span style="max-width: 400px; display: block;">La localidad de destino es el lugar hacia donde se dirigen los productos del traslado. Este espacio permite selección múltiple para filtrar por varias localidades en paralelo. Si ninguna localidad es seleccionada, se buscarán todas los traslados de producto sin importar la localidad de destino. Al presionar el ícono de la derecha se limpian las localidades seleccionadas</span>
                </v-tooltip>
                <v-tooltip open-delay="2000" location="top">
                  <template v-slot:activator="{ props }">
                    <v-autocomplete 
                      v-bind="props"
                      v-model="input.productTransferState" 
                      :append-inner-icon="input.productTransferState.length == 0 ? 'mdi-clock' : 'mdi-clock-remove'" 
                      @click:append-inner="input.productTransferState.length = 0; this.blurInput('productTransferState')"  
                      :items="['Traslado solicitado', 'Traslado aprobado', 'Traslado rechazado']" 
                      multiple
                      chips
                      closable-chips
                      color="primary"
                      ref="productTransferState"
                      label="Filtrar por estado del traslado" 
                      placeholder="Todos los estados del traslado"
                      variant="solo-filled" 
                      density="compact"
                    ></v-autocomplete>
                  </template>
                  <span style="max-width: 400px; display: block;">El estado del traslado es el momento en el tiempo en el que se encuentra un traslado. Este espacio permite selección múltiple para filtrar por varios estados del traslado en paralelo. Si ningún estado del traslado es seleccionado, se buscarán todas los traslados de producto sin importar el estado del traslado. Al presionar el ícono de la derecha se limpian los estados del traslado seleccionados</span>
                </v-tooltip>
              </div>
              <div style="width: 49.5%; margin-left: 0.5%;">
                <v-text-field 
                  v-model="display.startDateFormatted" 
                  @click="navigation.startDatePickerIsVisible = true" 
                  readonly 
                  label="Filtrar por fecha inicial" 
                  append-inner-icon="mdi-calendar-start" 
                  variant="solo-filled" 
                  density="compact"
                  style="cursor: pointer;" 
                ></v-text-field>
                <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
                  <v-card style="display: flex;">
                    <v-date-picker v-model="input.startDate" hide-header color="primary" width="100%"></v-date-picker>
                    <v-chip @click="input.startDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
                  </v-card>
                </v-dialog>
                <v-text-field 
                  v-model="display.endDateFormatted" 
                  @click="navigation.endDatePickerIsVisible = true" 
                  readonly 
                  label="Filtrar por fecha final" 
                  append-inner-icon="mdi-calendar-end" 
                  variant="solo-filled" 
                  density="compact"
                  style="cursor: pointer;"
                ></v-text-field>
                <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
                  <v-card>
                    <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
                    <v-chip @click="input.endDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
                  </v-card>
                </v-dialog>
              </div>
            </div>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-title>
                  Búsqueda avanzada por producto
                  <template v-slot:actions>
                    <v-icon icon="mdi-magnify"></v-icon>
                  </template>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <br>
                  <v-tooltip open-delay="2000" location="top">
                    <template v-slot:activator="{ props }">
                      <v-autocomplete 
                        v-bind="props"
                        v-model="input.productID" 
                        v-model:search="input.searchedProductID"
                        :append-inner-icon="input.productID == null ? 'mdi-alphabetical-variant' : 'mdi-alphabetical-variant-off'" 
                        @click:append-inner="cleanProductIDInput()" 
                        :items="display.productOptions" 
                        :loading="loader.selectProductBasicBasicInformationForBillsView" 
                        :disabled="loader.selectProductBasicBasicInformationForBillsView" 
                        @keydown.enter.prevent="selectProductByScanner(input.searchedProductID)"
                        closable-chips
                        color="primary"
                        ref="productID" 
                        item-title="productAutocompleteTitle" 
                        item-value="productID" 
                        label="Filtrar por producto" 
                        placeholder="Todos los productos" 
                        variant="solo-filled" 
                        density="compact"
                      ></v-autocomplete>
                    </template>
                    <span style="max-width: 400px; display: block;">Este espacio permite la búsqueda o escaneo de un producto. Si ningún producto es seleccionado, se buscarán todos los traslados de producto sin importar los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpia el producto seleccionado</span>
                  </v-tooltip>   
                  <div style="display: flex;">
                    <div style="width: 49.5%; margin-right: 0.5%;">
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete 
                            v-bind="props"
                            v-model="input.productCategoryID" 
                            :append-inner-icon="input.productCategoryID.length == 0 ? 'mdi-cog' : 'mdi-cog-off'" 
                            @click:append-inner="input.productCategoryID.length = 0; this.blurInput('productCategoryID')" 
                            :items="display.productCategoryOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            closable-chips
                            color="primary"
                            ref="productCategoryID" 
                            item-title="productCategoryName" 
                            item-value="productCategoryID" 
                            label="Filtrar por categoría del producto" 
                            placeholder="Todas las categorías del producto" 
                            variant="solo-filled" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">La categoría del producto hace referencia al primer nivel de categorización de un producto. Este espacio permite selección múltiple para filtrar por varias categorías de producto en paralelo. Si ninguna categoría de producto es seleccionada, se buscarán todos los traslados de producto sin importar las categorías de los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpian las categorías de producto seleccionadas</span>
                      </v-tooltip>
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete 
                            v-bind="props"
                            v-model="input.productBrandID" 
                            :append-inner-icon="input.productBrandID.length == 0 ? 'mdi-briefcase-variant' : 'mdi-briefcase-variant-off'" 
                            @click:append-inner="input.productBrandID.length = 0; this.blurInput('productBrandID')" 
                            :items="display.productBrandOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            closable-chips
                            color="primary"
                            ref="productBrandID" 
                            item-title="productBrandName" 
                            item-value="productBrandID" 
                            label="Filtrar por marca del producto" 
                            placeholder="Todas las marcas del producto" 
                            variant="solo-filled" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">La marca del producto hace referencia al segundo nivel de categorización de un producto. Este espacio permite selección múltiple para filtrar por varias marcas de producto en paralelo. Si ninguna marca de producto es seleccionada, se buscarán todos los traslados de producto sin importar las marcas de los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpian las marcas de producto seleccionadas</span>
                      </v-tooltip>
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete 
                            v-bind="props"
                            v-model="input.productModelID" 
                            :append-inner-icon="input.productModelID.length == 0 ? 'mdi-source-branch' : 'mdi-source-branch-remove'" 
                            @click:append-inner="input.productModelID.length = 0; this.blurInput('productModelID')" 
                            :items="display.productModelOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            closable-chips
                            color="primary"
                            ref="productModelID" 
                            item-title="productModelName" 
                            item-value="productModelID" 
                            label="Filtrar por modelo del producto" 
                            placeholder="Todos los modelos del producto" 
                            variant="solo-filled" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">El modelo del producto hace referencia al tercer nivel de categorización de un producto. Este espacio permite selección múltiple para filtrar por varios modelos de producto en paralelo. Si ningún modelo de producto es seleccionado, se buscarán todos los traslados de producto sin importar los modelos de los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpian los modelos de producto seleccionados</span>
                      </v-tooltip>
                    </div>
                    <div style="width: 49.5%; margin-left: 0.5%;">
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete 
                            v-bind="props"
                            v-model="input.productFlavorID" 
                            :append-inner-icon="input.productFlavorID.length == 0 ? 'mdi-water' : 'mdi-water-off'" 
                            @click:append-inner="input.productFlavorID.length = 0; this.blurInput('productFlavorID')" 
                            :items="display.productFlavorOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            closable-chips
                            color="primary"
                            ref="productFlavorID" 
                            item-title="productFlavorName" 
                            item-value="productFlavorID" 
                            label="Filtrar por sabor del producto" 
                            placeholder="Todos los sabores del producto" 
                            variant="solo-filled" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">El sabor del producto hace referencia al primer tipo de categorización opcional de un producto. Este espacio permite selección múltiple para filtrar por varios sabores de producto en paralelo. Si ningún sabor de producto es seleccionado, se buscarán todas los traslados de producto sin importar los sabores de los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpian los sabores de producto seleccionados</span>
                      </v-tooltip>
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete 
                            v-bind="props"
                            v-model="input.productNicotineLevelID" 
                            :append-inner-icon="input.productNicotineLevelID.length == 0 ? 'mdi-cloud-outline' : 'mdi-cloud-off-outline'" 
                            @click:append-inner="input.productNicotineLevelID.length = 0; this.blurInput('productNicotineLevelID')" 
                            :items="display.productNicotineLevelOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            closable-chips
                            color="primary"
                            ref="productNicotineLevelID" 
                            item-title="productNicotineLevelName" 
                            item-value="productNicotineLevelID" 
                            label="Filtrar por graduación de nicotina del producto"  
                            placeholder="Todas las graduaciones de nicotina del producto" 
                            variant="solo-filled" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">La graduación de nicotina del producto hace referencia al segundo tipo de categorización opcional de un producto. Este espacio permite selección múltiple para filtrar por varias graduaciones de nicotina de producto en paralelo. Si ninguna graduación de nicotina de producto es seleccionada, se buscarán todos los traslados de producto sin importar las graduaciones de nicotina de los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpian las graduaciones de nicotina de producto seleccionadas</span>
                      </v-tooltip>
                      <v-tooltip open-delay="2000" location="top">
                        <template v-slot:activator="{ props }">
                          <v-autocomplete
                            v-bind="props" 
                            v-model="input.productSizeID" 
                            :append-inner-icon="input.productSizeID.length == 0 ? 'mdi-ruler' : 'mdi-pencil-ruler'" 
                            @click:append-inner="input.productSizeID.length = 0; this.blurInput('productSizeID')" 
                            :items="display.productSizeOptions" 
                            :loading="loader.selectProductSearchParameters" 
                            :disabled="loader.selectProductSearchParameters" 
                            multiple 
                            chips 
                            closable-chips
                            color="primary"
                            ref="productSizeID" 
                            item-title="productSizeName" 
                            item-value="productSizeID" 
                            label="Filtrar por tamaño del producto" 
                            variant="solo-filled" 
                            placeholder="Todos los tamaños del producto" 
                            density="compact"
                          ></v-autocomplete>
                        </template>
                        <span style="max-width: 400px; display: block;">El tamaño del producto hace referencia al tercer tipo de categorización opcional de un producto. Este espacio permite selección múltiple para filtrar por varios tamaños de producto en paralelo. Si ningún tamaño de producto es seleccionado, se buscarán todos los traslados de producto sin importar los tamaños de los productos incluidos en los mismos. Al presionar el ícono de la derecha se limpian los tamaños de producto seleccionados</span>
                      </v-tooltip>
                    </div>
                  </div>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
            <br><br>
            <div v-if="loader.selectProductTransferOnProductTransferView" style="text-align: center;">
              <v-progress-circular color="primary" indeterminate></v-progress-circular>
              <br><br>
            </div>
            <div v-else>
              <v-btn @click="selectProductTransferOnProductTransferView()" :disabled="loader.selectLocalityIDAndLocalityName || loader.selectProductBasicBasicInformationForBillsView || loader.selectProductSearchParameters || loader.selectProductTransferOnProductTransferView" block dark height="38" color="primary">
                <h3>BUSCAR</h3>
                <v-icon style="margin-left: 20px;" dark right>mdi-magnify</v-icon>
              </v-btn>
              <br>
              <v-tooltip open-delay="2000" location="top">
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" @click="setDefaultValues()" :disabled="loader.selectLocalityIDAndLocalityName || loader.selectProductBasicBasicInformationForBillsView || loader.selectProductSearchParameters || loader.selectProductTransferOnProductTransferView" dark block height="38" color="#b3b3b3">
                    <h3>LIMPIAR BÚSQUEDA</h3>
                    <v-icon style="margin-left: 20px;" dark right>mdi-backspace</v-icon>
                  </v-btn>
                </template>
                <span style="max-width: 400px; display: block;">Al presionar este botón, se limpiarán los resultados de la búsqueda actual, y se reestablecerán todos los filtros de búsqueda a sus valores por defecto</span>
              </v-tooltip>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <br><br>
    <v-card v-if="display.productTransferRows.length > 0" color="border" elevation="3" style="padding: 20px;">
      <v-data-table :headers="display.productTransferHeaders" :items="display.productTransferRows" :items-per-page="250" hide-default-footer style="max-height: 700px; overflow: auto;">
        <template v-slot:item.productTransferState="{ item }">
          <v-chip :color="localStorage.productTransferStateColors[item.productTransferState].productTransferStateColor" variant="flat">
            <p :style="{color: localStorage.productTransferStateColors[item.productTransferState].productTransferStateFontColor}">{{ item.productTransferState }}</p>
          </v-chip>
        </template>

        <template v-slot:item.sendLocalityName="{ item }">
          <v-tooltip open-delay="500" location="top">
            <template v-slot:activator="{ props }">
              <div v-bind="props" style="margin-top: 10px; margin-bottom: 10px; cursor: pointer;">
                <div style="display: flex;">
                  <v-chip :color="item.sendLocalityColor" variant="flat">
                    <p :style="{color: item.sendLocalityFontColor}">{{ item.sendLocalityName }}</p>
                  </v-chip>
                  <p style="margin-left: 10px; margin-right: 10px; margin-top: 5px;">por</p>
                  <v-chip :color="item.sendAgentColor" variant="flat">
                    <p :style="{color: item.sendAgentFontColor}">{{ item.sendAgentName }}</p>
                  </v-chip>
                </div>
              </div>
            </template>
            <div style="max-width: 400px;">
              <p style="display: block;"><strong>Enviado el: </strong>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.productTransferSendDatetime) }}</p>
              <p><strong>Nota al enviar: </strong>{{ item.productTransferSendNote || 'Sin nota al enviar' }}</p>
            </div>
          </v-tooltip>
        </template>

        <template v-slot:item.receiveLocalityName="{ item }">
          <v-tooltip open-delay="500" location="top">
            <template v-slot:activator="{ props }">
              <div v-bind="props" style="margin-top: 10px; margin-bottom: 10px; cursor: pointer;">
                <div style="display: flex;">
                  <v-chip :color="item.receiveLocalityColor" variant="flat">
                    <p :style="{color: item.receiveLocalityFontColor}">{{ item.receiveLocalityName }}</p>
                  </v-chip>
                  <p style="margin-left: 10px; margin-right: 10px; margin-top: 5px;">por</p>
                  <v-chip :color="item.receiveAgentColor" variant="flat">
                    <p :style="{color: item.receiveAgentFontColor}">{{ item.receiveAgentName || 'No recibido' }}</p>
                  </v-chip>
                </div>
              </div>
            </template>
            <div style="max-width: 400px;">
              <p style="display: block;"><strong>Recibido el: </strong>{{ item.productTransferReceiveDatetime ? $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.productTransferReceiveDatetime) : 'No recibido' }}</p>
              <p style="display: block;"><strong>Nota al recibir: </strong>{{ item.receiveAgentName ? (item.productTransferReceiveNote || 'Sin nota al recibir') : 'No recibido' }}</p>
            </div>
          </v-tooltip>
        </template>
        
        <template v-slot:item.productTransferUpdateAction="{ item }">
          <div v-if="item.productTransferState != 'Traslado aprobado' && item.productTransferState != 'Traslado rechazado'">
            <div v-if="item.receiveLocalityID == localStorage.localityID" style="display: flex;">
              <v-btn @click="openUpdateProductTransferDialog(item, 'Aceptar')" dark height="38" color="success">
                <h3>ACEPTAR</h3>
              </v-btn>
              <v-btn @click="openUpdateProductTransferDialog(item, 'Rechazar')" dark height="38" color="error" style="margin-left: 10px;">
                <h3>RECHAZAR</h3>
              </v-btn>
            </div>
            <div v-else>
              <p>Esperando aprobación de {{ item.receiveLocalityName }}</p>
            </div>
          </div>
        </template>
        <template v-slot:item.productTransferAction="{ item }">
          <div style="display: flex;">
            <v-icon @click="openProductTransferDialog(item.productTransferID)" color="info">mdi-magnify</v-icon>
            <div style="margin-left: 10px;">
              <v-progress-circular v-if="item.productTransferPrintLoader" color="success" indeterminate></v-progress-circular>
              <v-icon v-else @click="createPDFDocumentForProductTransfer(item)" color="success">mdi-printer</v-icon>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <br><br>
  </div>
</template>

<style scoped>
</style>

<script>
import { defineComponent } from 'vue';
import { viewMethodCaller } from '../viewMethodCaller.js';

export default defineComponent({
  name: 'ProductTransferView',
  inject: ['$generalFunctions'],

  data: () => ({

    input: 
    {
      productTransferID: null,
      productTransferSendLocalityID: [],
      productTransferSendAgentID: [],
      productTransferReceiveLocalityID: [],
      startDate: null,
      endDate: null,
      productTransferState: [],
      productID: null,
      searchedProductID: null,
      productCategoryID: [],
      productBrandID: [],
      productModelID: [],
      productFlavorID: [],
      productSizeID: [],
      productNicotineLevelID: []
    },

    display: 
    {
      startDateFormatted: '',
      endDateFormatted: '',
      productOptions: [],
      productCategoryOptions: [],
      productModelOptions: [],
      productBrandOptions: [],
      productFlavorOptions: [],
      productNicotineLevelOptions: [],
      productSizeOptions: [],
      productTransferRows: [],
      backupProductTransferRows: [],
      productTransferHeaders: 
      [
        {key: 'productTransferID', title: 'ID', sortable: false},
        {key: 'productTransferState', title: 'ESTADO', sortable: false},
        {key: 'sendLocalityName', title: 'ENVÍO', sortable: false},
        {key: 'receiveLocalityName', title: 'DESTINO', sortable: false},
        {key: 'productTransferUpdateAction', title: null, sortable: false},
        {key: 'productTransferAction', title: null, sortable: false}
      ],
      productTransferProducts: []
    },

    loader: 
    {
      selectLocalityIDAndLocalityName: false,
      selectProductSearchParameters: false,
      selectProductBasicBasicInformationForBillsView: false,
      selectProductTransferOnProductTransferView: false
    },

    navigation: 
    {
      productTransferProductsDialogIsVisible: false,
      searchVisible: 'searchVisible'
    },

    localStorage:
    {
      localityID: null,
      agentID: null,
      productTransferStateColors: null
    },

    accessCredential: {}

  }),

  watch: {
    'input.startDate': function() {
      this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB');
      this.navigation.startDatePickerIsVisible = false;
    },
    'input.endDate': function() {
      this.display.endDateFormatted = new Date(this.input.endDate).toLocaleDateString('en-GB');
      this.navigation.endDatePickerIsVisible = false;
    }
  },

  methods: {
    async selectProductTransferOnProductTransferView(){
      this.display.productTransferRows.length = 0;
      this.display.backupProductTransferRows.length = 0;
      this.loader.selectProductTransferOnProductTransferView = true;
      const selectProductTransferOnProductTransferViewRequestQuery =
      {
        'productTransferID': this.input.productTransferID,
        'productTransferSendLocalityID': this.input.productTransferSendLocalityID,
        'productTransferReceiveLocalityID': this.input.productTransferReceiveLocalityID,
        'productTransferState': this.input.productTransferState,
        'productID': this.input.productID,
        'productCategoryID': this.input.productCategoryID,
        'productBrandID': this.input.productBrandID,
        'productModelID': this.input.productModelID,
        'productFlavorID': this.input.productFlavorID,
        'productNicotineLevelID': this.input.productNicotineLevelID,
        'productSizeID': this.input.productSizeID,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate
      };
      const selectProductTransferOnProductTransferViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productTransfer/functions/selectProductTransferOnProductTransferView', selectProductTransferOnProductTransferViewRequestQuery);
      if (selectProductTransferOnProductTransferViewResult.success){
        if (selectProductTransferOnProductTransferViewResult.result.length > 0){
          this.display.productTransferRows = selectProductTransferOnProductTransferViewResult.result;
          this.navigation.searchVisible = '';
          this.display.productTransferRows.forEach(productTransferRow => {
            productTransferRow.productTransferPrintLoader = false;
            productTransferRow.productTransferProducts.forEach(productTransferProduct => {
              productTransferProduct.productTransferProductReceiveAmount = productTransferProduct.productTransferProductSendAmount;
            });
          });
          this.display.backupProductTransferRows = JSON.parse(JSON.stringify(this.display.productTransferRows));
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ATENCIÓN',
            'notificationDialogBody': 'No se ha encontrado ningún traslado con los filtros seleccionados, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los traslados, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectProductTransferOnProductTransferView = false;
    },

    async createPDFDocumentForProductTransfer(productTransfer){
      productTransfer.productTransferPrintLoader = true;
      const createPDFDocumentForProductTransferRequestQuery =
      {
        'productTransferID': productTransfer.productTransferID,
        'reprint': true
      };
      const createPDFDocumentForProductTransferResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productTransfer/functions/createPDFDocumentForProductTransfer', createPDFDocumentForProductTransferRequestQuery);
      if (createPDFDocumentForProductTransferResult.success){
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': createPDFDocumentForProductTransferResult.result.documentFile.data,
          'printablePDFDocumentName': createPDFDocumentForProductTransferResult.result.documentName,
          'printableExcelDocumentFile': null,
          'printableExcelDocumentName': null
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el reporte del traslado de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      productTransfer.productTransferPrintLoader = false;
    },

    async selectLocalityIDAndLocalityName(){
      this.loader.selectLocalityIDAndLocalityName = true;
      const selectLocalityIDAndLocalityNameResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityName');
      if (selectLocalityIDAndLocalityNameResult.success){
        this.display.localityOptions = selectLocalityIDAndLocalityNameResult.result;
        this.loader.selectLocalityIDAndLocalityName = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectAgentByAgentLocalityIDAndByAgentIsActive(){
      this.loader.selectAgentByAgentLocalityIDAndByAgentIsActive = true;
      this.input.productTransferSendAgentID.length = 0;
      this.input.productTransferReceiveAgentID.length = 0;
      const selectAgentByAgentLocalityIDAndByAgentIsActiveRequestQuery = 
      {
        'agentLocalityID': this.input.productTransferSendLocalityID, 
        'agentIsActive': true
      };
      const selectAgentByAgentLocalityIDAndByAgentIsActiveResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/select/byAgentLocalityIDAndByAgentIsActive', selectAgentByAgentLocalityIDAndByAgentIsActiveRequestQuery);
      if (selectAgentByAgentLocalityIDAndByAgentIsActiveResult.success){
        this.display.agentOptions = selectAgentByAgentLocalityIDAndByAgentIsActiveResult.result;
        this.loader.selectAgentByAgentLocalityIDAndByAgentIsActive = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los facturadores, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductBasicBasicInformationForBillsView(){
      this.loader.selectProductBasicBasicInformationForBillsView = true;
      const selectProductBasicBasicInformationForBillsViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductBasicBasicInformationForBillsView');
      if (selectProductBasicBasicInformationForBillsViewResult.success){
        this.display.productOptions = selectProductBasicBasicInformationForBillsViewResult.result.map(productOption => ({
          'productID': productOption.i,
          'productSKU': productOption.s,
          'productName': productOption.n,
          'productAutocompleteTitle': productOption.s + ' - ' + productOption.n
        }));
        this.loader.selectProductBasicBasicInformationForBillsView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los facturadores, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductSearchParameters(){
      this.loader.selectProductSearchParameters = true;
      const selectProductSearchParametersResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductSearchParameters');
      if (selectProductSearchParametersResult.success){
        this.display.productCategoryOptions = selectProductSearchParametersResult.result.productCategory;
        this.display.productBrandOptions = selectProductSearchParametersResult.result.productBrand;
        this.display.productModelOptions = selectProductSearchParametersResult.result.productModel;
        this.display.productFlavorOptions = selectProductSearchParametersResult.result.productFlavor;
        this.display.productNicotineLevelOptions = selectProductSearchParametersResult.result.productNicotineLevel;
        this.display.productSizeOptions = selectProductSearchParametersResult.result.productSize;
        this.loader.selectProductSearchParameters = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de características de los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async openUpdateProductTransferDialog(productTransfer, productTransferResult){
      const updateProductTransferDialogRequestQuery =
      {
        'productTransferResult': productTransferResult,
        'productTransferID': productTransfer.productTransferID,
        'productTransferProducts': productTransfer.productTransferProducts
      };
      this.$root.updateProductTransferDialog.openUpdateProductTransferDialog(updateProductTransferDialogRequestQuery);
    },

    async openProductTransferDialog(productTransferID){
      const productTransferDialogRequestQuery =
      {
        'productTransferID': productTransferID
      };
      this.$root.productTransferDialog.openProductTransferDialog(productTransferDialogRequestQuery);
    },

    async blurInput(inputReference){
      const blurInputInterval = setInterval(() => {
        if (this.$refs[inputReference]) {
          this.$refs[inputReference].blur();
          clearInterval(blurInputInterval);
        }
      }, 1);
    },

    async cleanProductIDInput(){
      this.input.searchedProductID = null;
      this.input.productID = null;
      this.blurInput('productID');
    },

    async selectProductByScanner(){
      const selectedProduct = this.display.productOptions.find(productOption => productOption.productID == this.input.searchedProductID);
      if (selectedProduct){
        await this.blurInput('productID');
        this.input.productID = selectedProduct.productID;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': null,
          'notificationDialogBody': 'El producto escaneado no ha sido encontrado, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async listenToViewMethodCaller(){
      viewMethodCaller.on('ProductTransferView/methods/selectProductTransferOnProductTransferView()', this.selectProductTransferOnProductTransferView);
    },

    async setDefaultValues(){
      this.input.productTransferID = null;
      this.input.productTransferSendLocalityID.length = 0;
      this.input.productTransferSendAgentID.length = 0;
      this.input.productTransferReceiveLocalityID.length = 0;
      this.input.productTransferState.length = 0;
      this.input.productID = null;
      this.input.searchedProductID = null;
      this.input.productCategoryID.length = 0;
      this.input.productBrandID.length = 0;
      this.input.productModelID.length = 0;
      this.input.productFlavorID.length = 0;
      this.input.productNicotineLevelID.length = 0;
      this.input.productSizeID.length = 0;
      const startDate = new Date();
      startDate.setDate(1);
      this.input.startDate = startDate;
      this.input.endDate = new Date();
      this.display.productTransferRows.length = 0;
      this.display.backupProductTransferRows.length = 0;
    },

    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.localStorage.productTransferStateColors = this.$generalFunctions.default.data.productTransferStateColors;
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    }
  },

  async mounted(){
    await this.listenToViewMethodCaller();
    await this.getLocalStorageData();
    await this.setDefaultValues();
    this.selectLocalityIDAndLocalityName();
    this.selectProductBasicBasicInformationForBillsView();
    this.selectProductSearchParameters();
  }

});
</script>