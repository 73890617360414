<template>
  <v-dialog v-model="navigation.billCancelationDialogIsVisible" width="auto" :persistent="getBillCancelationDialogIsPersistent()">
    <v-card width="600" class="billCancelationDialogContainer">
      <div v-if="navigation.billCancelationView">
        <v-select v-model="input.selectedBillCancelationType" :items="display.billCancelationTypeOptions" append-inner-icon="mdi-chat-question" label="Tipo de cancelación de la factura" variant="solo-filled" density="compact"></v-select>
        <v-select v-model="input.selectedBillCancelationReason" :items="display.billCancelationReasonOptions" append-inner-icon="mdi-call-merge" label="Motivo de cancelación de la factura" variant="solo-filled" density="compact"></v-select>
        <v-textarea v-model="input.billCancelationNote" rows="4" append-inner-icon="mdi-text-box-outline" label="Nota de la cancelación de la factura" variant="solo-filled"></v-textarea>
        <br>  
        <div v-if="loader.cancelBill" style="text-align: center;">
          <v-progress-circular color="info" indeterminate></v-progress-circular>
          <br><br>
        </div>
        <div v-else>
          <v-btn @click="cancelBill()" :disabled="getCancelBillButtonIsDisabled()" dark block height="38" color="error">
            <h3>CANCELAR FACTURA</h3>
            <v-icon style="margin-left: 20px;" dark right>mdi-trash-can</v-icon>
          </v-btn>
          <br>
          <v-btn @click="closeBillCancelationDialog()" dark block height="38" color="info">
            <h3>SALIR</h3>
            <v-icon style="margin-left: 20px;" dark right>mdi-close-circle</v-icon>
          </v-btn>
        </div>
      </div>
      <div v-else>
        <h3 class="billCancelationWarningLabel"><strong>¡ATENCIÓN!</strong></h3>
        <br>
        <p>Cancelar una factura es un proceso NO REVERSIBLE</p>
        <br>
        <p>Se recomienda que, antes de cancelar una factura, verifique la información de la misma y consulta con su supervisor. Si necesita asistencia, contacte con el administrador del sistema</p>
        <v-checkbox v-model="input.billCancelationWarningAccepted" color="error" label="Deseo continuar con el proceso de cancelación" hide-details></v-checkbox>
        <br>
        <div>
          <v-btn @click="openBillCancelationView()" :disabled="!input.billCancelationWarningAccepted" dark block height="38" color="error">
            <h3>CANCELAR FACTURA</h3>
            <v-icon style="margin-left: 20px;" dark right>mdi-trash-can</v-icon>
          </v-btn>
          <br>
          <v-btn @click="closeBillCancelationDialog()" dark block height="38" color="info">
            <h3>SALIR</h3>
            <v-icon style="margin-left: 20px;" dark right>mdi-close-circle</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped>
  .billCancelationDialogContainer {
    padding: 20px;
    text-align: center;
  }

  .billCancelationWarningLabel {
    color: var(--warningFontColor);
  }
</style>

<script>
import { viewMethodCaller } from '../viewMethodCaller.js';

export default {
  name: "CancelBillDialog",
  inject: ['$generalFunctions'],

  data: () => ({
    navigation: 
    {
      billID: null,
      billCancelationDialogIsVisible: false,
      billCancelationView: false
    },

    input:
    {
      billCancelationWarningAccepted: false,
      selectedBillCancelationType: null,
      selectedBillCancelationReason: null,
      billCancelationNote: null
    },

    display: 
    {
      localityOptions: [],
      agentOptions: [],
      billCancelationLocationOptions: ['El producto está en la localidad', 'El producto está en la bodega'],
      billCancelationTypeOptions: ['Sin devolución de inventario', 'Con devolución de inventario'],
      billCancelationReasonOptions: ['Factura duplicada', 'Pedido cancelado', 'Cliente no retiró de la localidad', 'Cliente no recibió el pedido', 'Menor de edad', 'Otro']
    },

    loader:
    {
      selectLocalityIDAndLocalityNameByLocalityIsActive: false,
      selectAgentByAgentLocalityIDAndByAgentIsActive: false,
      cancelBill: false
    },

    localStorage: 
    {
      localityID: null,
      agentID: null
    }

  }),

  watch: {
    async 'navigation.billCancelationDialogIsVisible'(){
      if (!this.navigation.billCancelationDialogIsVisible){
        await this.$generalFunctions.default.methods.splitURLActions('/openBillCancelationDialog');
        await this.$generalFunctions.default.methods.updateURLParameter('billCancelationDialogBillID', null);
      }
    }
  },

  methods: {
    async cancelBill(){
      if (this.input.selectedBillCancelationType){
        if (this.input.selectedBillCancelationReason){
          this.loader.cancelBill = true;
          const cancelBillRequestQuery = 
          {
            'billCancelationBillID': this.navigation.billID,
            'billCancelationLocalityID': this.localStorage.localityID,
            'billCancelationAgentID': this.localStorage.agentID,
            'billCancelationType': this.input.selectedBillCancelationType,
            'billCancelationReason': this.input.selectedBillCancelationReason,
            'billCancelationNote': this.input.billCancelationNote
          };
          const cancelBillResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/billCancelation/functions/cancelBill', cancelBillRequestQuery);
          if (cancelBillResult.success){
            this.closeBillCancelationDialog();
            viewMethodCaller.emit('BillsView/methods/selectBillOnBillsView()');
            this.$root.notificationDialog.showNotificationDialog({
              'notificationDialogTitle': 'ÉXITO',
              'notificationDialogBody': 'Se ha cancelado la factura exitosamente',
              'notificationDialogColor': 'success',
              'notificationDialogIsPersistent': false
            });
            this.$generalFunctions.default.methods.printRequest(cancelBillResult.result);
          } else {
            this.$root.notificationDialog.showNotificationDialog({
              'notificationDialogTitle': 'ERROR',
              'notificationDialogBody': 'Ha ocurrido un error inesperado al cancelar la factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
              'notificationDialogColor': 'error',
              'notificationDialogIsPersistent': false
            });
          }
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
            'notificationDialogBody': 'Coloque un motivo de cancelación de la factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque un tipo de cancelación de la factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async openBillCancelationView(){
      this.navigation.billCancelationView = true;
      this.input.billCancelationWarningAccepted = false;
    },

    getCancelBillButtonIsDisabled(){
      return this.input.selectedBillCancelationType == null || this.input.selectedBillCancelationReason == null || this.loader.cancelBill;
    },

    getBillCancelationDialogIsPersistent(){
      const regularExpressionChecker = /\S/;
      return this.input.selectedAgentID || this.input.selectedBillCancelationType || this.input.selectedBillCancelationReason || regularExpressionChecker.test(this.input.billCancelationNote) || this.loader.cancelBill;
    },

    getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
    },

    setDefaultValues() {
      this.navigation.billID = null;
      this.navigation.billCancelationDialogIsVisible = false;
      this.navigation.billCancelationView = false;
      this.input.billCancelationWarningAccepted = false;
      this.input.selectedBillCancelationType = null;
      this.input.selectedBillCancelationReason = null;
      this.input.billCancelationNote = null;
      this.display.localityOptions = [];
      this.display.agentOptions = [];
      this.loader.cancelBill = false;
      this.loader.selectLocalityIDAndLocalityNameByLocalityIsActive = false;
      this.loader.selectAgentByAgentLocalityIDAndByAgentIsActive = false;
    },

    showlBillCancelationDialog(billCancelationDialogRequestQuery) {
      this.setDefaultValues();
      this.getLocalStorageData();
      this.navigation.billID = billCancelationDialogRequestQuery.billID;
      this.navigation.billCancelationDialogIsVisible = true;
    },

    closeBillCancelationDialog(){
      this.setDefaultValues();
    }
  },

  mounted(){
  }
}
</script>