<template>
  <v-app-bar color="primary">
  <v-container fluid>
    <v-row align="center" no-gutters>
      <!-- Menú lateral -->
      <v-col cols="auto">
        <v-app-bar-nav-icon 
          @click="navigation.navigationDrawerIsVisible = !navigation.navigationDrawerIsVisible"
        ></v-app-bar-nav-icon>
      </v-col>

      <!-- Título -->
      <v-col class="d-none d-sm-flex">
        <h3 class="app-bar-title">SOUQ - {{ localStorage.localityName }}</h3>
      </v-col>

      <v-spacer />

      <!-- Fecha y tipo de cambio (Ocultos en pantallas muy pequeñas) -->
      <v-col class="d-none d-md-flex" style="text-align: center;">
        <p class="app-bar-text">{{ display.currentDate }}</p>
      </v-col>

      <v-col class="d-none d-md-flex" style="text-align: center;">
        <p class="app-bar-text">1$ = ₡{{ display.dolarExchangeRate }}</p>
      </v-col>

      <v-spacer />


      <v-col cols="auto" v-if="accessCredential['/task']">
        <v-app-bar-nav-icon 
          @click="navigation.taskDrawerIsVisible = !navigation.taskDrawerIsVisible"
          @mouseover="navigation.taskDrawerIsVisible = true"
        ></v-app-bar-nav-icon>
      </v-col>

      <!-- Botón de Dark Mode -->
      <v-col cols="auto">
        <v-btn icon color="white" @click="isDarkMode = !isDarkMode; toggleDarkMode()">
          <v-icon class="icon-rotate">
            {{ isDarkMode ? 'mdi-weather-night' : 'mdi-weather-sunny' }}
          </v-icon>
        </v-btn>
      </v-col>

      <!-- Menú de usuario -->
      <v-col cols="auto">
        <v-menu open-on-hover>
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" icon><v-icon>mdi-account-circle</v-icon></v-btn>
          </template>
          <v-list>
            <v-list-item style="background-color: rgba(255,255,255,0.1); border-radius: 5px; margin: 2px 0;">
              <v-list-item-title @click="signOut()" style="cursor: pointer;">Cerrar sesión</v-list-item-title>
            </v-list-item>
            <div style="text-align: center;">
              <p style="font-size: smaller; color: #c4c4c4;">v {{ navigation.version }}</p>
            </div>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</v-app-bar>
  
  <v-navigation-drawer v-model="navigation.navigationDrawerIsVisible" @mouseleave="navigation.navigationDrawerIsVisible = false"
    absolute
    temporary
    class="bg-primary"  
    :width="$vuetify.display.mdAndUp ? '30%' : '80vw'"
    style="height: 100vh; position: fixed; top: 60px; left: 0;"
    >
    <v-text-field
      v-model="input.searchNavigationDrawerOption"
      append-inner-icon="mdi-magnify"
      variant="solo-filled"
      label="Búsqueda de opciones"
      theme="light"
      density="compact"
      style="margin: 20px 20px 0px 20px;"
    ></v-text-field>

    
    <div style="display: flex; justify-content: space-between; width: 100%; display: none;">
      <v-btn 
        @click="changeModule('billing')" 
        dark 
        height="38" 
        color="success" 
        style="flex-grow: 1; margin: 0px 10px 20px 10px;">
        <h3>BILLING</h3>
      </v-btn>
      <v-btn 
        @click="changeModule('CRM')" 
        dark 
        height="38" 
        color="success" 
        style="flex-grow: 1; margin: 0px 10px 20px 10px;">
        <h3>CRM</h3>
      </v-btn>
    </div>


    <v-list style="position: relative; top: -10px; overflow-y: auto; max-height: 80%;">
      <div v-for="item1 in display.displayedNavigationDrawerOptions" v-bind:key="item1">
        <div v-if="item1.accessCredential.some(accessCredentialElement => accessCredential[accessCredentialElement] == 1)">
          <div v-if="item1.type == 'item'">
            <v-list-item  active-class="bg-secondary text-white" style="white-space: normal; word-break: break-word;" v-if="item1.route" @click="navigateTo(item1.route)" :prepend-icon="item1.icon" :title="item1.title" :style="getNavigationDrawerOptionStyle(item1)"></v-list-item>
            <v-list-item  active-class="bg-secondary text-white" style="white-space: normal; word-break: break-word;" v-else @click="openDialog(item1.dialog)" :prepend-icon="item1.icon" :title="item1.title"></v-list-item>
          </div>
          <div v-else>
            <v-list-group color="secondary">
              <template v-slot:activator="{ props }">
                <v-list-item style="white-space: normal; word-break: break-word;" v-bind="props" :prepend-icon="item1.icon" :title="item1.title"></v-list-item>
              </template>
              <div v-for="item2 in item1.items" v-bind:key="item2">
                <div v-if="item2.accessCredential.some(accessCredentialElement => accessCredential[accessCredentialElement] == 1)">
                  <div v-if="item2.type == 'item'">
                    <v-list-item style="white-space: normal; word-break: break-word;" v-if="item2.route" @click="navigateTo(item2.route)" :prepend-icon="item2.icon" :title="item2.title" :style="getNavigationDrawerOptionStyle(item2)"></v-list-item>
                    <v-list-item style="white-space: normal; word-break: break-word;" v-else @click="openDialog(item2.dialog)" :prepend-icon="item2.icon" :title="item2.title"></v-list-item>
                  </div>
                  <div v-else>
                    <v-list-group>
                      <template v-slot:activator="{ props }">
                        <v-list-item style="white-space: normal; word-break: break-word;" v-bind="props" :prepend-icon="item2.icon" :title="item2.title"></v-list-item>
                      </template>
                      <div v-for="item3 in item2.items" v-bind:key="item3">
                        <div v-if="item3.accessCredential.some(accessCredentialElement => accessCredential[accessCredentialElement] == 1)">
                          <div v-if="item3.type == 'item'">
                            <v-list-item style="white-space: normal; word-break: break-word;" v-if="item3.route" @click="navigateTo(item3.route)" :prepend-icon="item3.icon" :title="item3.title" :style="getNavigationDrawerOptionStyle(item3)"></v-list-item>
                            <v-list-item style="white-space: normal; word-break: break-word;" v-else @click="openDialog(item3.dialog)" :prepend-icon="item3.icon" :title="item3.title"></v-list-item>
                          </div>
                          <div v-else>
                            <!-- APARTADO EN CASO DE QUE SE QUIERA AGRUPAR MÁS -->
                          </div>
                        </div>
                      </div>
                    </v-list-group>
                  </div>
                </div>
              </div>
            </v-list-group>
          </div>
        </div>
      </div>
    </v-list>
    <div v-if="display.displayedNavigationDrawerOptions.length == 0" style="text-align: center;">
      <p style="font-size: large;">Sin opciones disponibles</p>
    </div>

  </v-navigation-drawer>

  <v-navigation-drawer v-model="navigation.taskDrawerIsVisible"
    absolute
    temporary
    class="bg-primary"  
    :width="$vuetify.display.mdAndUp ? '30%' : '80vw'"
    location="right"
    style="height: 100vh; position: fixed; top: 60px; right: 0;"
    >
    
    <v-card color="primary" elevation="3" style="height: 65%; overflow-y: auto;">
      <div v-for="task in display.taskDrawer" v-bind:key="task">
        <v-card color="border" elevation="3" style="margin: 20px; padding: 10px;">
          <div style="display: flex; align-items: center; margin-bottom: 15px;">
            <v-chip :color="task.creationAgentColor" variant="flat">
              <p :style="{color: task.creationAgentFontColor}">{{ task.creationAgentName }}</p>
            </v-chip>
            <v-icon color="gray" icon="mdi-arrow-right-bold" size="20" style="margin: 7px 10px 0px 10px;"></v-icon>
            <v-chip :color="task.assignedAgentColor" variant="flat">
              <p :style="{color: task.assignedAgentFontColor}">{{ task.assignedAgentName }}</p>
            </v-chip>
            <div style="display: flex; margin-left: auto;">
              <p style="font-size: small;">{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(task.taskCreationDatetime) }}</p>
              <v-tooltip v-if="task.taskState == 'Tarea asignada'" :text="task.taskState" location="top">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="warning" style="cursor: pointer; margin-left: 10px;">mdi-clock</v-icon>
                </template>
              </v-tooltip>
              <v-tooltip v-else-if="task.taskState == 'Tarea completada'" :text="task.taskState" location="top">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="success" style="cursor: pointer; margin-left: 10px;">mdi-check-circle</v-icon>
                </template>
              </v-tooltip>
              <v-tooltip text="Eliminar tarea" location="top">
                <template v-slot:activator="{ props }">
                  <v-icon @click="deleteTask(task.taskID)" v-bind="props" color="error" style="cursor: pointer; margin-left: 10px;">mdi-delete</v-icon>
                </template>
              </v-tooltip>
            </div>
          </div>
          <p><strong>Tipo de la tarea: </strong>{{ task.taskType }}</p>
          <p v-if="task.taskExpectedDatetime"><strong>Fecha límite: </strong>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(task.taskExpectedDatetime) }}</p>
          <p>"{{ task.taskComment }}"</p>
        </v-card>
      </div>
    </v-card>
    <br>
    <v-card elevation="3" style="padding: 20px 20px 20px 20px; background-color: #e9e8e8; height: 35%; overflow-y: auto;">
      <v-autocomplete v-model="input.taskAssignedAgentID" :items="display.agentOptions" :loading="loader.selectAgentOptions" :disabled="loader.selectAgentOptions" item-value="agentID" item-title="agentName" append-inner-icon="mdi-account" label="Agente asignado" variant="solo-filled" density="compact"></v-autocomplete>
      <v-select v-model="input.taskType" :items="display.taskTypeOptions" append-inner-icon="mdi-source-branch" label="Tipo de la tarea" variant="solo-filled" density="compact"></v-select>
      <v-textarea v-model="input.taskComment" rows="3" append-inner-icon="mdi-text-box-outline" label="Detalle de la tarea" variant="solo-filled"></v-textarea>
      <v-text-field v-model="display.taskExpectedDatetimeFormatted" @click="navigation.datePickerIsVisible = true" readonly label="Fecha límite para la tarea (opcional)" append-inner-icon="mdi-calendar" variant="solo-filled" density="compact"></v-text-field>
      <v-dialog v-model="navigation.datePickerIsVisible" width="auto">
        <v-card style="display: flex;">
          <v-date-picker v-model="input.taskExpectedDatetime" hide-header color="primary" width="100%"></v-date-picker>
          <v-chip @click="input.taskExpectedDatetime = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
        </v-card>
      </v-dialog>
      <br>
      <div style="text-align: center;">
        <div v-if="loader.insertTask">
          <v-progress-circular color="success" indeterminate></v-progress-circular>
        </div>
        <v-btn v-else @click="insertTask()" height="40" color="success">
          <h3>ASIGNAR TAREA</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-content-save</v-icon>
        </v-btn>
      </div>
    </v-card>


  </v-navigation-drawer>



    
  


</template>

<style scoped>


.v-list-item--active {
  background-color: #4CAF50 !important; /* Cambia al color que desees */
  color: white !important; /* Cambia el color del texto si es necesario */
}

.v-list-group__items {
  background-color: rgba(255, 255, 255, 0.1); /* Color más claro */
  border-radius: 5px;
}

@media (max-width: 600px) {
  .v-list-item-title {
    font-size: 12px !important; /* Reduce el tamaño del texto */
  }
  
  .v-list-item {
    padding: 5px 10px !important; /* Reduce el padding para que quepa mejor */
  }

  .v-navigation-drawer {
    width: 80vw !important;
  }
}

.app-bar-title {
  font-size: 18px; /* Ajusta el tamaño del título en pantallas grandes */
}

.app-bar-text {
  font-size: 14px;
  margin: 0 8px;
}


@keyframes rotate-icon {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Animación de rotación */
.icon-rotate {
  animation: rotate-icon 3s linear infinite;
}

@keyframes rotate-icon {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { useTheme } from 'vuetify';

// Estado del modo oscuro
const isDarkMode = ref(false);
const theme = useTheme();

// Alternar entre Dark Mode y Light Mode
const toggleDarkMode = () => {
  theme.global.name.value = isDarkMode.value ? 'darkTheme' : 'customTheme';
};

// Guardar preferencia en localStorage
watch(isDarkMode, (newValue) => {
  localStorage.setItem('darkMode', JSON.stringify(newValue));
});

// Cargar preferencia al montar el componente
onMounted(() => {
  isDarkMode.value = JSON.parse(localStorage.getItem('darkMode')) || false;
  toggleDarkMode();
});
</script>
<script>
import { viewMethodCaller } from '../viewMethodCaller.js';

export default {
  name: 'TopNavigationBar',
  inject: ['$generalFunctions'],

  data: () => ({
    input: 
    {
      taskAssignedAgentID: null,
      taskType: null,
      taskComment: null,
      taskExpectedDatetime: null,
      searchNavigationDrawerOption: null
    },

    navigation: 
    {
      navigationDrawerIsVisible: false,
      taskDrawerIsVisible: false,
      currentDateInterval: null,
      datePickerIsVisible: false,
      version: '1.1.10 B'
    },

    display:
    {
      
      originalNavigationDrawerOptions:
      [
        {
          type: 'group',
          title: 'Dashboard',
          icon: 'mdi-monitor-dashboard',
          accessCredential: ['/dashboard/master', '/dashboard/operation', '/dashboard/agentSale', '/dashboard/storeAdmin', '/dashboard/warehouse', '/dashboard/wholesale', '/dashboard/office'],
          route: null,
          dialog: null,
          items: 
          [
            {
              type: 'item',
              title: 'Dashboard (master)',
              icon: 'mdi-crown',
              accessCredential: ['/dashboard/master'],
              route: '/dashboard/master',
              dialog: null,
              items: []
            },
            {
              type: 'item',
              title: 'Dashboard (operativo)',
              icon: 'mdi-chart-gantt',
              accessCredential: ['/dashboard/operation'],
              route: '/dashboard/operation',
              dialog: null,
              items: []
            },
            {
              type: 'item',
              title: 'Dashboard (ventas por agente)',
              icon: 'mdi-medal',
              accessCredential: ['/dashboard/agentSale'],
              route: '/dashboard/agentSale',
              dialog: null,
              items: []
            },
            {
              type: 'item',
              title: 'Dashboard (administrador de tienda)',
              icon: 'mdi-briefcase-account',
              accessCredential: ['/dashboard/storeAdmin'],
              route: '/dashboard/storeAdmin',
              dialog: null,
              items: []
            },
            {
              type: 'item',
              title: 'Dashboard (bodega)',
              icon: 'mdi-warehouse',
              accessCredential: ['/dashboard/warehouse'],
              route: '/dashboard/warehouse',
              dialog: null,
              items: []
            },
            {
              type: 'item',
              title: 'Dashboard (venta al por mayor)',
              icon: 'mdi-package',
              accessCredential: ['/dashboard/wholesale'],
              route: '/dashboard/wholesale',
              dialog: null,
              items: []
            },
            {
              type: 'item',
              title: 'Dashboard (oficina)',
              icon: 'mdi-archive',
              accessCredential: ['/dashboard/office'],
              route: '/dashboard/office',
              dialog: null,
              items: []
            }
          ]
        },

        {
          type: 'group',
          title: 'Tokens',
          icon: 'mdi-lock',
          accessCredential: ['/token', '/token/insertTokenDialog'],
          route: null,
          dialog: null,
          items: 
          [
            {
              type: 'item',
              title: 'Generación de token',
              icon: 'mdi-lock-plus',
              accessCredential: ['/token/insertTokenDialog'],
              route: null,
              dialog: 'openInsertTokenDialog',
              items: []
            },
            {
              type: 'item',
              title: 'Historial de tokens',
              icon: 'mdi-lock-clock',
              accessCredential: ['/token'],
              route: '/token',
              dialog: null,
              items: []
            }
          ]
        },

        {
          type: 'item',
          title: 'Aprobación de pagos',
          icon: 'mdi-bank',
          accessCredential: ['/billPaymentValidation'],
          route: '/billPaymentValidation',
          dialog: null,
          items: []
        },

        {
          type: 'item',
          title: 'Facturar',
          icon: 'mdi-cart',
          accessCredential: ['/bill'],
          route: '/bill',
          dialog: null,
          items: []
        },

        {
          type: 'item',
          title: 'Facturas previas',
          icon: 'mdi-printer-pos',
          accessCredential: ['/bills'],
          route: '/bills',
          dialog: null,
          items: []
        },

        {
          type: 'item',
          title: 'Facturas de Uber / Rappi',
          icon: 'mdi-motorbike',
          accessCredential: ['/uberBills'],
          route: '/uberBills',
          dialog: null,
          items: []
        },

        {
          type: 'item',
          title: 'Órdenes de call center',
          icon: 'mdi-monitor-cellphone',
          accessCredential: ['/callCenter'],
          route: '/callCenter',
          dialog: null,
          items: []
        },


        {
          type: 'group',
          title: 'Cierres de caja',
          icon: 'mdi-finance',
          accessCredential: ['/close'],
          route: null,
          dialog: null,
          items: 
          [
            {
              type: 'item',
              title: 'Generar cierre de caja',
              icon: 'mdi-finance',
              accessCredential: ['/close'],
              route: '/close',
              dialog: null,
              items: []
            },
            {
              type: 'item',
              title: 'Cierre de mensajeros',
              icon: 'mdi-motorbike',
              accessCredential: ['/deliveryClose'],
              route: '/deliveryClose',
              dialog: null,
              items: []
            },
            {
              type: 'item',
              title: 'Historial de cierres de caja',
              icon: 'mdi-clipboard-clock-outline',
              accessCredential: ['/closes'],
              route: '/closes',
              dialog: null,
              items: []
            }
          ]
        },


        {
          type: 'group',
          title: 'Proformas',
          icon: 'mdi-invoice-text',
          accessCredential: ['/proformas', '/proformas/insertProformaDialog'],
          route: null,
          dialog: null,
          items: 
          [
            {
              type: 'item',
              title: 'Generación de proforma',
              icon: 'mdi-invoice-text-plus',
              accessCredential: ['/proformas/insertProformaDialog'],
              route: null,
              dialog: 'openInsertProformaDialog',
              items: []
            },
            {
              type: 'item',
              title: 'Lista de proformas',
              icon: 'mdi-invoice-text-clock',
              accessCredential: ['/proformas'],
              route: '/proformas',
              dialog: null,
              items: []
            }
          ]
        },

        {
          type: 'group',
          title: 'Contactos',
          icon: 'mdi-account',
          accessCredential: ['/contacts', '/pendingPayments', '/contacts/contactDialog/insertContact'],
          route: null,
          dialog: null,
          items: 
          [
            {
              type: 'item',
              title: 'Creación de contacto',
              icon: 'mdi-account-plus',
              accessCredential: ['/contacts/contactDialog/insertContact'],
              route: null,
              dialog: 'openContactDialog',
              items: []
            },
            {
              type: 'item',
              title: 'Lista de contactos',
              icon: 'mdi-account-details',
              accessCredential: ['/contacts'],
              route: '/contacts',
              dialog: null,
              items: []
            },
            {
              type: 'item',
              title: 'Cuentas por cobrar',
              icon: 'mdi-cash-clock',
              accessCredential: ['/pendingPayments'],
              route: '/pendingPayments',
              dialog: null,
              items: []
            }
          ]
        },

        {
          type: 'group',
          title: 'Productos',
          icon: 'mdi-warehouse',
          accessCredential: ['/product/insertMainProductDialog', '/product/insertProductDialog', '/products', '/product', '/productHistory', '/productCompare', '/productDailySale', '/productTopSale', '/productLowSale', '/productStock', '/productLowStock'],
          route: null,
          dialog: null,
          items: 
          [
            {
              type: 'group',
              title: 'Creación de productos',
              icon: 'mdi-plus-circle-outline',
              accessCredential: ['/product/insertMainProductDialog', '/product/insertProductDialog'],
              route: null,
              dialog: null,
              items: 
              [
                {
                  type: 'item',
                  title: 'Creación de producto madre',
                  icon: 'mdi-source-branch',
                  accessCredential: ['/product/insertMainProductDialog'],
                  route: null,
                  dialog: 'openInsertMainProductDialog',
                  items: []
                },
                {
                  type: 'item',
                  title: 'Creación de producto',
                  icon: 'mdi-source-branch-plus',
                  accessCredential: ['/product/insertProductDialog'],
                  route: null,
                  dialog: 'openInsertProductDialog',
                  items: []
                }
              ]
            },
            {
              type: 'item',
              title: 'Lista de productos',
              icon: 'mdi-view-list-outline',
              accessCredential: ['/products'],
              route: '/products',
              dialog: null,
              items: []
            },
            {
              type: 'item',
              title: 'Panel de producto',
              icon: 'mdi-bulletin-board',
              accessCredential: ['/product'],
              route: '/product',
              dialog: null,
              items: []
            },
            {
              type: 'item',
              title: 'Tracking de producto',
              icon: 'mdi-update',
              accessCredential: ['/productHistory'],
              route: '/productHistory',
              dialog: null,
              items: []
            },
            {
              type: 'item',
              title: 'Comparación de producto',
              icon: 'mdi-compare-horizontal',
              accessCredential: ['/productCompare'],
              route: '/productCompare',
              dialog: null,
              items: []
            },
            {
              type: 'group',
              title: 'Ventas por producto',
              icon: 'mdi-chart-areaspline',
              accessCredential: ['/productSale', '/productDailySale', '/productTopSale', '/productLowSale'],
              route: null,
              dialog: null,
              items: 
              [
                {
                  type: 'item',
                  title: 'Ventas por producto',
                  icon: 'mdi-view-list',
                  accessCredential: ['/productSale'],
                  route: '/productSale',
                  dialog: null,
                  items: []
                },
                {
                  type: 'item',
                  title: 'Ventas por producto para reposición',
                  icon: 'mdi-view-list',
                  accessCredential: ['/productDailySale'],
                  route: '/productDailySale',
                  dialog: null,
                  items: []
                },
                {
                  type: 'item',
                  title: 'Productos más vendidos',
                  icon: 'mdi-trending-up',
                  accessCredential: ['/productTopSale'],
                  route: '/productTendencySale/top',
                  dialog: null,
                  items: []
                },
                {
                  type: 'item',
                  title: 'Productos menos vendidos',
                  icon: 'mdi-trending-down',
                  accessCredential: ['/productLowSale'],
                  route: '/productTendencySale/low',
                  dialog: null,
                  items: []
                },
                {
                  type: 'item',
                  title: 'Unidades vendidas',
                  icon: 'mdi-counter',
                  accessCredential: ['/saleCount'],
                  route: '/saleCount',
                  dialog: null,
                  items: []
                },
              ]
            },
            {
              type: 'group',
              title: 'Control de inventario',
              icon: 'mdi-archive',
              accessCredential: ['/productStock', '/productLowStock'],
              route: null,
              dialog: null,
              items: 
              [
                {
                  type: 'item',
                  title: 'Inventario de producto',
                  icon: 'mdi-archive-search',
                  accessCredential: ['/productStock'],
                  route: '/productStock',
                  dialog: null,
                  items: []
                },
                {
                  type: 'item',
                  title: 'Productos con inventario bajo',
                  icon: 'mdi-archive-alert',
                  accessCredential: ['/productLowStock'],
                  route: '/productLowStock',
                  dialog: null,
                  items: []
                }
              ]
            }
          ]
        },

        {
          type: 'group',
          title: 'Movimiento de productos',
          icon: 'mdi-package',
          accessCredential: ['/productInAndOut', '/productInAndOut/insertProductInAndOutDialog', '/productTransfer', '/productTransfer/insertProductTransferDialog', '/productChange', '/productChange/insertProductChangeDialog'],
          route: null,
          dialog: null,
          items: 
          [
            {
              type: 'group',
              title: 'Entrada/salida de productos',
              icon: 'mdi-clipboard-outline',
              accessCredential: ['/productInAndOut', '/productInAndOut/insertProductInAndOutDialog'],
              route: null,
              dialog: null,
              items: 
              [
                {
                  type: 'item',
                  title: 'Generación de entrada/salida',
                  icon: 'mdi-clipboard-plus-outline',
                  accessCredential: ['/productInAndOut/insertProductInAndOutDialog'],
                  route: null,
                  dialog: 'openInsertProductInAndOutDialog',
                  items: []
                },
                {
                  type: 'item',
                  title: 'Historial de entradas/salidas',
                  icon: 'mdi-clipboard-clock-outline',
                  accessCredential: ['/productInAndOut'],
                  route: '/productInAndOut',
                  dialog: null,
                  items: []
                }
              ]
            },
            {
              type: 'group',
              title: 'Traslado de productos',
              icon: 'mdi-briefcase',
              accessCredential: ['/productTransfer', '/productTransfer/insertProductTransferDialog'],
              route: null,
              dialog: null,
              items: 
              [
                {
                  type: 'item',
                  title: 'Generación de traslado',
                  icon: 'mdi-briefcase-plus',
                  accessCredential: ['/productTransfer/insertProductTransferDialog'],
                  route: null,
                  dialog: 'openInsertProductTransferDialog',
                  items: []
                },
                {
                  type: 'item',
                  title: 'Historial de traslados',
                  icon: 'mdi-briefcase-clock',
                  accessCredential: ['/productTransfer'],
                  route: '/productTransfer',
                  dialog: null,
                  items: []
                }
              ]
            },
            {
              type: 'group',
              title: 'Cambios de producto',
              icon: 'mdi-alert',
              accessCredential: ['/productChange', '/productChange/insertProductChangeDialog'],
              route: null,
              dialog: null,
              items: 
              [
                {
                  type: 'item',
                  title: 'Generación de solicitud de cambio',
                  icon: 'mdi-alert-plus',
                  accessCredential: ['/productChange/insertProductChangeDialog'],
                  route: null,
                  dialog: 'openInsertProductChangeDialog',
                  items: []
                },
                {
                  type: 'item',
                  title: 'Historial de cambios',
                  icon: 'mdi-timer-alert',
                  accessCredential: ['/productChange'],
                  route: '/productChange',
                  dialog: null,
                  items: []
                }
              ]
            }
          ]
        },

        {
          type: 'item',
          title: 'Proveedores',
          icon: 'mdi-briefcase',
          accessCredential: ['/productProvider'],
          route: '/productProvider',
          dialog: null,
          items: []
        },

        {
          type: 'group',
          title: 'Gastos',
          icon: 'mdi-cash',
          accessCredential: ['/operationalExpense', '/localityExpense'],
          route: null,
          dialog: null,
          items: 
          [
          {
              type: 'item',
              title: 'Gastos operativos',
              icon: 'mdi-cash-sync',
              accessCredential: ['/operationalExpense'],
              route: '/operationalExpense',
              dialog: null,
              items: []
            },
            {
              type: 'item',
              title: 'Gastos de localidades',
              icon: 'mdi-cash-marker',
              accessCredential: ['/localityExpense'],
              route: '/localityExpense',
              dialog: null,
              items: []
            }
          ]
        },

        {
          type: 'item',
          title: 'Localidades',
          icon: 'mdi-store',
          accessCredential: ['/locality'],
          route: '/locality',
          dialog: null,
          items: []
        },

        {
          type: 'group',
          title: 'Agentes',
          icon: 'mdi-account',
          accessCredential: ['/agent', '/agent/insertAgentDialog'],
          route: null,
          dialog: null,
          items: 
          [
            {
              type: 'item',
              title: 'Creación de agente',
              icon: 'mdi-account-plus',
              accessCredential: ['/agent/insertAgentDialog'],
              route: null,
              dialog: 'openInsertAgentDialog',
              items: []
            },
            {
              type: 'item',
              title: 'Manejo de agentes',
              icon: 'mdi-account-edit',
              accessCredential: ['/agent'],
              route: '/agent',
              dialog: null,
              items: []
            }
          ]
        },
      ],
      displayedNavigationDrawerOptions: [],
      productTransferAmount: 0,
      currentDate: null,
      dolarExchangeRate: null,
      taskDrawer: [],
      agentOptions: [],
      taskTypeOptions: ['Venta de producto', 'Meta de tienda', 'Tarea individual', 'Otro'],
      taskExpectedDatetimeFormatted: null
    },

    localStorage: 
    {
      JWTValue: null,
      JWTAgentID: null,
      agentID: null,
      localityID: null,
      localityName: null
    },

    loader: 
    {
      selectAgentOptions: false,
      insertTask: false
    },

    accessCredential: {},

    interval:
    {
      validateJWT: null,
      selectTask: null
    }
  }),

  watch: {
    'input.searchNavigationDrawerOption'(){
      this.display.displayedNavigationDrawerOptions = JSON.parse(JSON.stringify(this.display.originalNavigationDrawerOptions));
      const displayedNavigationDrawerOptions = [];
      const regularExpressionChecker = /\S/;
      if (regularExpressionChecker.test(this.input.searchNavigationDrawerOption)){
        for (var index1 in this.display.originalNavigationDrawerOptions){
          const item1 = this.display.originalNavigationDrawerOptions[index1];
          const title1 = item1.title;
          if (title1.toLocaleLowerCase().includes(this.input.searchNavigationDrawerOption.toLocaleLowerCase())){
            displayedNavigationDrawerOptions.push(item1);
          } else {
            loop2:
            for (var index2 in item1.items){
              const item2 = item1.items[index2];
              const title2 = item2.title;
              if (title2.toLocaleLowerCase().includes(this.input.searchNavigationDrawerOption.toLocaleLowerCase())){
                displayedNavigationDrawerOptions.push(item1);
                break loop2;
              } else {
                for (var index3 in item2.items){
                  const item3 = item2.items[index3];
                  const title3 = item3.title;
                  if (title3.toLocaleLowerCase().includes(this.input.searchNavigationDrawerOption.toLocaleLowerCase())){
                    displayedNavigationDrawerOptions.push(item1);
                    break loop2;
                  }
                }
              }
            }
          }
        }
        this.display.displayedNavigationDrawerOptions = displayedNavigationDrawerOptions;
      }
    },

    'input.taskExpectedDatetime'() {
      this.display.taskExpectedDatetimeFormatted = this.input.taskExpectedDatetime ? new Date(this.input.taskExpectedDatetime).toLocaleDateString('en-GB') : null;
      this.navigation.datePickerIsVisible = false;
    },
  },

  methods: {

    changeModule(moduleName){
      console.log(moduleName)
    },

    openDialog(dialogName){
      if (typeof this[dialogName] === 'function') {
        this[dialogName]();
      }
    },

    openInsertProductChangeDialog(){
      this.$root.insertProductChangeDialog.openInsertProductChangeDialog();
      this.navigation.navigationDrawerIsVisible = false;
    },
    openInsertProductInAndOutDialog(){
      this.navigateTo('/productInAndOut');
      this.$root.insertProductInAndOutDialog.openInsertProductInAndOutDialog();
      this.navigation.navigationDrawerIsVisible = false;
    },
    openInsertProductTransferDialog(){
      this.navigateTo('/productTransfer');
      this.$root.insertProductTransferDialog.openInsertProductTransferDialog();
      this.navigation.navigationDrawerIsVisible = false;
    },
    openInsertMainProductDialog(){
      this.navigateTo('/products');
      this.$root.insertMainProductDialog.openInsertMainProductDialog(null);
      this.navigation.navigationDrawerIsVisible = false;
    },
    openInsertProductDialog(){
      this.navigateTo('/products');
      this.$root.insertProductDialog.openInsertProductDialog(null);
      this.navigation.navigationDrawerIsVisible = false;
    },
    openInsertAgentDialog(){
      this.navigateTo('/agent');
      this.$root.insertAgentDialog.openInsertAgentDialog();
      this.navigation.navigationDrawerIsVisible = false;
    },
    openInsertTokenDialog(){
      this.navigateTo('/token');
      this.$root.insertTokenDialog.openInsertTokenDialog();
      this.navigation.navigationDrawerIsVisible = false;
    },
    openInsertProformaDialog(){
      this.navigateTo('/proformas');
      this.$root.insertProformaDialog.openInsertProformaDialog(null);
      this.navigation.navigationDrawerIsVisible = false;
    }, 
    openContactDialog(){
      this.navigateTo('/contacts');
      this.$root.contactDialog.openContactDialog(null);
      this.navigation.navigationDrawerIsVisible = false;
    }, 

    async validateJWT(){
      const validateJWTRequestQuery = 
      {
        'JWTValue': this.localStorage.JWTValue, 
        'JWTAgentID': this.localStorage.JWTAgentID
      };
      await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/functions/validateJWT', validateJWTRequestQuery);
    },

    async selectCountProductTransferByProductTransferReceiveLocalityIDAndProductTransferState(){
      const selectCountProductTransferByProductTransferReceiveLocalityIDAndProductTransferStateRequestQuery = 
      {
        'productTransferReceiveLocalityID': this.localStorage.localityID,
        'productTransferState': 'Activo'
      }
      const selectCountProductTransferByProductTransferReceiveLocalityIDAndProductTransferStateResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productTransfer/select/countProductTransfer/byProductTransferReceiveLocalityIDAndProductTransferState', selectCountProductTransferByProductTransferReceiveLocalityIDAndProductTransferStateRequestQuery);
      if (selectCountProductTransferByProductTransferReceiveLocalityIDAndProductTransferStateResult.success){
        this.display.productTransferAmount = selectCountProductTransferByProductTransferReceiveLocalityIDAndProductTransferStateResult.result;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la cantidad de traslados activos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectTaskFromTaskDrawer(){
      const selectTaskFromTaskDrawerResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/task/functions/selectTaskFromTaskDrawer');
      if (selectTaskFromTaskDrawerResult.success){
        this.display.taskDrawer = selectTaskFromTaskDrawerResult.result;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de tareas, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
        
      }
    },

    getNavigationDrawerOptionStyle(navigationDrawerOption){
      const currentRoute = this.$route.path;
      if (navigationDrawerOption.route == currentRoute){
        return 'background-color: rgba(115, 179, 104, 0.5);'
      }
    },

    signOut(){
      this.$generalFunctions.default.methods.navigateTo('/');
      localStorage.clear();
    },

    navigateTo(route){
      this.$generalFunctions.default.methods.navigateTo(route);
    },

    async getLocalStorageData(){
      this.display.displayedNavigationDrawerOptions = JSON.parse(JSON.stringify(this.display.originalNavigationDrawerOptions));
      this.localStorage.JWTValue = localStorage.getItem('JWTValue');
      this.localStorage.JWTAgentID = localStorage.getItem('JWTAgentID');
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.localityName = localStorage.getItem('localityName');
      this.display.dolarExchangeRate = await this.$generalFunctions.default.methods.selectDolarExchangeRate();
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
      if (!(this.localStorage.JWTValue && this.localStorage.JWTAgentID && this.localStorage.agentID)){
        this.$generalFunctions.default.methods.sessionExpired('/');
      }
    },

    handleMouseMove(event) {
      // Detecta si el mouse está cerca del borde izquierdo (por ejemplo, a 50px del borde izquierdo)
      if (event.clientX <= 10 && !this.navigation.navigationDrawerIsVisible) {
        this.navigation.navigationDrawerIsVisible = true;
      }
    },

    getCurrentDate(){
      this.navigation.currentDateInterval = setInterval(() => {
        const date = new Date();
        const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio','Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        const dayName = days[date.getDay()];
        const day = date.getDate();
        const monthName = months[date.getMonth()];
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        this.display.currentDate = `${dayName} ${day} de ${monthName} ${year}, ${hours}:${minutes}:${seconds}`;
      }, 1000);
    },

    async selectAgentOptions(){
      this.loader.selectAgentOptions = true;
      const selectAgentOptionsRequestQuery = 
      {
        'agentLocalityID': [], 
        'agentIsActive': true
      };
      const selectAgentOptionsRequestResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/select/byAgentLocalityIDAndByAgentIsActive', selectAgentOptionsRequestQuery);
      if (selectAgentOptionsRequestResult.success){
        this.display.agentOptions = selectAgentOptionsRequestResult.result;
        this.loader.selectAgentOptions = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de agentes, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async insertTask(){
      this.loader.insertTask = true;
      const insertTaskRequestQuery = 
      {
        'taskCreationAgentID': this.localStorage.agentID,
        'taskAssignedAgentID': this.input.taskAssignedAgentID,
        'taskType': this.input.taskType,
        'taskComment': this.input.taskComment,
        'taskExpectedDatetime': this.input.taskExpectedDatetime
      };
      const insertTaskResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/task/functions/insertTask', insertTaskRequestQuery);
      if (insertTaskResult.success){
        this.input.taskAssignedAgentID = null;
        this.input.taskType = null;
        this.input.taskComment = null;
        this.input.taskExpectedDatetime = null;
        this.display.taskExpectedDatetimeFormatted = null;
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'La tarea ha sido asignada exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        await this.selectTaskFromTaskDrawer();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al asignar la tarea, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.insertTask = false;
    },

    async deleteTask(taskID){
      this.loader.insertTask = true;
      const deleteTaskRequestQuery = 
      {
        'taskID': taskID,
        'taskState': 'Tarea eliminada'
      };
      const deleteTaskResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/task/functions/updateTaskState', deleteTaskRequestQuery);
      if (deleteTaskResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'La tarea ha sido eliminada exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        await this.selectTaskFromTaskDrawer();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al eliminar la tarea, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.insertTask = false;
    },

    listenToViewMethodCaller(){
      viewMethodCaller.on('MasterDashboardView/methods/updateAgentLocalityID()', () => {
        this.localStorage.localityName = localStorage.getItem('localityName')
      });
    },

  },


  async mounted(){
    window.addEventListener("mousemove", this.handleMouseMove);
    this.listenToViewMethodCaller();
    await this.getLocalStorageData();
    this.getCurrentDate();
    if (this.accessCredential['/task']){
      await this.selectAgentOptions();
      await this.selectTaskFromTaskDrawer();
      this.interval.selectTask = setInterval(async () => {
        await this.selectTaskFromTaskDrawer();
      }, 3000);
    }
  },

  beforeUnmount(){
    window.removeEventListener("mousemove", this.handleMouseMove);
    if (this.interval.validateJWT){
      clearInterval(this.interval.validateJWT);
    }
    if (this.interval.selectTask){
      clearInterval(this.interval.selectTask);
    }
  }
}
</script>
