<template>
  <v-container fluid class="full-height-container">
    <v-row no-gutters class="full-height-row">
      
      <!-- Columna de productos -->
      <v-col cols="12" md="7">
        <v-card class="full-height-card">
          <v-card class="bg-background" elevation="3">
              <v-autocomplete
              v-model="input.matchedProductIDOrName"
              v-model:search="input.searchedProductIDOrName"
              @keydown.enter.prevent="appendProductIntoCurrentBillProducts(input.searchedProductIDOrName)"
              @update:modelValue="appendProductIntoCurrentBillProducts(input.matchedProductIDOrName.productID)"
              :items="display.productOptions"
              :loading="loader.selectProductBasicInformationForBillViewByLocalityID"
              :disabled="loader.selectProductBasicInformationForBillViewByLocalityID"
              return-object
              item-title="productAutocompleteTitle" 
              item-value="productID"
              ref="productIDOrNameInputReference"
              append-inner-icon="mdi-magnify"
              label="Código o nombre del producto"
              variant="solo-filled"
              class="productIDOrNameInput"
            >
            </v-autocomplete>
             <!-- Contenedor con scroll solo para la tabla -->
                  <v-card class="currentBillProductsTableContainer dense-table" elevation="3">
                    <v-data-table-virtual style="max-width: 100%; overflow-x: auto;" 
                    mobile-breakpoint="600"
                    density="compact"
                    :headers="display.billProductHeaders" 
                    :items="display.currentBillProducts" 
                    class="currentBillProductsTable">
                      <template v-slot:item="row">
                        <tr :style="row.item.billProductSelectedAction ? 'background-color: #c7e8fc' : ''" @contextmenu.prevent="row.item.billProductSelectedAction = !row.item.billProductSelectedAction">
                          <td>
                            <v-checkbox v-model="row.item.billProductSelectedAction" color="info" ></v-checkbox>
                            <div v-if="display.currentBillProducts.findIndex(currentBillProduct => currentBillProduct.billProductProductID == row.item.billProductProductID) == 0">
                              <v-checkbox v-model="input.allBillProductSelected" @click="selectAllBillProduct()" color="info" style="position: absolute; top: 0px;"></v-checkbox>
                            </div>
                            
                          </td>
                          <!-- Columna de nombre -->
                          <td>
                            <div style="display: flex; align-items: center; gap: 8px; width: 100%;">
                              
                              <!-- Icono de imagen -->
                              <v-icon 
                                @click="openProductImageDialog(row.item.billProductProductID, row.item.billProductName)" 
                                color="accent"
                                style="font-size: 20px; cursor: pointer;"
                              >
                                mdi-image
                              </v-icon>

                              <!-- Contenedor de texto que se expande -->
                              <div style="flex-grow: 1;">
                                {{ row.item.billProductName }}<br>
                                <strong>{{ row.item.billProductProductID }}</strong>
                              </div>

                            </div>
                          </td>
                              <!-- Columna de cantidades -->
                          <td>
                            <div @click="openUpdateBillProductAmountDialog(row.item, row.item.billProductAmount)" class="updatableBillProductTableRow">
                              <!-- Cantidad -->
                              {{ row.item.billProductAmount }}

                              <!-- Stock en la misma línea -->
                              <div style="display: flex; align-items: center; gap: 4px;">
                                <span style="color:grey;">Stock:</span>
                                <div v-if="row.item.billProductLocalityStockAmount >= row.item.billProductAmount">
                                  <p style="color: #009929; font-size: larger; margin: 0;">
                                    <strong>{{ row.item.billProductLocalityStockAmount }}</strong>
                                  </p>
                                </div>
                                <div v-else>
                                  <v-tooltip text="El producto no cuenta con stock suficiente en esta localidad" location="bottom">
                                    <template v-slot:activator="{ props }">
                                      <p v-bind="props" class="billProductAmountWarningLabel" style="margin: 0;">
                                        <strong>{{ row.item.billProductLocalityStockAmount }}</strong>
                                      </p>
                                    </template>
                                  </v-tooltip>
                                </div>
                              </div>
                            </div>
                          </td>

                          <!-- Columna de Descuento -->
                          <td>
                            <div @click="openUpdateBillProductDiscountMarginDialog(row.item)" class="updatableBillProductTableRow">
                              {{row.item.billProductDiscountMargin.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}} %<br>
                              <strong> {{ input.selectedCurrencySymbol == '₡' ? row.item.billProductDiscount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) : (row.item.billProductDiscount/display.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}} </strong>
                            </div>
                          </td>
                          <!-- Columna de Totales y subTotal -->
                          <td>
                            {{ input.selectedCurrencySymbol == '₡' ? row.item.billProductSubtotal.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) : (row.item.billProductSubtotal/display.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}} <br>
                            <strong> {{ input.selectedCurrencySymbol == '₡' ? row.item.billProductTotal.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) : (row.item.billProductTotal/display.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}} </strong>
                          </td>
                          <!-- Columna de Total -->
                          <!-- Columna de Eliminar -->
                          <td>
                            <v-icon @click="deleteBillProduct(row.item.billProductProductID)" style="margin-right: 2%;" color="error">mdi-trash-can</v-icon>
                          </td>
                        </tr>
                      </template>
                    </v-data-table-virtual>
                  </v-card>
          </v-card>
          
          <!-- Botón de opciones -->
          <div class="facturar-container">
            <v-row justify="center" align="center">
              <!-- Botón para alternar moneda -->
              <v-col cols="12" sm="6" md="3">
                
                <v-tooltip text="Agregar cliente" location="bottom">
                  <template v-slot:activator="{ props }">
                    <p v-bind="props" style="margin: 0;">
                      <v-btn @click="openInsertClientDialog()" color="text" class="custom-btn">
                        <v-icon size="x-large">mdi-account</v-icon>
                      </v-btn>
                    </p>
                  </template>
                </v-tooltip>
              </v-col>

              <!-- Botón agregar cliente -->
              <v-col cols="12" sm="6" md="3">
                <v-tooltip text="Cambiar Moneda a Dolares o Colones" location="bottom">
                  <template v-slot:activator="{ props }">
                    <p v-bind="props" style="margin: 0;">
                      <v-btn
                        :color="input.selectedCurrencySymbol === '$' ? 'green' : 'text'"
                        @click="toggleCurrency"
                        class="custom-btn"
                        
                      >
                        <div v-if="input.selectedCurrencySymbol === '$'">
                          <v-icon size="x-large">mdi-currency-usd</v-icon>
                        </div>

                        <div v-else>
                          <p> ₡ </p>
                        </div>
                      </v-btn>
                    </p>
                  </template>
                </v-tooltip>
              </v-col>

              <!-- Botón historial por cliente -->
              <v-col cols="12" sm="6" md="3">
                <v-tooltip text="Historial del cliente" location="bottom">
                  <template v-slot:activator="{ props }">
                    <p v-bind="props" style="margin: 0;">
                      <v-btn @click="openClientBillDialog()" color="text" class="custom-btn">
                        <v-icon size="x-large">mdi-folder-account</v-icon>
                      </v-btn>
                    </p>
                  </template>
                </v-tooltip>
              </v-col>

              <!-- Facturas previas -->
              <v-col cols="12" sm="6" md="3">
                <v-tooltip text="Facturas Previas" location="bottom">
                  <template v-slot:activator="{ props }">
                    <p v-bind="props" style="margin: 0;">
                      <v-btn color="text" class="custom-btn" to="/bills">
                        <v-icon size="x-large">mdi-printer-pos</v-icon>
                      </v-btn>
                    </p>
                  </template>
                </v-tooltip>
              </v-col>
            </v-row>
           </div>

        </v-card>
        
      </v-col>

      <!-- Columna de facturación -->
      <v-col cols="12" md="5">
        <v-card class="full-height-card d-flex flex-column">
          <v-card class="bg-background" elevation="3">
              <div>
                <v-row class="mx-2 mt-2" dense>
                <v-col cols="12" md="6">
                  <v-select hide-details="auto" :items="display.copiesAmount" v-model="input.billCopies" label="Cantidad copias" density="compact" variant="solo-filled"/> 
                </v-col>
                <!-- Factura y Agente -->
                <v-col cols="12" md="6">
                  <v-select hide-details="auto" v-model="input.selectedBillType" :items="display.billTypeOptions" append-inner-icon="mdi-text-box-outline" label="Tipo de factura" density="compact" variant="solo-filled"></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select hide-details="auto" v-model="input.selectedAgentID" :items="display.agentOptions" :loading="loader.selectAgentFromBillView" :disabled="loader.selectAgentByAgentLocalityID || navigation.callcenterBill" append-inner-icon="mdi-briefcase-account" item-title="agentName" item-value="agentID" label="Agente" density="compact" variant="solo-filled"></v-select>
                </v-col>
                <!-- Métodos de pago -->
                <v-col cols="12" md="6">
                  <v-select hide-details="auto" v-model="input.selectedBillPaymentType" :items="display.billPaymentTypeOptions" append-inner-icon="mdi-cash-multiple" label="Método de pago" density="compact" variant="solo-filled"></v-select>
                </v-col>
                <v-col v-if="accessCredential['/bill/billNote']">
                  <v-textarea v-model="input.billNote" append-inner-icon="mdi-text-box-outline" rows="2" label="Nota de la factura" density="compact" variant="solo-filled"></v-textarea>
                </v-col>

                <!-- Pago en efectivo -->
                <v-col cols="12" v-if="input.selectedBillPaymentType == 'Efectivo'">
                  <v-text-field class="mixed-payment-container" hide-details="auto" v-model="input.billCashPaymentCashAmount" append-inner-icon="mdi-cash" label="El cliente cancela con" density="compact" variant="solo-filled" type="number" hide-spin-buttons></v-text-field>
                </v-col>
                <!-- Pago mixto -->
                <template  v-if="input.selectedBillPaymentType == 'Mixto'">
                  <v-col cols="12" md="6">
                    <v-text-field class="mixed-payment-container" hide-details="auto" v-model="input.billMixedPaymentCashAmount" append-inner-icon="mdi-cash" label="Cantidad en efectivo" density="compact" variant="solo-filled"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field class="mixed-payment-container" hide-details="auto" v-model="input.billMixedPaymentCardAmount" append-inner-icon="mdi-credit-card-outline" label="Cantidad en tarjeta" density="compact" variant="solo-filled"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field class="mixed-payment-container" hide-details="auto" v-model="input.billMixedPaymentSINPEAmount" append-inner-icon="mdi-cellphone" label="Cantidad en SINPE" density="compact" variant="solo-filled"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field  class="mixed-payment-container" hide-details="auto" v-model="input.billMixedPaymentDepositAmount" append-inner-icon="mdi-bank" label="Cantidad en transferencia o depósito" density="compact" variant="solo-filled"></v-text-field>
                  </v-col> 
                </template>
              </v-row>
                <br>
              </div>
          </v-card>
          <v-card class="pa-1" elevation="3">
              <div >
                <v-row class="mx-1" dense>
                  <v-col cols="12" md="12">
                    <v-text-field class="mixed-payment-container-client" hide-details="auto" v-model="input.clientSSN" @keydown.enter.prevent="selectClientOnBillView(true)" @click:append-inner="setDefaultClientValues()" :loading="loader.selectClientOnBillView" :readonly="loader.selectClientOnBillView || navigation.clientFound" :append-inner-icon="navigation.clientFound ? 'mdi-cancel' : 'mdi-card-account-details-outline'" label="Cédula del cliente" density="compact" variant="solo-filled"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field class="mixed-payment-container-client" hide-details="auto" v-model="input.clientName" append-inner-icon="mdi-account-edit" label="Nombre del cliente" density="compact" variant="solo-filled"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select class="mixed-payment-container-client" hide-details="auto" v-model="input.selectedClientSSNType" :items="display.clientSSNTypeOptions" append-inner-icon="mdi-grid" label="Tipo documento" density="compact" variant="solo-filled"></v-select>
                  </v-col>
                  
                  <v-col cols="12" md="6">
                    <v-text-field class="mixed-payment-container-client" hide-details="auto" v-model="input.clientEmail" append-inner-icon="mdi-email-outline" label="Correo del cliente" density="compact" variant="solo-filled"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field class="mixed-payment-container-client" hide-details="auto" v-model="input.clientPhoneNumber" append-inner-icon="mdi-phone" label="Número de teléfono" density="compact" variant="solo-filled"></v-text-field>
                  </v-col>
                </v-row>
              </div>
          </v-card>
          <!-- Botón Resumen de factura pegado abajo -->
          <div class="billResultNumbersContainer">
            <div>
              <p><strong>{{ display.billUnitAmount == 1 ? 'Unidad:' : 'Unidades:' }}</strong></p>
              <p><strong>Subtotal:</strong></p>
              <p><strong>Impuesto:</strong></p>
              <p v-if="display.billDiscountAmount != 0"><strong>Descuento:</strong></p>
              <h2><strong>Total:</strong></h2>
              <h2 v-if="input.selectedBillPaymentType == 'Efectivo' && display.billCashPaymentChangeAmount != null"><strong>Cambio:</strong></h2>
            </div>
            <div style="text-align: right; width: 100%;">
              <div v-if="input.selectedCurrencySymbol == '₡'">
                <p>{{ display.billUnitAmount }}</p>
                <p>₡ {{display.billSubtotal.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}}</p>
                <p>₡ {{display.billTaxAmount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}}</p>
                <p v-if="display.billDiscountAmount != 0">₡ {{display.billDiscountAmount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}}</p>
                <h2>₡ {{display.billTotal.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}}</h2>
                <h2 v-if="input.selectedBillPaymentType == 'Efectivo' && display.billCashPaymentChangeAmount != null">₡ {{display.billCashPaymentChangeAmount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}}</h2>
              </div>
              <div v-else>
                <p>{{ display.billUnitAmount }}</p>
                <p>{{(display.billSubtotal/display.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}} $</p>
                <p>{{(display.billTaxAmount/display.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}} $</p>
                <p v-if="display.billDiscountAmount != 0">{{(display.billDiscountAmount/display.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}} $</p>
                <h2>{{(display.billTotal/display.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}} $</h2>
                <h2 v-if="input.selectedBillPaymentType == 'Efectivo' && display.billCashPaymentChangeAmount != null">₡ {{display.billCashPaymentChangeAmount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}}</h2>
              </div>
            </div>
          </div>
          <!-- Botón de factura pegado abajo -->
          <div class="facturar-container">
            <div v-if="loader.generateBill">
              <v-progress-circular color="success" indeterminate></v-progress-circular>
            </div>
            <v-btn :disable="display.currentBillProducts.length > 0" v-else @click="generateBill(true)" dark block class="facturar-btn" color="text">
              <h2>FACTURAR</h2>
              <v-icon
                :color="display.printerAvailable ? 'green' : 'red'"
                size="20"
                dark
                style="margin-left: 10px;"
              >
                {{ display.printerAvailable ? 'mdi-printer-check' : 'mdi-printer-off' }}
              </v-icon>
            </v-btn>
          </div>

        </v-card>
        
      </v-col>

    </v-row>
     <v-dialog v-model="navigation.updateBillProductPriceDialogIsVisible" width="auto">
      <v-card width="300" class="insertProductCategoryDialogContainer">
        <v-text-field
          v-model="input.updatingBillProductPrice"
          @keydown.enter.prevent="updateBillProductPrice()"
          label="Precio del producto"
          variant="solo-filled"
          class="updatingBillProductInput"
          type="number"
          ref="updatingBillProductPriceInputReference"
          hide-spin-buttons
        ></v-text-field>
        <v-btn @click="updateBillProductPrice()" dark height="38" color="success" class="updateBillProductButton">
          <h3>GUARDAR</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-check-bold</v-icon>
        </v-btn>
        <v-btn @click="navigation.updateBillProductPriceDialogIsVisible = false" dark height="38" color="error" class="updateBillProductButton">
          <h3>CANCELAR</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <v-dialog v-model="navigation.updateBillProductTaxMarginDialogIsVisible" width="auto">
      <v-card width="300" class="insertProductCategoryDialogContainer">
        <v-text-field
          v-model="input.updatingBillProductTaxMargin"
          @keydown.enter.prevent="updateBillProductTaxMargin()"
          label="Márgen de impuesto del producto"
          variant="solo-filled"
          onfocus="this.select();"
          class="updatingBillProductInput"
          type="number"
          ref="updatingBillProductTaxMarginInputReference"
          hide-spin-buttons
        ></v-text-field>
        <v-btn @click="updateBillProductTaxMargin()" dark height="38" color="success" class="updateBillProductButton">
          <h3>GUARDAR</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-check-bold</v-icon>
        </v-btn>
        <v-btn @click="navigation.updateBillProductTaxMarginDialogIsVisible = false" dark height="38" color="error" class="updateBillProductButton">
          <h3>CANCELAR</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <v-dialog v-model="navigation.updateBillProductDiscountMarginDialogIsVisible" width="auto">
      <v-card width="300" class="insertProductCategoryDialogContainer">
        <v-text-field
          v-model="input.updatingBillProductDiscountMargin"
          @keydown.enter.prevent="updateBillProductDiscountMargin()"
          label="Márgen de descuento del producto"
          variant="solo-filled"
          onfocus="this.select();"
          class="updatingBillProductInput"
          type="number"
          ref="updatingBillProductDiscountMarginInputReference"
          hide-spin-buttons
        ></v-text-field>
        <div style="width: 100%; text-align: center;">
          <p><strong>Precio resultante: </strong>{{ (input.updatingBillProduct[0].billProductTotal - (input.updatingBillProduct[0].billProductTotal * input.updatingBillProductDiscountMargin/100)).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
        </div>
        <br>
        <v-btn @click="updateBillProductDiscountMargin()" dark height="38" color="success" class="updateBillProductButton">
          <h3>GUARDAR</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-check-bold</v-icon>
        </v-btn>
        <v-btn @click="navigation.updateBillProductDiscountMarginDialogIsVisible = false" dark height="38" color="error" class="updateBillProductButton">
          <h3>CANCELAR</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <v-dialog v-model="navigation.updateBillProductAmountDialogIsVisible" width="auto">
        <v-card width="300" class="insertProductCategoryDialogContainer">
        <v-text-field
          v-model="input.updatingBillProductAmount"
          @keydown.enter.prevent="updateBillProductAmount()"
          label="Cantidad del producto"
          variant="solo-filled"
          class="updatingBillProductInput"
          type="number"
          onfocus="this.select();"
          ref="updatingBillProductAmountInputReference"
        ></v-text-field>
        <v-btn @click="updateBillProductAmount()" dark height="38" color="success" class="updateBillProductButton">
          <h3>GUARDAR</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-check-bold</v-icon>
        </v-btn>
        <v-btn @click="navigation.updateBillProductAmountDialogIsVisible = false" dark height="38" color="error" class="updateBillProductButton">
          <h3>CANCELAR</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-card>
      
    </v-dialog>

    <v-dialog v-model="navigation.productDiscountMarginTokenDialogIsVisible" :persistent="true" width="auto">
      <v-card width="300" class="insertProductCategoryDialogContainer">
        <div v-if="loader.validateProductDiscountMarginToken" style="text-align: center;">
          <br>
          <v-progress-circular color="info" indeterminate></v-progress-circular>
          <br><br>
        </div>
        <div v-else style="text-align: center;">
          <v-text-field
            v-model="input.productDiscountMarginToken"
            @keydown.enter.prevent="validateProductDiscountMarginToken()"
            label="Token"
            variant="solo-filled"
            class="updatingBillProductInput"
            type="number"
            ref="productDiscountMarginTokenInputReference"
          ></v-text-field>
          <v-btn @click="validateProductDiscountMarginToken()" dark height="38" color="success" class="updateBillProductButton">
            <h3>VALIDAR</h3>
            <v-icon style="margin-left: 10px;" dark right>mdi-check-bold</v-icon>
          </v-btn>
          <v-btn @click="navigation.productDiscountMarginTokenDialogIsVisible = false" dark height="38" color="error" class="updateBillProductButton">
            <h3>CANCELAR</h3>
            <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
          </v-btn>        
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="navigation.waitForBillPaymentValidationDialogIsVisible" :persistent="true" width="auto">
      <v-card width="400" style="padding: 20px;">
        <div style="text-align: center;">
          Esperando aprobación del pago. Por favor espere...
          <br><br>
          <v-progress-circular color="primary" indeterminate></v-progress-circular>
          <br>
        </div>
      </v-card>
    </v-dialog>


    <v-dialog v-model="navigation.SINPEDialogIsVisible" width="auto">
      <v-card color="background" width="900" style="padding: 20px;">
        <div v-if="loader.selectSINPE" style="text-align: center;">
          <v-progress-circular color="primary" indeterminate></v-progress-circular>
          <br>
        </div>
        <div v-else>
          <v-data-table :headers="display.sinpeHeaders" :items="display.sinpe" :items-per-page="-1" hide-default-footer style="max-height: 700px; overflow-y: auto;">
            <template v-slot:item.SINPENote="{ item }">
              <p>{{ item.SINPENote || 'Sin descripción' }}</p>
            </template>
            <template v-slot:item.SINPEAmount="{ item }">
              <p><strong>₡</strong>{{ $generalFunctions.default.methods.parseAmountToDisplay(item.SINPEAmount) }} </p>
            </template>
            <template v-slot:item.SINPEReceivedDate="{ item }">
              <p>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSSAuxiliar(item.SINPEReceivedDate) }} </p>
            </template>
            <template v-slot:item.SINPEAction="{ item }">
              <v-btn @click="validateSINPE(item.SINPEID, null)" dark height="38" color="success" :disabled="SINPEDisabled(item.SINPEAmount)">
                <h3>VALIDAR</h3>
                <v-icon style="margin-left: 10px;" dark right>mdi-check-bold</v-icon>
              </v-btn>
            </template>
            <template #bottom></template>
          </v-data-table>
          <br><br>
          <h3>Validar por token: </h3>
          <br>
          <div style="display: flex;">
            <v-text-field
              v-model="input.sinpeToken"
              @keydown.enter.prevent="validateSINPE(null, input.sinpeToken)"
              label="Token"
              variant="solo-filled"
              @wheel.prevent
              type="number" 
              hide-spin-buttons 
              density="compact"
            ></v-text-field>
            <v-btn @click="validateSINPE(null, input.sinpeToken)" dark height="38" color="success" style="margin-left: 20px;">
              <h3>VALIDAR</h3>
              <v-icon style="margin-left: 10px;" dark right>mdi-check-bold</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

  </v-container>
  
</template>

<style scoped>
.mixed-payment-container-client {
  border-radius: 5px;
  border: 2px solid #6C5CE7; /* Color por defecto */
}

.electronic-bill {
  border-color: red !important; /* Cambia el borde a rojo */
}
::v-deep(.animated-border) {
  animation: borderColorAnimation 2s infinite alternate ease-in-out;
}

@keyframes borderColorAnimation {
  0% { border-color: #A0D8FF; }  /* Color más claro */
  50% { border-color: #74B9FF; } /* Color original */
  100% { border-color: #A0D8FF; } /* Regresa al color más claro */
}

.mixed-payment-container {
  border-radius: 5px;
  border: 2px solid #74B9FF; /* Color inicial */
}

.animated-border {
  animation: borderColorAnimation 2s infinite alternate ease-in-out;
  border: 2px solid #74B9FF !important;  /* Asegurar que se vea */
  box-shadow: none !important; /* Evitar interferencias de Vuetify */
}

 .billProductAmountWarningLabel {
    cursor: pointer;
    color: rgb(171, 0, 0);
    font-size: larger;
    animation: billProductAmountWarningLabelAnimation 2s infinite;
  }

  @keyframes billProductAmountWarningLabelAnimation {
    0% {transform: scale(1)}
    50% {transform: scale(1.4)}
    100% {transform: scale(1)}
  }
.dense-table tr {
  height: 36px !important; /* Ajusta según tu preferencia */
  min-height: 36px !important;
}

/* Ajustar las celdas para que coincidan con la fila */
.dense-table td,
.dense-table th {
  padding: 2px 6px !important; /* Reducir espacio interno */
  font-size: 13px !important; /* Ajustar tamaño del texto */
  height: 36px !important; /* Misma altura que las filas */
  min-height: 36px !important;
  line-height: 1.1 !important; /* Asegurar alineación vertical */
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Asegura que las líneas sean uniformes */
}

/* Asegurar que los checkboxes no desajusten la fila */
.dense-table .v-input {
  min-height: 28px !important;
  height: 28px !important;
  transform: scale(0.85); /* Ajusta el tamaño del checkbox */
  display: flex;
  align-items: center;
  justify-content: center;
}



/* Ajustar el tamaño de los iconos */
.dense-table .v-icon {
  font-size: 16px !important;
  height: 16px !important;
  width: 16px !important;
}

/* Contenedor de la tabla con scroll */
.currentBillProductsTableContainer {
  flex-grow: 1;
  overflow-y: auto;
  height: calc(90vh - 180px); /* Ajusta 180px según la altura del header y el footer */
}

/* Contenedor de la tabla con scroll */
.currentBillProductsTableContainer {
  flex-grow: 1;
  overflow-y: auto;
  height: calc(90vh - 180px); /* Ajusta 180px según la altura del header y el footer */
}

.custom-btn {
  width: 100%;
  min-width: 100px;
  height: 48px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
}
/* Contenedor principal ajustado a la altura sin contar el app-bar */
.full-height-container {
  height: calc(100vh - 64px); /* Restamos la altura del app-bar */
  display: flex;
  flex-direction: column;
}
 .insertProductCategoryDialogContainer {
    padding: 20px;
    background-color: rgb(169, 169, 169);
  }
 .billResultNumbersContainer {
    width: 100%; 
    overflow-y: auto;
    padding: 1%;
    display: flex;
  }
/* Asegurar que el row ocupe el espacio completo */
.full-height-row {
  flex: 1;
  display: flex;
}

/* Ajustar los cards a la altura completa */
.full-height-card {
  height: 100%;
  display: flex;
  flex-direction: column;
}
/* Ajustar los cards a la altura completa */
.full-height-card-items {
  height: 100%;
  display: flex;
}

/* Contenedor del botón FACTURAR para que quede fijo abajo */
.facturar-container {
  margin-top: auto;
  width: 100%;
  text-align: center;
  padding: 20px;
}

.facturar-btn {
  font-size: 1.2rem;
  height: 50px;
}
</style>
<script>
import axios from 'axios';
import { defineComponent } from 'vue';
import { viewMethodCaller } from '../viewMethodCaller.js';

export default defineComponent({
  name: 'BillView',
  inject: ['$generalFunctions'],

  
  data: () => ({
    input: 
    {
      searchedProductIDOrName: null,
      matchedProductIDOrName: null,
      allBillProductSelected: false,
      updatingBillProduct: null,
      updatingBillProductMultipleSelected: null,
      updatingBillProductPrice: null,
      updatingBillProductTaxMargin: null,
      updatingBillProductDiscountMargin: null,
      updatingBillProductAmount: null,
      productDiscountMarginToken: null,
      selectedBillType: null,
      selectedBillPaymentType: null,
      selectedCurrencySymbol: '₡',
      selectedAgentID: null,
      billCopies: 1,
      billCashPaymentCashAmount: null,
      billMixedPaymentCashAmount: 0,
      billMixedPaymentCardAmount: 0,
      billMixedPaymentSINPEAmount: 0,
      billMixedPaymentDepositAmount: 0,
      selectedClientSSNType: null,
      clientSSN: '',
      clientName: '',
      clientEmail: '',
      clientPhoneNumber: '',
      billNote: null,
      sinpeToken: null
    },

    navigation: 
    {
      updateBillProductPriceDialogIsVisible: false,
      updateBillProductTaxMarginDialogIsVisible: false,
      updateBillProductDiscountMarginDialogIsVisible: false,
      updateBillProductAmountDialogIsVisible: false,
      productDiscountMarginTokenDialogIsVisible: false,
      clientFound: null,
      waitForBillPaymentValidationDialogIsVisible: false,
      SINPEDialogIsVisible: false,
      billPaymentValidationID: null,
      billPaymentValidationQueryInterval: null,
      callcenterBill: false
    },

    loader: 
    {
      selectProductBasicInformationForBillViewByLocalityID: false,
      validateProductDiscountMarginToken: false,
      selectAgentFromBillView: false,
      selectClientOnBillView: false,
      generateBill: false,
      selectClientForClientSearch: false,
      selectSINPE: false
    },

    display:
    {
      copiesAmount:[0,1,2,3,4,5],
      productOptions: [],
      billProductHeaders: 
      [
        {key: 'billProductSelectAction', title: ''},
        {key: 'billProductName', title: 'Nombre'},
        {key: 'billProductPrice', title: 'Cantidad'},
        {key: 'billProductDiscount', title: 'Descuento'},
        {key: 'billProductTotal', title: 'Total'},
        {key: 'billProductDeleteAction', title: 'Eliminar'}
      ],
      currentBillProducts: [],
      billTypeOptions: ['Factura electrónica', 'Tiquete electrónico', 'Recibo'],
      billPaymentTypeOptions: ['Efectivo', 'Tarjeta', 'SINPE', 'Transferencia o depósito', 'Página web', 'Uber', 'Rappi', 'Mixto'],
      agentOptions: [],
      clientSSNTypeOptions: ['FISICA', 'JURIDICA', 'DIMEX','NITE'],
      billUnitAmount: 0,
      billSubtotal: 0,
      billDiscountAmount: 0,
      billTaxAmount: 0,
      billTotal: 0,
      billCashPaymentChangeAmount: 0,
      billMixedPaymentAmount: 0,
      dolarExchangeRate: null,
      clientOptions: [],
      printerAvailable: false,
      sinpe: [],

      sinpeHeaders: 
      [
        {key: 'SINPEID', title: 'ID', sortable: false},
        {key: 'SINPEName', title: 'NOMBRE', sortable: false},
        {key: 'SINPENote', title: 'DESCRIPCIÓN', sortable: false},
        {key: 'SINPEAmount', title: 'MONTO', sortable: false},
        {key: 'SINPEReceivedDate', title: 'RECIBIDO EL', sortable: false},
        {key: 'SINPEAction', title: null, sortable: false}
      ],
    },

    localStorage: 
    {
      localityID: null,
      agentID: null
    },

    accessCredential: {}

  }),

  watch: {
    'input.billCashPaymentCashAmount'(){
      this.calculateBillResult();
    },
    'input.selectedCurrencySymbol'(){
      this.calculateBillResult();
    },
    'input.billMixedPaymentCashAmount'(){
      const billMixedPaymentAmount = parseInt(this.input.billMixedPaymentCashAmount) + parseInt(this.input.billMixedPaymentCardAmount) + parseInt(this.input.billMixedPaymentSINPEAmount) + parseInt(this.input.billMixedPaymentDepositAmount);
      this.display.billMixedPaymentAmount = isNaN(billMixedPaymentAmount) ? 'Error' : billMixedPaymentAmount;
    },
    'input.billMixedPaymentCardAmount'(){
      const billMixedPaymentAmount = parseInt(this.input.billMixedPaymentCashAmount) + parseInt(this.input.billMixedPaymentCardAmount) + parseInt(this.input.billMixedPaymentSINPEAmount) + parseInt(this.input.billMixedPaymentDepositAmount);
      this.display.billMixedPaymentAmount = isNaN(billMixedPaymentAmount) ? 'Error' : billMixedPaymentAmount;
    },
    'input.billMixedPaymentSINPEAmount'(){
      const billMixedPaymentAmount = parseInt(this.input.billMixedPaymentCashAmount) + parseInt(this.input.billMixedPaymentCardAmount) + parseInt(this.input.billMixedPaymentSINPEAmount) + parseInt(this.input.billMixedPaymentDepositAmount);
      this.display.billMixedPaymentAmount = isNaN(billMixedPaymentAmount) ? 'Error' : billMixedPaymentAmount;
    },
    'input.billMixedPaymentDepositAmount'(){
      const billMixedPaymentAmount = parseInt(this.input.billMixedPaymentCashAmount) + parseInt(this.input.billMixedPaymentCardAmount) + parseInt(this.input.billMixedPaymentSINPEAmount) + parseInt(this.input.billMixedPaymentDepositAmount);
      this.display.billMixedPaymentAmount = isNaN(billMixedPaymentAmount) ? 'Error' : billMixedPaymentAmount
    },
  },

  methods: {
    SINPEDisabled(SINPEAmount) {
    if (!this.display.billTotal) return true;
    return SINPEAmount < this.display.billTotal - 100 || SINPEAmount > this.display.billTotal + 100;
    },

    printerStatus(){
      axios.get('http://localhost:5001/status')
      .then(() => {
        this.display.printerAvailable = true;
      })
      .catch(() => {
        this.display.printerAvailable = false;
      });
    },
    selectAllBillProduct(){
      this.input.allBillProductSelected = !this.input.allBillProductSelected;
      this.display.currentBillProducts.forEach(currentBillProduct => {
        currentBillProduct.billProductSelectedAction = this.input.allBillProductSelected;
      });    
    },

    async selectClientOnBillView(showNotificationDialog){
      this.loader.selectClientOnBillView = true;
      this.navigation.clientFound = false;
      const selectClientOnBillViewRequestQuery = {'clientSSN': this.input.clientSSN};
      const selectClientOnBillViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/client/functions/selectClientOnBillView', selectClientOnBillViewRequestQuery);
      if (selectClientOnBillViewResult.success){
        if (selectClientOnBillViewResult.result){
          this.input.selectedClientSSNType = selectClientOnBillViewResult.result.clientSSNType;
          this.input.clientName = selectClientOnBillViewResult.result.clientName;
          this.input.clientEmail = selectClientOnBillViewResult.result.clientEmail;
          this.input.clientPhoneNumber = selectClientOnBillViewResult.result.clientPhoneNumber;
          this.navigation.clientFound = true;
          if (showNotificationDialog){
            this.$root.notificationDialog.showNotificationDialog({
              'notificationDialogTitle': 'ÉXITO',
              'notificationDialogBody': 'La información del cliente ha sido completada',
              'notificationDialogColor': 'success',
              'notificationDialogIsPersistent': false
            });
          }
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': null,
            'notificationDialogBody': 'No se ha encontrado al cliente seleccionado, por favor intente nuevamente. Consultando datos del registro nacional.',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          this.idRegistroFinder();
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la información del cliente, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectClientOnBillView = false;
    },
    idRegistroFinder(){
      this.loader.selectClientOnBillView = true;
			let me=this;
			var familiasArray=[];
			axios.get('https://apis.gometa.org/cedulas/'+this.input.clientSSN+'&key=u0vSHKh0dVRckNH').then(function(response){
				familiasArray=response.data;
				me.input.clientName = familiasArray.results[0].fullname;
				me.input.selectedClientSSNType = familiasArray.results[0].guess_type
        me.loader.selectClientOnBillView = false;
				
			}).catch(function(error){
			this.snackbar = true;
			this.Mensaje = 'Error de conexión con el TSE, digite los datos de forma manual'
      this.loader.selectClientOnBillView = false;
				alert(error);
			});
      this.loader.selectClientOnBillView = false;

		},
    
    toggleCurrency() {
      this.input.selectedCurrencySymbol = this.input.selectedCurrencySymbol === '$' ? '₡' : '$';
    },
    setDefaultClientValues(){
      if (this.navigation.clientFound){
        this.input.selectedClientSSNType = null;
        this.input.clientSSN = '';
        this.input.clientName = '';
        this.input.clientEmail = '';
        this.input.clientPhoneNumber = '';
        this.navigation.clientFound = false;
      }
    },

    async selectProductBasicInformationForBillViewByLocalityID(){
      this.loader.selectProductBasicInformationForBillViewByLocalityID = true;
      const selectProductBasicInformationForBillViewByLocalityIDRequestQuery = {'localityID': this.localStorage.localityID};
      const selectProductBasicInformationForBillViewByLocalityIDResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductBasicInformationForBillViewByLocalityID', selectProductBasicInformationForBillViewByLocalityIDRequestQuery);
      if (selectProductBasicInformationForBillViewByLocalityIDResult.success){
        this.display.productOptions = selectProductBasicInformationForBillViewByLocalityIDResult.result.map(productOption => ({
          'productID': productOption.i,
          'productSKU': productOption.s,
          'productCABYS': productOption.cb,
          'productName': productOption.n,
          'productPrice': productOption.p,
          'productTaxMargin': productOption.t,
          'productMaxDiscountMargin': productOption.d,
          'productLocalityStockAmount': productOption.a,
          'productAutocompleteTitle': productOption.s + ' - ' + productOption.n
        }));
        this.loader.selectProductBasicInformationForBillViewByLocalityID = false;
        this.focusOnInput('productIDOrNameInputReference');
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectAgentFromBillView(){
      this.loader.selectAgentFromBillView = true;
      const selectAgentFromBillViewRequestQuery = 
      {
        'agentLocalityID': this.localStorage.localityID, 
        'agentIsActive': true,
        'accessCredentialName': '/bill/billAgentID'
      };
      const selectAgentFromBillViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/select/agentIDAndAgentName/byAgentLocalityIDAndByAgentIsActiveAndByAccessCredentialName', selectAgentFromBillViewRequestQuery);
      if (selectAgentFromBillViewResult.success){
        this.display.agentOptions = selectAgentFromBillViewResult.result;
        this.loader.selectAgentFromBillView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de agentes facturadores, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    validateGenerateBillInput(){
      const regularExpressionChecker = /\S/;
      const positiveNumberChecker = /^(?:0|[1-9]\d*)(?:\.\d+)?$/;
      if (this.display.currentBillProducts.length == 0){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Seleccione al menos un producto para facturar, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (!this.accessCredential['/bill/generateBillWithoutStock'] && !this.display.currentBillProducts.every(currentBillProduct => currentBillProduct.billProductAmount <= currentBillProduct.billProductLocalityStockAmount)){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Su usuario no tiene permitido facturar productos sin stock en la localidad, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.selectedBillType == null){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Seleccione un tipo de factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.selectedBillPaymentType == null){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Seleccione un método de pago, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.selectedAgentID == null){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Seleccione un agente facturador, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.selectedBillType == 'Factura electrónica'){
        if (this.input.selectedClientSSNType == null){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
            'notificationDialogBody': 'Coloque un tipo de documento del cliente, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
        if (positiveNumberChecker.test(this.input.clientSSN) == false){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
            'notificationDialogBody': 'Coloque una cédula del cliente (sin espacios, guiones, comas...), por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
        if (this.input.clientSSN.length > 13 ){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN DEMASIADO LARGA',
            'notificationDialogBody': 'La cédula del cliente no puede exceder los 12 caracteres, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
        if (regularExpressionChecker.test(this.input.clientName) == false){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
            'notificationDialogBody': 'Coloque un nombre del cliente, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
        if (this.input.clientName.length > 255){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN DEMASIADO LARGA',
            'notificationDialogBody': 'El nombre del cliente no puede exceder los 255 caracteres, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
        if (regularExpressionChecker.test(this.input.clientEmail) == false){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
            'notificationDialogBody': 'Coloque un correo del cliente, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
        if (this.input.clientEmail.length > 255){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN DEMASIADO LARGA',
            'notificationDialogBody': 'El correo del cliente no puede exceder los 255 caracteres, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
        if (regularExpressionChecker.test(this.input.clientPhoneNumber) == false){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
            'notificationDialogBody': 'Coloque un teléfono del cliente, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
        if (this.input.clientPhoneNumber.length > 255){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN DEMASIADO LARGA',
            'notificationDialogBody': 'El teléfono del cliente no puede exceder los 30 caracteres, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
      }
      if (this.input.selectedBillPaymentType == 'Efectivo'){
        if (this.display.billCashPaymentChangeAmount == null){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
            'notificationDialogBody': 'Coloque el monto en efectivo con el que cancela el cliente. El monto debe ser mayor o igual al monto total total de la factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
      }
      if (this.input.selectedBillPaymentType == 'Mixto'){
        if (positiveNumberChecker.test(this.input.billMixedPaymentCashAmount) == false){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
            'notificationDialogBody': 'Coloque un número válido positivo para la cantidad de dinero en efectivo de la factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
        if (positiveNumberChecker.test(this.input.billMixedPaymentCardAmount) == false){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
            'notificationDialogBody': 'Coloque un número válido positivo para la cantidad de dinero en tarjeta de la factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
        if (positiveNumberChecker.test(this.input.billMixedPaymentSINPEAmount) == false){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
            'notificationDialogBody': 'Coloque un número válido positivo para la cantidad de dinero en SINPE de la factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
        if (positiveNumberChecker.test(this.input.billMixedPaymentDepositAmount) == false){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
            'notificationDialogBody': 'Coloque un número válido positivo para la cantidad de dinero en transferencia o depósito de la factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
      }
      return true;
    },

    async generateBill(waitForBillPaymentValidation){
      this.loader.generateBill = true;
      if (this.validateGenerateBillInput())
      {
        if (waitForBillPaymentValidation){
          await this.waitForBillPaymentValidation();
        } else {
          const billProducts = this.display.currentBillProducts.map(currentBillProduct => {
            return {
              'billProductName': currentBillProduct.billProductName,
              'billProductProductID': currentBillProduct.billProductProductID,
              'billProductCABYS': currentBillProduct.billProductCABYS,
              'billProductPrice': currentBillProduct.billProductPrice,
              'billProductTaxMargin': currentBillProduct.billProductTaxMargin,
              'billProductDiscountMargin': currentBillProduct.billProductDiscountMargin,
              'billProductSubtotal': currentBillProduct.billProductSubtotal,
              'billProductAmount': currentBillProduct.billProductAmount
            };
          });

          const generateBillRequestQuery = 
          {
            'billLocalityID': this.localStorage.localityID,
            'billAgentID': this.input.selectedAgentID == 'Call center' ? 61 : this.input.selectedAgentID,
            'billAgentName': this.input.selectedAgentID == 'Call center' ? 'Call center' : this.display.agentOptions.find(agent => agent.agentID == this.input.selectedAgentID).agentName,
            'billType': this.input.selectedBillType,
            'billPaymentType': this.input.selectedBillPaymentType,
            'billCurrency': this.input.selectedCurrencySymbol,
            'billExchangeRate': this.input.selectedCurrencySymbol == '$' ? this.display.dolarExchangeRate : null,
            'billProducts': billProducts,
            'clientFound': this.navigation.clientFound,
            'clientSSNType': this.input.selectedClientSSNType,
            'clientSSN': this.input.clientSSN,
            'clientName': this.input.clientName,
            'clientEmail': this.input.clientEmail,
            'clientPhoneNumber': this.input.clientPhoneNumber,
            'billCashPaymentCashAmount': this.input.selectedCurrencySymbol == '$' ? this.input.billCashPaymentCashAmount * this.display.dolarExchangeRate : this.input.billCashPaymentCashAmount,
            'billCashPaymentChangeAmount': this.display.billCashPaymentChangeAmount,
            'billMixedPaymentCashAmount': this.input.billMixedPaymentCashAmount,
            'billMixedPaymentCardAmount': this.input.billMixedPaymentCardAmount,
            'billMixedPaymentSINPEAmount': this.input.billMixedPaymentSINPEAmount,
            'billMixedPaymentDepositAmount': this.input.billMixedPaymentDepositAmount,
            'billNote': this.input.billNote
          };

          const generateBillResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/functions/generateBill', generateBillRequestQuery);
          if (generateBillResult.success){
            const printInformation = 
            {
              'agentName': this.input.selectedAgentID == 'Call center' ? 'Call center' : this.display.agentOptions.find(agent => agent.agentID == this.input.selectedAgentID).agentName,
              'billCopies': this.input.billCopies,
              'billType': this.input.selectedBillType.replace('ó', 'o'),
              'billCurrency': this.input.selectedCurrencySymbol == '$' ? `USD (${this.display.dolarExchangeRate})` : 'CRC',
              'items': billProducts.map(billProduct => {
                return {
                  'itemName': billProduct.billProductName,
                  'itemPrice': billProduct.billProductPrice,
                  'itemAmount': billProduct.billProductAmount
                };
              }),
              'electronicDocumentCreationDatetime': new Date().toISOString().replace('T', ' ').split('.')[0],
              "billIVA13Amount": billProducts.reduce((billIVA13Amount, billProduct) => (billProduct.billProductTaxMargin == 13 ? (billIVA13Amount + (billProduct.billProductPrice - (billProduct.billProductPrice * (1 + (billProduct.billProductTaxMargin / 100))))) : (billIVA13Amount + 0)), 0).toFixed(2) * -1,
              "billIVA4Amount": billProducts.reduce((billIVA4Amount, billProduct) => (billProduct.billProductTaxMargin == 4 ? (billIVA4Amount + (billProduct.billProductPrice - (billProduct.billProductPrice * (1 + (billProduct.billProductTaxMargin / 100))))) : (billIVA4Amount + 0)), 0).toFixed(2) * -1,
              "billIVA2Amount": billProducts.reduce((billIVA2Amount, billProduct) => (billProduct.billProductTaxMargin == 2 ? (billIVA2Amount + (billProduct.billProductPrice - (billProduct.billProductPrice * (1 + (billProduct.billProductTaxMargin / 100))))) : (billIVA2Amount + 0)), 0).toFixed(2) * -1,
              "billIVA1Amount": billProducts.reduce((billIVA1Amount, billProduct) => (billProduct.billProductTaxMargin == 1 ? (billIVA1Amount + (billProduct.billProductPrice - (billProduct.billProductPrice * (1 + (billProduct.billProductTaxMargin / 100))))) : (billIVA1Amount + 0)), 0).toFixed(2) * -1,
              "billSubtotal": billProducts.reduce((billSubtotalAmount, billProduct) => (billSubtotalAmount + (billProduct.billProductPrice * billProduct.billProductAmount)), 0).toFixed(2),
              "billDiscount": Math.round(billProducts.reduce((billDiscountAmount, billProduct) => (billDiscountAmount + ((billProduct.billProductPrice * billProduct.billProductAmount) * billProduct.billProductDiscountMargin/100)), 0) / 10) * 10,
              "billTotal": (billProducts.reduce((billSubtotalAmount, billProduct) => (billSubtotalAmount + (billProduct.billProductPrice * billProduct.billProductAmount)), 0) - billProducts.reduce((billDiscountAmount, billProduct) => (billDiscountAmount + ((billProduct.billProductPrice * billProduct.billProductAmount) * billProduct.billProductDiscountMargin/100)), 0)).toFixed(2),
              "billSpecialTax": 0,
              "billServiceTax": 'N/A',
              "billChange": this.display.billCashPaymentChangeAmount ? this.display.billCashPaymentChangeAmount.toFixed(2) : 'N/A',
              "billNote": '',
              "billFooter": '',
              "billResolution": 'Autorizada mediante resolucion No DGT-R-033-2019 del 20 de junio de 2019'
            };

            Object.assign(printInformation, generateBillResult.result);

            this.$generalFunctions.default.methods.printRequest(printInformation);

            await this.selectProductBasicInformationForBillViewByLocalityID();
            this.setDefaultValues();
            this.focusOnInput('productIDOrNameInputReference');
            this.$root.notificationDialog.showNotificationDialog({
              'notificationDialogTitle': 'ÉXITO',
              'notificationDialogBody': 'Se ha generado la factura exitosamente.',
              'notificationDialogColor': 'success',
              'notificationDialogIsPersistent': false
            });

            

          } else {
            this.$root.notificationDialog.showNotificationDialog({
              'notificationDialogTitle': 'ERROR',
              'notificationDialogBody': 'Ha ocurrido un error inesperado al crear la factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
              'notificationDialogColor': 'error',
              'notificationDialogIsPersistent': false
            });
          }
        }
      }
      this.loader.generateBill = false;
    },

    async waitForBillPaymentValidation(){
      if (this.input.selectedBillPaymentType == 'Transferencia o depósito' || this.input.billMixedPaymentDepositAmount != 0){
        this.navigation.waitForBillPaymentValidationDialogIsVisible = true;
        const insertBillPaymentValidationRequestQuery =
        {
          'billPaymentValidationRequestLocalityID': this.localStorage.localityID,
          'billPaymentValidationRequestAgentID': this.input.selectedAgentID == 'Call center' ? 61 : this.input.selectedAgentID,
          'billPaymentValidationPaymentType': 'Transferencia o depósito',
          'billPaymentValidationAmount': this.input.billMixedPaymentDepositAmount != 0 ? this.input.billMixedPaymentDepositAmount : this.display.billTotal
        };
        const insertBillPaymentValidationResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/billPaymentValidation/functions/insert', insertBillPaymentValidationRequestQuery);
        if (insertBillPaymentValidationResult.success){
          this.navigation.billPaymentValidationID = insertBillPaymentValidationResult.result;
          this.navigation.billPaymentValidationQueryInterval = setInterval(async () => {
            const checkForBillPaymentValidationRequestQuery =
            {
              'billPaymentValidationID': this.navigation.billPaymentValidationID
            };
            const checkForBillPaymentValidationResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/billPaymentValidation/functions/checkForBillPaymentValidation', checkForBillPaymentValidationRequestQuery);
            if (checkForBillPaymentValidationResult.success){
              if (checkForBillPaymentValidationResult.result == true){
                this.$root.notificationDialog.showNotificationDialog({
                  'notificationDialogTitle': 'ÉXITO',
                  'notificationDialogBody': 'Se ha comprobado el pago exitosamente',
                  'notificationDialogColor': 'success',
                  'notificationDialogIsPersistent': false
                });
                this.navigation.waitForBillPaymentValidationDialogIsVisible = false;
                clearInterval(this.navigation.billPaymentValidationQueryInterval);
                await this.generateBill(false);
              }
            } else {
              this.$root.notificationDialog.showNotificationDialog({
                'notificationDialogTitle': 'ERROR',
                'notificationDialogBody': 'Ha ocurrido un error inesperado al recibir la confirmación de aprobación del pago, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
                'notificationDialogColor': 'error',
                'notificationDialogIsPersistent': false
              });
            }
          }, 3000);
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al generar la solicitud de aprobación del pago, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
          this.navigation.waitForBillPaymentValidationDialogIsVisible = false;
        }
      } else if ((this.input.selectedBillPaymentType == 'SINPE' || this.input.billMixedPaymentSINPEAmount != 0) && this.input.selectedAgentID != 'Call center') {
        this.navigation.SINPEDialogIsVisible = true;
        this.display.sinpe.length = 0;
        this.input.sinpeToken = null;
        this.loader.selectSINPE = true;
        const selectSINPEResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/billPaymentValidation/functions/selectSINPE', {localityID: this.localStorage.localityID});
        if (selectSINPEResult.success){
          this.display.sinpe = selectSINPEResult.result;
          this.loader.selectSINPE = false;
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los SINPE disponibles, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
          this.navigation.SINPEDialogIsVisible = false;
        }
      } else {
        await this.generateBill(false);
      }
    },

    async appendProductIntoCurrentBillProducts(productID){
      const regularExpressionChecker = /\S/;
      if (regularExpressionChecker.test(productID)){
        const product = this.display.productOptions.find(productOption => productOption.productID == productID);
        if (product){
          const billProductTax = product.productPrice * (product.productTaxMargin/100);
          const billProductPriceWithoutTax = product.productPrice - billProductTax;
          const billProductAlreadyOnCurrentBillProducts = this.display.currentBillProducts.find(currentBillProduct => currentBillProduct.billProductProductID == product.productID)
          if (billProductAlreadyOnCurrentBillProducts){
            billProductAlreadyOnCurrentBillProducts.billProductAmount = billProductAlreadyOnCurrentBillProducts.billProductAmount + 1;
            billProductAlreadyOnCurrentBillProducts.billProductTotal = product.productPrice * billProductAlreadyOnCurrentBillProducts.billProductAmount;
          } else {
            this.display.currentBillProducts.push({
              'billProductSelectedAction': false,
              'billProductProductID': product.productID,
              'billProductCABYS': product.productCABYS,
              'billProductName': product.productName,
              'billProductPrice': product.productPrice,
              'billProductWithoutTax': billProductPriceWithoutTax,
              'billProductTaxMargin': product.productTaxMargin,
              'billProductMaxDiscountMargin': product.productMaxDiscountMargin,
              'billProductTax': billProductTax,
              'billProductDiscountMargin': 0,
              'billProductDiscount': 0,
              'billProductSubtotal': product.productPrice,
              'billProductAmount': 1,
              'billProductLocalityStockAmount': product.productLocalityStockAmount,
              'billProductTotal': product.productPrice
            });
          }
          this.input.searchedProductIDOrName = null;
          this.input.matchedProductIDOrName = null;
          this.input.allBillProductSelected = false;
          this.focusOnInput('productIDOrNameInputReference');
          this.calculateBillResult();
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': null,
            'notificationDialogBody': 'No se ha encontrado el producto seleccionado, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque un código para el producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async appendProductIntoCurrentBillProductsFromCallcenterBill(callcenterBillProduct){
      const product = this.display.productOptions.find(productOption => productOption.productID == callcenterBillProduct.productID);
      if (product){
        const billProductTax = product.productPrice * (product.productTaxMargin/100);
        const billProductPriceWithoutTax = product.productPrice - billProductTax;
        
        this.display.currentBillProducts.push({
          'billProductSelectedAction': false,
          'billProductProductID': product.productID,
          'billProductName': product.productName,
          'billProductPrice': product.productPrice,
          'billProductWithoutTax': billProductPriceWithoutTax,
          'billProductTaxMargin': product.productTaxMargin,
          'billProductMaxDiscountMargin': product.productMaxDiscountMargin,
          'billProductTax': billProductTax,
          'billProductDiscountMargin': callcenterBillProduct.productDiscount || 0,
          'billProductDiscount': product.productPrice * ((callcenterBillProduct.productDiscount || 0)/100),
          'billProductSubtotal': product.productPrice - (product.productPrice * ((callcenterBillProduct.productDiscount || 0)/100)),
          'billProductAmount': callcenterBillProduct.productAmount || 1,
          'billProductLocalityStockAmount': product.productLocalityStockAmount,
          'billProductTotal': (product.productPrice - (product.productPrice * ((callcenterBillProduct.productDiscount || 0)/100))) * (callcenterBillProduct.productAmount || 1)
        });
        
        this.calculateBillResult();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': null,
          'notificationDialogBody': 'No se ha encontrado el producto seleccionado, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
      }
    },


    setDefaultValues(){
      this.input.searchedProductIDOrName = null;
      this.input.matchedProductIDOrName = null;
      this.input.allBillProductSelected = false;
      this.input.updatingBillProduct = null;
      this.input.updatingBillProductPrice = null;
      this.input.updatingBillProductTaxMargin = null;
      this.input.updatingBillProductDiscountMargin = null;
      this.input.updatingBillProductAmount = null;
      this.input.productDiscountMarginToken = null;
      this.input.selectedBillType = null;
      this.input.selectedBillPaymentType = null;
      this.input.selectedCurrencySymbol = '₡';
      this.input.selectedAgentID = null;
      this.input.billCopies = 1;
      this.input.billCashPaymentCashAmount = null;
      this.display.billCashPaymentChangeAmount = null;
      this.display.sinpe.length = 0;
      this.input.billMixedPaymentCashAmount = 0;
      this.input.billMixedPaymentCardAmount = 0;
      this.input.billMixedPaymentSINPEAmount = 0;
      this.input.billMixedPaymentDepositAmount = 0;
      this.input.selectedClientSSNType = null;
      this.input.clientSSN = '';
      this.input.clientName = '';
      this.input.clientEmail = '';
      this.input.clientPhoneNumber = '';
      this.input.billNote = null;
      this.navigation.updateBillProductPriceDialogIsVisible = false;
      this.navigation.updateBillProductTaxMarginDialogIsVisible = false;
      this.navigation.updateBillProductDiscountMarginDialogIsVisible = false;
      this.navigation.updateBillProductAmountDialogIsVisible = false;
      this.navigation.productDiscountMarginTokenDialogIsVisible = false;
      this.navigation.waitForBillPaymentValidationDialogIsVisible = false;
      this.navigation.clientFound = false;
      this.navigation.callcenterBill = false;
      this.display.currentBillProducts = [];
      this.display.billUnitAmount = 0;
      this.display.billSubtotal = 0;
      this.display.billDiscountAmount = 0;
      this.display.billTaxAmount = 0;
      this.display.billTotal = 0;
    },

    focusOnInput(inputReference){
      const blurInputInterval = setInterval(() => {
        if (this.$refs[inputReference]) {
          this.$refs[inputReference].blur();
          const focusInputInterval = setInterval(() => {
            if (this.$refs[inputReference]) {
              this.$refs[inputReference].focus();
              clearInterval(focusInputInterval);
            }
          }, 1);
          clearInterval(blurInputInterval);
        }
      }, 1);
    },

    calculateBillResult(){
      this.display.billUnitAmount = this.display.currentBillProducts.reduce((billUnitAmount, currentBillProduct) => billUnitAmount + currentBillProduct.billProductAmount, 0);
      this.display.billSubtotal = this.display.currentBillProducts.reduce((billSubtotalAmount, currentBillProduct) => billSubtotalAmount + currentBillProduct.billProductWithoutTax * currentBillProduct.billProductAmount, 0);
      this.display.billTaxAmount = this.display.currentBillProducts.reduce((billTaxAmount, currentBillProduct) => billTaxAmount + currentBillProduct.billProductTax * currentBillProduct.billProductAmount, 0);
      this.display.billDiscountAmount = this.display.currentBillProducts.reduce((billDiscountAmount, currentBillProduct) => billDiscountAmount + currentBillProduct.billProductDiscount * currentBillProduct.billProductAmount, 0);
      this.display.billTotal = this.display.currentBillProducts.reduce((billTotal, currentBillProduct) => billTotal + currentBillProduct.billProductTotal, 0);
      if (this.input.selectedCurrencySymbol == '$'){
        this.input.billCashPaymentCashAmount * this.display.dolarExchangeRate - this.display.billTotal >= 0 ? (this.display.billCashPaymentChangeAmount = this.input.billCashPaymentCashAmount * this.display.dolarExchangeRate - this.display.billTotal) : (this.display.billCashPaymentChangeAmount = null);
      } else {
        this.input.billCashPaymentCashAmount - this.display.billTotal >= 0 ? (this.display.billCashPaymentChangeAmount = this.input.billCashPaymentCashAmount - this.display.billTotal) : (this.display.billCashPaymentChangeAmount = null);
      }
    },

    updateBillProduct(){
      this.input.updatingBillProduct.forEach(updatingBillProduct => {
        updatingBillProduct.billProductTax = updatingBillProduct.billProductPrice * (updatingBillProduct.billProductTaxMargin / 100);
        updatingBillProduct.billProductDiscount = (updatingBillProduct.billProductPrice) * (updatingBillProduct.billProductDiscountMargin / 100);
        updatingBillProduct.billProductSubtotal = Math.round((updatingBillProduct.billProductPrice - updatingBillProduct.billProductDiscount)/ 10) * 10;
        updatingBillProduct.billProductTotal = updatingBillProduct.billProductSubtotal * updatingBillProduct.billProductAmount;
      });
      if (this.display.updatingBillProductMultipleSelected){
        this.display.currentBillProducts.forEach(currentBillProduct => {
          currentBillProduct.billProductSelectedAction = false;
        });
      }
    },

    openUpdateBillProductPriceDialog(billProduct){
      if (this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction).find(currentBillProduct => currentBillProduct.billProductProductID == billProduct.billProductProductID)){
        this.input.updatingBillProduct = this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction);
        if (this.input.updatingBillProduct.every(currentUpdatingBillProduct => currentUpdatingBillProduct.billProductPrice == this.input.updatingBillProduct[0].billProductPrice)){
          this.input.updatingBillProductPrice = this.input.updatingBillProduct[0].billProductPrice;
        } else {
          this.input.updatingBillProductPrice = 0;
        }
        this.display.updatingBillProductMultipleSelected = true;
      } else {
        this.input.updatingBillProduct = [billProduct];
        this.input.updatingBillProductPrice = billProduct.billProductPrice;
        this.display.updatingBillProductMultipleSelected = false;
      }
      this.focusOnInput('updatingBillProductPriceInputReference');
      this.navigation.updateBillProductPriceDialogIsVisible = true;
    },

    updateBillProductPrice(){
      this.input.updatingBillProductPrice = parseFloat(this.input.updatingBillProductPrice);
      this.input.updatingBillProduct.forEach(updatingBillProduct => {
        updatingBillProduct.billProductPrice = this.input.updatingBillProductPrice;
      });
      this.updateBillProduct();
      this.calculateBillResult();
      this.navigation.updateBillProductPriceDialogIsVisible = false;
    },

    openUpdateBillProductTaxMarginDialog(billProduct){
      if (this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction).find(currentBillProduct => currentBillProduct.billProductProductID == billProduct.billProductProductID)){
        this.input.updatingBillProduct = this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction);
        if (this.input.updatingBillProduct.every(currentUpdatingBillProduct => currentUpdatingBillProduct.billProductTaxMargin == this.input.updatingBillProduct[0].billProductTaxMargin)){
          this.input.updatingBillProductTaxMargin = this.input.updatingBillProduct[0].billProductTaxMargin;
        } else {
          this.input.updatingBillProductTaxMargin = 0;
        }
        this.display.updatingBillProductMultipleSelected = true;
      } else {
        this.input.updatingBillProduct = [billProduct];
        this.input.updatingBillProductTaxMargin = billProduct.billProductTaxMargin;
        this.display.updatingBillProductMultipleSelected = false;
      }
      this.focusOnInput('updatingBillProductTaxMarginInputReference');
      this.navigation.updateBillProductTaxMarginDialogIsVisible = true;      
    },

    updateBillProductTaxMargin(){
      this.input.updatingBillProductTaxMargin = parseFloat(isNaN(parseFloat(this.input.updatingBillProductTaxMargin)) ? 0 : this.input.updatingBillProductTaxMargin);
      this.input.updatingBillProduct.forEach(updatingBillProduct => {
        updatingBillProduct.billProductTaxMargin = this.input.updatingBillProductTaxMargin;
      });
      this.updateBillProduct();
      this.calculateBillResult();
      this.navigation.updateBillProductTaxMarginDialogIsVisible = false;
    },

    openUpdateBillProductDiscountMarginDialog(billProduct){
      if (this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction).find(currentBillProduct => currentBillProduct.billProductProductID == billProduct.billProductProductID)){
        this.input.updatingBillProduct = this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction);
        if (this.input.updatingBillProduct.every(currentUpdatingBillProduct => currentUpdatingBillProduct.billProductDiscountMargin == this.input.updatingBillProduct[0].billProductDiscountMargin)){
          this.input.updatingBillProductDiscountMargin = this.input.updatingBillProduct[0].billProductDiscountMargin;
        } else {
          this.input.updatingBillProductDiscountMargin = 0;
        }
        this.display.updatingBillProductMultipleSelected = true;
      } else {
        this.input.updatingBillProduct = [billProduct];
        this.input.updatingBillProductDiscountMargin = billProduct.billProductDiscountMargin;
        this.display.updatingBillProductMultipleSelected = false;
      }
      this.focusOnInput('updatingBillProductDiscountMarginInputReference');
      this.navigation.updateBillProductDiscountMarginDialogIsVisible = true;
    },

    async validateProductDiscountMarginToken(){
      this.loader.validateProductDiscountMarginToken = true;
      const validateTokenRequestQuery = 
      {
        'tokenType': 'Descuento',
        'tokenValue': this.input.productDiscountMarginToken
      };
      const validateTokenResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/token/functions/validateToken', validateTokenRequestQuery);
      if (validateTokenResult.success){
        this.input.updatingBillProduct.forEach(updatingBillProduct => {
          updatingBillProduct.billProductDiscountMargin = this.input.updatingBillProductDiscountMargin;
        });
        this.updateBillProduct();
        this.calculateBillResult();
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': `El token "${this.input.productDiscountMarginToken}" ha sido validado exitosamente, descuento aplicado`,
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': `El token "${this.input.productDiscountMarginToken}" no es válido, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema`,
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.validateProductDiscountMarginToken = false;
      this.navigation.productDiscountMarginTokenDialogIsVisible = false;
    },

    openProductDiscountMarginTokenDialog(productExcededMaxDiscountMargin){
      this.focusOnInput('productDiscountMarginTokenInputReference');
      this.input.productDiscountMarginToken = null;
      this.navigation.updateBillProductDiscountMarginDialogIsVisible = false;
      this.navigation.productDiscountMarginTokenDialogIsVisible = true;
      this.$root.notificationDialog.showNotificationDialog({
        'notificationDialogTitle': 'ATENCIÓN',
        'notificationDialogBody': 
          `
            El descuento máximo permitido para el producto 
            "${productExcededMaxDiscountMargin.billProductName}" 
            es del 
            ${productExcededMaxDiscountMargin.billProductMaxDiscountMargin}%
            ${this.accessCredential['/bill/maxDiscountMargin'] ? `. Su usuario tiene habilitado un descuento máximo del ${this.accessCredential['/bill/maxDiscountMargin']}%`: ''}.
            Coloque un token de descuento en caso de contar con uno
          `,
        'notificationDialogColor': 'warning',
        'notificationDialogIsPersistent': false
      });
    },

    updateBillProductDiscountMargin(){
      this.input.updatingBillProductDiscountMargin = parseFloat(isNaN(parseFloat(this.input.updatingBillProductDiscountMargin)) ? 0 : this.input.updatingBillProductDiscountMargin);
      const productExcededMaxDiscountMargin = this.input.updatingBillProduct.find(product => this.input.updatingBillProductDiscountMargin > product.billProductMaxDiscountMargin);
      if (productExcededMaxDiscountMargin){
        if (this.accessCredential['/bill/maxDiscountMargin'] < this.input.updatingBillProductDiscountMargin || this.accessCredential['/bill/maxDiscountMargin'] == null){
          this.openProductDiscountMarginTokenDialog(productExcededMaxDiscountMargin);
          return;
        }
      }
      this.input.updatingBillProduct.forEach(updatingBillProduct => {
        updatingBillProduct.billProductDiscountMargin = this.input.updatingBillProductDiscountMargin;
      });
      this.updateBillProduct();
      this.calculateBillResult();
      this.navigation.updateBillProductDiscountMarginDialogIsVisible = false;
    },


    openUpdateBillProductAmountDialog(billProduct){
      if (this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction).find(currentBillProduct => currentBillProduct.billProductProductID == billProduct.billProductProductID)){
        this.input.updatingBillProduct = this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction);
        if (this.input.updatingBillProduct.every(currentUpdatingBillProduct => currentUpdatingBillProduct.billProductAmount == this.input.updatingBillProduct[0].billProductAmount)){
          this.input.updatingBillProductAmount = this.input.updatingBillProduct[0].billProductAmount;
        } else {
          this.input.updatingBillProductAmount = 0;
        }
        this.display.updatingBillProductMultipleSelected = true;
      } else {
        this.input.updatingBillProduct = [billProduct];
        this.input.updatingBillProductAmount = billProduct.billProductAmount;
        this.display.updatingBillProductMultipleSelected = false;
      }
      this.focusOnInput('updatingBillProductAmountInputReference');
      this.navigation.updateBillProductAmountDialogIsVisible = true;
    },

    updateBillProductAmount(){
      this.input.updatingBillProduct.forEach(updatingBillProduct => {
        updatingBillProduct.billProductAmount = parseInt(isNaN(parseInt(this.input.updatingBillProductAmount)) ? 1 : this.input.updatingBillProductAmount);
      });
      this.updateBillProduct();
      this.calculateBillResult();
      this.navigation.updateBillProductAmountDialogIsVisible = false;
    },

    openProductImageDialog(productID, productName){
      const productImageDialogRequestQuery = 
      {
        'productImageProductID': productID,
        'productName': productName
      };
      this.$root.productImageDialog.openProductImageDialog(productImageDialogRequestQuery);
    },

    deleteBillProduct(billProductProductID){
      if (this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction).find(currentBillProduct => currentBillProduct.billProductProductID == billProductProductID)){
        this.display.currentBillProducts = this.display.currentBillProducts.filter(currentBillProduct => !currentBillProduct.billProductSelectedAction);
      } else {
        this.display.currentBillProducts = this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductProductID != billProductProductID);
      }
      this.calculateBillResult();
    },

    openInsertClientDialog(){
      const insertClientDialogRequestQuery = 
      {
        'clientType': 'Persona'
      };
      this.$root.insertClientDialog.openInsertClientDialog(insertClientDialogRequestQuery);
    },

    openClientBillDialog(){
      this.$root.clientBillDialog.openClientBillDialog();
    },

    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
      this.display.dolarExchangeRate = await this.$generalFunctions.default.methods.selectDolarExchangeRate();
    },

    async decodeCallcenterBill(){
      const callcenterBill = this.$route.query.callcenterBill;
      if (callcenterBill){
        const decodedQuery = JSON.parse(decodeURIComponent(callcenterBill));
        const callcenterBillProducts = JSON.parse(decodedQuery.callcenterBillProducts);
        callcenterBillProducts.forEach(callcenterBillProduct => {
          this.appendProductIntoCurrentBillProductsFromCallcenterBill(callcenterBillProduct);
        });

        const paymentTypeReconciliation =
        {
          'Efectivo': 'Efectivo',
          'Tarjeta': 'Tarjeta',
          'SINPE (confirmado)': 'SINPE',
          'SINPE (contra entrega)': 'SINPE',
          'Transferencia': 'Transferencia o depósito',
          'Mixto': 'Mixto'
        };

        this.input.selectedBillPaymentType = paymentTypeReconciliation[decodedQuery.callcenterBillPaymentType];
        this.input.selectedClientSSNType = 'FISICA';
        this.input.clientSSN = decodedQuery.callcenterBillClientSSN;
        this.input.clientName = decodedQuery.callcenterBillClientName;
        this.input.clientEmail = decodedQuery.callcenterBillClientEmail;
        this.input.clientPhoneNumber = decodedQuery.callcenterBillClientPhoneNumber;
        this.input.selectedAgentID = 'Call center';
        this.navigation.callcenterBill = true;
        this.calculateBillResult();
      }
    },

    async selectClientForClientSearch(){
      this.loader.selectClientForClientSearch = true;
      const selectClientForClientSearchResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/client/functions/selectClientForClientSearch');
      if (selectClientForClientSearchResult.success){
        this.display.clientOptions = selectClientForClientSearchResult.result;
        this.loader.selectClientForClientSearch = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de clientes, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async deleteBillPaymentValidation(){
      if (this.navigation.billPaymentValidationID){
        const deleteBillPaymentValidationRequestQuery =
        {
          'billPaymentValidationID': this.navigation.billPaymentValidationID
        };
        await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/billPaymentValidation/delete', deleteBillPaymentValidationRequestQuery);
      }
    },

    async validateSINPE(SINPEID, tokenValue){
      this.loader.selectSINPE = true;
      const validateSINPERequestQuery =
      {
        'SINPEID': SINPEID,
        'tokenValue': tokenValue
      };
      const validateSINPEResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/billPaymentValidation/functions/validateSINPE', validateSINPERequestQuery);
      if (validateSINPEResult.success){
        this.navigation.SINPEDialogIsVisible = false;
        this.generateBill(false);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al validar el SINPE, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectSINPE = false;
    }

  },

  created() {
    window.addEventListener('beforeunload', this.deleteBillPaymentValidation)
    viewMethodCaller.on('InsertClientDialog/methods/insertClient', (params) => {
      this.input.clientSSN = params.clientSSN;
    });
  },

  async mounted(){
    await this.getLocalStorageData();
    await this.selectProductBasicInformationForBillViewByLocalityID();
    this.selectAgentFromBillView();
    this.selectClientForClientSearch();
    await this.decodeCallcenterBill();
    this.printerStatus();
  },

});

</script>
