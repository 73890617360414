// plugins/vuetify.js
import { createVuetify } from 'vuetify'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

const customTheme = {
  dark: false,
  colors: {
    primary: '#343A40',    // Azul Profesional
    secondary: '#34C38F',  // Verde Suave
    background: '#F5F7FA', // Gris Claro
    
    accent: '#F1B44C',     // Amarillo Dorado
    text: '#495057',       // Gris Oscuro
    card: '#FFFFFF',       // Blanco para tarjetas
    border: '#E9ECEF'      // Bordes suaves
  }
}

const darkTheme = {
  dark: true,
  colors: {
    background: '#1E1E2D',  // Fondo principal
    surface: '#26293C',     // Color de las tarjetas (v-card)
    primary: '#4A4F6A',     // Botones e iconos activos
    secondary: '#B0B3C2',   // Texto secundario
    text: '#FFFFFF',        // Texto principal
    accent: '#4A4F6A',      // Bordes y detalles
  }
}

export default createVuetify({
  theme: {
    defaultTheme: 'customTheme',
    themes: {
      customTheme,
      darkTheme
    },
  },
})
